// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert, Button, Paper, Stack, TextField, CircularProgress, Menu, MenuItem, useTheme, useMediaQuery, Typography } from "@mui/material";

// My components imports
import SubscriptionPlansList from "../lists/subscriptionPlansList";
import { getPlans, getProduct, subscribe } from "../../utils/billingApi";
import MyLoadingList from "../basic/myLoadingList";
import UserAvatar from "../basic/userAvatar.js";
import { logoutUser } from "../../utils/authUtils.js";
import { acceptFamilyInvitation, getFamilyInvitations, getUser, leaveFamily, rejectFamilyInvitation } from "../../utils/api.js";
import { isInvitationActive } from "../../utils/family.js";
import ReceivedFamilyInvitationListItem from "../basic/receivedFamilyInvitationListItem.js";
import FamilyMemberListItem from "../basic/familyMemberListItem.js";


// Auth wrapper
function SubscribePage(props) {

  // Props destructuring
  const { checkingSubscription } = props;

  // Constants
  const logoUrl = '/FinanzasUY.svg';
  const videoUrl = '/fondo.mp4';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logoWidth = isMobile ? '350px' : '600px';

  // State variables
  const [anchorElement, setAnchorElement] = useState(null);
  const [promotionalPlans, setPromotionalPlans] = useState(null);
  const [plans, setPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [promotionalCode, setPromotionalCode] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [errorLoadingPlans, setErrorLoadingPlans] = useState(false);
  const [noPromotionalPlansFoundForCode, setNoPromotionalPlansFoundForCode] = useState(false);
  const [familyInvitations, setFamilyInvitations] = useState([]);
  const [errorAcceptingFamilyInvitation, setErrorAcceptingFamilyInvitation] = useState(false);
  const [user, setUser] = useState(null);

  // Auxiliary functions
  const updatePlans = (code = null) => {
    setLoadingPlans(true);
    let promises = [getPlans()];
    if (code) {
      promises.push(getPlans(code));
    }
    Promise.all(promises)
      .then(response => {
        setPlans(response[0].results);
        if (response.length > 1) {
          console.log('Promotional plans', response[1].results);
          setPromotionalPlans(response[1].results);
          if (response[1].results.length > 0) {
            setNoPromotionalPlansFoundForCode(false);
          }
        }
        setLoadingPlans(false);
        setErrorLoadingPlans(false);
      })
      .catch(error => {
        setLoadingPlans(false);
        setErrorLoadingPlans(true);
        console.log(error);
      });
  }

  // Effect hooks
  useEffect(() => {
    // Check if user has an active subscription
    let isMounted = true;
    isMounted && setErrorLoadingPlans(false);
    isMounted && updatePlans();
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    // update user
    let isMounted = true;
    getUser()
      .then(response => {
        isMounted && setUser(response);
      })
      .catch(err => console.log(err))
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    // Check if the user has been invited to a family
    let isMounted = true;
    getFamilyInvitations()
      .then(response => {
        isMounted && setFamilyInvitations(response.results);
      })
      .catch(error => {
        console.log('No invitation found');
        console.log(error);
      });
    return () => { isMounted = false };
  }, [])

  // Handlers
  const handleAvatarClick = event => {
    setAnchorElement(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorElement(null);
  }

  const subscribeHandler = plan => {
    setLoadingPlans(true);
    console.log('Suscribiendo a plan ' + plan.id);
    subscribe(plan)
      .then(response => {
        setLoadingPlans(false);
        console.log(response);
        window.location.href = response.link_de_suscripcion;
      })
      .catch(error => {
        console.log(error);
        setLoadingPlans(false);
        setErrorLoadingPlans(true);
      });
  }

  const handlePromotionalCodeChange = event => {
    // Capitalize user input
    const value = event.target.value.toUpperCase();
    // Remove invalid characters
    const newValue = value.replace(/[^A-Z0-9]/g, '');
    // Set new value
    setPromotionalCode(newValue);
  }

  const handlePromotionalCodeApplication = () => {
    // Assume an error, and clear flag if promotional plans are found
    setNoPromotionalPlansFoundForCode(true);
    updatePlans(promotionalCode);
  }

  const acceptFamilyInvitationHander = invitation => {
    acceptFamilyInvitation(invitation.id)
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        setErrorAcceptingFamilyInvitation(true);
      });
  }

  const rejectFamilyInvitationHander = invitation => {
    rejectFamilyInvitation(invitation.id)
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        window.location.reload();
      });
  }

  const handleFamilyLeave = () => {
    leaveFamily(user.familia.id)
      .then(response => {
        console.log(response);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
        window.location.reload();
      });
  }

  return <Stack>
    <video
      autoPlay
      loop
      muted
      className='video-background'
    >
      <source src={videoUrl} type='video/mp4' />
    </video>

    <Stack alignItems={"flex-end"} sx={{ marginTop: "30px", marginRight: "30px" }}>
      <UserAvatar onClick={handleAvatarClick} />
      <Menu open={Boolean(anchorElement)} anchorEl={anchorElement} onClose={handleMenuClose}>
        <MenuItem sx={{ marginRight: "5px", marginLeft: "5px" }}><Button variant="outlined" color="primary" onClick={logoutUser}>Cerrar sesión</Button></MenuItem>
      </Menu>
    </Stack>

    <Stack spacing={3} alignItems={"center"}>
      <img src={logoUrl} alt='Logo' style={{ width: logoWidth, marginTop: "50px" }} />
      {checkingSubscription ?
        <Stack spacing={1} alignItems="center">
          <Typography variant="body1" color="white" fontWeight="bold">Verificando tu suscripción</Typography>
          <CircularProgress />
        </Stack> : null
      }

      <Stack spacing={0.5} sx={{ padding: "15px", paddingTop: "0" }}>

        {(user && user.familia && !user.familia_administrada) ? <Stack spacing={.5}>
          <Alert severity="warning">
            <Typography fontWeight="bold">Parece que eres miembro de una familia pero el administrador no renovó su suscripción.</Typography>
            <Typography>Puedes contactarte con el administrador de la familia, o puedes abandonar la familia y suscribirte como miembro individual.</Typography>
          </Alert>
          <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: "10px" }}>
            <FamilyMemberListItem member={user} user={user} onLeave={handleFamilyLeave} onExpel={() => { }} />
          </Paper>
        </Stack> :

          <Stack>
            <Stack spacing={1} sx={{ marginBottom: "5px" }}>
              {errorAcceptingFamilyInvitation ?
                <Alert severity="error">Ocurrió un error y no se pudo aceptar la invitación a la familia</Alert> :
                familyInvitations.filter(invitation => {
                  return isInvitationActive(invitation);
                }).map(invitation => {
                  return <Paper sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)", padding: "10px" }}>
                    <ReceivedFamilyInvitationListItem
                      key={invitation.id}
                      invitation={invitation}
                      onAccept={acceptFamilyInvitationHander}
                      onReject={rejectFamilyInvitationHander}
                      userHasActiveSubscription={false} // If we are in this page is because the user has no active subscription
                    />
                  </Paper>
                })}
            </Stack>

            <Stack spacing={0.5}>
              {loadingPlans ? <MyLoadingList /> :
                (errorLoadingPlans ? <Alert severity="error">No se pudo obtener la información de planes</Alert>
                  :
                  <SubscriptionPlansList
                    plansList={plans}
                    promotionalPlansList={promotionalPlans}
                    initialPlan={selectedPlan}
                    onChange={plan => setSelectedPlan(plan)}
                    showPromotionalPlanError={noPromotionalPlansFoundForCode}
                  />
                )
              }

              <Paper sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: "10px" }}>
                <Stack spacing={1} sx={{ backgroundColor: "rgba(255, 255, 255, 0)" }}>
                  <Button variant="contained" color="primary" onClick={event => subscribeHandler(selectedPlan)} disabled={!selectedPlan}>Suscribirme</Button>
                  <TextField
                    label="Código promocional"
                    variant="outlined"
                    value={promotionalCode}
                    onChange={handlePromotionalCodeChange}
                    InputProps={{
                      endAdornment: <Button variant="contained" color="primary" onClick={handlePromotionalCodeApplication} disabled={!promotionalCode}>Aplicar</Button>
                    }}
                    fullWidth
                  />
                </Stack>
              </Paper>
            </Stack>

          </Stack>}

      </Stack>
    </Stack>
  </Stack>

}

SubscribePage.propTypes = {
  checkingSubscription: PropTypes.bool,       // True if subscription is being checked
}

export default SubscribePage;