// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Stack } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import EtiquetaList from "../lists/etiquetaList";
import { urls } from "../../settings.js"
import { getLabels } from "../../utils/api";
import MyPaper from "../basic/myPaper";


function ConfigureLabelsPage() {

  // State constants
  const [etiquetas, setEtiquetas] = useState([]);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLabels()
        .then(response => {
          setEtiquetas(response.results);
        })
        .catch(err => console.log(err));
    }
    return () => { isMounted = false };
  }, [])

  // Handlers
  const handleUpdateList = () => {
    getLabels()
      .then(response => {
        setEtiquetas(response.results);
      })
      .catch(err => console.log(err));
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configurar' },
    ]}
    currentPageName="Etiquetas"
  >
    <Stack direction="column">

      <MyPaper>
        <EtiquetaList
          etiquetas={etiquetas}
          updateList={handleUpdateList}
        />
      </MyPaper>
    </Stack>

  </MyMenuComponent>

}

export default ConfigureLabelsPage;