// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import MovimientoTarjetaListItemCompact from './movimientoTarjetaListItemCompact';
import MovimientoTarjetaListItemClassic from "./movimientoTarjetaListItemClassic";
import { cardMovementPropType } from '../../utils/myPropTypes';

function MovimientoTarjetaListItem(props) {
  return props.compact ? <MovimientoTarjetaListItemCompact {...props} /> : <MovimientoTarjetaListItemClassic {...props} />;
}

MovimientoTarjetaListItem.propTypes = {
  movimientoTarjeta: cardMovementPropType, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete MovimientoTarjeta
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify MovimientoTarjeta
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
  compact: PropTypes.bool, // Indicates if the component should be displayed in compact mode
};

export default MovimientoTarjetaListItem;