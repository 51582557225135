// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function ConfirmationDialog(props) {

  // Constants
  const requiereTextEntry = !(typeof props.expectedText === 'undefined')

  // State constants
  const [textOk, setTextOk] = useState(!requiereTextEntry);
  const [text, setText] = useState('');

  // Change handlers
  const handleTextChange = event => {
    setText(event.target.value);
    if (event.target.value === props.expectedText) {
      setTextOk(true);
    } else {
      setTextOk(false);
    }
  };

  const handleClose = event => {
    setText('');
    setTextOk(!requiereTextEntry);
    props.onClose(event);
  }

  return <Dialog open={props.open} onClose={handleClose}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props.text}
        {
          requiereTextEntry ?
            <div>Para proceder escribe: <strong>{props.expectedText}</strong></div> :
            <></>
        }
      </DialogContentText>
      {
        requiereTextEntry ?
          <TextField
            autoFocus
            value={text}
            onChange={handleTextChange}
            margin="dense"
            id="name"
            label="Escribe para confirmar"
            type="email"
            fullWidth
            variant="standard"
          /> :
          <></>
      }

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="contained" color="primary">{props.cancelButtonText ? props.cancelButtonText : 'Cancelar'}</Button>
      {
        props.dontShowConfirmationButton ?
          <></> :
          <Button onClick={props.onConfirm} disabled={!textOk} color="error" variant="contained">{props.confirmButtonText ? props.confirmButtonText : 'Confirmar'}</Button>
      }
    </DialogActions>
  </Dialog>
}

ConfirmationDialog.propTypes = {
  expectedText: PropTypes.string, // text to acknowledge dialog, if not specified the confirmation dialog does not enforce text entry
  onConfirm: PropTypes.func, // Function to execute when action is confirmed
  text: PropTypes.string.isRequired, // text to show in the dialog
  open: PropTypes.bool.isRequired, // Tells whether the dialog is showing
  onClose: PropTypes.func.isRequired, // Function to execute when dialog is closed
  title: PropTypes.string, // Title to show in the dialog
  cancelButtonText: PropTypes.string, // Text to display in cancel button
  confirmButtonText: PropTypes.string, // Text to display in confirm button
  dontShowConfirmationButton: PropTypes.bool // Indicates whether the confirmation button should be diplayed or not
}

export default ConfirmationDialog;