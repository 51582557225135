// Proptypes imports
import PropTypes from 'prop-types';

// React imports
import { useEffect, useState } from 'react';

// MUI imports
import { Divider, FormControl, Stack, Switch, Typography } from '@mui/material';

// My component imports
import FamilyInvitationListItem from '../basic/familyInvitationListItem';
import SortButtonGroup from '../inputs/sortButtonGroup';


function FamilyInvitationsList(props) {
  // State constants
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [sortedList, setSortedList] = useState(props.invitations);
  const [sortBy, setSortBy] = useState('Fecha'); // ['date', 'alphabetical']
  const [sortAscending, setSortAscending] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSortedList(props.invitations.sort((a, b) => {
        if (sortBy === 'A-Z') {
          if (a.correo < b.correo) {
            return sortAscending ? -1 : 1;
          } else if (a.correo > b.correo) {
            return sortAscending ? 1 : -1;
          } else {
            return 0;
          }
        } else {
          if (a.creada_en < b.creada_en) {
            return sortAscending ? -1 : 1;
          } else if (a.creada_en > b.creada_en) {
            return sortAscending ? 1 : -1;
          } else {
            return 0;
          }
        }
      }).filter(invitation => !showOnlyActive ? true : !invitation.revocada && !invitation.rechazada && !invitation.aceptada))
    }
    return () => { isMounted = false }
  }, [props.invitations, sortBy, sortAscending, showOnlyActive])

  return <Stack>
    <FormControl fullWidth>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'subtitle2'}>Mostrar sólo activas:</Typography>
        <Switch checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} />
      </Stack>
    </FormControl>
    {sortedList.length > 0 ?
      <>
        <SortButtonGroup
          onChange={sortObject => {
            setSortBy(sortObject.sortBy);
            setSortAscending(sortObject.sortAscending);
          }}
          initialSortBy={sortBy}
          initiallySortAscending={sortAscending}
          sortTypes={['Fecha', 'A-Z']}
        />
        <Stack spacing={2} sx={{ paddingTop: "20px" }}>
          {sortedList.map((invitation, index) => {
            return <Stack>
              {
                !invitation.revocada ?
                  <FamilyInvitationListItem invitation={invitation} onRevoke={props.onRevoke} /> :
                  (!showOnlyActive ? <FamilyInvitationListItem invitation={invitation} onRevoke={props.onRevoke} /> : <></>)
              }
              {sortedList.length - 1 !== index ? <Divider /> : <></>}
            </Stack>
          })}
        </Stack>
      </>
      :
      <Typography variant={'caption'}>No hay invitaciones</Typography>
    }

  </Stack >
}

FamilyInvitationsList.propTypes = {
  invitations: PropTypes.array.isRequired, // Array of invitations
  onRevoke: PropTypes.func, // Function to be called when user whats to revoke the invitation
};

export default FamilyInvitationsList;