// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Chip, Stack, Typography, Box, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { getCategoryIcon, getCustodyAgentTypeIcon, parseName } from "../../utils/misc";
import ConfirmationDialog from "./confirmationDialog";
import { movementPropType } from "../../utils/myPropTypes";

function MovimientoListItemClassic(props) {

  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const theme = useTheme();
  const ammountText = props.movimiento ?
    props.movimiento.cuenta.moneda.nombre_corto + ' ' + props.movimiento.monto.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) : '';
  const commentText = props.movimiento ? props.movimiento.comentario : '';
  const acctName = props.movimiento ? props.movimiento.cuenta.nombre : '';
  const categoryName = props.movimiento ? props.movimiento.categoria.nombre : '';
  const icon = getCategoryIcon(props.movimiento ? props.movimiento.categoria.nombre : null, "small");

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const modifyClickHandler = event => {
    props.onModify(props.movimiento);
  }

  const handleDeleteConfirmation = event => {
    event.stopPropagation();
    props.onDelete(props.movimiento)
  }

  // Effect hooks
  useEffect(() => {
    if (props.movimiento) {
      let myLabels = JSON.parse(JSON.stringify(props.movimiento.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
    }
  }, [props.movimiento])

  return <Stack direction="column" onClick={modifyClickHandler} sx={{ padding: "25px", cursor: "pointer" }}>

    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estas seguro que quieres borrar este gasto?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={() => setShowConfirmationDialog(false)}
      onConfirm={handleDeleteConfirmation}
    />

    <Stack direction="column" spacing={1}>

      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        {props.movimiento.movimiento_programado_id &&
          <Chip
            size="medium"
            icon={<SmartToyIcon fontSize="small" />}
            variant="outlined"
          />
        }
        <Chip
          // color="primary"
          label={props.movimiento.fecha}
          size="medium"
          icon={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
        <Chip
          // color="primary"
          label={parseName(acctName)}
          size="medium"
          icon={getCustodyAgentTypeIcon(props.movimiento ? props.movimiento.cuenta.agente_de_custodia.tipo.nombre : null, "small")}
          variant="outlined"
        />
      </Stack>
      <Stack direction="row">
        <Chip
          // color="primary"
          label={categoryName}
          size="medium"
          icon={icon}
          variant="outlined"
        />
      </Stack>
    </Stack>

    <Stack direction="row" spacing={1}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
        <Typography sx={{
          fontWeight: 'bold',
          alignSelf: 'center',
          paddingBottom: '2px',
          fontSize: '1.1rem',
          color: props.movimiento ? (props.movimiento.monto < 0 ? theme.palette.error.main : "green") : "green"
        }}
        >
          {ammountText}
        </Typography>
      </Stack>
      <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
        <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 35 }} />
      </Box>
    </Stack>
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">{commentText}</Typography>
    </Stack>
    <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
      {labelsToShow}
    </Stack>
  </Stack >
}

MovimientoListItemClassic.propTypes = {
  movimiento: movementPropType, // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func, // Function to be called when user whats to modify Movimiento
};

export default MovimientoListItemClassic;