// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Chip, Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import ConfirmationDialog from "./confirmationDialog.js";
import MyDialog from "./MyDialog.js";
import { getAmountText, parseName } from "../../utils/misc.js";

function TransferenciaListItemClassic(props) {

  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isCurrencyExchange, setIsCurrencyExchange] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  // Other constants
  const theme = useTheme();
  const srcAmmountText = getAmountText(props.transferencia.monto_origen, props.transferencia.cuenta_origen.moneda)
  const srcAcctName = props.transferencia.cuenta_origen.nombre;
  const dstAmmountText = getAmountText(props.transferencia.monto_destino, props.transferencia.cuenta_destino.moneda)
  const dstAcctName = props.transferencia.cuenta_destino.nombre;
  const commentText = props.transferencia.comentario;
  const perspective = props.pointOfView ? (props.transferencia.cuenta_origen.id === props.pointOfView.id ? "sourceAccount" : "destinationAccount") : "sourceAccount";
  const infoIcon = <Stack justifyContent="center">
    <MyDialog
      onClose={() => setInfoDialogOpen(false)}
      open={infoDialogOpen}
      onOpen={() => setInfoDialogOpen(true)}
      title="¿Cuál es la otra cuenta?"
    >
      {perspective === "destinationAccount" ?
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Stack justifyContent="center">
            <Typography variant="body2">
              {isCurrencyExchange ? ("El monto fue cambiado por " + srcAmmountText + " que vinieron de la cuenta " + srcAcctName +
                " (" + props.transferencia.cuenta_origen.moneda.nombre_corto + ")")
                :
                ("El monto se transfirió desde la cuenta " + srcAcctName + " (" + props.transferencia.cuenta_origen.moneda.nombre_corto + ")")}
            </Typography>
          </Stack>
        </Stack>
        :
        <Stack spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Stack direction="row" justifyContent="center">
            <Typography variant="body2">
              {isCurrencyExchange ? ("El monto fue cambiado por " + dstAmmountText + " que fueron a la cuenta " + dstAcctName +
                " (" + props.transferencia.cuenta_destino.moneda.nombre_corto + ")")
                :
                ("El monto se transfirió hacia la cuenta " + dstAcctName + " (" + props.transferencia.cuenta_destino.moneda.nombre_corto + ")")}
            </Typography>
          </Stack>

        </Stack>
      }
    </MyDialog>
  </Stack>

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const modifyClickHandler = event => {
    props.onModify(props.transferencia);
  }

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  // Effect hooks
  useEffect(() => {
    if (props.transferencia) {
      let myLabels = JSON.parse(JSON.stringify(props.transferencia.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
      if (props.transferencia.cuenta_origen.moneda.id === props.transferencia.cuenta_destino.moneda.id) {
        setIsCurrencyExchange(false);
      } else {
        setIsCurrencyExchange(true);
      }
    }
  }, [props.transferencia])

  return <Stack direction="column" onClick={modifyClickHandler} sx={{ padding: "25px", cursor: "pointer" }}>
    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estas seguro que quieres borrar esta transferencia?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={handleDeleteCancel}
      onConfirm={() => props.onDelete(props.transferencia)}
    />
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        <Chip
          // color="primary"
          label={props.transferencia.fecha}
          size="medium"
          icon={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
        {perspective === "destinationAccount" ?
          <Chip
            // color="primary"
            label={parseName(dstAcctName)}
            size="medium"
            icon={<AccountBalanceIcon fontSize="small" />}
            variant="outlined"
            deleteIcon={infoIcon}
            onDelete={() => console.log('clicked')}
          /> : <Chip
            // color="primary"
            label={parseName(srcAcctName)}
            size="medium"
            icon={<AccountBalanceIcon fontSize="small" />}
            variant="outlined"
            deleteIcon={infoIcon}
            onDelete={() => console.log('clicked')}
          />
        }
      </Stack>

      <Stack direction="row">
        {isCurrencyExchange ?
          <Chip icon={<CurrencyExchangeIcon fontSize="small" />} label="Cambio" variant="outlined" /> :
          <Chip icon={<SyncAltIcon fontSize="small" />} label="Transferencia" variant="outlined" />
        }
      </Stack>

      {perspective === "destinationAccount" ?
        <Stack direction="row" spacing={1}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: props.transferencia ? (perspective === "destinationAccount" ? theme.palette.success.main : theme.palette.error.main) : theme.palette.success.main
          }}
          >
            {perspective === "destinationAccount" ? dstAmmountText : srcAmmountText}
          </Typography>
          <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
            <DeleteIcon onClick={deleteClickHandler} color="primary" sx={{ fontSize: 35 }} />
          </Box>
        </Stack> :
        <Stack direction="row" spacing={1}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: props.transferencia ? (perspective === "destinationAccount" ? theme.palette.success.main : theme.palette.error.main) : theme.palette.success.main
          }}
          >
            {perspective === "destinationAccount" ? dstAmmountText : srcAmmountText}
          </Typography>
          <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
            <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 35 }} />
          </Box>
        </Stack>
      }

    </Stack>

    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2" color="primary.lightText" sx={{ marginLeft: "3px" }}>{commentText}</Typography>
    </Stack>

    <Stack direction={"row"} flexWrap={"wrap"}>
      {labelsToShow}
    </Stack>
  </Stack >
}

TransferenciaListItemClassic.propTypes = {
  transferencia: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
};

export default TransferenciaListItemClassic;