// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Button, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from '@mui/material/styles';

// My component imports
import { urls } from "../../settings.js";
import MyPaper from "../basic/myPaper.js";
import MyMenuComponent from "./myMenuComponent";
import MyLoadingList from "../basic/myLoadingList.js";
import NewMovementList from "../lists/newMovementList.js";
import { getCurrencies, getExpenses, getCategories, getUserAccounts, getCards, getMovements, getCardMovements } from "../../utils/api.js";
import { dateGetFirstDayOfMonth, dateGetLastDayOfMonth, getDateTimeFromDateString } from "../../utils/date.js";
import FilterMonthlyExpenses from "../filters/filterMonthlyExpenses.js";
import { deepObjectCopy } from "../../utils/misc.js";
import { emptyFilterMonthlyExpenses } from "../../utils/constants.js";
import { useUserProfile } from "../../utils/userProfile.js";
import MyBarChart from "../graphs/myBarchart.js";
import MyDialog from "../basic/MyDialog.js";
import MonedaPicker from "../inputs/monedaPicker.js";

// Auxiliary functions
async function getMixedMovements(movFilter, queryObject) {
  let newQueryObject = deepObjectCopy(queryObject)
  newQueryObject.initialDate = movFilter.initialDate;
  newQueryObject.finalDate = movFilter.finalDate;
  if (movFilter.type === 'expense') {
    newQueryObject.amountLte = 0;
    newQueryObject.filterAmountWithAnd = false;
  } else if (movFilter.type === 'income') {
    newQueryObject.amountGte = 0;
    newQueryObject.filterAmountWithAnd = false;
  } else if (movFilter.type === 'savings') {
    newQueryObject.amountGte = 0;
    newQueryObject.amountLte = 0;
    newQueryObject.filterAmountWithAnd = false;
  }
  const mixedMovements = await Promise.all([getMovements(newQueryObject), getCardMovements(newQueryObject)])
    .then(response => response[0].results.concat(response[1].results))

  return mixedMovements;
}

function constructQueryPromises(queryObject) {
  let promises = [];
  let currentDate = getDateTimeFromDateString(queryObject.initialDate);
  for (let i = 0; i <= queryObject.months; i++) {
    promises.push(getExpenses({
      initialDate: dateGetFirstDayOfMonth(currentDate),
      finalDate: dateGetLastDayOfMonth(currentDate),
      currency: queryObject.currency,
      categories: queryObject.categories,
      labels: queryObject.labels,
      filterLabelsWithAnd: queryObject.filterLabelsWithAnd,
      cashCredit: 'both',
    }));
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  }
  return promises;
}

function constructDateLabelArray(queryObject) {
  let dateLabelArray = [];
  let currentDate = getDateTimeFromDateString(queryObject.initialDate);
  for (let i = 0; i <= queryObject.months; i++) {
    dateLabelArray.push((currentDate.getMonth() + 1) + '/' + currentDate.getFullYear());
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  }
  return dateLabelArray;
}

function constructDataObject(response, dateLabelArray) {
  let gastos = [];
  let ingresos = [];
  let ahorros = [];
  for (let i = 0; i < response.length; i++) {
    gastos.push({
      fecha: dateLabelArray[i],
      gasto: response[i].results.length > 0 ? Math.abs(parseFloat(response[i].results[0].gastos.total).toFixed(2)) : 0
    });
    ingresos.push({
      fecha: dateLabelArray[i],
      ingreso: response[i].results.length > 0 ? Math.abs(parseFloat(response[i].results[0].ingresos.total).toFixed(2)) : 0
    });
    ahorros.push({
      fecha: dateLabelArray[i],
      ahorro: response[i].results.length > 0 ? parseFloat(response[i].results[0].ingresos.total) + parseFloat(response[i].results[0].gastos.total) : 0
    });
  }
  return {
    gastos: gastos,
    ingresos: ingresos,
    ahorros: ahorros
  };
}


function VisualizeMonthlyExpensesPage(props) {

  // constants
  const theme = useTheme();
  const selectOptions = ["Gastos", "Ingresos", "Ahorro"];

  // State constants
  const { profile } = useUserProfile();
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies.find(cur => cur.nombre_corto === "UYU"));
  const [cards, setCards] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const [data, setData] = useState(null);
  const [currentQueryObject, setCurrentQueryObject] = useState(emptyFilterMonthlyExpenses); // Todo: set desired initial state
  const [movementFilter, setMovementFilter] = useState(null);
  const [mixedMovements, setMixedMovements] = useState([]);
  const [loadingMixedMovements, setLoadingMixedMovements] = useState(true);
  const [showMovementList, setShowMovementList] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectedTypeOfGraph, setSelectedTypeOfGraph] = useState("Gastos");
  const [viewGraphValues, setViewGraphValues] = useState(true);
  const [behaviourDialogOpen, setBehaviourDialogOpen] = useState(false);
  const [currencyDiaglOpen, setCurrencyDiaglOpen] = useState(false);

  // Inner auxiliary functions
  const eraseMovementList = () => {
    setLoadingMixedMovements(true);
    setShowMovementList(false);
    setMovementFilter(null);  // This will not trigger the getMixedMovements inside the useEffect because it is null
    setMixedMovements([]);
  }

  const getDataFromApiAndSetStateData = async (queryObject) => {
    let promises = constructQueryPromises(queryObject);
    let dateLabelArray = constructDateLabelArray(queryObject);
    await Promise.all(promises)
      .then(response => {
        const newDataValues = constructDataObject(response, dateLabelArray);
        setData(newDataValues);
      })
      .catch(err => console.error(err))
  }

  // Effect hooks

  // Initialize filter form
  useEffect(() => {
    let isMounted = true;
    Promise.all([getCurrencies(), getCategories(), getUserAccounts(), getCards()])
      .then(responses => {
        if (isMounted) {
          // First filter out Ticket alimentación (UYT) as it is not a valid currency for this report
          setCurrencies(responses[0].results.filter(cur => cur.nombre_corto !== "UYT"));
          setCategories(responses[1].results);
          setAccounts(responses[2].results);
          setCards(responses[3].results);
          setLoadingForm(false);
        }
      })
      .catch(err => console.error(err));
    return () => isMounted = false;
  }, []);

  // Initialize selectedCurrency
  useEffect(() => {
    let isMounted = true;
    if (isMounted && currencies.length > 0) {
      setSelectedCurrency(currencies.find(cur => cur.nombre_corto === "UYU"));
    }
    return () => isMounted = false;
  }, [currencies])

  // Get movements and construct mixedMovements when movementFilter changes
  useEffect(() => {
    let isMounted = true;
    if (isMounted && (movementFilter !== null)) {
      getMixedMovements(movementFilter, currentQueryObject)
        .then(newMixedMovements => {
          setMixedMovements(newMixedMovements);
          setLoadingMixedMovements(false);
        })
        .catch(err => console.error(err))
    }
    return () => isMounted = false;
  }, [movementFilter])

  // Stop loading when data is loaded and ready
  useEffect(() => {
    let isMounted = true;
    if (data && isMounted && ready) {
      setLoading(false);
    }
    return () => isMounted = false;
  }, [data, ready])

  // Handlers
  const handleSubmit = async (queryObject) => {
    setCurrentQueryObject(queryObject);
    setShowForm(false);
    setLoading(true);
    setReady(true);
    getDataFromApiAndSetStateData(queryObject);
  }

  const handleFilterAgain = (event) => {
    setShowForm(true);
    setLoading(false);
    eraseMovementList();
  }

  const handleSelectedCurrencyChange = (cur) => {
    if (cur === null) return; // When user clears the selected currency
    setData(null);
    setSelectedCurrency(cur);
    let newQueryObject = deepObjectCopy(currentQueryObject);
    newQueryObject.currency = cur.id;
    setCurrentQueryObject(newQueryObject);
    getDataFromApiAndSetStateData(newQueryObject);
  }

  const handleSelectedTypeOfGraphChange = (event) => {
    eraseMovementList();
    setSelectedTypeOfGraph(event.target.value);
  }

  const handleViewGraphValuesChange = (event, newValue) => {
    if (newValue === null) return; // When user clicks on the selected button, newValue is null
    setViewGraphValues(newValue);
    if (newValue) { // Showing values instead of movements
      eraseMovementList();
    }
  }

  const handleGraphClick = result => {
    if (!viewGraphValues) {
      setLoadingMixedMovements(true);
      setShowMovementList(true);
      // result.fecha comes in the format "MM/YYYY" or "M/YYYY"
      let currentDate = "01/" + result.fecha;
      // Transform currentDate from format "DD/MM/YYYY" or "DD/M/YYYY" to "YYYY-MM-DD", it is important that the month has always two digits
      currentDate = currentDate.split("/");
      currentDate = currentDate[2] + "-" + (currentDate[1].length === 1 ? "0" + currentDate[1] : currentDate[1]) + "-" + currentDate[0];
      const newFilter = {
        initialDate: dateGetFirstDayOfMonth(getDateTimeFromDateString(currentDate)),
        finalDate: dateGetLastDayOfMonth(getDateTimeFromDateString(currentDate)),
        type: selectedTypeOfGraph === "Gastos" ? "expense" : selectedTypeOfGraph === "Ingresos" ? "income" : "savings"
      }
      setMovementFilter(newFilter);
    }
  }

  const updateListHandler = async () => {
    setLoadingMixedMovements(true);
    const newMixedMovements = await getMixedMovements(movementFilter, currentQueryObject);
    setMixedMovements(newMixedMovements);
  }

  return (
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
        { url: urls.visualizationsUrl, name: 'Analizar' },
      ]}
      currentPageName={'Mensual'}
    >

      {showForm &&
        <MyPaper>
          {
            loadingForm ?
              <MyLoadingList /> :
              <FilterMonthlyExpenses onSubmit={handleSubmit} categories={categories} currencies={currencies} initialQueryObject={currentQueryObject} />
          }
        </MyPaper>
      }

      {
        !showForm &&
        <Stack spacing={1}>

          <MyPaper>
            <Stack spacing={1.5}>

              <Button variant="contained" onClick={handleFilterAgain}>Volver a filtrar</Button>

              {
                loading ?
                  <MyLoadingList /> :
                  <Stack spacing={1}>

                    <Select
                      value={selectedTypeOfGraph}
                      onChange={handleSelectedTypeOfGraphChange}
                      variant="outlined"
                    >
                      {selectOptions.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)}
                    </Select>

                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                      <Stack justifyContent="center">
                        <MyDialog onClose={() => setCurrencyDiaglOpen(false)} open={currencyDiaglOpen} onOpen={() => setCurrencyDiaglOpen(true)}>
                          El reporte considerarará los gastos e ingresos en todas las monedas, pero los convertirá (a cada uno individualmente) a la moneda seleccionada
                          antes de agregarlos para graficarlos.
                          El tipo de cambio utilizado para la conversión será el del día que se efectuó cada gasto o ingreso individual.
                        </MyDialog>
                      </Stack>
                      <MonedaPicker
                        currencies={currencies}
                        onChange={handleSelectedCurrencyChange}
                        initialValues={selectedCurrency}
                        multiple={false}
                      />
                    </Stack>

                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      <MyDialog
                        onClose={() => { setBehaviourDialogOpen(false) }}
                        onOpen={() => { setBehaviourDialogOpen(true) }}
                        open={behaviourDialogOpen}
                      >
                        Modifica el comportamiento de la gráfica. <br /><br />
                        Cuando está seleccionado <strong>"Mostrar valores"</strong>, al hacer click sobre una barra en la gráfica se mostrará el valor correspondiente a dicha barra.<br /><br />
                        Cuando se encuentra seleccionado <strong>"Mostrar movimientos"</strong>, al hacer click sobre una barra se mostrarán los movimientos que componen dicha barra.
                      </MyDialog>
                      <ToggleButtonGroup
                        value={viewGraphValues}
                        exclusive
                        onChange={handleViewGraphValuesChange}
                        sx={{ marginLeft: "8px !important" }}
                        fullWidth
                      >
                        <ToggleButton value={true}><Typography variant="caption">Mostrar valores</Typography></ToggleButton>
                        <ToggleButton value={false} sx={{ padding: "15px" }}><Typography variant="caption">Mostrar movimientos</Typography></ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                    {
                      data === null && !loading && < MyLoadingList />
                    }
                    {
                      data && selectedTypeOfGraph === "Gastos" &&
                      <MyBarChart data={data.gastos} xLegend="fecha" yLegend="gasto" color={theme.palette.error.main} handleGraphClick={handleGraphClick} />
                    }
                    {
                      data && selectedTypeOfGraph === "Ingresos" &&
                      <MyBarChart data={data.ingresos} xLegend="fecha" yLegend="ingreso" color={theme.palette.success.main} handleGraphClick={handleGraphClick} />
                    }
                    {
                      data && selectedTypeOfGraph === "Ahorro" &&
                      <MyBarChart data={data.ahorros} xLegend="fecha" yLegend="ahorro" color={theme.palette.primary.main} handleGraphClick={handleGraphClick} />
                    }
                  </Stack>
              }
            </Stack>
          </MyPaper>

          {
            showMovementList ?
              (
                loadingMixedMovements ?
                  <MyPaper><MyLoadingList /></MyPaper> :
                  <NewMovementList
                    movements={mixedMovements}
                    updateList={updateListHandler}
                    cards={cards}
                    accounts={accounts}
                    categories={categories}
                    compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
                  />
              ) : <></>

          }

        </Stack>

      }



    </MyMenuComponent>
  );
}

export default VisualizeMonthlyExpensesPage;