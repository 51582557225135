// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// My component imports
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import { urls } from "../../settings.js";
import RegisterCurrencyExchange from "./registerCurrencyExchange";
import { deleteTransfer, getUserAccounts } from "../../utils/api.js";
import { accountsOkForCurrencyExchanges } from "../../utils/misc.js";
import AddButton from "../basic/addButton.js";
import TransferenciaListItem from "../basic/transferenciaListItem.js";
import { getDateFromDateTime } from "../../utils/date.js";
import { CurrencyExchangeError } from "../basic/errors.js";

// Auxiliary functions
function updateInitialValues(initValues, newMov, intent) {
  let newInitialValues = JSON.parse(JSON.stringify(initValues));
  newInitialValues.initialTransfer = newMov;
  newInitialValues.intent = intent;
  return newInitialValues;
}

function RegisterCurrencyExchangePage(props) {

  // Constants
  const history = useHistory();
  const emptyTransfer = {
    cuenta_origen: null,
    monto_origen: 0,
    cuenta_detino: null,
    monto_destino: 0,
    etiquetas: [],
    fecha: getDateFromDateTime(new Date()),
    comentario: '',
    id: null,
  }
  const emptyInitialValues = {
    initialValues: {
      initialTransfer: emptyTransfer,
    },
    accounts: [],
    intent: 'create',
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [accounts, setAccounts] = useState([]);
  const [accountsOK, setAccountsOK] = useState(false);
  const [updateAccounts, setUpdateAccounts] = useState(false);
  const [showForm, setShowForm] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    getUserAccounts()
      .then(response => {
        if (isMounted) {
          setAccounts(response.results);
          setAccountsOK(accountsOkForCurrencyExchanges(response.results));
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [updateAccounts]);

  // Handlers
  const handleTransferChange = transf => {
    setUpdateAccounts(prev => !prev);
    setInitialValues(prev => updateInitialValues(prev, transf, 'modify'));
    setShowForm(false);
  }

  const handleNewTransferClick = event => {
    setInitialValues(prev => updateInitialValues(emptyInitialValues, emptyTransfer, 'create'));
    setShowForm(true);
  }

  const handleModifyClick = transfer => {
    setInitialValues(prev => updateInitialValues(prev, transfer, 'modify'));
    setShowForm(true);
  }

  const handleDelete = transfer => {
    let isMounted = true;
    deleteTransfer(transfer.id)
      .then(response => {
        if (isMounted) {
          setInitialValues(emptyInitialValues);
          setShowForm(true);
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.registrationsUrl, name: 'Registrar' },
    ]}
    currentPageName={'Cambio d...'}
  >
    {!accountsOK ?
      <CurrencyExchangeError />
      : showForm ? <MyPaper>
        <RegisterCurrencyExchange
          initialValues={initialValues}
          accounts={accounts}
          onSubmit={handleTransferChange}
          onCancel={() => setShowForm(false)}
        />
      </MyPaper> : <>
        <MyPaper>
          <TransferenciaListItem
            onDelete={handleDelete}
            onModify={handleModifyClick}
            transferencia={initialValues.initialTransfer}
          />
        </MyPaper>
        <AddButton onClick={handleNewTransferClick} />
      </>
    }
  </MyMenuComponent >
}

export default RegisterCurrencyExchangePage;