// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { TableRow, TableCell, Typography } from "@mui/material";

// My components imports
import { getAmountText } from "../../utils/misc.js";
import { accountPropType, currencyPropType } from '../../utils/myPropTypes.js';

function BalanceCuentaListItem(props) {

  // Deconstruct props
  const { account, currency } = props;

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle1" fontWeight='bold'>{account.cuenta.nombre}</Typography>
        <Typography variant="caption">{account.cuenta.tipo_de_cuenta.nombre}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" fontWeight='bold'>{getAmountText(account.balance, currency)}</Typography>
        <Typography variant="caption">{'(' + getAmountText(account.cuenta.balance, account.cuenta.moneda) + ')'}</Typography>
      </TableCell>
    </TableRow>
  )

}

// PropTypes
BalanceCuentaListItem.propTypes = {
  account: PropTypes.shape({
    cuenta: accountPropType.isRequired,
    balance: PropTypes.number.isRequired,
  }).isRequired,
  currency: currencyPropType.isRequired,
}

export default BalanceCuentaListItem;