// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// My component imports
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import { urls } from "../../settings.js";
import RegisterTransfer from "./registerTransfer.js";
import { deleteTransfer, getUserAccounts } from "../../utils/api.js";
import { accountsOkForTransfers } from "../../utils/misc.js";
import TransferenciaListItem from "../basic/transferenciaListItem.js";
import AddButton from "../basic/addButton.js";
import { getDateFromDateTime } from "../../utils/date.js";
import { TransferError } from "../basic/errors.js";

// Auxiliary functions
function updateInitialValues(initValues, newMov, intent) {
  let newInitialValues = JSON.parse(JSON.stringify(initValues));
  newInitialValues.initialTransfer = newMov;
  newInitialValues.intent = intent;
  return newInitialValues;
}

function RegisterTransferPage(props) {

  // Constants
  const history = useHistory();
  const emptyTransfer = {
    cuenta_origen: null,
    monto_origen: 0,
    cuenta_detino: null,
    monto_destino: 0,
    etiquetas: [],
    fecha: getDateFromDateTime(new Date()),
    comentario: '',
    id: null,
  };
  const emptyInitialValues = {
    initialValues: {
      initialTransfer: emptyTransfer,
    },
    intent: 'create',
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [accounts, setAccounts] = useState([]);
  const [accountsOK, setAccountsOK] = useState(false);
  const [showForm, setShowForm] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    getUserAccounts()
      .then(response => {
        if (isMounted) {
          setAccounts(response.results);
          // Check if there is at least two accounts with the same currency
          setAccountsOK(accountsOkForTransfers(response.results));
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [initialValues]);

  // Handlers
  const handleTransferChange = transf => {
    setInitialValues(prev => updateInitialValues(prev, transf, 'modify'));
    setShowForm(false);
  }

  const handleDeleteTransfer = transfer => {
    deleteTransfer(transfer.id)
      .then(response => {
        setInitialValues(prev => updateInitialValues(emptyInitialValues, emptyTransfer, 'create'))
        setShowForm(true);
      })
      .catch(err => console.log(err))
  }

  const handleNewTransferClick = transf => {
    setInitialValues(prev => updateInitialValues(emptyInitialValues, emptyTransfer, 'create'));
    setShowForm(true);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.registrationsUrl, name: 'Registrar' },
    ]}
    currentPageName={'Transf...'}
  >
    {!accountsOK ?
      <TransferError />
      : showForm ? <MyPaper>
        <RegisterTransfer
          initialValues={initialValues}
          accounts={accounts}
          onSubmit={handleTransferChange}
          accountsOK={accountsOK}
          onCancel={() => setShowForm(false)}
        />
      </MyPaper> : <>{initialValues.initialTransfer.cuenta_origen ? <MyPaper>
        <TransferenciaListItem
          onDelete={handleDeleteTransfer}
          onModify={() => setShowForm(true)}
          transferencia={initialValues.initialTransfer}
        /></MyPaper> : <></>}
        <AddButton onClick={handleNewTransferClick} /></>
    }
  </MyMenuComponent>
}

export default RegisterTransferPage;