// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from "prop-types";

// My component imports
import { getCards, getCategories, getAgendas, createMovimientoTarjetaProgramado, modifyMovimientoTarjetaProgramado } from "../../utils/api";
import ScheduledCardMovementForm from "../forms/scheduledCardMovementForm";
import MyLoadingList from "../basic/myLoadingList";

function CreateModifyScheduledCardMovement(props) {

    // Deconstructing props
    const initialScheduledMovement = props.initialValues.initialScheduledMovement;
    const { onSubmit, onCancel } = props;

    // State constants
    const [cards, setCards] = useState([]);
    const [categories, setCategories] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [formIsReady, setFormIsReady] = useState(false);

    // Effect hooks
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const promises = [getCards(), getCategories(), getAgendas()];
            Promise.all(promises)
                .then(response => {
                    setCards(response[0].results);
                    setCategories(response[1].results);
                    setAgendas(response[2].results);
                })
                .catch(err => console.error('Error getting data for scheduled movement form:', err));
        }
        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (cards.length > 0 && categories.length > 0 && agendas.length > 0) {
                setFormIsReady(true);
            }
        }
        return () => { isMounted = false };
    }, [cards, categories, agendas]);

    // Handlers
    const handleFormSubmit = (movement) => {
        console.log('Scheduled movement to be submitted:', movement);
        const body = {
            agenda_id: movement.agenda.id,
            tarjeta_id: movement.tarjeta.id,
            categoria_id: movement.categoria.id,
            moneda_id: movement.moneda.id,
            monto: movement.monto,
            cantidad_de_cuotas: movement.cantidad_de_cuotas,
            comentario: movement.comentario,
            etiquetas_ids: movement.etiquetas.map(etiq => etiq.id),
        };
        const promise = props.initialValues.intent === "create" ? createMovimientoTarjetaProgramado(body) : modifyMovimientoTarjetaProgramado(movement.id, body);
        promise.then(result => {
            console.log('Scheduled movement created/modified:', result);
            onSubmit(result);
        }).catch(err => console.error('Error creating/modifying scheduled movement:', err));
    }

    return formIsReady ?
        <ScheduledCardMovementForm
            agendas={agendas}
            cards={cards}
            categories={categories}
            initialMovement={initialScheduledMovement}
            onSubmit={handleFormSubmit}
            onCancel={onCancel}
            intent={props.initialValues.intent}
            isExpense={initialScheduledMovement.monto <= 0}
        /> :
        <MyLoadingList />
}

CreateModifyScheduledCardMovement.propTypes = {
    initialValues: PropTypes.shape({
        initalScheduledMovement: PropTypes.object.isRequired, // Todo: Define the shape of this object
        intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing account
    }).isRequired,
    onSubmit: PropTypes.func.isRequired, // Function to be called when an scheduled movement is submitted
    onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the operation
};

export default CreateModifyScheduledCardMovement;