// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// AWS imports
import { Auth } from 'aws-amplify';

// My components imports
import { RequestPasswordResetForm, PasswordResetForm } from "./forms/cognitoForms.js";


function PasswordResetComponent(props) {

  const [codeSent, setCodeSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const handleSendCodeClick = email => {
    // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then(data => {
        console.log(data);
        setEmail(email);
        setCodeSent(true);
      })
      .catch(err => console.log(err));
  };

  const handlePasswordReset = (code, newPassword) => {
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(data => {
        console.log(data);
        setSuccess(true);
      })
      .catch(err => console.log(err));
  };

  return codeSent ?
    <PasswordResetForm onClick={handlePasswordReset} success={success} /> :
    <RequestPasswordResetForm onClick={handleSendCodeClick} />
}

PasswordResetComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired
}

export default PasswordResetComponent;