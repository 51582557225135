// React imports
import { useEffect, useState } from 'react';

// MUI imports
import { Chip, Stack, Typography, Box, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { getAmountText, getCategoryIcon, parseName } from '../../utils/misc';
import { generateAgendaPeriodicityText } from './agendaListItem';
import ConfirmationDialog from './confirmationDialog';
import { scheduledCardMovementPropType } from '../../utils/myPropTypes';

function MovimientoTarjetaProgramadoListItemClassic({ movimiento, onDelete, onModify, showInstallment }) {
  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const theme = useTheme();
  const amount = showInstallment ? movimiento.monto : movimiento.monto * movimiento.cantidad_de_cuotas;
  const amountText = getAmountText(amount, movimiento.moneda);
  const commentText = movimiento.comentario;
  const installmentsText = movimiento.cantidad_de_cuotas > 1
    ? showInstallment
      ? `(cuota ${movimiento.numero_de_cuota}/${movimiento.cantidad_de_cuotas})`
      : `(en ${movimiento.cantidad_de_cuotas} cuotas)`
    : '';
  const cardName = movimiento.tarjeta.nombre;
  const icon = getCategoryIcon(movimiento.categoria.nombre, "small");

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  const modifyClickHandler = event => {
    event.stopPropagation();
    onModify(movimiento);
  };

  const handleDeleteConfirmation = event => {
    event.stopPropagation();
    onDelete(movimiento);
  };

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  };

  // Effect hooks
  useEffect(() => {
    if (movimiento) {
      let myLabels = JSON.parse(JSON.stringify(movimiento.etiquetas));
      let labelsStack = myLabels.map(label => (
        <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      ));
      setLabelsToShow(labelsStack);
    }
  }, [movimiento]);

  return <Stack
    direction="column"
    spacing={1}
    sx={{ cursor: "pointer", backgroundColor: theme.palette.primary.main, borderRadius: "5px", padding: "3px" }}
    onClick={modifyClickHandler}
  >
    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estás seguro que quieres borrar este movimiento programado?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={handleDeleteCancel}
      onConfirm={handleDeleteConfirmation}
    />
    <Stack direction="column" alignItems={"flex-start"} sx={{ padding: '3px' }}>
      <Stack direction={"row"} spacing={1}>
        <EditCalendarIcon color="primary" sx={{ fontSize: 15, color: "white" }} />
        <Typography variant="caption" fontWeight={"bold"}>{movimiento.agenda.nombre.toUpperCase()}</Typography>
      </Stack>
      {generateAgendaPeriodicityText(movimiento.agenda)}
    </Stack>

    <Stack direction="column" spacing={1} justifyContent={"center"} alignItems={"flex-start"} sx={{ backgroundColor: "white", borderRadius: "3px", padding: "3px" }}>
      <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"}>
        <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
          <Chip
            label={parseName(cardName)}
            size="medium"
            icon={<CreditCardIcon fontSize="small" />}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row">
          <Chip
            label={movimiento.categoria.nombre}
            size="medium"
            icon={icon}
            variant="outlined"
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1} justifyContent={"space-between"} sx={{ width: "100%" }}>
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: movimiento ? (movimiento.monto < 0 ? theme.palette.error.main : "green") : "green"
          }}
          >
            {amountText}
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
          <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 35 }} />
        </Box>
      </Stack>

      <Stack direction="row" spacing={1} sx={{ marginBottom: "5px" }}>
        <Typography variant="subtitle2">{commentText}</Typography>
      </Stack>

      <div sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {labelsToShow}
      </div>

    </Stack>
  </Stack>
}

MovimientoTarjetaProgramadoListItemClassic.propTypes = {
  movimiento: scheduledCardMovementPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
};

export default MovimientoTarjetaProgramadoListItemClassic;
