// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material';

function MyDialog(props) {

  // Handlers
  const handleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    props.onOpen();
  }

  const handleClose = event => {
    event.stopPropagation();
    event.preventDefault();
    props.onClose();
  }

  return <>
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle>{props.title ? props.title : "¿Para qué sirve esto?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
    </Dialog>
    <InfoIcon color="primary" fontSize={props.iconSize ? props.iconSize : "medium"} onClick={handleClick} />
  </>
}

MyDialog.propTypes = {
  onClose: PropTypes.func.isRequired, // Function to be called when user try to close the dialog
  onOpen: PropTypes.func.isRequired, // Function to be called when user try to open the dialog
  open: PropTypes.bool.isRequired, // Value that determines whether dialog should be visible or not
  title: PropTypes.string, // Optional, string to be displayed as dialog title
  iconSize: PropTypes.string, // Optional, size of the icon
}

export default MyDialog;