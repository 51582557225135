// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function TarjetaDeCredito() {
  return <Typography variant="body1">
    Dentro de los <TextChipLink label="lugares de custodia" url={urls.configureCustodyAgentUrl} /> del tipo <strong>banco</strong> o <strong>corredor de bolsa</strong>, podrás agregar tus <strong>tarjetas de crédito</strong>.

    <br /><br />

    Tener tus tarjetas de crédito modeladas en la App te permitirá registrar las compras que realices con ellas.

    <br /><br />

    A cada una de tus tarjetas de crédito deberás cofigurarle la fecha de cierre. Utilizando este dato, <strong>FinanzasUY</strong> calculará el saldo de cada tarjeta en cada ciclo de facturación y generará automáticamente los estados de cuenta correspondientes.
  </Typography>
}

export default TarjetaDeCredito;