// MUI imports
import { Typography } from "@mui/material";

function Introduccion() {
  return <Typography variant="body1">
    En <strong>FinanzasUY</strong>, nos dedicamos a transformar la gestión de tus finanzas familiares, dándote las herramientas para administrarlas con la misma eficacia que una empresa, pero de forma simple y accesible. Tu solo debes registrar tu actividad financiera, y nosotros nos encargamos del resto.
    Nuestro sistema de registros está diseñado para que puedas registrar cada transacción en el momento que la realizas, en menos 15 segundos, devolviéndote tu tiempo y evitándote interminables horas en planillas Excel.
    <br /><br />
    A medida que registres tu actividad financiera, <strong>FinanzasUY</strong> te proporcionará toda la información necesaria para gestionar tus finanzas, facilitándote la toma de decisiones informadas sobre tu economía familiar.
    <br /><br />
    <strong>FinanzasUY</strong> es desarrollada por y para Uruguayos, por lo que todos los aspectos de la aplicación están adaptados a nuestra realidad. Por ejemplo, podrás reflejar tus cuentas en unidades indexadas, o analizar la evolución de tus ingrsos y gastos en términos reales, es decir, descontando la inflación.
    <br /><br />
    Bienvenido a <strong>FinanzasUY</strong>, tu aliado para gestionar tus finanzas familiares de forma simple y profesional. ¡Comencemos!
  </Typography>
}

export default Introduccion;