// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import CurrencyExchangeForm from "../forms/currencyExchangeForm.js";
import { createTransfer, modifyTransfer } from "../../utils/api.js";

function RegisterTransfer(props) {

  // Change handlers
  const handleRegisterTransfer = data => {
    const labelsIds = data.etiquetas.map(label => label.id);
    if (props.initialValues.intent === 'create') {
      createTransfer(data.cuenta_origen.id, -Math.abs(data.monto_origen), data.cuenta_destino.id, Math.abs(data.monto_destino), data.fecha, data.comentario, labelsIds)
        .then(response => {
          props.onSubmit(response);
        })
        .catch(err => console.log(err));
    } else {
      modifyTransfer(data.id, data.cuenta_origen.id, -Math.abs(data.monto_origen), data.cuenta_destino.id, Math.abs(data.monto_destino), data.fecha, data.comentario, labelsIds)
        .then(response => {
          props.onSubmit(response);
        })
        .catch(err => console.log(err));
    }
  }

  return <CurrencyExchangeForm
    accounts={props.accounts}
    onSubmit={handleRegisterTransfer}
    onCancel={() => props.onCancel()}
    initialTransfer={props.initialValues.initialTransfer}
    intent={props.initialValues.intent}
  />

}

RegisterTransfer.propTypes = {
  initialValues: PropTypes.shape({
    initialTransfer: PropTypes.shape({
      cuenta_origen: PropTypes.object, // Value to initializa cuenta_origen
      monto_origen: PropTypes.number, // Value to initialize monto_origen
      cuenta_detino: PropTypes.object, // Value to initialize cuenta_destino
      monto_destino: PropTypes.number, // Value to initialize monto_destino
      etiquetas: PropTypes.arrayOf(PropTypes.object), // Value to initialize labels
      fecha: PropTypes.instanceOf(Date), // Value to initialize date
      comentario: PropTypes.string, // Value to initialize comment
      id: PropTypes.number, // Id of initial transfer 
    }),
    intent: PropTypes.oneOf(['create', 'modify']).isRequired, // Value that determines if we are creating or modifying a MovimientoTarjeta
  }), // values to initialize data inputs
  accounts: PropTypes.arrayOf(PropTypes.object), // List containing all available accounts
  onSubmit: PropTypes.func.isRequired, // Optional function to be called when user clicks on submit button
  onCancel: PropTypes.func.isRequired, // Function to be called when the user cancels
}

export default RegisterTransfer;