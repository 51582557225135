// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, FormControl, ButtonGroup, Button, Switch, Typography, Collapse } from "@mui/material";

// My components imports
import LabelInput from "../inputs/labelInput";
import CuentaPicker from "../inputs/cuentaPicker";
import TarjetaPicker from "../inputs/tarjetaPicker.js";
import CategoriaPicker from "../inputs/categoriaPicker";
import EasyDateRangePicker from "../inputs/easyDateRangePicker";
import MyPaper from "../basic/myPaper.js";
import MyDialog from "../basic/MyDialog.js";
import ExpenseIncomeTransferPicker from "../inputs/expenseIncomeTransferPicker.js";
import CommentInput from "../inputs/commentInput.js";


function FilterMovements(props) {

  // Deconstruct props
  const {
    initialValues,
    hideAccountSelector = false,
    hideCardSelector = false,
    hideCashCreditSelector = false,
    dateHelperValues = ["hoy", "ayer", "antesDeAyer", "inicioDeMes"],
    searchOnFilterChange = false,
  } = props;

  // State constants
  const [initialDate, setInitialDate] = useState(initialValues.initialDate);
  const [finalDate, setFinalDate] = useState(initialValues.finalDate);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [cashCredit, setCashCredit] = useState(initialValues.cashCredit);
  const [movementTypes, setMovementTypes] = useState(initialValues.movementTypes);
  const [accounts, setAccounts] = useState(initialValues.accounts);
  const [cards, setCards] = useState(initialValues.cards);
  const [categories, setCategories] = useState(initialValues.categories);
  const [labels, setLabels] = useState(initialValues.labels);
  const [filterLabelsWithAnd, setFilterLabelsWithAnd] = useState(initialValues.filterLabelsWithAnd);
  const [commentContains, setCommentContains] = useState(initialValues.commentContains);
  const [labelsDialogOpen, setLabelsDialogOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (cashCredit === 'credit') {
        setMovementTypes(
          movementTypes
            .filter(item => item !== 'transfers')
            .filter(item => item !== 'exchanges')
            .filter(item => item !== 'cardPayments')
        );
      } else {
        setMovementTypes(movementTypes);
      }
    }
    return () => { isMounted = false };
  }, [cashCredit]);

  useEffect(() => {
    if (searchOnFilterChange) {
      handleSearchClick();
    }
  }, [initialDate, finalDate, cashCredit, movementTypes, accounts, cards, categories, labels, filterLabelsWithAnd, commentContains]);

  // Handlers
  const handleDateRangeChange = (initDate, finDate) => {
    setInitialDate(initDate);
    setFinalDate(finDate);
  }

  const handleSearchClick = () => {
    let objToReturn = {
      initialDate: initialDate,
      finalDate: finalDate,
      cashCredit: cashCredit,
      movementTypes: movementTypes,
      accounts: accounts,
      cards: cards,
      categories: categories,
      labels: labels,
      filterLabelsWithAnd: filterLabelsWithAnd,
      commentContains: commentContains,
    }
    props.onSearchStarted(objToReturn);
  };

  return <MyPaper>
    <Stack direction="column" spacing={1}>

      <FormControl fullWidth>
        <EasyDateRangePicker
          initialLabel="Fecha inicial"
          finalLabel="Fecha final"
          initialValues={{ initialDate: initialDate, finalDate: finalDate }}
          strict={false}
          helperValues={dateHelperValues}
          onChange={handleDateRangeChange}
        />
      </FormControl>

      <Button variant="outlined" onClick={() => setShowAdvancedFilters(prev => !prev)} fullWidth>
        {showAdvancedFilters ? 'Ocultar filtros avanzados' : 'Mostrar filtros avanzados'}
      </Button>

      <Collapse in={showAdvancedFilters}>
        <Stack spacing={1}>
          {hideCashCreditSelector ? <></> :
            <FormControl fullWidth sx={{ marginBottom: '15px' }}>
              <ButtonGroup size="small">
                <Button
                  variant={cashCredit === 'cash' ? "contained" : "outlined"}
                  onClick={() => setCashCredit('cash')}
                  size="medium"
                  sx={{ display: "flex", flexGrow: 1, width: "33%" }}
                >Contado</Button>
                <Button
                  variant={cashCredit === 'credit' ? "contained" : "outlined"}
                  onClick={() => setCashCredit('credit')}
                  size="medium"
                  sx={{ display: "flex", flexGrow: 1, width: "33%" }}
                >Crédito</Button>
                <Button
                  variant={cashCredit === 'both' ? "contained" : "outlined"}
                  onClick={() => setCashCredit('both')}
                  size="medium"
                  sx={{ display: "flex", flexGrow: 1, width: "33%" }}
                >Ambos</Button>
              </ButtonGroup>
            </FormControl>
          }

          <FormControl fullWidth>
            <ExpenseIncomeTransferPicker
              initialValues={movementTypes}
              onChange={values => setMovementTypes(values)}
              includeTransfers={cashCredit === 'credit' ? false : true}
              includeCardPayments={cashCredit === 'credit' ? false : true}
              multiple={true}
            />
          </FormControl>

          {hideAccountSelector ? <></> : ((cashCredit === 'both' || cashCredit === 'cash') ?
            <FormControl fullWidth>
              <CuentaPicker
                accounts={props.accounts}
                onChange={selectedAccounts => setAccounts(selectedAccounts)}
                initialValues={accounts}
                multiple
              />
            </FormControl> :
            <></>)
          }

          {hideCardSelector ? <></> : ((cashCredit === 'both' || cashCredit === 'credit') ?
            <FormControl fullWidth>
              <TarjetaPicker
                cards={props.cards}
                onChange={selectedCards => setCards(selectedCards)}
                initialValues={cards}
              />
            </FormControl> :
            <></>)
          }

          <FormControl fullWidth>
            <Stack direction="row" spacing={1}>
              <Stack justifyContent="center">
                <MyDialog onClose={() => setCategoryDialogOpen(false)} open={categoryDialogOpen} onOpen={() => setCategoryDialogOpen(true)} title={"Información importante"}>
                  El filtro de categoría se le aplica únicamente a los ingresos y gastos. No tiene ningún efecto sobre transferencias, cambios de moneda y pagos de tarjeta.
                </MyDialog>
              </Stack>
              <CategoriaPicker
                categories={props.categories}
                onChange={selectedCategories => setCategories(selectedCategories)}
                initialValues={categories}
              />
            </Stack>
          </FormControl>

          <FormControl fullWidth>
            <LabelInput
              initialLabels={labels}
              onChange={(selectedLabels) => setLabels(selectedLabels)}
              hideAddButton
            />
          </FormControl>

          <FormControl fullWidth>
            <Stack direction="row" spacing={1}>
              <Stack justifyContent="center">
                <MyDialog onClose={() => setLabelsDialogOpen(false)} open={labelsDialogOpen} onOpen={() => setLabelsDialogOpen(true)}>
                  Al activar este switch la búsqueda incluirá únicamente aquellos movimientos
                  que tengan todas las etiquetas seleccionadas a la vez
                </MyDialog>
              </Stack>
              <Stack justifyContent="center" flexGrow={1}>
                <Typography>Debe tener todas las etiquetas</Typography>
              </Stack>
              <Stack justifyContent="center">
                <Switch checked={filterLabelsWithAnd} onChange={() => setFilterLabelsWithAnd(prev => !prev)} />
              </Stack>
            </Stack>
          </FormControl>

          <FormControl fullWidth>
            <CommentInput
              initialComment={commentContains}
              onChange={comment => setCommentContains(comment)}
              label={"El comentario contiene..."}
            />
          </FormControl>
        </Stack>

      </Collapse>

      {searchOnFilterChange ? <></> :
        <Button variant="contained" onClick={handleSearchClick} fullWidth disabled={movementTypes.length === 0} >Buscar</Button>
      }

    </Stack>
  </MyPaper>

}

FilterMovements.propTypes = {
  initialValues: PropTypes.object, // Values to initialize filter
  onSearchStarted: PropTypes.func.isRequired, // Function to be called when user click to start searching
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of avialable accounts
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available cards
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available categories
  hideAccountSelector: PropTypes.bool, // If true, account selector will be hidden. False if not specified
  hideCardSelector: PropTypes.bool, // If true, card selector will be hidden. False if not specified
  hideCashCreditSelector: PropTypes.bool, // If true, cash/credit selector will be hidden. False if not specified
  dateHelperValues: PropTypes.arrayOf(PropTypes.string), // Values to be shown in date helper
  searchOnFilterChange: PropTypes.bool, // If true, search will be started when filter changes. False if not specified
}

export default FilterMovements;