// MUI imports
import { Typography } from "@mui/material";

function Etiquetas() {
  return <Typography variant="body1">
    Puedes utilizar etiquetas para clasificar todo tipo de actividad en <strong>FinanzasUY</strong>. Por ejemplo, puedes utilizarlas para clasificar tus gastos e ingresos, para clasificar transferencias, compras de moneda, etc.
    <br /><br />
    Las etiquetas son opcionales y las puedes utilizar o no según tu preferencia. Las etiquetas son una forma de clasificación adicional a las categorías. Puedes pensarlas como categorías personalizadas que te permitiran filtrar y sacar reportes de acuerdo a criterios arbitrarios que tu definas.
    <br /><br />
    Un mismo movimiento, por ejemplo un gasto o un ingreso, puede tener asignadas tantas etiquetas como quieras.
  </Typography>
}

export default Etiquetas;