// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

function AccountHeader(props) {

  // Constants
  const theme = useTheme();
  const custodyAgent = props.cuenta ? (props.cuenta.agente_de_custodia.tipo.nombre + ': ' + props.cuenta.agente_de_custodia.nombre) : '';
  const acctTypeText = props.cuenta ? props.cuenta.tipo_de_cuenta.nombre : '';
  const acctNameText = props.cuenta ? props.cuenta.nombre : '';
  const balanceText = props.cuenta ?
    props.cuenta.moneda.nombre_corto + ' ' + props.cuenta.balance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) : '';

  return <Stack spacing={1} direction="column" onClick={() => props.onClick(props.cuenta)}>
    <Stack direction="row" spacing={1}>
      <Typography variant="h6">{custodyAgent}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">{"Tipo de instrumento: " + acctTypeText}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">{"Nombre: " + acctNameText}</Typography>
    </Stack>
    <Stack direction="row">
      <Typography
        sx={{
          fontWeight: 'bold',
          // paddingBottom: '2px',
          // fontSize: '1.1rem',
          color: props.cuenta ? (props.cuenta.balance < 0 ? theme.palette.error.main : "green") : "green"
        }}
        variant="h5"
      >
        {balanceText}
      </Typography>
    </Stack>

  </Stack>
}

AccountHeader.propTypes = {
  cuenta: PropTypes.object.isRequired, // Cuenta to display
  onClick: PropTypes.func.isRequired, // Function to be called when user clicks on Header
}

export default AccountHeader;