// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function FoldUnfoldButton(props) {

  // State constants
  const [folded, setFolded] = useState(props.startFolded ? props.startFolded : false);

  // Handlers
  const handleClick = () => {
    setFolded(prev => !prev);
    props.onClick();
  }

  return <Button endIcon={folded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />} variant="contained" onClick={handleClick} fullWidth>
    {folded ? (props.foldedText ? props.foldedText : "Más") : (props.unFoldedText ? props.unFoldedText : "Menos")}
  </Button>
}

FoldUnfoldButton.propTypes = {
  onClick: PropTypes.func.isRequired, // Function to be called when user clicks the button
  foldedText: PropTypes.string, // Text to display when folded
  unFoldedText: PropTypes.string, // Text to display when unfolded
  startFolded: PropTypes.bool,  // Initial state
}

export default FoldUnfoldButton;