// React imports 
import { useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Snackbar, Alert, Typography, Stack } from '@mui/material';

// My components imports
import AgendaForm from "../forms/agendaForm";
import { agendaPropType } from '../../utils/myPropTypes';
import { createAgenda, modifyAgenda } from '../../utils/api';
import MyLoadingList from '../basic/myLoadingList';

function CreateModifyAgenda({ initialData, onSubmit, onCancel }) {

  // State constants
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [fechaDeInicioError, setFechaDeInicioError] = useState(false);
  const [fechaDeFinError, setFechaDeFinError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [currentData, setCurrentData] = useState(initialData);

  // Auxiliary functions
  const cleanErrors = () => {
    setNameError(false);
    setFechaDeInicioError(false);
    setFechaDeFinError(false);
  }

  // Handlers
  const handleSubmmit = async (data) => {
    setLoading(true);
    cleanErrors();
    setCurrentData(data);
    try {
      setErrorText('');
      console.log('Creando agenda', data);
      let response = null;
      if (initialData.id) {
        response = await modifyAgenda(initialData.id, data);
        console.log('agenda modificada', response);
      } else {
        response = await createAgenda(data);
        console.log('agenda creada', response);
      }

      setLoading(false);
      onSubmit(response);
    } catch (error) {
      console.error('Error al crear agenda', error.text);
      if (error.text === 'name') {
        setNameError(true);
        setErrorText('El nombre ya existe.');
      }
      if (error.text === 'fecha de inicio') {
        setFechaDeInicioError(true);
        setErrorText('La fecha de inicio debe de ser en el futuro');
      }
      if (error.text === 'fecha de fin') {
        setFechaDeFinError(true);
        setErrorText('La fecha de fin debe de ser posterior a la fecha de inicio');
      }
      setShowError(true);
      setLoading(false);
    }
  }

  const handleCancel = () => {
    console.log('Cancelado');
    onCancel();
  }

  return loading ?
    <MyLoadingList /> :
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error" onClose={() => setShowError(false)}>
          <Stack>
            <Typography variant="button" fontWeight="bold">Error al crear la agenda.</Typography>
            <Typography variant="body1">{errorText}</Typography>
          </Stack>
        </Alert>
      </Snackbar>
      <AgendaForm
        initialData={currentData}
        onSubmit={handleSubmmit}
        onCancel={handleCancel}
        nameError={nameError}
        fechaDeInicioError={fechaDeInicioError}
        fechaDeFinError={fechaDeFinError}
      />
    </>
}

CreateModifyAgenda.propTypes = {
  initialData: agendaPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default CreateModifyAgenda;