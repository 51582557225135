function applyAmountFilters(query, movementTypes) {
  if (movementTypes.includes('income') && movementTypes.includes('expenses')) {
    query.amountGte = 0;
    query.amountLte = 0;
    query.filterAmountWithAnd = false;
  } else if (movementTypes.includes('income')) {
    query.amountGte = 0;
    query.filterAmountWithAnd = false;
  } else if (movementTypes.includes('expenses')) {
    query.amountLte = 0;
    query.filterAmountWithAnd = false;
  } else {
    query.amountGte = 0;
    query.amountLte = 0;
    query.filterAmountWithAnd = false;
  }
  return query;
}


export function getMovementsQuery(searchObject) {

  // Deconstruct searchObject
  const {
    initialDate,
    finalDate,
    cashCredit,
    movementTypes,
    accounts,
    categories,
    labels,
    filterLabelsWithAnd,
    commentContains
  } = searchObject;

  if (cashCredit === "credit") {
    throw new Error('getMovementsQuery: cashCredit cannot be credit');
  }

  if (!(movementTypes.includes('income') || movementTypes.includes('expenses'))) {
    throw new Error('getMovementsQuery: movementTypes must include income or expenses');
  }

  let query = {
    initialDate: initialDate,
    finalDate: finalDate,
    accounts: accounts,
    categories: categories,
    labels: labels,
    filterLabelsWithAnd: filterLabelsWithAnd,
    commentContains: commentContains,
  };

  query = applyAmountFilters(query, movementTypes);

  return query;
}

export function getCardMovementsQuery(searchObject) {

  // Deconstruct searchObject
  const {
    initialDate,
    finalDate,
    cashCredit,
    movementTypes,
    cards,
    categories,
    labels,
    filterLabelsWithAnd,
    commentContains
  } = searchObject;

  if (cashCredit === "cash") {
    throw new Error('getCardMovementsQuery: cashCredit cannot be cash');
  }

  if (!(movementTypes.includes('income') || movementTypes.includes('expenses'))) {
    throw new Error('getCardMovementsQuery: movementTypes must include income or expenses');
  }

  let query = {
    initialDate: initialDate,
    finalDate: finalDate,
    cards: cards,
    categories: categories,
    labels: labels,
    filterLabelsWithAnd: filterLabelsWithAnd,
    commentContains: commentContains,
  };

  query = applyAmountFilters(query, movementTypes);

  return query;
}

function getTransfersExchangeQuery(searchObject) {

  // Deconstruct searchObject
  const {
    initialDate,
    finalDate,
    cashCredit,
    accounts,
    labels,
    filterLabelsWithAnd,
    commentContains
  } = searchObject;

  if (cashCredit === "credit") {
    throw new Error('getTransfersQuery: cashCredit cannot be credit');
  }

  let query = {
    initialDate: initialDate,
    finalDate: finalDate,
    accounts: accounts,
    labels: labels,
    filterLabelsWithAnd: filterLabelsWithAnd,
    commentContains: commentContains,
  };

  return query;
}

export function getTransfersQuery(searchObject) {
  if (!searchObject.movementTypes.includes('transfers')) {
    throw new Error('getTransfersQuery: movementTypes must include transfers');
  }
  let query = getTransfersExchangeQuery(searchObject);
  query.onlyTransfers = true;
  return query;
}

export function getExchangesQuery(searchObject) {
  if (!searchObject.movementTypes.includes('exchanges')) {
    throw new Error('getExchangesQuery: movementTypes must include exchanges');
  }
  let query = getTransfersExchangeQuery(searchObject);
  query.onlyCurrencyExchanges = true;
  return query;
}

export function getCardPaymentsQuery(searchObject) {

  // Deconstruct searchObject
  const {
    initialDate,
    finalDate,
    cashCredit,
    movementTypes,
    cards,
    accounts,
    labels,
    filterLabelsWithAnd,
    commentContains
  } = searchObject;

  if (!movementTypes.includes('cardPayments')) {
    throw new Error('getCardPaymentsQuery: movementTypes must include cardPayments');
  }

  if (cashCredit === "credit") {
    throw new Error('getCardPaymentsQuery: cashCredit cannot be credit');
  }

  let query = {
    initialDate: initialDate,
    finalDate: finalDate,
    cards: cards,
    accounts: accounts,
    labels: labels,
    filterLabelsWithAnd: filterLabelsWithAnd,
    commentContains: commentContains,
  };

  return query;

}