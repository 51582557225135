// React imports
import { useState } from "react";

// Proptypes imports
import PropTypes from "prop-types";

// MUI imports
import { FormControl, TextField, Button, Stack } from "@mui/material";

function SupportForm(props) {

  // State constants
  const [subject, setSubject] = useState(props.initialSubject);
  const [message, setMessage] = useState(props.initialMessage);

  // Handlers
  const handleSubmit = () => {
    props.onSubmit(subject, message);
  }

  return <FormControl fullWidth>
    <Stack spacing={1}>
      <TextField label="Asunto" value={subject} onChange={e => setSubject(e.target.value)} />
      <TextField label="Mensaje" multiline={true} rows={4} value={message} onChange={e => setMessage(e.target.value)} />
      <Button variant="contained" color="primary" onClick={handleSubmit}>Enviar</Button>
    </Stack>
  </FormControl>
}

SupportForm.propTypes = {
  initialSubject: PropTypes.string,
  initialMessage: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default SupportForm;

