// MUI components imports
import { Typography, Chip } from "@mui/material";

// My components imports
import { urls } from "../../settings.js";
import TextChipLink from "../basic/textChipLink.js";
// import registerExpenseImage from "./images/registar_gasto.png";


function RegistroDeGastosEIngresos() {

  return <Typography variant="body1">
    Una vez que hayas cargado tus <TextChipLink label="lugares de custodia" url={urls.configureCustodyAgentUrl} /> y dentro de ellos tus <strong>cuentas</strong> y <strong>tarjetas de crédito</strong>, podrás comenzar a registrar tus gastos e ingresos.
    <br /><br />
    El objetivo es que cada vez que recibas dinero, <TextChipLink label="registres un ingreso" url={urls.registerIncomeUrl} />, y cada vez que gastes dinero,
    <TextChipLink label="registres un gasto" url={urls.registerExpenseUrl} />.
    <br /><br />

    Tanto los ingresos como los gastos pueden registrarse al contado o a crédito; esto se selecciona con la pestaña que se encuentra en la parte superior de la pantalla.
    <br /><br />

    Para poder registrar gastos o ingresos al contado, debes tener al menos una cuenta creada, ya que al registrarlos deberás ingresar la cuenta desde donde sacaste el dinero para efectuar el pago en el caso de los gastos, o la cuenta donde recibiste el dinero en el caso de los ingresos.
    <br /><br />

    Para poder registrar gastos o ingresos a crédito, debes tener al menos una tarjeta de crédito creada, ya que al registrarlos deberás ingresar la tarjeta de crédito con la que efectuaste el pago en el caso de los gastos, o la tarjeta de crédito donde recibiste el dinero en el caso de los ingresos.
    <br /><br />

    El formulario de registro de gastos e ingresos te pedirá categorizar el gasto o ingreso, utilizando una de las categorías existentes en la aplicación. <br />
    Adicionalmente podrás agregar etiquetas y una descripción.
  </Typography>
}

export default RegistroDeGastosEIngresos;