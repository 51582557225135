// Style imports
import { useTheme } from '@mui/material/styles';

// Prptotypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// Material UI imports
import { Stack, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from './confirmationDialog';

function FamilyMemberListItem(props) {

  // Other constants
  const theme = useTheme();

  // State constants
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogExpectedText, setDialogExpectedText] = useState('');

  // Handlers
  const leaveClickHandler = (event, member) => {
    event.stopPropagation();
    setDialogText('¿Está seguro que desea abandonar la familia?');
    setDialogTitle('Abandonar familia');
    setDialogExpectedText('Abandonar');
    setDialogOpen(true);
  }

  const expelClickHandler = (event, family, member) => {
    event.stopPropagation();
    setDialogText('¿Está seguro que desea expulsar a ' + member.nombre + ' ' + member.apellido + ' de la familia?');
    setDialogTitle('Expulsar de familia');
    setDialogExpectedText('Expulsar');
    setDialogOpen(true);
  }


  return <Stack key={props.member.id} spacing={1} sx={{ marginTop: "10px" }}>
    <ConfirmationDialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => {
        setDialogOpen(false);
        if (dialogTitle === 'Abandonar familia') {
          props.onLeave(props.user.familia);
        } else if (dialogTitle === 'Expulsar de familia') {
          props.onExpel(props.user.familia, props.member);
        }
      }}
      title={dialogTitle}
      text={dialogText}
      expectedText={dialogExpectedText}
    />
    <Stack direction={'row'} justifyContent={'flex-start'} spacing={2}>
      <Stack spacing={0} justifyContent={'center'}>
        {props.member.familia_administrada ?
          <FamilyRestroomIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
          :
          <PersonIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
        }
      </Stack>
      <Stack spacing={0}>
        {props.hideFamilyName ? <></> :
          <Stack direction="row" spacing={0.5}>
            <Typography variant="subtitle1">Familia:</Typography>
            <Typography variant="subtitle1" fontWeight="bold">{props.member.familia ? props.member.familia.nombre : ""}</Typography>
          </Stack>
        }

        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant='subtitle1'>Miembro:</Typography>
          <Typography variant='subtitle1' fontWeight="bold">{props.member.nombre + ' ' + props.member.apellido}</Typography>
          <Typography variant='caption'>{props.member.familia_administrada ? '(administrador)' : ''}</Typography>
        </Stack>
        <Typography variant={'caption'}>{"Correo: " + props.member.correo}</Typography>
      </Stack>

    </Stack>
    <Stack spacing={0} justifyContent={'center'}>
      {props.member.familia_administrada ?
        // This is the admin element
        <></> :
        // This is not the admin element
        (props.user.familia_administrada ?
          // This is the admin user
          <Button variant="contained" color="error" onClick={(event) => expelClickHandler(event, props.user.familia, props.member)}>Expulsar</Button> :
          // <DeleteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} onClick={(event) => expelClickHandler(event, props.user.familia, props.member)} /> :
          // This is not the admin user
          (props.user.id === props.member.id ?
            // This is the user itself
            <Button variant="contained" color="error" onClick={(event) => leaveClickHandler(event, props.member)}>Abandonar familia</Button> :
            // <DeleteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} onClick={(event) => leaveClickHandler(event, props.member)} /> :
            // This is not the user itself
            <></>
          )
        )
      }
    </Stack>
  </Stack>
}

FamilyMemberListItem.propTypes = {
  member: PropTypes.object.isRequired, // Family member
  user: PropTypes.object.isRequired, // User logged in
  onLeave: PropTypes.func.isRequired, // Handler for leave button
  onExpel: PropTypes.func.isRequired, // Handler for expel button
  hideFamilyName: PropTypes.bool // Hide family name, default false
}

export default FamilyMemberListItem;