// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import ConfirmationDialog from "./confirmationDialog";

function TarjetaListItem(props) {

  // Effect hooks
  useEffect(() => {
    if (props.tarjeta && props.tarjeta.agente_de_custodia) {
      setCustodyAgentNameText(props.tarjeta.agente_de_custodia.tipo.nombre + ": " + props.tarjeta.agente_de_custodia.nombre);
      setCardNameText("Tarjeta: " + props.tarjeta.nombre);
      setCardNumberText(props.tarjeta.numero);
    }
  }, [props.tarjeta]);

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [custodyAgentNameText, setCustodyAgentNameText] = useState('');
  const [cardNameText, setCardNameText] = useState('');
  const [cardNumberText, setCardNumberText] = useState('');

  // Other constants
  const icon = <CreditCardIcon fontSize="large" color="primary" />;

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    props.onDelete(props.tarjeta);
  }
  const modifyClickHandler = event => {
    event.stopPropagation();
    props.onModify(props.tarjeta);
  }

  return <Stack direction="row" onClick={modifyClickHandler} sx={{ padding: "25px" }}>
    <ConfirmationDialog
      title='Borrar tarjeta de crédito'
      text={'¿Estas seguro de que quieres borrar la tarjeta?'}
      open={showConfirmationDialog}
      cancelButtonText='Cancelar'
      confirmButtonText='Borrar'
      onClose={event => setShowConfirmationDialog(false)}
      onConfirm={() => props.onDelete(props.tarjeta)}
      expectedText='estoy seguro'
    />
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      {icon}
    </Box>

    <Stack direction="column" spacing={0} sx={{ marginLeft: "10px" }}>
      <Typography variant="subtitle1">
        {custodyAgentNameText}
      </Typography>
      <Typography variant="subtitle1">
        {cardNameText}
      </Typography>
      <Typography variant="subtitle1">
        {cardNumberText}
      </Typography>
    </Stack>
    <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
      <DeleteIcon onClick={deleteClickHandler} color="primary" sx={{ fontSize: 35 }} />
    </Box>
  </Stack >
}

TarjetaListItem.propTypes = {
  tarjeta: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user confirms that wants to delete the Account
  onModify: PropTypes.func, // Function to be called when user whats to modify Account
};

export default TarjetaListItem;