// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function LugaresDeCustodiaYCuentas() {
  return <Typography variant="body1">
    Los <TextChipLink label="lugares de custodia" url={urls.configureCustodyAgentUrl} /> representan a las instituciones o lugares físicos que custodian nuestro dinero. En <strong>FinanzasUY</strong>, un lugar de custodia puede ser un banco, un corredor de bolsa o un lugar físico como nuestra casa o nuestra billetera.

    <br /><br />

    Dentro de los <TextChipLink label="lugares de custodia" url={urls.configureCustodyAgentUrl} />, el dinero se almacena en cuentas de diferentes tipos. Por ejemplo, un banco podrá tener cuentas del tipo <strong>caja de ahorro</strong>, <strong>cuenta corriente</strong> o <strong>plazo fijo</strong>. Mientras que tu casa o tu billetera, tendrán una cuenta del tipo <strong>efectivo</strong>.

    <br /><br />
    Además, cada <strong>cuenta</strong> tendrá una <strong>moneda</strong> asociada.
    Por ejemplo, dentro de tu billetera podras tener una cuenta de efectivo en dólares y otra cuenta de efectivo en pesos.

  </Typography>
}

export default LugaresDeCustodiaYCuentas;