// React imports
import React, { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from "prop-types";

// ChartJS imports
import { Chart } from "chart.js/auto"
import { Stack, Typography } from "@mui/material";


function MyChart(props) {

  // Refs
  const chartRef = React.createRef(null);

  // State constants
  const [thereIsData, setThereIsData] = useState(props.data ? props.data.labels.length > 0 : false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setThereIsData(props.data ? props.data.labels.length > 0 : false);
    }
    return () => { isMounted = false }
  }, [props.data])


  // Effect hooks
  useEffect(() => {
    if (thereIsData) {
      new Chart(chartRef.current.getContext("2d"), {
        type: props.chartType,
        data: props.data,
        options: {
          //Customize chart options
          events: ['click', 'touchstart'],
          onClick: event => {
            if (event.chart._active[0]) {
              let index = event.chart._active[0].index;
              let dataSetIndex = event.chart._active[0].datasetIndex;
              props.onClick({ success: true, index: index, dataSetIndex: dataSetIndex });
            } else {
              props.onClick({ success: false, index: null, dataSetIndex: null });
            }
          },
          plugins: {
            title: {
              display: props.title !== undefined,
              text: props.title,
            },
          }
        }
      });
    }
  }, [props.data])

  return (thereIsData ?
    <canvas id={props.id} ref={chartRef} /> :
    <Stack direction={'column'} alignItems={'center'}>
      <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
        {props.emptyDataText ? props.emptyDataText : 'No hay datos para mostrar'}
      </Typography>
    </Stack>)
}


MyChart.propTypes = {
  id: PropTypes.string.isRequired, // Unique id for the chart
  chartType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      backgroundColor: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string, // Optional
  emptyDataText: PropTypes.string, // Optional, default: 'No hay datos para mostrar'
}

export default MyChart;


