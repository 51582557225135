// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

// My component imports
import LabelInput from '../inputs/labelInput.js';
import EasyDatePicker from '../inputs/easyDatePicker.js';
import CuentaPicker from '../inputs/cuentaPicker.js';
import { getButtonText } from "../../utils/misc.js";
import { apiDateParser } from "../../utils/date.js";
import MontoInput from "../inputs/montoInput.js";
import CategoriaPicker from "../inputs/categoriaPicker.js";
import { Stack, Typography } from "@mui/material";
import themes from "../../styles/themes.js";

function MovementForm(props) {

  // State constants
  const [selectedAcct, setSelectedAcct] = useState(props.initialMovement.cuenta ? props.initialMovement.cuenta : props.accounts[0]);
  const [labels, setLabels] = useState(props.initialMovement.etiquetas);
  const [selectedCategory, setSelectedCategory] = useState(
    props.initialMovement.categoria ?
      props.initialMovement.categoria :
      props.categories.filter(cat => cat.nombre === 'Supermercado')[0]); // Supermercado is supposed to be the most frequently used category
  const [date, setDate] = useState(props.initialMovement.fecha);
  const [ammount, setAmmount] = useState(props.initialMovement.monto);
  const [comment, setComment] = useState(props.initialMovement.comentario);
  const [formIsReady, setFormIsReady] = useState(false);
  const [hintText, setHintText] = useState('');

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (selectedAcct && selectedCategory && date && isMounted) {
      setFormIsReady(true);
    } else {
      setFormIsReady(false);
    }
    return () => { isMounted = false }
  }, [selectedAcct, selectedCategory, date, ammount]);

  useEffect(() => {
    let isMounted = true;
    let icompleteFields = [];
    if (!selectedAcct) {
      icompleteFields.push('la cuenta');
    }
    if (!selectedCategory) {
      icompleteFields.push('la categoría');
    }
    if (!date) {
      icompleteFields.push('la fecha');
    }
    if (isMounted) {
      // Give user feedback on mandatory fields, add a 'y' before the last one, only if there are more than one. 
      // At last add a recommendation to add labes aswell, only if there are no labels
      if (icompleteFields.length > 0) {
        let hint = 'Antes de registrar el movimiento por favor completa ';
        if (icompleteFields.length > 1) {
          hint += icompleteFields.slice(0, -1).join(', ') + ' y ' + icompleteFields.slice(-1);
        } else {
          hint += icompleteFields[0];
        }
        if (labels.length === 0) {
          hint += '. También te recomendamos agregar etiquetas para poder filtrar tus movimientos más fácilmente.';
        } else {
          hint += '.';
        }
        setHintText(hint);
      } else {
        setHintText('');
      }
    }
    return () => { isMounted = false }
  }, [selectedAcct, selectedCategory, date, labels]);

  // Change handlers
  const handleCommentChange = event => setComment(event.target.value);

  const modifyMovementHandler = event => {
    props.onSubmit({
      id: props.initialMovement ? props.initialMovement.id : 0,
      cuenta: selectedAcct,
      monto: ammount,
      fecha: date,
      categoria: selectedCategory,
      comentario: comment,
      etiquetas: labels,
    });
  };

  return <Stack spacing={1}>


    <FormControl fullWidth>
      <EasyDatePicker
        helperValues={['hoy', 'ayer', 'antesDeAyer']}
        onChange={newDate => setDate(newDate)}
        initialValue={date}
      />
    </FormControl>

    <FormControl fullWidth>
      <CuentaPicker
        initialValues={selectedAcct}
        onChange={newValue => setSelectedAcct(newValue)}
        accounts={props.accounts}
        multiple={false}
      />
    </FormControl>

    <FormControl fullWidth>
      <CategoriaPicker
        categories={props.categories}
        initialValues={selectedCategory}
        onChange={newVal => setSelectedCategory(newVal)}
        multiple={false}
      />
    </FormControl>

    <FormControl fullWidth>
      <MontoInput
        ammount={ammount}
        onChange={value => setAmmount(value)}
        label="Monto"
        isExpense={props.isExpense}
        allowSwitching={props.allowSwitching}
      />
    </FormControl>

    <FormControl fullWidth>
      <LabelInput
        initialLabels={props.initialMovement.etiquetas}
        onChange={(selectedLabels) => setLabels(selectedLabels)}
      />
    </FormControl>

    <FormControl fullWidth>
      <TextField
        id="comentario"
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        type="text"
        label="Comentario"
      />
    </FormControl>

    {formIsReady ?
      <Button variant="contained" onClick={modifyMovementHandler} fullWidth>
        {getButtonText(props.intent, props.isExpense)}
      </Button> :
      <Stack>
        <Typography variant="caption" sx={{ color: themes.primary.palette.primary.cancel, padding: '5px' }}>{hintText}</Typography>
      </Stack>
    }

    <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>
      Cancelar
    </Button>
  </Stack>

}

MovementForm.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available accounts, this variable must be initialized before rendering this component
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available categories, this variable must be initialized before rendering this component
  initialMovement: PropTypes.object, // Object to initialize Transfer
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user submit the Transfer
  onCancel: PropTypes.func.isRequired, // Function to be called when the user cancel the operation
  intent: PropTypes.string.isRequired, // Indicates whether we are creating a transfer or modificating one
  isExpense: PropTypes.bool.isRequired, // Indicates whether the user in registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default MovementForm;