// React imports
import { useEffect, useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import PaletteIcon from '@mui/icons-material/Palette';
import BoltIcon from '@mui/icons-material/Bolt';
import TocIcon from '@mui/icons-material/Toc';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// My components imports
import { getUser, getUserProfile, modifyProfile } from "../../utils/api.js";
import MyMenuComponent from "./myMenuComponent.js";
import themes from "../../styles/themes.js";
import UserAvatar from '../basic/userAvatar.js';
import { useUserProfile } from '../../utils/userProfile.js';

function UserProfile(props) {

  // Deconstructing props
  const { changeProfile } = props;

  // State constants
  const { profile, setProfile } = useUserProfile();
  const [user, setUser] = useState(null);
  const [rollbackTheme, setRollbackTheme] = useState(themes.primary);
  const [selectedTheme, setSelectedTheme] = useState(profile.tema ? profile.tema : "ValidProfiles.primary");
  const [rollbackTableFormat, setRollbackTableFormat] = useState("ValidListTypes.compact");
  const [selectedTableFormat, setSelectedTableFormat] = useState(profile.tipo_de_lista ? profile.tipo_de_lista : "ValidListTypes.compact");
  const [ready, setReady] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showThemeOptions, setShowThemeOptions] = useState(false);
  const [showTableFormatOptions, setShowTableFormatOptions] = useState(false);

  useEffect(() => {
    let isMounted = true;
    getUserProfile()
      .then(prof => isMounted && setProfile(prof))
      .catch(err => console.log(err));
    getUser()
      .then(user => isMounted && setUser(user))
      .catch(err => console.log(err));
    return () => isMounted = false;
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && ready) {
      modifyProfile({
        tema: selectedTheme.split('.')[1],
        tipo_de_lista: selectedTableFormat.split('.')[1]
      })
        .then(result => changeProfile(result))
        .catch(err => {
          console.log(err);
          setSelectedTheme(rollbackTheme);
          setSelectedTableFormat(rollbackTableFormat);
          setShowErrorMessage(true);
        });
    }
    return () => isMounted = false;
  }, [selectedTheme, selectedTableFormat]);

  const handleThemeChange = event => {
    setRollbackTheme(selectedTheme);
    setSelectedTheme(event.target.value);
    setReady(true);
  }

  const handleTableFormatChange = event => {
    setRollbackTableFormat(selectedTableFormat);
    setSelectedTableFormat(event.target.value);
    setReady(true);
  }

  const handleClickOnTheme = () => {
    setShowThemeOptions(!showThemeOptions);
    // Only hide table options if theme options are shown
    if (!showThemeOptions) {
      setShowTableFormatOptions(false);
    };
  }

  const handleClickOnTableFormat = () => {
    setShowTableFormatOptions(!showTableFormatOptions);
    // Only hide theme options if table options are shown
    if (!showTableFormatOptions) {
      setShowThemeOptions(false);
    }
  }


  return <MyMenuComponent
    links={[]}
  >
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showErrorMessage}
      onClose={() => setShowErrorMessage(false)}
      autoHideDuration={3000}
    >
      <Alert severity="error" sx={{ width: '100%' }}>"Error actualizando preferencias"</Alert>
    </Snackbar>

    <Stack spacing={1}>
      <Paper elevation={1} sx={{ padding: "15px", width: "90vw", maxWidth: "600px" }}>
        <List>

          <ListItem>
            <Stack alignItems={"center"} flexGrow={1}>
              <ListItemAvatar>
                <UserAvatar size={"medium"} />
              </ListItemAvatar>
            </Stack>
          </ListItem>

          <ListItem sx={{ marginBottom: "25px" }}>
            <Stack alignItems={"center"} flexGrow={1}>
              <Typography variant={"h6"}>{user ? user.nombre : "N/A"}</Typography>
              <Typography variant={"caption"}>{user ? user.correo : "N/A"}</Typography>
            </Stack>
          </ListItem>
        </List>
      </Paper>

      <Paper elevation={1} sx={{ padding: "15px", width: "90vw", maxWidth: "600px" }}>
        <List>

          <ListItem onClick={handleClickOnTheme} sx={{ cursor: "pointer" }}>
            <ListItemAvatar>
              <Avatar>
                <PaletteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Temas" secondary="Elije el tema que mas te guste" />
          </ListItem>

          <Collapse in={showThemeOptions} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              <ListItem
                key={1}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Radio
                    checked={selectedTheme === 'ValidProfiles.primary' ? true : false}
                    onChange={handleThemeChange}
                    value="ValidProfiles.primary"
                    name="theme"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              >
                <ListItemIcon>
                  <Avatar>
                    <WbSunnyIcon style={{ color: themes.primary.palette.primary.main }} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Primario" style={{ color: themes.primary.palette.primary.main }} />
              </ListItem>
              <ListItem
                key={2}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Radio
                    checked={selectedTheme === 'ValidProfiles.dark' ? true : false}
                    onChange={handleThemeChange}
                    value="ValidProfiles.dark"
                    name="theme"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              >
                <ListItemIcon>
                  <Avatar>
                    <Brightness2Icon style={{ color: themes.dark.palette.primary.main }} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Oscuro" style={{ color: themes.dark.palette.primary.main }} />
              </ListItem>
              <ListItem
                key={3}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Radio
                    checked={selectedTheme === 'ValidProfiles.pink' ? true : false}
                    onChange={handleThemeChange}
                    value="ValidProfiles.pink"
                    name="theme"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              >
                <ListItemIcon>
                  <Avatar>
                    <BoltIcon style={{ color: themes.pink.palette.primary.main }} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Pink" style={{ color: themes.pink.palette.primary.main }} />
              </ListItem>
            </List>
          </Collapse>


          <Divider sx={{ marginTop: "10px" }} />

          <ListItem onClick={handleClickOnTableFormat} sx={{ cursor: "pointer" }}>
            <ListItemAvatar>
              <Avatar>
                <TableRowsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Tablas" secondary="Selecciona tu formato de tablas preferido" />
          </ListItem>

          <Collapse in={showTableFormatOptions} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>

              <ListItem
                key={'ValidListTypes.compact'}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Radio
                    checked={selectedTableFormat === 'ValidListTypes.compact' ? true : false}
                    onChange={handleTableFormatChange}
                    value="ValidListTypes.compact"
                    name="theme"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              >
                <ListItemIcon>
                  <Avatar>
                    <TocIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Compacto" />
              </ListItem>

              <ListItem
                key={'ValidListTypes.classic'}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Radio
                    checked={selectedTableFormat === 'ValidListTypes.classic' ? true : false}
                    onChange={handleTableFormatChange}
                    value="ValidListTypes.classic"
                    name="theme"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                }
              >
                <ListItemIcon>
                  <Avatar>
                    <TableRowsIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Clásico" />
              </ListItem>

            </List>
          </Collapse>

        </List>
      </Paper>

    </Stack>


  </MyMenuComponent >

}

UserProfile.propTypes = {
  changeProfile: PropTypes.func.isRequired // Function to change profile
}

export default UserProfile;