// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, Stack, Typography } from "@mui/material";

// My component imports
import { ErrorBoundary } from '../../utils/system.js';
import { getObjectListItem } from '../../utils/visualizations.js';


function DisplayMovementList(props) {

  return <ErrorBoundary>
    <Stack spacing={1} direction="column" divider={<Divider flexItem />}>
      {props.movementList && props.movementList.length > 0 ?
        props.movementList.map((movement, index) => {
          if (!props.showInstallment && movement.numero_de_cuota && movement.numero_de_cuota !== 1) {
            // If I don't need to show installments and this is not the first installment, show nothing
            return null
          } else {
            return getObjectListItem(
              movement,
              {
                key: index,
                onModify: () => props.onModify(movement),
                onDelete: () => props.onDelete(movement),
                showInstallment: props.showInstallment ? props.showInstallment : false,
                pointOfView: props.pointOfView,
                compact: props.compact ? props.compact : false,
              })
          }
        })
        :
        <Stack alignItems="center">
          <Typography variant="body2">No hay movimentos para mostrar</Typography>
        </Stack>
      }
    </Stack>
  </ErrorBoundary>
}

DisplayMovementList.propTypes = {
  movementList: PropTypes.arrayOf(PropTypes.object).isRequired, // List of movements to display
  onDelete: PropTypes.func.isRequired, // Function to be called when a movement is to be deleted
  onModify: PropTypes.func.isRequired, // Function to be called when the user clicks to modify the momvement
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be shown on MovimientoTarjeta
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
  compact: PropTypes.bool, // If true, show compact version of the list, otherwise show classic version. Default is false
}

export default DisplayMovementList;