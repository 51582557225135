// Proptypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// My component imports
import FamilyForm from "../forms/familyForm";
import { emptyFamily } from "../../utils/constants";
import { createFamily, modifyFamily } from '../../utils/api';

function CreateModifyFamily(props) {

  // Change handlers
  const handleSubmit = family => {
    if (props.initialValues.intent === 'create') {
      createFamily(family.nombre)
        .then(response => {
          console.log(response);
          props.onSubmit(response);
        })
        .catch(err => console.log(err))
    } else if (props.initialValues.intent === 'modify') {
      modifyFamily(family.nombre, family.id)
        .then(response => {
          console.log(response);
          props.onSubmit(response);
        })
        .catch(err => console.log(err))
    }
  }

  return <FamilyForm
    onSubmit={handleSubmit}
    onCancel={props.onCancel}
    family={props.initialValues.initialFamily}
    intent={props.initialValues.intent}
  />
}

CreateModifyFamily.propTypes = {
  initialValues: PropTypes.shape({
    initialFamily: PropTypes.object,
    intent: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateModifyFamily;