// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField } from "@mui/material";

// Auxiliary functions
function pushCountry(country, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === country.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(country);
  }
  return list;
}

function PaisPicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [selectedCountries, setSelectedCountries] = useState(props.selectedCountries ? props.selectedCountries : []);
  const [selectedCountry, setSelectedCountry] = useState(props.selectedCountries ? props.selectedCountries : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedCountries);
      } else {
        props.onChange(selectedCountry);
      }
    }
  }, [selectedCountries, selectedCountry, ready])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="paisId"
    options={props.countries.sort((a, b) => a.nombre === 'Uruguay' ? -1 : b.nombre === 'Uruguay' ? 1 : 0)}
    getOptionLabel={country => {
      if (country !== null && country.nombre !== undefined) {
        return country.nombre;
      } else {
        return null;
      }
    }}
    value={multiple ? selectedCountries : selectedCountry}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));
        const lastItem = myNewList.pop();
        // if lastItem is undefined set selectedCountries to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedCountries([]);
          setReady(true);
          return null;
        };
        // User is trying to add an existing country, make sure the country isn't already selected
        setSelectedCountries(pushCountry(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedCountry(newValue);
        setReady(true);
      }
    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((country, index) => (
        <Chip
          label={country.nombre}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Paises" : "País"}
      placeholder={multiple ? "Selecciona los paises" : "Selecciona el país"}
    />
    }
  />
}

PaisPicker.propTypes = {
  selectedCountries: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  countries: PropTypes.arrayOf(PropTypes.object).isRequired, // All available countries
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
}

export default PaisPicker;