// React imports
import { useState } from "react";

// Proptypes imports
import PropTypes from "prop-types";

// MUI imports
import { ToggleButtonGroup, ToggleButton, Stack } from "@mui/material";

// My components imports
import MyPaper from "../basic/myPaper.js";
import CreateModifyScheduledMovement from "./createModifyScheduledMovement.js";
import CreateModifyScheduledCardMovement from "./createModifyScheduledCardMovement.js";
import { emptyScheduledMovement, emptyScheduledCardMovement } from "../../utils/constants.js";


function RegisterScheduledExpense({ onSubmit, onCancel }) {

  // Other constants
  const initialValuesForScheduledMovement = { initialScheduledMovement: emptyScheduledMovement, intent: 'create' };
  const initialValuesForScheduledCardMovement = { initialScheduledMovement: emptyScheduledCardMovement, intent: 'create' };

  // State constants
  const [typeOfPayment, setTypeOfPayment] = useState("cash"); // Possible options are "cash" or "credit"

  // Handlers
  const handleTypeOfPaymentChange = (event, newTypeOfPayment) => {
    if (newTypeOfPayment !== null) {
      setTypeOfPayment(newTypeOfPayment);
    }
  }

  return <MyPaper>
    <Stack spacing={1}>
      <ToggleButtonGroup
        color="primary"
        value={typeOfPayment}
        onChange={handleTypeOfPaymentChange}
        exclusive
        sx={{ display: "flex", flewGrow: 1, flexDirection: "row" }}
      >
        <ToggleButton value="cash" sx={{ width: "50%" }}>Contado</ToggleButton>
        <ToggleButton value="credit" sx={{ width: "50%" }}>Crédito</ToggleButton>
      </ToggleButtonGroup>
      {typeOfPayment === "cash" ?
        <CreateModifyScheduledMovement initialValues={initialValuesForScheduledMovement} onSubmit={onSubmit} onCancel={onCancel} /> :
        <CreateModifyScheduledCardMovement initialValues={initialValuesForScheduledCardMovement} onSubmit={onSubmit} onCancel={onCancel} />
      }
    </Stack>
  </MyPaper>
}

RegisterScheduledExpense.propTypes = {
  onsubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default RegisterScheduledExpense;
