// React imports
import { useEffect } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// Recharts imports
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// MUI imports
import { Stack } from '@mui/material';

// My components imports
import { useTheme } from '@mui/material/styles';

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;
  // Return no dot at all
  return (
    <circle cx={cx} cy={cy} r={0} fill={stroke} />
  );
};

function MyLineChart(props) {

  // Decontruct props
  const { data, xLegend, yLegend } = props;

  // Determine min and max for the Y-axis
  const yMax = (Math.max(...data.map(item => item[yLegend])) * 1.1).toFixed(2); // 10% padding
  const yMin = (Math.min(...data.map(item => item[yLegend])) * 0.9).toFixed(2); // 10% padding

  const theme = useTheme();

  useEffect(() => {
    console.log("MyPieChart mounted");
    console.log(data);
    return () => console.log("MyPieChart unmounted");
  }, [])

  return <Stack direction="column" height={350}>
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 15,
          right: 10,
          left: -30,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="1 5" vertical={false} />
        <XAxis dataKey={xLegend} tick={{ fontSize: 10, angle: -45, dy: 20 }} />
        <YAxis domain={[yMin, yMax]} tick={{ fontSize: 10, angle: -45 }} tickFormatter={tick => tick.toFixed(2)} />
        <Tooltip />
        <Line type="monotone" dataKey={yLegend} stroke={theme.palette.primary.main} dot={<CustomizedDot />} />
      </LineChart>
    </ResponsiveContainer>
  </Stack>;
}

// Proptypes
MyLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  xLegend: PropTypes.string.isRequired,
  yLegend: PropTypes.string.isRequired,
}

export default MyLineChart;
