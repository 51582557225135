// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// MUI imports
import { FormControl, TextField, Button, Stack } from "@mui/material";
import { useTheme } from '@mui/material/styles';

// My component imports
import CustodyAgentTypePicker from "../inputs/custodyAgentTypePicker";
import PaisPicker from "../inputs/paisPicker";
import { countryPropType, custodyAgentPropType, custodyAgentTypePropType } from '../../utils/myPropTypes';

function CustodyAgentForm(props) {

  // Constants
  const theme = useTheme();
  const buttonText = props.intent === 'create' ? 'Crear agente de custodia' : 'Modificar agente de custodia';

  // Deconstructing props
  const { custodyAgentTypes, countries, custodyAgent, intent } = props;

  // State constants
  const [name, setName] = useState(custodyAgent.nombre);
  const [selectedType, setSelectedType] = useState(custodyAgent.tipo);
  const [selectedCountry, setSelectedCountry] = useState(custodyAgent.pais);

  // Change handlers
  const handleNameChange = event => setName(event.target.value);
  const handleCustodyAgentTypeChange = newValue => setSelectedType(newValue);
  const handleCountryChange = newValue => setSelectedCountry(newValue);
  const modifyCustodyAgentHandler = event => {
    let data = {
      nombre: name,
      tipo: selectedType,
      pais: selectedCountry,
    };
    if (intent === 'modify') {
      data.id = custodyAgent.id;
    }
    props.onSubmit(data)
  };

  return <Stack spacing={1}>
    <FormControl fullWidth>
      <TextField id="name" label="Nombre" variant="filled" value={name} onChange={handleNameChange} />
    </FormControl>
    <FormControl fullWidth>
      <CustodyAgentTypePicker
        selectedTypes={selectedType}
        onChange={handleCustodyAgentTypeChange}
        types={props.custodyAgentTypes}
        multiple={false}
      />
    </FormControl>
    <FormControl fullWidth>
      <PaisPicker
        selectedCountries={selectedCountry}
        onChange={handleCountryChange}
        countries={props.countries}
        multiple={false}
      />
    </FormControl>
    <Button variant="contained" color="primary" onClick={modifyCustodyAgentHandler} fullWidth>{buttonText}</Button>
    <Button variant="contained" sx={{ backgroundColor: theme.palette.primary.cancel }} onClick={props.onCancel} fullWidth>Cancelar</Button>
  </Stack>
}

CustodyAgentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  custodyAgentTypes: PropTypes.arrayOf(custodyAgentTypePropType).isRequired,
  countries: PropTypes.arrayOf(countryPropType).isRequired,
  intent: PropTypes.oneOf(["create", "modify"]).isRequired,
  custodyAgent: custodyAgentPropType,  // Optional, if not present, it will be a create form
}

export default CustodyAgentForm;