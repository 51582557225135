// React router imports
import { useHistory } from 'react-router-dom';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert } from '@mui/material';

// My components imports
import { accountTypePropType } from '../../utils/myPropTypes';
import CuentaDeDeudaListItem from '../basic/cuentaDeDeudaListItem';
import { urls } from '../../settings';


function DebtAccountsList(props) {

  // Constants
  const history = useHistory();
  const accountList = props.accountsList.map(account => {
    return <CuentaDeDeudaListItem
      key={account.id}
      cuenta={account}
      onDelete={() => console.log('Delete clicked!')}
      onModify={() => history.push(urls.configureAccountsUrl + '/' + account.id)}
    />
  })

  return <>
    {accountList.length > 0 ?
      accountList :
      <Alert severity='info'>No hay cuentas de deuda creadas. Crea una para comenzar a reflejar tus compromisos financieros</Alert>}
  </>
}

DebtAccountsList.propTypes = {
  accountsList: PropTypes.arrayOf(accountTypePropType).isRequired,
};

export default DebtAccountsList;