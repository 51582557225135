// React imports
import { useState, useEffect } from "react";

// My component imports
import { urls } from "../../settings.js";
import MyPaper from "../basic/myPaper.js";
import DebtAccountsList from "../lists/debtAccountsList.js";
import MyMenuComponent from "./myMenuComponent.js";
import AddButton from '../basic/addButton';
import { getCurrencies, getDebtAccounts, getDebtCustodyAgents } from "../../utils/api.js";
import CreateModifyAccount from "./createModifyAccount.js";
import { emptyAccount } from "../../utils/constants.js";


function ConfigureDebtAccount(props) {

  // State constants
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [custodyAgents, setCustodyAgents] = useState([]);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Promise.all([getDebtAccounts(), getCurrencies(), getDebtCustodyAgents()])
        .then(responses => {
          setAccounts(responses[0].results);
          setCurrencies(responses[1].results);
          setCustodyAgents(responses[2].results);
        })
        .catch(error => console.log(error));
    }
    return () => { isMounted = false };
  }, [updateData]);

  // Handlers
  const createAccountHandler = account => {
    setShowCreateAccount(false);
    setUpdateData(prev => !prev);
  }

  return (
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
    >
      {showCreateAccount ?
        <MyPaper>
          <CreateModifyAccount
            currencies={currencies}
            custodyAgents={custodyAgents}
            initialValues={{
              initialAccount: emptyAccount,
              intent: 'create',
              custodyAgent: custodyAgents.length === 1 ? custodyAgents[0] : null,
            }}
            onSubmit={createAccountHandler}
            onCancel={() => setShowCreateAccount(false)}
          />
        </MyPaper> :
        <>
          <MyPaper>
            <DebtAccountsList accountsList={accounts} />
          </MyPaper>
          <AddButton onClick={() => setShowCreateAccount(true)} />
        </>
      }
    </MyMenuComponent>
  );
}

export default ConfigureDebtAccount;