// My components imports
import { apiEndpoints } from '../settings.js';
import { getAccessToken } from "./authUtils.js";
import { purgeQueryObject } from "./misc.js";

// Auxiliary functions
function HTTPException(code, text) {
  this.code = code;
  this.text = text;
}

async function get(url, errorMessage, queryObject) {
  // todo: implementar pagination
  const jwtToken = await getAccessToken();
  const completeUrl = queryObject ? url + '?' + new URLSearchParams(purgeQueryObject(queryObject)).toString() : url;
  // Executing the request
  const response = await fetch(completeUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    },
  })

  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return {
      results: [],
      ok: false,
      code: 404,
    };
  } else {
    throw new Error(errorMessage);
  }
}

function postPutGenerator(method) {
  return async (url, body, error, unauthenticated = false) => {
    const jwtToken = unauthenticated ? '' : await getAccessToken();
    const response = await fetch(url, {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      if (response.status === 204) {
        return {};
      } else {
        return response.json();
      }
    } else {
      const errorObject = await response.json();
      // throw new Error(error + ', ' + JSON.stringify(specificError));
      throw new HTTPException(response.status, errorObject.error);
    }
  }
}

const post = postPutGenerator('POST');

const put = postPutGenerator('PUT');

export async function getPlans(code = null) {
  if (code) {
    var response = await get(apiEndpoints.planes, 'Error al obtener los planes de suscripción', { promotional_code: code });
  } else {
    var response = await get(apiEndpoints.planes, 'Error al obtener los planes de suscripción');
  }
  return response;
}

export async function getProduct() {
  const response = await get(apiEndpoints.productos, 'Error al obtener el producto');
  return response;
}

export async function getSubscriptions() {
  const response = await get(apiEndpoints.suscripcionesDeUsuario, 'Error al obtener las suscripciones');
  return response;
}

export async function subscribe(plan) {
  // plan is the body
  const response = await post(apiEndpoints.suscripcionesDeUsuario, plan, 'Error al suscribirse');
  return response;
}

export async function cancelSubscription(subscriptionId, reason) {
  const response = await post(apiEndpoints.suscripcionesDeUsuario + '/' + subscriptionId, { accion: 'cancelar', razon: reason }, 'Error al cancelar la suscripción');
  return response;
}

export async function getPaymentsReceipts(subscriptionId, startDate = null, endDate = null) {
  const queryObject = {};
  if (startDate) {
    queryObject.start_date = startDate;
  }
  if (endDate) {
    queryObject.end_date = endDate;
  }
  const response = await get(apiEndpoints.suscripcionesDeUsuario + '/' + subscriptionId + '/receipts', 'Error al obtener los recibos de pago', queryObject);
  return response;
}