// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import PagoDeTarjetaListItemClassic from './pagoDeTarjetaListItemClassic';
import PagoDeTarjetaListItemCompact from './pagoDeTarjetaListItemCompact';

function PagoDeTarjetaListItem(props) {
  return props.compact ? <PagoDeTarjetaListItemCompact {...props} /> : <PagoDeTarjetaListItemClassic {...props} />;
}

PagoDeTarjetaListItem.propTypes = {
  pagoDeTarjeta: PropTypes.object.isRequired, // PagoDeTarjeta to be displayed
  onModify: PropTypes.func.isRequired, // Function to be called when user clicks to modify payment
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks to delete payment
  compact: PropTypes.bool.isRequired, // Whether to display the item in compact mode or not
}

export default PagoDeTarjetaListItem;