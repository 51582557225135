// React imports
import { useState } from "react";

// Proptypes imports
import PropTypes from 'prop-types';
import { createLabel, modifyLabel } from "../../utils/api";

// MUI imports
// To be imported based on the needs of the following code

// My components imports
import LabelForm from "../forms/labelForm";


function CreateModifyLabel(props) {

  // State constants
  const [label, setLabel] = useState(props.initialValues.initialLabel);

  // Handlers
  const submitHandler = label => {
    if (props.initialValues.intent === "create") {
      createLabel(label)
        .then(response => {
          // Label created succefully
          props.onSubmit(response);
        })
        .catch(error => {
          // Error creating label
          console.log(error);
        })
    } else {
      // Modify label
      modifyLabel(label.id, label.texto)
        .then(response => {
          // Label modified succefully
          props.onSubmit(response);
        })
        .catch(error => {
          // Error modifying label
          console.log(error);
        })
    }
  }

  return <LabelForm
    label={label}
    onSubmit={submitHandler}
    onCancel={props.onCancel}
    intent={props.initialValues.intent}
  />
}

CreateModifyLabel.propTypes = {
  initialValues: PropTypes.shape({
    initialLabel: PropTypes.shape({
      id: PropTypes.number,
      sub: PropTypes.string,
      texto: PropTypes.string,
    }),// Label object to initialize Form in case of modify intent
    intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing label
  }),
  onSubmit: PropTypes.func.isRequired, // Function to be called when a label is created or modified
  onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the operation
}

export default CreateModifyLabel;