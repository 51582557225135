// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// React router imports
import { useParams } from "react-router-dom";

// AWS imports
import { Auth } from 'aws-amplify';

// My components imports
import { ConfirmationForm } from "./forms/cognitoForms.js";


function ConfirmationComponent(props) {

  const { email: username } = useParams();
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('Ingresa el código de confirmación que enviamos a tu correo');

  const handleResendConfirmation = async () => {
    try {
      await Auth.resendSignUp(username);
      setMessage('Código re-enviado con éxito');
      setError(false);
    } catch (err) {
      setMessage('Hubo un error al re-enviar el código');
      setError(true);
      console.log(err);
    }
  }

  const handleConfirmClick = (code) => {
    Auth.confirmSignUp(username, code)
      .then(result => {
        setConfirmationSuccess(true);
        setError(false);
        setMessage('Cuenta confirmada con éxito.');
      })
      .catch(err => {
        switch (err.code) {
          case 'CodeMismatchException':
            setMessage('Código incorrecto');
            break
          default:
            setMessage('Hubo un error');
        };
        console.log(err);
      });
  }
  return <ConfirmationForm onConfirm={handleConfirmClick} onResendConfirm={handleResendConfirmation} success={confirmationSuccess} error={error} message={message} />
}

ConfirmationComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired
}

export default ConfirmationComponent;