// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, FormControl, TextField, Button, InputLabel, FilledInput, InputAdornment, Stack } from '@mui/material';

// My components imports
import LabelInput from "../inputs/labelInput.js";
import EasyDatePicker from "../inputs/easyDatePicker.js";
import { getDateFromDateTime } from "../../utils/date.js";

function CurrencyExchangeForm(props) {

  // State constants
  const [sourceAccount, setSourceAccount] = useState(props.initialTransfer ? props.initialTransfer.cuenta_origen : null);
  const [destinationAccount, setDestinationAccount] = useState(props.initialTransfer ? props.initialTransfer.cuenta_destino : null);
  const [availabelSrcAccounts, setAvailableSrcAccounts] = useState(props.accounts);
  const [availableDstAccounts, setAvailableDstAccounts] = useState(props.accounts);
  const [comment, setComment] = useState(props.initialTransfer ? props.initialTransfer.comentario : '');
  const [srcAmmount, setSrcAmmount] = useState(props.initialTransfer ? -props.initialTransfer.monto_origen : 0);
  const [dstAmmount, setDstAmmount] = useState(props.initialTransfer ? props.initialTransfer.monto_destino : 0);
  const [date, setDate] = useState(props.initialTransfer ? props.initialTransfer.fecha : getDateFromDateTime(new Date()));
  const [labels, setLabels] = useState(props.initialTransfer ? props.initialTransfer.etiquetas : []);

  // Effect hooks
  useEffect(() => {
    if (sourceAccount === null) {
      setAvailableSrcAccounts(props.accounts);
      setAvailableDstAccounts(props.accounts);
      setDestinationAccount(null);
    }
    if (props.accounts && sourceAccount) {
      const newAvailableDstAccounts = props.accounts.filter(acct => acct.moneda.id !== sourceAccount.moneda.id);
      setAvailableDstAccounts(newAvailableDstAccounts);
    }
  }, [sourceAccount])

  // Change handlers
  const handleAmmountChange = event => {
    let valueToSet = '';
    if (event.target.value !== '') {
      if (!isNaN(parseFloat(event.target.value))) {
        if (parseFloat(event.target.value) >= 0) {
          valueToSet = event.target.value;
        }
      }
    };
    if (event.target.id === "monto_origen") {
      setSrcAmmount(valueToSet);
    };
    if (event.target.id === "monto_destino") {
      setDstAmmount(valueToSet)
    }
  };

  const handleConfirm = () => {
    props.onSubmit({
      id: props.initialTransfer ? props.initialTransfer.id : 0,
      cuenta_origen: sourceAccount,
      cuenta_destino: destinationAccount,
      monto_origen: srcAmmount,
      monto_destino: dstAmmount,
      comentario: comment,
      etiquetas: labels,
      fecha: date,
    })
  }

  return <Stack spacing={1}>
    <FormControl fullWidth>
      <EasyDatePicker
        helperValues={['hoy', 'ayer', 'antesDeAyer']}
        onChange={newDate => setDate(newDate)}
        initialValue={props.initialTransfer ? props.initialTransfer.fecha : getDateFromDateTime(new Date())}
      />
    </FormControl>
    {sourceAccount !== undefined ?
      <>
        <FormControl fullWidth>
          <Autocomplete
            id="srcAcctId"
            options={availabelSrcAccounts}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={acct => {
              if (acct !== null && acct.nombre !== undefined) {
                return acct.nombre + ' (' + acct.moneda.nombre_corto + ' ' + acct.balance.toLocaleString() + ')';
              } else {
                return null;
              }
            }}
            value={sourceAccount}
            onChange={(event, newValue) => {
              setSourceAccount(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Cuenta de origen" />}
          />
        </FormControl>


        <FormControl fullWidth>
          <InputLabel htmlFor="monto">Monto de origen</InputLabel>
          <FilledInput
            id="monto_origen"
            value={srcAmmount}
            onChange={handleAmmountChange}
            variant="filled"
            type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Monto"
          />
        </FormControl>
      </>
      : <></>
    }

    {sourceAccount ?
      <>
        <FormControl fullWidth>
          <Autocomplete
            id="dstAcctId"
            options={availableDstAccounts}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={acct => {
              if (acct !== null && acct.nombre !== undefined) {
                return acct.nombre + ' (' + acct.moneda.nombre_corto + ' ' + acct.balance.toLocaleString() + ')';
              } else {
                return null;
              }
            }}
            value={destinationAccount}
            onChange={(event, newValue) => {
              setDestinationAccount(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Cuenta de destino" />}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="monto">Monto de destino</InputLabel>
          <FilledInput
            id="monto_destino"
            value={dstAmmount}
            onChange={handleAmmountChange}
            variant="filled"
            type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Monto"
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="monto">Tipo de cambio</InputLabel>
          <FilledInput
            id="tipo_de_cambio"
            value={dstAmmount !== 0 ? (srcAmmount / dstAmmount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) : 0}
            onChange={() => null}
            variant="filled"
            type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Monto"
            disabled
          />
        </FormControl>

        <FormControl fullWidth>
          <LabelInput initialLabels={labels} onChange={(selectedLabels) => setLabels(selectedLabels)} />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            id="comentario"
            value={comment}
            onChange={event => setComment(event.target.value)}
            variant="outlined"
            type="text"
            label="Comentario"
          />
        </FormControl>
      </> :
      <></>
    }
    <Button
      variant="contained"
      onClick={handleConfirm}
      fullWidth
      disabled={!(sourceAccount && destinationAccount && srcAmmount && dstAmmount)}
    >
      Registrar transferencia
    </Button>
    {props.intent === 'modify' ?
      <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>
        Cancelar
      </Button> : <></>
    }


  </Stack>
}

CurrencyExchangeForm.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available accounts, this variable must be initialized before rendering this component
  initialTransfer: PropTypes.object, // Object to initialize Transfer
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user submit the Transfer
  onCancel: PropTypes.func.isRequired, // Function to be called when the user cancel the operation
  intent: PropTypes.string.isRequired, // Indicates whether we are creating a transfer or modificating one
}

export default CurrencyExchangeForm;