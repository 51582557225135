// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

// My components imports
import { urls } from "../../settings.js";
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";


function AdminPage(props) {

  // State constants

  // Other constants
  const history = useHistory();

  // State hooks

  // Change handlers

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
      currentPageName={'Admin'}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <MyPaper>
          <Stack spacing={1}>
            <List >
              <ListItem onClick={event => history.push()}>
                <ListItemAvatar>
                  <Avatar>
                    <LocalAtmIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Cuentas" secondary="Crea o modifica una moneda" />
              </ListItem>
            </List>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>

  </div>
}

export default AdminPage;