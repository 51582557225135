// React imports
import { useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, FormControl, Button, TextField } from "@mui/material";


function LabelForm(props) {

  // State constants
  const [currentText, setCurrentText] = useState(props.label.texto);

  // Change handlers
  const handleTextChange = (event) => {
    setCurrentText(event.target.value);
  }

  const submitHandler = event => {
    event.preventDefault();
    // make a deep copy of the label object in props
    let newLabel = JSON.parse(JSON.stringify(props.label));
    newLabel.texto = currentText;
    props.onSubmit(newLabel);
  }

  const cancelHandler = event => {
    event.preventDefault();
    props.onCancel();
  }

  return <FormControl>
    <Stack spacing={1}>
      <TextField label="Etiqueta" value={currentText} onChange={handleTextChange} />
      <Button variant="contained" onClick={submitHandler}>{props.intent === 'create' ? 'Crear etiqueta' : 'Modificar etiqueta'}</Button>
      <Button variant="contained" onClick={cancelHandler}>Cancelar</Button>
    </Stack>
  </FormControl>
}

LabelForm.propTypes = {
  label: PropTypes.shape({
    id: PropTypes.number.isRequired,
    texto: PropTypes.string.isRequired,
    sub: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(["create", "modify"]).isRequired,
}


export default LabelForm;