// React imports
import { useState, useEffect } from "react";

// React router imports
import { Redirect } from "react-router-dom";

// PropTypes imports
import PropTypes from 'prop-types';

// AWS imports
import { Auth } from 'aws-amplify';

// My components imports
import { SignUpForm } from "./forms/cognitoForms.js";
import { urls } from "../../settings.js";


function SignUpComponent(props) {

  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [userInUrl, setUserInUrl] = useState('');
  const [redirect, setRedirect] = useState(false);

  let redirectComponent = <Redirect to={{ pathname: urls.confirmAccountUrl + '/' + userInUrl, state: { from: "signUpForm" } }} />;

  useEffect(() => {
    console.log('Success: ' + signUpSuccess);
    console.log('Username: ' + userInUrl);
    setRedirect(signUpSuccess && userInUrl !== '');
  }, [userInUrl, signUpSuccess]);

  const handleSignUpClick = (givenName, email, password) => {

    Auth.signUp({
      username: email,
      email,
      password,
      attributes: {
        email,          // optional
        given_name: givenName
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: true,
      }
    }).then(user => {
      setUserInUrl(email);
      setSignUpSuccess(true);
    }).catch(err => console.log(err));
  }

  return redirect ? redirectComponent : <SignUpForm onClick={handleSignUpClick} />;
}

SignUpComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired
}

export default SignUpComponent;