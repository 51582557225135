// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from "@mui/system";
import { Divider, Typography, MenuItem, InputLabel, Select, FormControl } from "@mui/material";

// My component imports
import MyChart from "../graphs/myChart";
import MyPieChart from '../graphs/myPieChart';
import { getCategoryIcon } from '../../utils/misc';
import MyPaper from './myPaper';
import MyDialog from './MyDialog';
import MonedaPicker from "../inputs/monedaPicker";
import CategorySummaryTable from './categorySummaryTable';
import MyLoadingList from './myLoadingList';

function ExpenseIncomeGraphs(props) {

  // Constants
  const tipoDeReporte = props.isCashflow ? ['Egresos', 'Ingresos', 'Flujo de fondos'] : ['Gastos', "Ingresos", "Gastos + Ingresos"];

  // State constants
  const [infoReportTypeDialogOpen, setInfoReportTypeDialogOpen] = useState(false);
  const [reportType, setReportType] = useState(props.isCashflow ? "Egresos" : "Gastos");
  const [infoMonedaDialogOpen, setInfoMonedaDialogOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(props.currencies.find(currency => currency.nombre_corto === "UYU"));
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      props.onCurrencyChange(selectedCurrency);
    }
  }, [selectedCurrency, ready])

  return <Stack>

    <MyPaper>
      <Stack spacing={1}>

        <Stack direction="row" spacing={1}>
          <MyDialog onClose={() => setInfoMonedaDialogOpen(false)} open={infoMonedaDialogOpen} onOpen={() => setInfoMonedaDialogOpen(true)}>
            Para calcular los gastos totales por categorías y etiquetas, los gastos individuales serán convertidos a la moneda seleccionada de
            acuerdo al tipo de cambio del día en el cual se realizó cada gasto individual.
          </MyDialog>
          <Typography>Selcciona la moneda del reporte</Typography>
        </Stack>

        <MonedaPicker
          currencies={props.currencies}
          onChange={newValue => {
            setSelectedCurrency(newValue);
            setReady(true);
          }}
          initialValues={selectedCurrency}
          multiple={false}
        />

      </Stack>
    </MyPaper>

    {props.loading ?
      <MyPaper><MyLoadingList /></MyPaper> :
      <>
        <MyPaper>
          <CategorySummaryTable
            data={props.data}
            queryObject={props.queryObject}
            onRowClick={props.onRowClick}
            isCashflow={props.isCashflow ? props.isCashflow : false}
          />
        </MyPaper>

        <MyPaper>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <MyDialog
                onClose={() => setInfoReportTypeDialogOpen(false)}
                open={infoReportTypeDialogOpen}
                onOpen={() => setInfoReportTypeDialogOpen(true)}
              >
                <div>El gráfico de <i><strong>{props.isCashflow ? 'Egresos' : 'Gastos'}</strong></i> muestra sólo <span>{props.isCashflow ? 'egresos.' : 'gastos.'}</span></div>
                <div>El de <i><strong>Ingresos</strong></i> solo ingresos.</div>
                <div>El de<i><strong>{props.isCashflow ? ' Flujo de fondos' : ' Gastos + Ingresos'}</strong></i> los muestra combinados. Por ejemplo, si para la categoría: <Stack direction="row">{getCategoryIcon("Compras", "small")} <i><strong>Compras</strong></i></Stack> hay <span>{props.isCashflow ? 'egresos' : 'gastos'}</span> de $ -1.500 e ingresos de $ 500 mostará un <span>{props.isCashflow ? 'egreso' : 'gasto'}</span> de $ -1.000 para dicha categoría.</div>
              </MyDialog>
              <Typography>Selcciona el tipo de gráfico</Typography>
            </Stack>
            <FormControl fullWidth>
              <InputLabel>Tipo de gráfico</InputLabel>
              <Select
                value={reportType}
                label="Tipo de reporte"
                onChange={event => setReportType(event.target.value)}
              >
                {tipoDeReporte.map(tipo => <MenuItem value={tipo} key={tipo}>{tipo}</MenuItem>)}
              </Select>
            </FormControl>

          </Stack>
        </MyPaper>

        <MyPaper>
          {(reportType === "Gastos" || reportType === 'Egresos') ?
            <Stack spacing={1}>
              <MyPieChart
                id="gastosPorCategoria"
                data={props.data.gastos.por_categoria.map(item => ({ name: item.categoria.nombre, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                xLegend='name'
                yLegend='value'
                currency={selectedCurrency}
              />
              <Stack alignItems="center">
                <Typography variant='subtitle1'><i>Por categoría</i></Typography>
              </Stack>
              <Divider />
              <MyPieChart
                id="gastosPorEtiqueta"
                data={props.data.gastos.por_etiqueta.map(item => ({ name: item.etiqueta.texto, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                xLegend='name'
                yLegend='value'
                currency={selectedCurrency}
              />
              <Stack alignItems="center">
                <Typography variant='subtitle1'><i>Por etiqueta</i></Typography>
              </Stack>
            </Stack> :
            reportType === "Ingresos" ?
              <Stack spacing={1}>
                <MyPieChart
                  id="ingresosPorCategoria"
                  data={props.data.ingresos.por_categoria.map(item => ({ name: item.categoria.nombre, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                  xLegend='name'
                  yLegend='value'
                  currency={selectedCurrency}
                />
                <Stack alignItems="center">
                  <Typography variant='subtitle1'><i>Por categoría</i></Typography>
                </Stack>
                <Divider />
                <MyPieChart
                  id="ingresosPorEtiqueta"
                  data={props.data.ingresos.por_etiqueta.map(item => ({ name: item.etiqueta.texto, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                  xLegend='name'
                  yLegend='value'
                  currency={selectedCurrency}
                />
                <Stack alignItems="center">
                  <Typography variant='subtitle1'><i>Por etiqueta</i></Typography>
                </Stack>
              </Stack> :
              <Stack spacing={1}>
                <MyPieChart
                  id="gastosIngresosPorCategoria"
                  data={props.data.total.por_categoria.map(item => ({ name: item.categoria.nombre, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                  xLegend='name'
                  yLegend='value'
                  currency={selectedCurrency}
                />
                <Stack alignItems="center">
                  <Typography variant='subtitle1'><i>Por categoría</i></Typography>
                </Stack>
                <Divider />
                <MyPieChart
                  id="gastosIngresosPorEtiqueta"
                  data={props.data.total.por_etiqueta.map(item => ({ name: item.etiqueta.texto, value: Math.abs(parseFloat(item.monto)) }))} // Pie chart data
                  xLegend='name'
                  yLegend='value'
                  currency={selectedCurrency}
                />
                <Stack alignItems="center">
                  <Typography variant='subtitle1'><i>Por etiqueta</i></Typography>
                </Stack>
              </Stack>}
        </MyPaper>
      </>
    }


  </Stack>
}

MyChart.propTypes = {
  data: PropTypes.shape({
    gastos: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    ingresos: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    total: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    moneda: PropTypes.shape({
      nombre_corto: PropTypes.string,
      nombre: PropTypes.string,
      id: PropTypes.number
    })
  }),
  queryObject: PropTypes.object.isRequired, // Object with the query parameters
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired, // List of all available currencies
  onCurrencyChange: PropTypes.func.isRequired, // Function to be called when the user changes the selected currency
  loading: PropTypes.bool.isRequired, // Whether the component is loading or not
  onRowClick: PropTypes.func.isRequired, // Function to be called when the user clicks on a row of the table
  isCashflow: PropTypes.bool, // Whether the component is being used in the cashflow page or not. Default: false
}

export default ExpenseIncomeGraphs;