// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from "prop-types";

// MUI imports
import { TextField } from "@mui/material";

function CommentInput(props) {
  // Function that issues a TextField to allow the user to input a comment

  // State constants
  const [value, setValue] = useState(props.initialComment);

  return (
    <TextField
      fullWidth
      id="comment"
      label={props.label ? props.label : "Comentario"}
      value={value}
      onBlur={() => { props.onChange(value) }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
}

CommentInput.propTypes = {
  initialComment: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string, // Label for the TextField, if not provided, "Comentario" is used
};

export default CommentInput;


