// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// Date-fns imports
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import set from "date-fns/set";

// MUI imports
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField, Button } from "@mui/material";
import { useEffect } from "react";

// My components imports
import { getDateFromDateTime, getDateTimeFromDateString } from "../../utils/date";

// Global constants
const HELPERS = {
  hoy: {
    initialDate: new Date(),
    finalDate: new Date(),
    text: 'Hoy'
  },
  ayer: {
    initialDate: subDays(new Date(), 1),
    finalDate: subDays(new Date(), 1),
    text: 'Ayer'
  },
  antesDeAyer: {
    initialDate: subDays(new Date(), 2),
    finalDate: new Date(),
    text: 'Antes de ayer'
  },
  inicioDeMes: {
    initialDate: set(new Date(), { date: 1 }),
    finalDate: new Date(),
    text: 'Este mes'
  },
  inicioDeAno: {
    initialDate: set(new Date(), { date: 1, month: 0 }),
    finalDate: new Date(),
    text: 'Este año'
  },
  mesPasado: {
    initialDate: set(subMonths(new Date(), 1), { date: 1 }),
    finalDate: set(new Date(), { date: 0 }),
    text: 'Mes pasado'
  },
}

// Auxiliary functions
function getButonType(helperValue, actualValue) {
  const initialDateMatches = getDateFromDateTime(HELPERS[helperValue].initialDate) === getDateFromDateTime(actualValue.initialDate);
  const finalDateMatches = getDateFromDateTime(HELPERS[helperValue].finalDate) === getDateFromDateTime(actualValue.finalDate);
  return initialDateMatches && finalDateMatches ? "contained" : "outlined"
}

function EasyDateRangePicker(props) {

  // Deconstructing props
  const { initialDateError = false, initialDateTextError = '', finalDateError = false, finalDateTextError = '' } = props;

  // State constants
  const [initialDate, setInitialDate] = useState(getDateTimeFromDateString(props.initialValues.initialDate));
  const [finalDate, setFinalDate] = useState(getDateTimeFromDateString(props.initialValues.finalDate));
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      props.onChange(getDateFromDateTime(initialDate), getDateFromDateTime(finalDate));
    }
  }, [initialDate, finalDate, ready])

  // Handlers
  const handleHelperClick = (helperValue) => {
    setInitialDate(HELPERS[helperValue].initialDate);
    setFinalDate(HELPERS[helperValue].finalDate);
    props.onChange(getDateFromDateTime(HELPERS[helperValue].initialDate), getDateFromDateTime(HELPERS[helperValue].finalDate))
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', marginBottom: '15px' }}>
      {props.helperValues ? props.helperValues.map((helperValue, index) => {
        return (
          <Button
            variant={getButonType(helperValue, { initialDate: initialDate, finalDate: finalDate })}
            onClick={() => handleHelperClick(helperValue)}
            key={index}
            size="small"
            style={{ flexGrow: 1, margin: '2px' }}
          >
            {HELPERS[helperValue].text}
          </Button>
        )
      }) : <></>}
    </div>
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <DatePicker
        label={props.initialLabel ? props.initialLabel : "Fecha inicial"}
        value={initialDate}
        onChange={newValue => {
          setInitialDate(new Date(newValue));
          setReady(true);
        }}
        renderInput={(params) => <TextField {...params} error={initialDateError} helperText={initialDateTextError} />}
        minDate={props.minDate}
        maxDate={finalDate ? (props.strict ? subDays(finalDate, 1) : finalDate) : props.maxDate}
        readOnly={props.initialDateDisabled}
        inputFormat="DD/MM/YYYY"
      />
      <DatePicker
        label={props.finalLabel ? props.finalLabel : "Fecha final"}
        value={finalDate}
        onChange={newValue => {
          setFinalDate(new Date(newValue));
          setReady(true);
        }}
        renderInput={(params) => <TextField {...params} error={finalDateError} helperText={finalDateTextError} />}
        minDate={initialDate ? (props.strict ? addDays(initialDate, 1) : initialDate) : props.minDate}
        maxDate={props.maxDate}
        readOnly={props.finalDateDisabled}
        inputFormat="DD/MM/YYYY"
      />
    </Stack>

  </LocalizationProvider>
}

EasyDateRangePicker.propTypes = {
  initialLabel: PropTypes.string, // label to show in first date input
  finalLabel: PropTypes.string, // label to show in second date input
  initialValues: PropTypes.shape({
    initialDate: PropTypes.string, // initial date to show in first date input, format "YYYY-MM-DD"
    finalDate: PropTypes.string, // final date to show in second date input, format "YYYY-MM-DD"
  }), // values to initialize date inputs
  strict: PropTypes.bool, // if true initialDate has to be at least one day less than finalDate, if fals it can be up to equal
  helperValues: PropTypes.arrayOf(PropTypes.string), // object with convenient dates preloaded
  minDate: PropTypes.instanceOf(Date), // Minimum settable date
  maxDate: PropTypes.instanceOf(Date), // Maximum settable date
  initialDateDisabled: PropTypes.bool,
  finalDateDisabled: PropTypes.bool,
  onChange: PropTypes.func, // Function to be called when dates are changed
  initialDateError: PropTypes.bool,
  initialDateTextError: PropTypes.string,
  finalDateError: PropTypes.bool,
  finalDateTextError: PropTypes.string,
}

export default EasyDateRangePicker;