// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import TransferenciaListItemCompact from "./transferenciaListItemCompact.js";
import TransferenciaListItemClassic from "./transferenciaListItemClassic.js";

function TransferenciaListItem(props) {
  if (props.compact) {
    return <TransferenciaListItemCompact {...props} />
  } else {
    return <TransferenciaListItemClassic {...props} />
  }
}

TransferenciaListItem.propTypes = {
  transferencia: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
  compact: PropTypes.bool, // If true, show only the compact version of the item
};

export default TransferenciaListItem;