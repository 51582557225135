// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useState, useEffect } from 'react';

// MUI imports
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import DeleteIcon from '@mui/icons-material/Delete';

// My components imports
import ConfirmationDialog from './confirmationDialog';

function FamilyHeader(props) {

  // Constants
  const theme = useTheme();
  const nameText = props.family ? props.family.nombre : '';

  // State constants
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogExpectedText, setDialogExpectedText] = useState('');


  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (props.user.familia_administrada && props.user.familia_administrada.id === props.family.id && isMounted) {
      setUserIsAdmin(true);
    }
    return () => { isMounted = false };
  }, [props.user, props.family]);

  // Handlers
  const deleteClickHandler = (event, family) => {
    event.stopPropagation();
    setDialogText('¿Está seguro que desea eliminar la familia?');
    setDialogTitle('Eliminar familia');
    setDialogExpectedText('Eliminar');
    setDialogOpen(true);
  }

  return <Stack direction="row" spacing={1} justifyContent={'space-between'}>
    <ConfirmationDialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => {
        setDialogOpen(false);
        if (dialogTitle === 'Eliminar familia') {
          props.onDelete(props.family);
        }
      }}
      title={dialogTitle}
      text={dialogText}
      expectedText={dialogExpectedText}
    />
    <FamilyRestroomIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
    <Stack direction="column" spacing={0} justifyContent={"center"}>
      <Typography
        sx={{
          fontWeight: 'bold',
        }}
        variant="subtitle2"
      >
        {"Familia " + nameText}
      </Typography>
    </Stack>
    <Stack direction={'column'} justifyContent={'center'}>
      <DeleteIcon
        fontSize="medium"
        sx={{ color: userIsAdmin ? theme.palette.primary.main : theme.palette.primary.cancel }}
        onClick={userIsAdmin ? deleteClickHandler : null}
      />
    </Stack>
  </Stack>

}

FamilyHeader.propTypes = {
  family: PropTypes.object, // Familia to display
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks on Header
  user: PropTypes.object.isRequired, // User logged in
}

export default FamilyHeader;