// React imports
import { useState } from "react";

// MUI imports
import { Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import ConfirmationDialog from "./confirmationDialog";
import { getCustodyAgentTypeIcon } from "../../utils/misc";

function CuentaListItem(props) {

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const theme = useTheme();
  const balanceText = props.cuenta ?
    props.cuenta.moneda.nombre_corto + ' ' + props.cuenta.balance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) : '';
  const acctName = props.cuenta ? (props.cuenta.agente_de_custodia.tipo.nombre + ': ' + props.cuenta.agente_de_custodia.nombre) : '';
  const acctTypeText = props.cuenta ? props.cuenta.tipo_de_cuenta.nombre + ' ' + (props.cuenta.nombre ? '(' + props.cuenta.nombre + ')' : '') : '';
  const icon = getCustodyAgentTypeIcon(props.cuenta.agente_de_custodia.tipo.nombre, "large", theme.palette.primary.main);

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const deleteConfirmHandler = event => {
    event.stopPropagation();
    console.log('No está disponible aún la funcionalidad de borrar cuenta');
    // Uncomment following line when funcionality is developed
    // props.onDelete(props.cuenta);
  }

  const modifyClickHandler = event => {
    props.onModify(props.cuenta);
  }

  return <Stack direction="row" onClick={modifyClickHandler} sx={{ padding: "25px", cursor: "pointer" }}>
    <ConfirmationDialog
      title='Borrar cuenta'
      text={'Funcionalidad aún no disponible...'}
      open={showConfirmationDialog}
      cancelButtonText='Cancelar'
      confirmButtonText='Borrar'
      onClose={event => setShowConfirmationDialog(false)}
      onConfirm={deleteConfirmHandler}
      expectedText='estoy seguro'
    />
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      {icon}
    </Box>

    <Stack direction="column" alignItems="flex-start" spacing={0} sx={{ marginLeft: "10px" }}>
      <Typography variant="subtitle1">
        {acctTypeText}
      </Typography>
      {/* <Typography variant="subtitle2" fontStyle={'italic'} color={theme.palette.primary.lightText}>
        {acctName}
      </Typography> */}
      <Typography sx={{
        fontWeight: 'bold',
        paddingBottom: '2px',
        fontSize: '1.1rem',
        color: props.cuenta ? (props.cuenta.balance < 0 ? theme.palette.error.main : "green") : "green"
      }}
      >
        {balanceText}
      </Typography>
    </Stack>
    <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
      <DeleteIcon onClick={deleteClickHandler} color="primary" sx={{ fontSize: 35 }} />
    </Box>
  </Stack >
}

CuentaListItem.propTypes = {
  cuenta: PropTypes.shape({
  }), // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user confirms that wants to delete the Account
  onModify: PropTypes.func, // Function to be called when user whats to modify Account
};

export default CuentaListItem;