// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { movementPropType } from "../../utils/myPropTypes";
import MovimientoListItemCompact from "./movimientoListItemCompact";
import MovimientoListItemClassic from "./movimientoListItemClassic";

function MovimientoListItem(props) {
  if (props.compact) {
    return <MovimientoListItemCompact {...props} />
  } else {
    return <MovimientoListItemClassic {...props} />
  }
}

MovimientoListItem.propTypes = {
  movimiento: movementPropType.isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  compact: PropTypes.bool, // If true, show compact version of MovimientoListItem
};

export default MovimientoListItem;