// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Avatar from '@mui/material/Avatar';

// My components imports
import { getUserProfile } from "../../utils/api.js";
import { urls } from "../../settings.js";

function UserAvatar(props) {

  // State constants
  const [userPhotoUrl, setUserPhotoUrl] = useState(null);

  // Other constants
  const history = useHistory();
  const sizes = {
    small: { width: 56, height: 56, cursor: "pointer" },
    medium: { width: 96, height: 96, cursor: "pointer" },
    large: { width: 128, height: 128, cursor: "pointer" },
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUserProfile()
        .then(profile => setUserPhotoUrl(profile.picture))
        .catch(error => console.log(error));
    }

    return () => { isMounted = false };
  }, []);

  return (
    <Avatar
      alt="Foto de perfil del usuario"
      onClick={props.onClick ? props.onClick : () => history.push(urls.userProfileUrl)}
      src={userPhotoUrl}
      sx={props.size ? sizes[props.size] : sizes.small}
    />
  );

}

UserAvatar.propTypes = {
  onClick: PropTypes.func, // Function to execute when the user clicks on the avatar
  size: PropTypes.oneOf(["small", "medium", "large"]), // Size of the avatar, default is small
}

export default UserAvatar;