// Google login imports
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

// AWS imports
import { Hub } from 'aws-amplify';

// My components imports
import { googleConfiguration } from "../../settings.js";
import { appName } from "../../settings.js";

// Auxiliary functions
const signInWithGoogle = async googleResponse => {
  // User has logged in with Google, let's trigger an identityProviderSignIn event in the app channel
  const signInEvent = {
    event: 'identityProviderSignIn',
    data: {
      provider: 'google',
      token: googleResponse.credential
    },
    message: 'User has signed in using Google'
  };
  // Dispatch event
  Hub.dispatch(appName, signInEvent);
}

function GoogleLoginComponent() {
  return <GoogleOAuthProvider clientId={googleConfiguration.clientId}>
    <GoogleLogin
      auto_select
      onSuccess={signInWithGoogle}
      onError={err => console.log(err)}
      size='large'
      locale='es'
      shape='pill'
    />
  </GoogleOAuthProvider>
}

export default GoogleLoginComponent;