// PropTypes imports
import PropTypes from "prop-types";

// MUI component imports
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function TutorialSection(props) {

  return <Accordion defaultExpanded={props.defaultExpanded ? props.defaultExpanded : false}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      {props.title}
    </AccordionSummary>
    <AccordionDetails>
      {props.children}
    </AccordionDetails>
  </Accordion>
}

// PropTypes
TutorialSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool
}

export default TutorialSection;