// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Alert, Stack, Button, Typography } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings.js";
import MyPaper from "../basic/myPaper.js";
import SupportForm from "../forms/supportForm.js";
import { sendSupportEmail } from "../../utils/api.js";
import MyLoadingList from "../basic/myLoadingList.js";
import { useUser } from "../../utils/userContext.js";


function SupportPage() {

  // Other constants
  const initialSubject = '';
  const initialMessage = '';
  const { user } = useUser();

  // State constants
  const [sendingMessage, setSendingMessage] = useState(false);
  const [state, setState] = useState('form');  // form, success, error

  // Effect hooks
  useEffect(() => {
    console.log('user', user);
  }, [user]);

  // Handlers
  const handleSubmit = (subject, message) => {
    setSendingMessage(true);
    sendSupportEmail(subject, message)
      .then(response => {
        setSendingMessage(false);
        setState('success');
      })
      .catch(error => {
        setSendingMessage(false);
        setState('error');
      });
  }

  const handleTryAgain = () => {
    setState('form');
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
    ]}
    currentPageName="Soporte"
  >
    <MyPaper>
      {sendingMessage ? <MyLoadingList /> :
        (state === 'success' ?
          <Alert severity="success">
            <Stack>
              <Typography variant="h6">Mensaje enviado correctamente.</Typography>
              <Typography variant="body1">Te responderemos a la brevedad en tu correo electrónico:</Typography>
              <br />
              <Typography variant="body1">{user.correo}</Typography>
            </Stack>
          </Alert> :
          state === 'error' ?
            <Stack spacing={1}>
              <Alert severity="error">Error al enviar el mensaje</Alert>
              <Button variant="contained" color="primary" onClick={handleTryAgain}>Reintentar</Button>
            </Stack> :
            <SupportForm initialSubject={initialSubject} initialMessage={initialMessage} onSubmit={handleSubmit} />
        )
      }
    </MyPaper>
  </MyMenuComponent>
}


export default SupportPage;