// React Router imports
import { useHistory, useParams } from "react-router-dom";

// MUI components imports
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Stack } from '@mui/material';

// My components imports
import IconCard from "../basic/iconCard";
import { urls } from "../../settings";


function CardOtherActionsForm(props) {

  // Constants
  const history = useHistory();
  const params = useParams();

  return <Stack direction='row' spacing={2} sx={{ textAlign: 'center' }}>
    <IconCard text='Movimientos actuales'>
      <ListIcon fontSize="large" onClick={() => history.push(urls.configureCardsUrl + '/' + params.cardId + '/movements')} color="primary" />
    </IconCard>
    <IconCard text='Estados de cuenta'>
      <ListAltIcon fontSize="large" onClick={() => history.push(urls.configureCardsUrl + '/' + params.cardId + '/accountStates')} color="primary" />
    </IconCard>
    <IconCard text='Vencimientos'>
      <CalendarMonthIcon fontSize="large" onClick={() => history.push(urls.configureCardsUrl + '/' + params.cardId + '/dates')} color="primary" />
    </IconCard>
  </Stack>;
}

export default CardOtherActionsForm;