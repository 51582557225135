// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from "prop-types";

// My component imports
import ScheduledMovementForm from "../forms/scheduledMovementForm";
import { getUserAccounts, getCategories, getAgendas, createMovimientoProgramado, modifyMovimientoProgramado } from "../../utils/api";
import MyLoadingList from "../basic/myLoadingList";

function CreateModifyScheduledMovement(props) {

    // Deconstructing props
    const initialScheduledMovement = props.initialValues.initialScheduledMovement;
    const { onSubmit, onCancel } = props;

    // State constants
    const [accounts, setAccounts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [formIsReady, setFormIsReady] = useState(false);

    // Effect hooks
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const promises = [getUserAccounts(), getCategories(), getAgendas()];
            Promise.all(promises)
                .then(responses => {
                    setAccounts(responses[0].results);
                    setCategories(responses[1].results);
                    setAgendas(responses[2].results);
                    setFormIsReady(true);
                })
                .catch(error => {
                    console.error('Error al obtener las cuentas y categorías', error);
                });
        }
        return () => { isMounted = false };
    }, []);

    // Handlers
    const handleFormSubmit = (movement) => {
        console.log('Scheduled movement to be submitted:', movement);
        if (props.initialValues.intent === "create") {
            createMovimientoProgramado({
                id: movement.id,
                agenda_id: movement.agenda.id,
                cuenta_id: movement.cuenta.id,
                categoria_id: movement.categoria.id,
                monto: movement.monto,
                comentario: movement.comentario,
                etiquetas_ids: movement.etiquetas.map(etiq => etiq.id),
            }).then(response => {
                console.log('Scheduled movement created:', response);
                onSubmit(response);
            }).catch(error => {
                console.error('Error al crear el movimiento programado:', error);
            });
        } else {
            console.log('Modifying register:', movement);
            const body = {
                agenda_id: movement.agenda.id,
                cuenta_id: movement.cuenta.id,
                categoria_id: movement.categoria.id,
                monto: movement.monto,
                comentario: movement.comentario,
                etiquetas_ids: movement.etiquetas.map(etiq => etiq.id),
            };
            modifyMovimientoProgramado(movement.id, body)
                .then(result => {
                    onSubmit(result);
                })
                .catch(err => console.error('Ups! Error modifying register:', err));
        }
    }

    return formIsReady ?
        <ScheduledMovementForm
            agendas={agendas}
            accounts={accounts}
            categories={categories}
            initialMovement={initialScheduledMovement}
            onSubmit={handleFormSubmit}
            onCancel={onCancel}
            isExpense={initialScheduledMovement.monto <= 0}
            allowSwitching={true}
            intent={props.initialValues.intent}
        /> :
        <MyLoadingList />
}

CreateModifyScheduledMovement.propTypes = {
    initialValues: PropTypes.shape({
        initalScheduledMovement: PropTypes.object.isRequired, // Todo: Define the shape of this object
        intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing account
    }).isRequired,
    onSubmit: PropTypes.func.isRequired, // Function to be called when an scheduled movement is submitted
    onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the operation
};

export default CreateModifyScheduledMovement;