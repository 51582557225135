// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, Paper, Stack, Typography, Alert } from "@mui/material";

// My component imports
import { ErrorBoundary } from '../../utils/system.js';
import { paypalPlanPropType } from '../../utils/myPropTypes.js';
import SubscriptionPlanListItem from '../basic/subscriptionPlanListItem.js';


function SubscriptionPlansList(props) {

  // Props
  const { plansList, promotionalPlansList, initialPlan, onChange, showPromotionalPlanError } = props;

  // State constants
  const [selectedPlan, setSelectedPlan] = useState(initialPlan);

  // Effect hooks
  useEffect(() => {
    onChange(selectedPlan);
  }, [selectedPlan, onChange]);

  return <ErrorBoundary>
    <Paper sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
      <Typography variant="h6" sx={{ paddingLeft: "15px", paddingTop: "5px" }}>Planes regulares</Typography>
      <Stack spacing={0} direction="column" divider={<Divider flexItem />}>
        {plansList && plansList.length > 0 ?
          plansList.map((plan, index) => {
            return <SubscriptionPlanListItem
              key={plan.id}
              plan={plan}
              onClick={plan => setSelectedPlan(plan)}
              checked={selectedPlan && selectedPlan.id === plan.id}
            />
          })
          :
          <Stack alignItems="center">
            <Alert severity="error">No hay planes para mostrar</Alert>
          </Stack>
        }
      </Stack>
    </Paper>
    <Stack>
      {promotionalPlansList && promotionalPlansList.length > 0 ?
        <Paper sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
          <Typography variant="h6" sx={{ paddingLeft: "15px", paddingTop: "5px" }}>Planes promocionales</Typography>
          <Stack spacing={1} direction="column" divider={<Divider flexItem />}>
            {promotionalPlansList.map((plan, index) => {
              return <SubscriptionPlanListItem
                key={plan.id}
                plan={plan}
                onClick={plan => setSelectedPlan(plan)}
                checked={selectedPlan && selectedPlan.id === plan.id}
              />
            })}
          </Stack>
        </Paper> :
        showPromotionalPlanError ?
          <Stack alignItems="center">
            <Alert severity="error">No hay planes promocionales para ese código</Alert>
          </Stack> :
          null
      }
    </Stack>
  </ErrorBoundary >
}

SubscriptionPlansList.propTypes = {
  plansList: PropTypes.arrayOf(paypalPlanPropType).isRequired,
  promotionalPlansList: PropTypes.arrayOf(paypalPlanPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  initialPlan: paypalPlanPropType.isRequired, // Initial plan to be selected
  showPromotionalPlanError: PropTypes.bool.isRequired, // Show error message when no promotional plans are found
}

export default SubscriptionPlansList;