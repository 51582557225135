// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { CircularProgress, Typography, Stack, Container, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const MyContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
}));

function MyLoading(props) {

  const theme = useTheme();

  return props.success ?
    <Stack alignItems="center">
      <CheckCircleIcon color="success" fontSize="large" />
      <Typography>¡Hecho!</Typography>
    </Stack>
    :
    <Stack>
      <CircularProgress sx={{ marginLeft: '20px' }} />
      <Typography>Cargando</Typography>
    </Stack>
}

MyLoading.propTypes = {
  success: PropTypes.bool, // If true, show success icon
}

export default MyLoading;