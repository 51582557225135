// React Router imports
import { useHistory } from "react-router-dom";

// React imports
import { useState, useEffect } from "react";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PaidIcon from '@mui/icons-material/Paid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// My components imports
import MyMenuComponent from "./components/app/myMenuComponent.js";
import MyPaper from "./components/basic/myPaper.js";
import { urls } from "./settings.js";
import { Typography } from "@mui/material";
import { getUserAccounts, getActivity, getCards, getCategories } from "./utils/api.js";
import NoAccountComponent from "./components/basic/noAccountComponent.js";
import MyListItem from "./components/basic/myListItem.js";
import MyLoadingList from "./components/basic/myLoadingList.js";
import NewMovementList from "./components/lists/newMovementList.js";
import { useUserProfile } from "./utils/userProfile.js";


function Home(props) {

  // Constants
  const history = useHistory();
  const { profile } = useUserProfile();
  const queryParams = {
    last: 3,
  }

  // State constants
  const [showNoAccountMessage, setShowNoAccountMessage] = useState(false);
  const [accounts, setAccounts] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [cards, setCards] = useState([]);
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Promise.all([getUserAccounts(), getActivity(queryParams), getCards(), getCategories()])
        .then(responses => {
          setAccounts(responses[0].results);
          setActivity(responses[1].results);
          setCards(responses[2].results);
          setCategories(responses[3].results);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && typeof accounts !== 'undefined') {
      if (accounts.length === 0) {
        setShowNoAccountMessage(true);
      }
    }
    return () => { isMounted = false }
  }, [accounts])

  // Handlers
  const handleUpdateMovementList = () => {
    setLoading(true);
    getActivity(queryParams)
      .then(response => {
        setActivity(response.results);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  return <MyMenuComponent links={[]} currentPageName={'Inicio'}>
    {showNoAccountMessage ?
      <MyPaper>
        <NoAccountComponent />
      </MyPaper>
      :
      <Stack direction="column" alignItems="center">
        <MyPaper>
          <Typography sx={{ marginBottom: "15px" }}>Accesos rápidos:</Typography>
          <Stack spacing={1}>
            <List >
              <MyListItem onClick={event => history.push(urls.registerExpenseUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <ShoppingCartIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Gasto" secondary="Registra un gasto" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.registerIncomeUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <PaidIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Ingreso" secondary="Registra un ingreso" />
              </MyListItem>

            </List>
          </Stack>
        </MyPaper>
        <MyPaper>
          {loading ? <MyLoadingList /> :
            activity.length > 0 && profile &&
            <Stack spacing={2}>
              <Typography sx={{ marginBottom: "15px" }}>Última actividad:</Typography>
              <NewMovementList
                movements={activity}
                cards={cards}
                accounts={accounts}
                categories={categories}
                showSortBar={false}
                compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
                updateList={handleUpdateMovementList}
                noContainer={true}
              />
            </Stack>
          }
        </MyPaper>
      </Stack>
    }
  </MyMenuComponent>
}

export default Home;