// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DnsIcon from '@mui/icons-material/Dns';

// My components imports
import { urls } from "../../settings.js";
import MyPaper from '../basic/myPaper.js';
import MyMenuComponent from './myMenuComponent.js';


function Searches(props) {

  // State constants

  // Other constants
  const history = useHistory();

  // State hooks

  // Change handlers

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
    ]}
    currentPageName={'Buscar'}
  >
    <Stack direction="column" justifyContent="center" alignItems="center">
      <MyPaper>
        <Stack spacing={1}>
          <List >
            <ListItem onClick={event => history.push(urls.searchMovementUrl)}>
              <ListItemAvatar>
                <Avatar>
                  <DnsIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Movimiento" secondary="Busca un movimiento" />
            </ListItem>
          </List>
        </Stack>
      </MyPaper>
    </Stack>
  </MyMenuComponent>
}

export default Searches;