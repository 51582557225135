// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField } from "@mui/material";

// Auxiliary functions
function pushCard(card, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === card.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(card);
  }
  return list;
}

function TarjetaPicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [selectedCards, setSelectedCards] = useState(props.initialValues ? props.initialValues : []);
  const [selectedCard, setSelectedCard] = useState(props.initialValues ? props.initialValues : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedCards);
      } else {
        props.onChange(selectedCard);
      }
    }
  }, [selectedCards, selectedCard, ready])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="tarjetaId"
    options={props.cards}
    // defaultValue={[]}
    getOptionLabel={card => {
      if (card !== null && card.nombre !== undefined) {
        return card.nombre;
      } else {
        return null;
      }
    }}
    value={multiple ? selectedCards : selectedCard}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set selectedCards to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedCards([]);
          setReady(true);
          return null;
        };

        // User is trying to add an existing label, make sure the label isn't already selected
        setSelectedCards(pushCard(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedCard(newValue);
        setReady(true);
      }
    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((card, index) => (
        <Chip
          label={card.nombre}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Tarjetas" : "Tarjeta"}
      placeholder={multiple ? "Selecciona las tarjetas" : "Selecciona la tarjeta"}
    />
    }
  />
}

TarjetaPicker.propTypes = {
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // All available cards
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
}

export default TarjetaPicker;