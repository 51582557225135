// React imports
import { useEffect, useState } from 'react';

// React Router imports
import { useHistory, useParams } from "react-router-dom";

// MUI imports
import { Stack, List, ListItemText, ListItemAvatar, Avatar, Typography, Button, Fab } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// My components imports
import MyMenuComponent from './myMenuComponent.js';
import { urls } from "../../settings.js";
import { getCardAccountStates, getCard } from "../../utils/api.js";
import MyPaper from '../basic/myPaper.js';
import MyListItem from '../basic/myListItem.js';
import { apiDateSerializator, dateToDisplayFormat } from '../../utils/date.js';


function ConfigureCardsDates(props) {

  // Constants
  const history = useHistory();
  const params = useParams();
  const { cardId } = useParams();
  const maxRows = 5;

  // State constants
  const [accountStates, setAccountStates] = useState([]);
  const [card, setCard] = useState(null);
  const [limitListLength, setLimitLength] = useState(true);

  // State hooks
  useEffect(() => {
    getCardAccountStates(params.cardId, apiDateSerializator(new Date()))
      .then(response => {
        setAccountStates(response.results);
      })
      .catch(err => {
        console.log(err);
        setAccountStates([])
      });
    getCard(params.cardId)
      .then(result => {
        setCard(result.response);
      })
  }, [])

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
        { url: card ? urls.configureCustodyAgentUrl + "/" + card.agente_de_custodia.id : urls.configureCustodyAgentUrl, name: card ? card.agente_de_custodia.nombre : 'Cargando...' },
        { url: urls.configureCardsUrl + "/" + params.cardId, name: card ? card.nombre : 'Cargando...' }
      ]}
    // currentPageName={"Tarjeta"}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <MyPaper elevation={1}>
          <Stack spacing={1}>
            <Typography>{card ? card.nombre : ''}</Typography>
            <List >
              {accountStates.map((cardAccountState, index) => {
                if (cardAccountState.cerrado) {
                  return <></>
                } else if (index >= maxRows && limitListLength) {
                  return <></>
                } else {
                  return <MyListItem
                    onClick={() => { history.push(urls.configureCardsUrl + '/' + params.cardId + '/dates/' + cardAccountState.id) }}
                    key={cardAccountState.id}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarMonthIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={'Cierre: ' + dateToDisplayFormat(cardAccountState.fecha_de_cierre)}
                      secondary={'Vencimiento: ' + dateToDisplayFormat(cardAccountState.fecha_de_vencimiento)}
                    />
                  </MyListItem>
                }
              })
              }
            </List>
            {limitListLength ?
              <Fab variant="extended" size="small" onClick={() => setLimitLength(false)}>
                <ExpandMoreIcon sx={{ mr: 1 }} />
                Mostrar mas
              </Fab> :
              <Fab variant="extended" size="small" onClick={() => setLimitLength(true)}>
                <ExpandLessIcon sx={{ mr: 1 }} />
                Mostrar menos
              </Fab>
            }
            <Button variant="contained" color="cancel" onClick={() => history.push(urls.configureCardsUrl + '/' + params.cardId)} fullWidth>Volver</Button>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>


  </div>
}

export default ConfigureCardsDates;