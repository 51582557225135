// MUI imports
import { Typography, Chip } from "@mui/material";

// My components imports
import { getRawCategoryIcon } from "../../utils/misc";

function Categorias() {

  const gastosFijosIcon = getRawCategoryIcon('Gastos fijos', 'small');
  const restauranteIcon = getRawCategoryIcon('Restaurante', 'small');
  const sueldoIcon = getRawCategoryIcon('Sueldo', 'small');
  const rentaIcon = getRawCategoryIcon('Renta', 'small');

  return <Typography variant="body1">
    En <strong>FinanzasUY</strong> puedes asignar categorías para clasificar tus gastos e ingresos.
    Por ejemplo, al registrar el pago de una factura de UTE, puedes asignarle la categoría <Chip variant="outlined" size="small" icon={gastosFijosIcon} label="Gastos fijos" /> o al ir a cenar afuera puedes registrar el gasto en la categoría <Chip variant="outlined" size="small" icon={restauranteIcon} label="Restaurante" />.
    Siguiendo con los ejemplos, cuando cobres, podrías registrar tu sueldo en la categoría <Chip variant="outlined" size="small" icon={sueldoIcon} label="Sueldo" /> y si tuvieras una casa en alquiler, al recibir el dinero del inquilino podrías registrarlo en la categoría <Chip variant="outlined" size="small" icon={rentaIcon} label="Renta" />.
    <br /><br />
    Con el objetivo de brindarte la mayor granularidad posible para clasificar tus gastos e ingresos, en <strong>FinanzasUY</strong> exísten una gran cantidad de categorías. Te recomendamos que antes de comenzar a utilizarlas te familiarices con ellas y planifiques cuáles utilizarás y cuales no, dado que no es necesario utilizarlas todas.
    <br /><br />
    El mismo conjunto de categorías estará disponible a la hora de registrar tanto gastos como ingresos. Si bien esto puede resultar contraintuitivo al principio, este comportamiento es importante para que puedas modelar todas las transacciones que harás en tu vida cotidiana. Pongamos un ejemplo, a priori parecería que no tiene sentido que la categoría <Chip variant="outlined" size="small" icon={restauranteIcon} label="Restaurante" /> esté disponible para registrar un ingreso. Pero supongamos que sales a cenar con amigos y definen que tu pagarás el total de la cuenta y que ellos te girararán su parte. En este caso, podrías registrar el pago del total de la cuenta en la categoría <Chip variant="outlined" size="small" icon={restauranteIcon} label="Restaurante" /> y luego registrar el ingreso de la parte de tus amigos en la misma categoría. De esta forma, gastos e ingresos se cancelarán entre sí y el saldo de la categoría <Chip variant="outlined" size="small" icon={restauranteIcon} label="Restaurante" /> será exactamente lo que tu gastaste realmente.
  </Typography>
}

export default Categorias;