// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Chip, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// My components imports
import ConfirmationDialog from './confirmationDialog';
import { getAmountText, parseName } from '../../utils/misc';

function PagoDeTarjetaListItemClassic(props) {

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [labelsToShow, setLabelsToShow] = useState([]);

  // Constants
  const theme = useTheme();
  const ammountText = props.pagoDeTarjeta ?
    props.pagoDeTarjeta.cuenta.moneda.nombre_corto + ' ' + getAmountText(props.pagoDeTarjeta.monto_origen) : '';

  // Effect hooks
  useEffect(() => {
    if (props.pagoDeTarjeta) {
      let myLabels = JSON.parse(JSON.stringify(props.pagoDeTarjeta.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
    }
  }, [props.pagoDeTarjeta])

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const modifyClickHandler = event => {
    props.onModify(props.pagoDeTarjeta);
  }

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  return <Stack direction="row" onClick={modifyClickHandler} sx={{ padding: "25px" }}>

    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estas seguro que quieres borrar este pago?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={handleDeleteCancel}
      onConfirm={() => props.onDelete(props.pagoDeTarjeta)}
    />

    <Stack direction="column" flexGrow={1}>

      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        <Chip
          label={props.pagoDeTarjeta.fecha}
          size="medium"
          icon={<CalendarMonthIcon fontSize="small" />}
          variant="outlined"
        />
        <Chip
          label={parseName(props.pagoDeTarjeta.cuenta.nombre)}
          size="medium"
          icon={<AccountBalanceIcon fontSize="small" />}
          variant="outlined"
        />
      </Stack>

      <Stack direction="row" spacing={1}>
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: theme.palette.error.main
          }}
          >
            {ammountText}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column">
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Typography variant="subtitle2">Pago de tarjeta:</Typography>
          <Chip
            label={props.pagoDeTarjeta.tarjeta.nombre}
            size="small"
            icon={<CreditCardIcon fontSize="small" />}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Stack>
            <Typography variant="subtitle2">
              {"Deuda cancelada:"}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">
              {props.pagoDeTarjeta.saldo_de_tarjeta.moneda.nombre_corto + ' ' + getAmountText(props.pagoDeTarjeta.monto_destino)}
            </Typography>
          </Stack>
        </Stack>

      </Stack>

      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
        {labelsToShow}
      </Stack>

    </Stack>


    <Stack direction="column" justifyContent="center" alignItems="flex-end">
      <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 35 }} />
    </Stack>

  </Stack>
}

PagoDeTarjetaListItemClassic.propTypes = {
  pagoDeTarjeta: PropTypes.object.isRequired, // PagoDeTarjeta to be displayed
  onModify: PropTypes.func.isRequired, // Function to be called when user clicks to modify payment
  onDelete: PropTypes.func.isRequired, // Function to be called when user clicks to delete payment
}

export default PagoDeTarjetaListItemClassic;