// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import MovementCardForm from "../forms/movementCardForm";
import { createCardMovement, deleteCardMovement } from "../../utils/api";

function RegisterCardMovement(props) {

  // Handlers
  const modifyCardMovementHandler = cardMov => {
    const createMov = async () => {
      const labelsIds = cardMov.etiquetas.map(label => label.id)
      return createCardMovement(cardMov.tarjeta.id, cardMov.monto, cardMov.moneda.id, cardMov.categoria.id, cardMov.fecha, cardMov.cantidad_de_cuotas, cardMov.comentario, labelsIds)
        .then(response => {
          if (response.results !== undefined) {
            props.onSubmit(response.results[0]);
          } else {
            props.onSubmit(response);
          }
        })
        .catch(err => console.log(err));
    }
    if (props.initialValues.intent === 'create') {
      createMov();
    } else if (props.initialValues.intent === 'modify') {
      deleteCardMovement(cardMov.id)
        .then(() => {
          createMov();
        }).catch(err => console.log(err));
    };
  }

  return <MovementCardForm
    initialCardMovement={props.initialValues.initialCardMovement}
    intent={props.initialValues.intent}
    onSubmit={modifyCardMovementHandler}
    onCancel={() => props.onCancel()}
    categories={props.categories}
    cards={props.cards}
    isExpense={props.isExpense}
    allowSwitching={props.allowSwitching}
  />
}

RegisterCardMovement.propTypes = {
  initialValues: PropTypes.shape({
    initialCardMovement: PropTypes.shape({
      tarjeta: PropTypes.object, // Value to initializa card selection
      categoria: PropTypes.object, // Value to initialize category selection
      saldo: PropTypes.object, // Value for currency initialization
      etiquetas: PropTypes.arrayOf(PropTypes.object), // Value to initialize labels
      fecha: PropTypes.string, // Value to initialize date
      comentario: PropTypes.string, // Value to initialize comment
      monto: PropTypes.number, // Value to initialize ammount
      cuotas: PropTypes.number, // Number of installments
      id: PropTypes.number, // Id of initial Card movement
    }).isRequired,
    intent: PropTypes.oneOf(['create', 'modify']).isRequired, // Value that determines if we are creating or modifying a MovimientoTarjeta
  }).isRequired, // values to initialize data inputs
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // List containing all available cards
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // LIst containing all available categories
  isExpense: PropTypes.bool.isRequired, // Indicates whether the movement is a expense or not. If it is a expense I need to invert symbol
  onSubmit: PropTypes.func.isRequired, // Function to be called when user click on submit button
  onCancel: PropTypes.func.isRequired, // Function to be called when user click on cancel button
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterCardMovement;