// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SellIcon from '@mui/icons-material/Sell';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';

// My components imports
import { urls } from "../../settings.js";
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import MyListItem from "../basic/myListItem.js";


function Configurations(props) {

  // State constants

  // Other constants
  const history = useHistory();

  // State hooks

  // Change handlers

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
      currentPageName={'Configurar'}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <MyPaper>
          <Stack spacing={1}>
            <List >
              <MyListItem onClick={event => history.push(urls.configureLabels)}>
                <ListItemAvatar>
                  <Avatar>
                    <SellIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Etiquetas" secondary="Gestiona tus etiquetas" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.configureFamily)}>
                <ListItemAvatar>
                  <Avatar>
                    <FamilyRestroomIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Familia" secondary="Crea o modifica una familia" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.schedulesUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <EditCalendarIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Agendas" secondary="Crea o modifica una agenda recurrente" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.scheduledMovementsUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <EventRepeatIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Registros programados" secondary="Progrma el registro de un gasto, un ingreso o una transferencia" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.configureApp)}>
                <ListItemAvatar>
                  <Avatar>
                    <SecurityUpdateIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Actualizaciones" secondary="Busca actualizaciones para la App" />
              </MyListItem>
            </List>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>

  </div>
}

export default Configurations;