// Proptypes imports
import PropTypes from 'prop-types';

// React imports
import { useEffect, useState } from 'react';

// MUI imports
import { FormControl, Stack, Switch, Typography } from '@mui/material';

// My component imports
import ReceivedFamilyInvitationListItem from '../basic/receivedFamilyInvitationListItem';
import SortButtonGroup from '../inputs/sortButtonGroup';


function ReceivedFamilyInvitationsList(props) {
  // State constants
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [sortedList, setSortedList] = useState(props.invitations);
  const [sortBy, setSortBy] = useState('Fecha'); // ['date', 'alphabetical']
  const [sortAscending, setSortAscending] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSortedList(props.invitations.sort((a, b) => {
        if (sortBy === 'A-Z') {
          if (a.correo < b.correo) {
            return sortAscending ? -1 : 1;
          } else if (a.correo > b.correo) {
            return sortAscending ? 1 : -1;
          } else {
            return 0;
          }
        } else {
          if (a.creada_en < b.creada_en) {
            return sortAscending ? -1 : 1;
          } else if (a.creada_en > b.creada_en) {
            return sortAscending ? 1 : -1;
          } else {
            return 0;
          }
        }
      }).filter(invitation => !showOnlyActive ? true : !invitation.revocada && !invitation.rechazada))
    }
    return () => { isMounted = false }
  }, [props.invitations, sortBy, sortAscending, showOnlyActive])

  return <Stack>
    <FormControl fullWidth>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'subtitle2'}>Mostrar sólo activas:</Typography>
        <Switch checked={showOnlyActive} onChange={() => setShowOnlyActive(!showOnlyActive)} />
      </Stack>
    </FormControl>
    {sortedList.length > 0 ?
      <>
        <SortButtonGroup
          onChange={sortObject => {
            setSortBy(sortObject.sortBy);
            setSortAscending(sortObject.sortAscending);
          }}
          initialSortBy={sortBy}
          initiallySortAscending={sortAscending}
          sortTypes={['Fecha', 'A-Z']}
        />
        {sortedList.map(invitation => {
          return !invitation.revocada ?
            <ReceivedFamilyInvitationListItem
              invitation={invitation}
              onReject={props.onReject}
              onAccept={props.onAccept}
              userHasActiveSubscription={props.userHasActiveSubscription}
            /> :
            (!showOnlyActive ?
              <ReceivedFamilyInvitationListItem
                invitation={invitation}
                onReject={props.onReject}
                onAccept={props.onAccept}
                userHasActiveSubscription={props.userHasActiveSubscription}
              /> :
              <></>)
        })}
      </>
      :
      <Typography variant={'caption'}>No hay invitaciones</Typography>
    }

  </Stack>
}

ReceivedFamilyInvitationsList.propTypes = {
  invitations: PropTypes.array.isRequired, // Array of invitations
  userHasActiveSubscription: PropTypes.bool, // True if user has an active subscription
  onReject: PropTypes.func, // Function to be called when user whats to reject the invitation
  onAccept: PropTypes.func, // Function to be called when user whats to accept the invitation
};

export default ReceivedFamilyInvitationsList;