// Proptypes imports
import PropTypes from "prop-types";

// MUI imports
import { Alert } from "@mui/material";

// My components imports
import SubscriptionPaymentReceiptListItem from "../basic/subscriptionPaymentReceiptListItem";
import { paymentReceiptPropType } from "../../utils/myPropTypes";

function SubscriptionPaymentsReceiptsList(props) {
  const { subscriptionPaymentsReceipts } = props;

  return subscriptionPaymentsReceipts.length > 0 ?
    subscriptionPaymentsReceipts.map(receipt => {
      return <SubscriptionPaymentReceiptListItem paymentReceipt={receipt} />
    }) :
    <Alert severity="info">No tienes recibos de pago</Alert>;
}

SubscriptionPaymentsReceiptsList.propTypes = {
  subscriptionPaymentsReceipts: PropTypes.arrayOf(paymentReceiptPropType)
};

export default SubscriptionPaymentsReceiptsList;

