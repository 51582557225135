// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function CambioDeMoneda() {
  return <Typography variant="body1">
    Cada vez que compres o vendas dinero en una moneda específica, lo registrarás utilizando un <TextChipLink label="cambio de moneda" url={urls.registerCurrencyExchangeUrl} />.
    <br /><br />
    Cuando realizas un <TextChipLink label="cambio de moneda" url={urls.registerCurrencyExchangeUrl} />, el dinero se descuenta de la <strong>cuenta de origen</strong> y se acredita en la <strong>cuenta de destino</strong>.
  </Typography>
}

export default CambioDeMoneda;