// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BarChartIcon from '@mui/icons-material/BarChart';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// My components imports
import { urls } from "../../settings.js";
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import MyListItem from "../basic/myListItem.js";


function Visualizations(props) {

  // Other constants
  const history = useHistory();

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
      currentPageName={'Analizar'}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <MyPaper>
          <Stack spacing={1}>
            <List >

              <MyListItem onClick={event => history.push(urls.visualizeExpenses)}>
                <ListItemAvatar>
                  <Avatar>
                    <BarChartIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Gastos e ingresos" secondary="Analizalos en un período de tiempo" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.visualizeCashFlow)}>
                <ListItemAvatar>
                  <Avatar>
                    <PriceChangeIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Flujo de fondos" secondary="Analizalo en un período de tiempo" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.visualizeBalance)}>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Balance" secondary="Analiza cuanto dinero tienes en este momento" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.visualizeMonthlyExpenses)}>
                <ListItemAvatar>
                  <Avatar>
                    <CalendarMonthIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Mensual" secondary="Análisis mensual de gastos e ingresos" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.visualizeCurrencyExchange)}>
                <ListItemAvatar>
                  <Avatar>
                    <CurrencyExchangeIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Cotizaciones de monedas" secondary="Analiza las cotizaciones de moneda" />
              </MyListItem>


            </List>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>

  </div>
}

export default Visualizations;