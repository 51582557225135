// React-router imports
import { useHistory } from "react-router-dom";

// MUI imports
import { Stack, Typography, Button, Alert } from "@mui/material";
import SchoolIcon from '@mui/icons-material/School';

// My components imports
import { urls } from "../../settings.js";


function NoAccountComponent(props) {

  // Constants
  const history = useHistory();

  return <Stack>
    <Alert severity="info">
      <Stack spacing={1}>
        <Typography variant="h6" align="center">¡Te damos la bienvenida a FinanzasUY!</Typography>
        <Typography variant="body1">Antes de comenzar a utilizar la aplicación te recomendamos que te tomes unos minutos para leer el tutorial y aprender a utilizarla.</Typography>
        <Button variant="contained" color="primary" onClick={() => history.push(urls.tutorialUrl)} fullWidth>
          <Stack direction="row" spacing={1} alignItems="center">
            <SchoolIcon />
            <Typography>Ir al tutorial</Typography>
          </Stack>
        </Button>
      </Stack>
    </Alert>
  </Stack>
}

export default NoAccountComponent;