// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// MUI imports
import { FormControl, TextField, Button, Switch, Alert, Stack } from "@mui/material";

function InviteFamilyMembersForm(props) {

  // State constants
  const [email, setEmail] = useState(props.invitation ? props.correo : '');
  const [revoked, setRevoke] = useState(props.invitation ? props.revocada : false);

  // Change handlers
  const handleEmailChange = event => setEmail(event.target.value);
  const handleSubmit = event => props.onSubmit({
    correo: email,
    familia: props.invitation ? props.invitation.familia : null,
    revocada: revoked
  });

  return <Stack spacing={0.5}>
    <FormControl fullWidth>
      <TextField id="email" label="Correo electrónico" variant="filled" value={email} onChange={handleEmailChange} disabled={props.intent === 'modify'} />
    </FormControl>

    {props.error ? <Alert severity="error" sx={{ marginBottom: "10px" }}>{props.errorText}</Alert> : <></>}

    <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
      {props.intent === "modify" ? "Modificar invitación" : "Enviar invitación"}
    </Button>

    <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>Volver</Button>
  </Stack>
}

InviteFamilyMembersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  intent: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired
}

export default InviteFamilyMembersForm;