// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function ReporteDeGastosEIngresos() {
  return <Typography variant="body1">
    El reporte de <TextChipLink label="Reporte de gastos e ingresos" url={urls.visualizeExpenses} /> te permitirá visualizar tus gastos e ingresos para un período de tiempo específico de una forma rápida y sencilla.
    <br /><br />
    Podrás filtrar por fecha, cuenta, tarjeta, categoría, etiqueta, tipo de movimiento, monto, descripción, y más.
    <br /><br />
    El reporte mostrará los gastos e ingresos agrupados por categoría. Al hacer click en una categoría, podrás acceder a la lista de movimientos que componen la misma.
    <br /><br />
    Es importante tener en cuenta que el reporte de gastos e ingresos incluye los gastos a crédito. Por ejemplo, si compras un producto con tarjeta de crédito, el gasto se contabilizará en el reporte de gastos e ingresos en el momento de la compra, y no cuando se pague la tarjeta de crédito.
    <br /><br />
    El objetivo de este reporte es permitirte saber cuanto dinero te ha ingresado y cuanto has gastado en un período de tiempo determinado. Este reporte no está diseñado para evaluar el flujo de efectivo.
    <br /><br />
    Para tener una situación financiera saludable, es importante gastar menos de lo que ingresa. Al mirar únicamente el flujo de efectivo, se puede pensar que se está gastando menos de lo que se ingresa, cuando en realidad se está gastando más de lo que se ingresa, pero se está financiando el gasto con tarjetas de crédito. <strong>Por eso es importante utilizar este reporte para evaluar qué tan bien lo hicimos en un período de tiempo determinado.</strong>
  </Typography>
}

export default ReporteDeGastosEIngresos;