// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { scheduledMovementPropType } from '../../utils/myPropTypes';
import MovimientoProgramadoListItemClassic from './movimientoProgramadoListItemClassic';
import MovimientoProgramadoListItemCompact from './movimientoProgramadoListItemCompact';

function MovimientoProgramadoListItem(props) {
  return props.compact ? <MovimientoProgramadoListItemCompact {...props} /> : <MovimientoProgramadoListItemClassic {...props} />;
}

MovimientoProgramadoListItem.propTypes = {
  movimiento: PropTypes.oneOf([scheduledMovementPropType]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default MovimientoProgramadoListItem;