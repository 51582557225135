// React imports
import { useState, useEffect } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Alert, Stack, Typography, useTheme } from '@mui/material';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

// My components imports
import { diasSemana } from '../../utils/constants';
import { agendaPropType } from '../../utils/myPropTypes';
import ConfirmationDialog from './confirmationDialog';

// Auxiliary functions
function getDiaSemanaText(dia) {
  return diasSemana.find(d => d.value === dia).label;
}

function getMonthName(monthNumber) {
  const months = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];
  return months[monthNumber - 1];
}

export function generateAgendaPeriodicityText(agenda) {
  if (agenda.tipo_periodicidad === 'diaria') {
    return <Stack direction="row" spacing={1}>
      <Typography variant="caption">Se ejecuta:</Typography>
      <Typography variant="caption" fontWeight={"bold"}>{'todos los días'}</Typography>
    </Stack>
  } else if (agenda.tipo_periodicidad === 'semanal') {
    return <Stack direction="row" spacing={1}>
      <Typography variant="caption">Se ejecuta:</Typography>
      <Typography variant="caption" fontWeight={"bold"}>{'cada semana los días ' + getDiaSemanaText(agenda.dia_semana)}</Typography>
    </Stack>
  } else if (agenda.tipo_periodicidad === 'mensual') {
    if (agenda.dia_mes !== 0) {
      return <Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption">Se ejecuta:</Typography>
          <Typography variant="caption" fontWeight={"bold"}>{'el día ' + agenda.dia_mes + ' de cada mes'}</Typography>
        </Stack>
        {agenda.dia_mes >= 29 &&
          <Stack direction="row" spacing={1} alignItems="center">
            <Alert severity="warning" variant="outlined">
              <Typography variant="caption" fontWeight={"bold"}>{`¡Ten cuidado! Si un mes determinado no tiene ${agenda.dia_mes} días, no se ejecutará ese mes`}</Typography>
            </Alert>

          </Stack >}
      </Stack>
    } else {
      return <Stack direction="row" spacing={1}>
        <Typography variant="caption">Se ejecuta:</Typography>
        <Typography variant="caption" fontWeight={"bold"}>{'el último día de cada mes'}</Typography>
      </Stack>
    }
  } else if (agenda.tipo_periodicidad === 'anual') {
    if (agenda.dia_mes !== 0) {
      return <Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption">Se ejecuta:</Typography>
          <Typography variant="caption" fontWeight={"bold"}>{'cada ' + agenda.dia_mes + ' de ' + getMonthName(agenda.mes)}</Typography>
        </Stack>
        {agenda.dia_mes === 29 && agenda.mes === 2 && <Stack direction="row" spacing={1} alignItems="center">
          <Alert severity="warning" variant="outlined">
            <Typography variant="caption" fontWeight={"bold"}>{`¡Ten cuidado! Lo años que el mes de ${getMonthName(agenda.mes)} no tiene ${agenda.dia_mes} días, no se ejecutará`}</Typography>
          </Alert>
        </Stack>}
      </Stack>
    } else {
      return <Stack direction="row" spacing={1}>
        <Typography variant="caption">Se ejecuta:</Typography>
        <Typography variant="caption" fontWeight={"bold"}>{'el último día de ' + getMonthName(agenda.mes)}</Typography>
      </Stack>
    }
  }
}

function AgendaListItem({ agenda, onDelete, onModify }) {

  // Constants
  const theme = useTheme();
  const numOfMovs = agenda.movimientos_programados ? parseInt(agenda.movimientos_programados.length) : 0;
  const numOfCardMovs = agenda.movimientos_tarjeta_programado ? parseInt(agenda.movimientos_tarjeta_programado.length) : 0;
  const totalMovs = numOfMovs + numOfCardMovs;
  const dialogTextWithMovs = <Stack spacing={1}>
    <Stack direction="row" spacing={1} alignItems="center">
      <WarningIcon sx={{ color: theme.palette.warning.main, fontSize: 25 }} />
      <Typography fontWeight={"bold"} variant="h6">¡Cuidado!</Typography>
    </Stack>
    <Typography>Esta agenda tiene {totalMovs} movimientos programados asociados.</Typography>
    <Typography fontWeight={"bold"}>En caso continuar, se borrarán también estos movimientos.</Typography>
    <Typography>Este proceso no se puede revertir. ¿Estás seguro de que quieres borrar la agenda?</Typography>
  </Stack>
  const confirmationDialogText = totalMovs > 0 ? dialogTextWithMovs : '¿Estás seguro de que quieres borrar esta agenda?';
  const activeColor = agenda.activa ? theme.palette.primary.main : theme.palette.disabled.main;

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    console.log('agenda', agenda);
  }, [agenda]);

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const handleCancelDelete = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  const handleDeleteConfirmation = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
    console.log('Borrando agenda', agenda);
    onDelete(agenda.id);
  }

  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-start"} onClick={() => onModify(agenda)} sx={{ cursor: "pointer" }}>
      <ConfirmationDialog
        open={showConfirmationDialog}
        text={confirmationDialogText}
        cancelButtonText="Cancelar"
        confirmButtonText="Borrar"
        onClose={handleCancelDelete}
        onConfirm={handleDeleteConfirmation}
      />
      <Stack>
        <EventRepeatIcon sx={{ color: activeColor, fontSize: 30 }} />
      </Stack>
      <Stack>
        <Typography variant="button" color={activeColor}>{agenda.nombre + (agenda.activa ? '' : ' (inactiva)')}</Typography>
        <Typography variant="caption">{generateAgendaPeriodicityText(agenda)}</Typography>
        <Typography variant="caption">{'Desde ' + agenda.fecha_inicio + (agenda.fecha_fin ? ' hasta ' + agenda.fecha_fin : ' sin fecha de fin')}</Typography>
      </Stack>
      <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
        <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 25 }} />
      </Stack>
    </Stack>
  )
}

AgendaListItem.propTypes = {
  agenda: agendaPropType.isRequired,
  onDelete: PropTypes.func,
  onModify: PropTypes.func,
}

export default AgendaListItem;