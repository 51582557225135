// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// My component imports
import { getCategoryIcon, getAmountText } from '../../utils/misc';
import { generateAgendaPeriodicityText } from './agendaListItem';
import ConfirmationDialog from './confirmationDialog';
import { scheduledCardMovementPropType } from '../../utils/myPropTypes';

function MovimientoTarjetaProgramadoListItemCompact({ movimiento, onModify, onDelete, showInstallment }) {
  // Other constants
  const theme = useTheme();
  const amount = showInstallment ? movimiento.monto : movimiento.monto * movimiento.cantidad_de_cuotas;
  const amountText = getAmountText(amount, movimiento.moneda);
  const installmentsText = movimiento.cantidad_de_cuotas > 1
    ? showInstallment
      ? `(cuota ${movimiento.numero_de_cuota}/${movimiento.cantidad_de_cuotas})`
      : `(en ${movimiento.cantidad_de_cuotas} cuotas)`
    : '';
  const amountColor = amount >= 0 ? theme.palette.number.positive : theme.palette.number.negative;

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Handlers
  const modifyClickHandler = event => {
    event.stopPropagation();
    onModify(movimiento);
  };

  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  const handleDeleteConfirmation = event => {
    event.stopPropagation();
    onDelete(movimiento);
  };

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{ cursor: 'pointer', backgroundColor: theme.palette.primary.main, borderRadius: '5px', padding: '3px' }}
      onClick={modifyClickHandler}
    >
      <ConfirmationDialog
        open={showConfirmationDialog}
        text="¿Estás seguro que quieres borrar este registro programado?"
        cancelButtonText="Cancelar"
        confirmButtonText="Borrar"
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirmation}
      />

      <Stack direction="column" alignItems={"flex-start"} sx={{ padding: '3px' }}>
        <EditCalendarIcon color="primary" sx={{ fontSize: 15, color: "white" }} />
        <Typography variant="caption" fontWeight={"bold"}>{movimiento.agenda.nombre.toUpperCase()}</Typography>
        {generateAgendaPeriodicityText(movimiento.agenda)}
      </Stack>

      <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ backgroundColor: "white", borderRadius: "3px", padding: "3px" }}>
        <Stack direction="column" justifyContent={"center"} sx={{ color: theme.palette.primary.main }}>
          {getCategoryIcon(movimiento ? movimiento.categoria.nombre : null, "medium")}
        </Stack>

        <Stack>
          <Stack direction="row" flexWrap={"wrap"}>
            {movimiento.etiquetas.map(label => (
              <Chip
                key={label.id}
                label={label.texto}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
              />
            ))}
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="caption">{'Tarjeta: ' + movimiento.tarjeta.nombre}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <Typography variant="subtitle1" color={amountColor} sx={{ fontWeight: "bold" }}>{amountText}</Typography>
            <Typography variant="caption">{installmentsText}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{movimiento.comentario}</Typography>
          </Stack>
        </Stack>

        <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"} flexGrow={1}>
          <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 25 }} />
        </Stack>
      </Stack>
    </Stack>
  );
}

MovimientoTarjetaProgramadoListItemCompact.propTypes = {
  movimiento: scheduledCardMovementPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be displayed or not
};

export default MovimientoTarjetaProgramadoListItemCompact;
