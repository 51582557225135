// React imports
import { useEffect, useState } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// Recharts imports
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector } from 'recharts';

// MUI imports
import { Stack } from '@mui/material';

// Chroma imports
import chroma from 'chroma-js';

// My component imports
import { getAmountText } from '../../utils/misc';
import { currencyPropType } from '../../utils/myPropTypes';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy - 24} dy={8} textAnchor="middle" fill={fill} style={{ fontWeight: "bolder" }}>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{ fontSize: "1.4em", fontWeight: "bolder" }}>
        {payload.name}
      </text>
      <text x={cx} y={cy + 22} dy={8} textAnchor="middle" fill={fill} style={{ fontWeight: "bolder" }}>
        {getAmountText(value, props.currency)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

function MyPieChart(props) {

  // Decontruct props
  const { data, xLegend, yLegend } = props;

  // Construct colors array based on the length of the data array
  const colors = chroma.scale(['#2196f3', '#f44336']).mode('lch').colors(data.length);

  // State constants
  const [activeIndex, setActiveIndex] = useState(0);

  // Auxiliary functions
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  }

  return <Stack direction="column" width={"100%"} height={550}>
    <style>
      {`
        .recharts-wrapper svg :focus {
          outline: none;
        }
      `}
    </style>
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          dataKey={yLegend}
          nameKey={xLegend}
          activeIndex={activeIndex}
          activeShape={(args) => renderActiveShape({ ...args, currency: props.currency })}
          onMouseEnter={onPieEnter}
          innerRadius={"60%"}
          outerRadius={"90%"}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
        </Pie>
        <Legend verticalAlign="top" />
      </PieChart>
    </ResponsiveContainer>
  </Stack>;
}

// Proptypes
MyPieChart.propTypes = {
  data: PropTypes.array.isRequired,
  xLegend: PropTypes.string.isRequired,
  yLegend: PropTypes.string.isRequired,
  currency: currencyPropType.isRequired,
}

export default MyPieChart;
