// React imports
import { useState, useEffect } from "react";

// React Router imports
import { Link, useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';

// My components imports
import { urls } from "../../../settings.js";
import MyPaper from "../../basic/myPaper.js";

function CognitoLoginForm(props) {

  // State constants
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Other constants
  const history = useHistory();

  // Auxiliary functions
  const goToConfirmAccount = () => history.push(urls.confirmAccountUrl + '/' + username);

  return props.showConfirmationButton ?

    <MyPaper>
      <Stack spacing={1}>
        <Alert severity="warning">Cuenta no confirmada aún</Alert>
        <Button variant="contained" size="large" onClick={goToConfirmAccount}>Confirmar cuenta</Button>
        <Button variant="outlined" size="large"><Link to={urls.loginUrl}>Volver al login</Link></Button>
      </Stack>
    </MyPaper>
    :
    <MyPaper>
      <Stack spacing={1}>
        <Stack spacing={1} sx={{ margin: '10px 0 0 0 !important', }}>
          {props.error ? <Alert severity="error">{props.errorText}</Alert> : <></>}
          <TextField
            variant="outlined"
            type="text"
            label="correo"
            autoComplete='username'
            value={username}
            name="username"
            error={props.error}
            onChange={event => setUsername(event.target.value)}
          ></TextField>
          <TextField
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label="password"
            value={password}
            name="password"
            error={props.error}
            onChange={event => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {
                    showPassword ?
                      <VisibilityOffIcon onClick={() => setShowPassword((showPassword) => setShowPassword(!showPassword))}></VisibilityOffIcon> :
                      <VisibilityIcon onClick={() => setShowPassword((showPassword) => setShowPassword(!showPassword))}></VisibilityIcon>
                  }
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Button variant="contained" size="large" onClick={() => props.onClick(username, password)}>Ingresar</Button>
          <Button variant="outlined" size="large"><Link to={urls.passwordResetUrl}>Recupera tu contraseña</Link></Button>
          <Button variant="outlined" size="large"><Link to={urls.signUpUrl}>Registrate</Link></Button>
        </Stack>
      </Stack>
    </MyPaper>
}

function SignUpForm(props) {

  // State constants
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [givenName, setGivenName] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <MyPaper>
        <Stack spacing={1}>
          <TextField
            variant="outlined"
            type='text'
            label="nombre"
            value={givenName}
            onChange={event => setGivenName(event.target.value)}
          ></TextField>
          <TextField
            variant="outlined"
            type='email'
            label='email'
            value={email}
            onChange={event => setEmail(event.target.value)}
          ></TextField>
          <TextField
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            label='password'
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon onClick={() => setShowPassword((showPassword) => setShowPassword(!showPassword))}></VisibilityIcon>
                </InputAdornment>
              ),
            }}
            onChange={event => setPassword(event.target.value)}
          ></TextField>
          <Button variant="contained" size="large" onClick={() => props.onClick(givenName, email, password)}>SignUp</Button>
          <Link to={urls.loginUrl}>¿Ya tienes usuario?</Link>
        </Stack>
      </MyPaper>
    </Stack>
  )
}

function ConfirmationForm(props) {

  // State constants
  const [code, setCode] = useState('');

  // Event handlers
  const handleCodeChange = (event) => setCode(event.target.value);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <MyPaper>
        <Stack spacing={1}>
          <Alert severity={props.confirmationSuccess ? "success" : props.error ? "error" : "info"}>{props.message}</Alert>
          {
            !props.success ?
              <Stack spacing={1}>
                <TextField type="text" variant="outlined" value={code} onChange={handleCodeChange} label="Código de confirmación"></TextField>
                <Button variant="contained" size="large" onClick={() => props.onConfirm(code)}>Confirmar registro</Button>
                <Button variant="outlined" size="large" onClick={props.onResendConfirm}>Volver a enviar código</Button>
              </Stack> :
              <></>
          }
          <Link to={urls.loginUrl}>Volver al login</Link>
        </Stack>
      </MyPaper>

    </Stack>
  )
}

function RequestPasswordResetForm(props) {

  // State constants
  const [email, setEmail] = useState('');

  return (
    <Stack direction="column" justifyContent="center" alignItems="Center">
      <MyPaper>
        <Stack spacing={1}>
          <TextField
            variant="outlined"
            type="text"
            label="correo"
            autoComplete='email'
            value={email}
            name="email"
            onChange={event => setEmail(event.target.value)}
          ></TextField>
          <Button variant="contained" size="large" onClick={() => props.onClick(email)}>Resetear contraseña</Button>
          <Link to={urls.loginUrl}>Volver al login</Link>
        </Stack>
      </MyPaper>
    </Stack>
  )
}

function PasswordResetForm(props) {

  // State constants
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMissmatch, setPasswordMissmatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

  useEffect(() => {
    if (password === confirmPassword) { setPasswordMissmatch(false) } else { setPasswordMissmatch(true) };
  }, [password, confirmPassword])

  return <Stack direction="column" justifyContent="center" alignItems="center">
    <MyPaper>
      <Stack spacing={1}>
        {props.success ? <Alert severity="success">Contraseña reseteada con éxito, vuelve a loguearte</Alert> :
          <Stack spacing={1}>
            <TextField variant="outlined" type='text' label="Ingresa el código" value={code} onChange={event => setCode(event.target.value)}></TextField>
            <TextField
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              value={password}
              onChange={event => setPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      showPassword ?
                        <VisibilityOffIcon onClick={() => setShowPassword((showPassword) => setShowPassword(!showPassword))}></VisibilityOffIcon> :
                        <VisibilityIcon onClick={() => setShowPassword((showPassword) => setShowPassword(!showPassword))}></VisibilityIcon>
                    }
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              variant="outlined"
              type={showConfirmationPassword ? 'text' : 'password'}
              label="Repite la contraseña"
              value={confirmPassword}
              error={passwordMissmatch}
              helperText={passwordMissmatch ? "Las contraseñas no coinciden" : ""}
              onChange={event => setConfirmPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      showConfirmationPassword ?
                        <VisibilityOffIcon onClick={() => setShowConfirmationPassword((showConfirmationPassword) => setShowConfirmationPassword(!showConfirmationPassword))}></VisibilityOffIcon> :
                        <VisibilityIcon onClick={() => setShowConfirmationPassword((showConfirmationPassword) => setShowConfirmationPassword(!showConfirmationPassword))}></VisibilityIcon>
                    }
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Button variant="contained" size="large" onClick={() => props.onClick(code, password)} disabled={passwordMissmatch}>Resetear contraseña</Button>
          </Stack>
        }
        <Link to={urls.loginUrl}>Volver al login</Link>
      </Stack>
    </MyPaper>
  </Stack>

}

export { CognitoLoginForm, SignUpForm, ConfirmationForm, RequestPasswordResetForm, PasswordResetForm }