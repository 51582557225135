// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField, Stack } from "@mui/material";

// My components imports
import { getCustodyAgentTypeIcon } from "../../utils/misc";

// Auxiliary functions
function pushCustodyAgentType(type, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === type.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(type);
  }
  return list;
}

function CustodyAgentTypePicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [selectedTypes, setSelectedTypes] = useState(props.selectedTypes ? props.selectedTypes : []);
  const [selectedType, setSelectedType] = useState(props.selectedTypes ? props.selectedTypes : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedTypes);
      } else {
        props.onChange(selectedType);
      }
    }
  }, [selectedTypes, selectedType, ready])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="typeId"
    options={props.types.sort((a, b) => a.nombre === 'Banco' ? -1 : b.nombre === 'Banco' ? 1 : 0)}
    getOptionLabel={type => {
      if (type !== null && type.nombre !== undefined) {
        return type.nombre;
      } else {
        return null;
      }
    }}
    renderOption={(props, option) => {
      return <Stack direction="row" spacing={3} {...props}>
        {getCustodyAgentTypeIcon(option.nombre, "medium")}
        {option.nombre}
      </Stack>
    }}
    value={multiple ? selectedTypes : selectedType}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set selectedTypes to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedTypes([]);
          setReady(true);
          return null;
        };

        // User is trying to add an existing label, make sure the label isn't already selected
        setSelectedTypes(pushCustodyAgentType(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedType(newValue);
        setReady(true);
      }

    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((type, index) => (
        <Chip
          label={type.nombre}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Tipos de agente" : "Tipo de agente"}
      placeholder={multiple ? "Selecciona los tipos de agente" : "Selecciona el tipo de agente"}
    />
    }
  />
}

CustodyAgentTypePicker.propTypes = {
  selectedTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  types: PropTypes.arrayOf(PropTypes.object).isRequired, // All available CustodyAgentTypes
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
}


export default CustodyAgentTypePicker;