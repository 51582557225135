// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Button, FormControl, Switch, Typography } from "@mui/material";
import { Stack } from "@mui/system";

// My component imports
import EasyMonthRangePicker from "../inputs/easyMonthRangePicker.js";
import CategoriaPicker from "../inputs/categoriaPicker.js";
import LabelInput from "../inputs/labelInput.js";
import MyDialog from "../basic/MyDialog.js";
import { getDateTimeFromDateString, monthDiff } from "../../utils/date.js";

function FilterMonthlyExpenses(props) {

  // constants
  const INITIAL_MONTHS = 6;

  // State constants
  const [initialDate, setInitialDate] = useState(
    props.initialQueryObject.initialDate ? props.initialQueryObject.initialDate :
      new Date(new Date().getFullYear(), new Date().getMonth() - INITIAL_MONTHS, new Date().getDate())
  );
  const [finalDate, setFinalDate] = useState(
    props.initialQueryObject.finalDate ? props.initialQueryObject.finalDate :
      new Date()
  );

  const [selectedCategories, setSelectedCategories] = useState(props.initialQueryObject.categories);
  const [selectedLabels, setSelectedLabels] = useState(props.initialQueryObject.labels);
  const [labelsDialogOpen, setLabelsDialogOpen] = useState(false);
  const [filterLabelsWithAnd, setFilterLabelsWithAnd] = useState(props.initialQueryObject.filterLabelsWithAnd);


  // Hanlders
  const handleSubmit = () => props.onSubmit({
    initialDate: initialDate,
    finalDate: finalDate,
    currency: props.currencies.find(cur => cur.nombre_corto === "UYU"),
    categories: selectedCategories,
    labels: selectedLabels,
    filterLabelsWithAnd: filterLabelsWithAnd,
    months: monthDiff(getDateTimeFromDateString(initialDate), getDateTimeFromDateString(finalDate)),
  });

  // Handlers
  const handleDateChange = (initialDate, finalDate) => {
    setInitialDate(initialDate);
    setFinalDate(finalDate);
  }

  return <Stack spacing={1}>
    <EasyMonthRangePicker
      onChange={handleDateChange}
      initialDate={initialDate}
      finalDate={finalDate}
    />

    <CategoriaPicker
      initialValues={selectedCategories}
      onChange={selectedCategories => { setSelectedCategories(selectedCategories) }}
      categories={props.categories}
    />
    <LabelInput
      initialLabels={selectedLabels}
      onChange={selectedLabels => { setSelectedLabels(selectedLabels) }}
      hideAddButton={true}
    />
    <FormControl fullWidth>
      <Stack direction="row" spacing={1}>
        <Stack justifyContent="center">
          <Switch checked={filterLabelsWithAnd !== undefined ? filterLabelsWithAnd : false} onChange={() => setFilterLabelsWithAnd(prev => !prev)} />
        </Stack>
        <Stack justifyContent="center">
          <Typography>Debe tener todas las etiquetas</Typography>
        </Stack>
        <Stack justifyContent="center">
          <MyDialog onClose={() => setLabelsDialogOpen(false)} open={labelsDialogOpen} onOpen={() => setLabelsDialogOpen(true)}>
            Al activar este switch la búsqueda incluirá únicamente aquellos movimientos
            que contengan todas las etiquetas seleccionadas. Caso contrario, con que tenga una de las etiquetas seleccionadas ya se incluirá.
          </MyDialog>
        </Stack>
      </Stack>
    </FormControl>
    <Button variant="contained" onClick={handleSubmit}>Ver reporte</Button>
  </Stack>
}

FilterMonthlyExpenses.propsTypes = {
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user clicks on submit button
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List of all available categories
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired, // List of all available currencies
  initialQueryObject: {
    initialDate: PropTypes.instanceOf(Date),
    finalDate: PropTypes.instanceOf(Date),
    categories: PropTypes.oneOf([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    labels: PropTypes.oneOf([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    filterLabelsWithAnd: PropTypes.bool,
    months: PropTypes.number,
  }
};

export default FilterMonthlyExpenses;