const awsConfiguration = {
  // Auth: {

  //   // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //   // identityPoolId: '212638417101-ml6d2rchrukev51t86j0sdtb8km71fb2.apps.googleusercontent.com',

  //   // REQUIRED - Amazon Cognito Region
  //   region: 'us-east-1',

  //   // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
  //   // Required only if it's different from Amazon Cognito Region
  //   //identityPoolRegion: 'XX-XXXX-X',

  //   // OPTIONAL - Amazon Cognito User Pool ID
  //   userPoolId: 'us-east-1_xUdsLzr3B',

  //   // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  //   userPoolWebClientId: '5ucujdns9ks1bd04n3n2i3hlvm',

  //   // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  //   mandatorySignIn: false,

  //   // OPTIONAL - Configuration for cookie storage
  //   // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  //   cookieStorage: {
  //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  //     domain: 'localhost',
  //     // OPTIONAL - Cookie path
  //     //path: '/',
  //     // OPTIONAL - Cookie expiration in days
  //     //expires: 365,
  //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  //     //sameSite: "strict" | "lax",
  //     // OPTIONAL - Cookie secure flag
  //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  //     //secure: true
  //   }
  // },

  // OPTIONAL - customized storage object
  //storage: MyStorage,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  //authenticationFlowType: 'USER_PASSWORD_AUTH',

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  //clientMetadata: { myCustomKey: 'myCustomValue' },

  // OPTIONAL - Hosted UI configuration
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_REGION,
  oauth: {
    region: process.env.REACT_APP_AWS_REGION,
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
    scope: ['email', 'openid', 'profile'],
    // scope: ['email', 'profile', 'openid'],
    // redirectSignIn: 'https://devlocal.finanzasuy.com:3000/',
    // redirectSignOut: 'https://devlocal.finanzasuy.com:3000/signout',
    redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
    responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
};

const googleConfiguration = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};

const urls = {
  loginUrl: "/login",
  loginError: "/loginError",
  signUpUrl: "/signUp",
  homeUrl: "/",
  confirmAccountUrl: "/confirmAccount",
  passwordResetUrl: "/passwordReset",
  adminUrl: "/admin",
  configurationsUrl: "/configure",
  configureCustodyAgentUrl: "/configure/custody_agent",
  configureAccountsUrl: "/configure/accounts",
  configureDebtAccountsUrl: "/configure/debt_accounts",
  configureCardsUrl: "/configure/cards",
  configureFamily: "/configure/family",
  configureLabels: "/configure/labels",
  configureApp: "/configure/app",
  registrationsUrl: "/register",
  visualizationsUrl: "/visualize",
  visualizeCurrencyExchange: "/visualize/currency_exchange",
  visualizeExpenses: "/visualize/expenses",
  visualizeCashFlow: "/visualize/cash_flow",
  visualizeBalance: "/visualize/balance",
  visualizeMonthlyExpenses: "/visualize/monthly",
  searchUrl: "/search",
  searchMovementUrl: "/search/movement",
  userProfileUrl: "/userProfile",
  registerExpenseUrl: "/register/expense",
  registerIncomeUrl: "/register/income",
  registerTransferUrl: "/register/transfer",
  registerCurrencyExchangeUrl: '/register/currency_echange',
  subscriptionUrl: "/subscription",
  tutorialUrl: "/tutorial",
  checkoutUrl: "/checkout",
  supportUrl: "/support",
  schedulesUrl: "/schedules",
  scheduledMovementsUrl: "/scheduled_movements",
};

const apiUrl = process.env.REACT_APP_API_URL;

const appName = process.env.REACT_APP_APP_NAME;

const endpointNames = {
  login: '/login',
  perfil: '/perfil',
  usuarios: '/usuarios',
  cuentas: '/cuentas',
  monedas: '/monedas',
  categorias: '/categorias',
  tarjetas: '/tarjetas',
  movimientos: '/movimientos',
  movimientosTarjeta: '/movimientos_tarjeta',
  transferencias: '/transferencias',
  etiquetas: '/etiquetas',
  saldosDeTarjeta: '/saldos_de_tarjeta',
  estadosDeCuenta: '/estados_de_cuenta',
  cotizaciones: '/cotizaciones',
  pagosDeTarjeta: '/pagos_de_tarjeta',
  gastos: '/gastos',
  cashflow: '/cashflow',
  balance: '/balance',
  agentesDeCustodia: '/agentes_de_custodia',
  tiposDeAgente: '/tipos_de_agente',
  paises: '/paises',
  familias: '/familias',
  invitaciones: '/invitaciones',
  revocar: '/revocar',
  aceptar: '/aceptar',
  rechazar: '/rechazar',
  miembrosDeFamilia: '/miembros',
  refreshToken: '/refresh_token',
  suscripciones: '/subscriptions',
  suscripcionesDeUsuario: '/subscriptions',
  productos: '/products',
  planes: '/plans',
  soporte: '/soporte',
  actividad: '/actividad',
  agendas: '/agendas',
  movimientos_programados: '/movimientos_programados',
  movimientosTarjetaProgramados: '/movimientos_tarjeta_programados',
}

const apiEndpoints = {
  login: apiUrl + endpointNames.login,
  perfil: apiUrl + endpointNames.perfil,
  usuarios: apiUrl + endpointNames.usuarios,
  cuentas: apiUrl + endpointNames.cuentas,
  monedas: apiUrl + endpointNames.monedas,
  categorias: apiUrl + endpointNames.categorias,
  tarjetas: apiUrl + endpointNames.tarjetas,
  movimientos: apiUrl + endpointNames.movimientos,
  movimientosTarjeta: apiUrl + endpointNames.movimientosTarjeta,
  transferencias: apiUrl + endpointNames.transferencias,
  etiquetas: apiUrl + endpointNames.etiquetas,
  saldosDeTarjeta: apiUrl + endpointNames.saldosDeTarjeta,
  estadosDeCuenta: apiUrl + endpointNames.estadosDeCuenta,
  pagosDeTarjeta: apiUrl + endpointNames.pagosDeTarjeta,
  gastos: apiUrl + endpointNames.gastos,
  cashflow: apiUrl + endpointNames.cashflow,
  balance: apiUrl + endpointNames.balance,
  agentesDeCustodia: apiUrl + endpointNames.agentesDeCustodia,
  tiposDeAgente: apiUrl + endpointNames.tiposDeAgente,
  paises: apiUrl + endpointNames.paises,
  familias: apiUrl + endpointNames.familias,
  invitaciones: apiUrl + endpointNames.invitaciones,
  revocarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.revocar,
  aceptarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.aceptar,
  rechazarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.rechazar,
  refreshToken: apiUrl + endpointNames.refreshToken,
  suscripciones: apiUrl + endpointNames.suscripciones,
  suscripcionesDeUsuario: apiUrl + endpointNames.suscripciones + endpointNames.suscripcionesDeUsuario,
  productos: apiUrl + endpointNames.suscripciones + endpointNames.productos,
  planes: apiUrl + endpointNames.planes,
  soporte: apiUrl + endpointNames.soporte,
  actividad: apiUrl + endpointNames.actividad,
  agendas: apiUrl + endpointNames.agendas,
  movimientosProgramados: apiUrl + endpointNames.movimientos_programados,
  movimientosTarjetaProgramados: apiUrl + endpointNames.movimientosTarjetaProgramados,
}

export default awsConfiguration;
export { urls, apiEndpoints, endpointNames, googleConfiguration, appName };

