// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { scheduledMovementPropType } from '../../utils/myPropTypes';
import MovimientoTarjetaProgramadoListItemClassic from './movimientoTarjetaProgramadoListItemClassic';
import MovimientoTarjetaProgramadoListItemCompact from './movimientoTarjetaProgramadoListItemCompact';

function MovimientoTarjetaProgramadoListItem(props) {
  return props.compact ? <MovimientoTarjetaProgramadoListItemCompact {...props} /> : <MovimientoTarjetaProgramadoListItemClassic {...props} />;
}

MovimientoTarjetaProgramadoListItem.propTypes = {
  movimiento: PropTypes.oneOf([scheduledMovementPropType]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

export default MovimientoTarjetaProgramadoListItem;