// Proptypes imports
import PropTypes from "prop-types";

// React imports
import { useState, useEffect } from "react";

// MUI imports
import { TextField, Autocomplete, Chip } from "@mui/material";

// My components imports
import { MOVEMENT_TYPES, MOVEMENT_TYPES_DICT } from "../../utils/constants";

function ExpenseIncomeTransferPicker(props) {
  // Function that issues an Autocomplete to allow the user to pick between any combination of these categories ['cash', 'credit', 'transfers', 'exchanges']
  // using the multiple prop, the user can chose to disable multiple selection

  // Deconstruct props
  const { onChange, includeTransfers, includeCardPayments = false } = props;

  // Other constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [value, setValue] = useState(props.initialValues[0]);
  const [values, setValues] = useState(props.initialValues);
  const [ready, setReady] = useState(false);
  const [options, setOptions] = useState(MOVEMENT_TYPES);


  // Effect hooks
  useEffect(() => {
    if (ready) {
      onChange(multiple ? values : value);
    }
  }, [ready, values, value, multiple, onChange]);

  useEffect(() => {
    let isMounted = true;
    let newOptions = MOVEMENT_TYPES;
    if (!includeCardPayments) {
      newOptions = newOptions.filter(movType => movType !== 'cardPayments');
    }
    if (!includeTransfers) {
      newOptions = newOptions.filter(movType => movType !== 'transfers' && movType !== 'exchanges');
    }
    if (isMounted) {
      setOptions(newOptions);
    }
    return () => { isMounted = false };
  }, [includeCardPayments, includeTransfers]);

  return (
    <Autocomplete
      filterSelectedOptions
      fullWidth
      clearOnBlur
      multiple={multiple}
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => MOVEMENT_TYPES_DICT[option]}
      value={multiple ? values : value}
      onChange={(event, newValue) => {
        multiple ? setValues(newValue) : setValue(newValue);
        setReady(true);
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((movType, index) => (
          <Chip
            label={MOVEMENT_TYPES_DICT[movType]}
            {...getTagProps({ index })}
            variant="outlined"
            color="primary"
          />
        )
        )
      }

      renderInput={(params) => (
        <TextField
          error={multiple ? values.length === 0 : value === null}
          helperText={multiple ? values.length === 0 ? "Selecciona al menos uno o no verás nada" : "" : value === null ? "Debes seleccionar un tipo de movimiento" : ""}
          {...params}
          label={"Selecciona " + (multiple ? "los " : "el ") + "tipo" + (multiple ? 's' : '') + " de movimientos"}
          placeholder={"Selecciona" + (multiple ? "los " : "el ") + "tipo" + (multiple ? 's' : '') + " de movimientos"}
        />
      )}
    />
  );
}

ExpenseIncomeTransferPicker.propTypes = {
  initialValues: PropTypes.array.isRequired, // This is an array of strings that will be the values that the user can select
  onChange: PropTypes.func.isRequired, // This is the function that will be called when the value changes
  includeTransfers: PropTypes.bool.isRequired, // This is a boolean that will allow the user to select transfers and exchanges
  includeCardPayments: PropTypes.bool, // This is a boolean that will allow the user to select card payments, if not provided, it will be set to false
  multiple: PropTypes.bool, // This is a boolean that will allow the user to select multiple values
};

export default ExpenseIncomeTransferPicker; 