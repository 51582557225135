// MUI imports
import { createTheme } from '@mui/material/styles';

const standardTheme = createTheme({
  name: "primary",
  palette: {
    type: 'light',
    primary: {
      main: '#2196f3',
      light: '#4dabf5',
      dark: '#1769aa',
      contrastText: '#FFFFFF',
      lightText: '#717070',
      success: '#4caf50',
      error: '#f44336',
      cancel: '#838a96',
      info: '#2196f4',
      tableMainHeader: '#1769aa', // darker color for more emphasis
      tableSecondaryHeader: '#2196f3',
      hoverBackground: '#e3f2fd',
    },
    secondary: {
      main: '#4f504e',
    },
    cancel: {
      main: '#838a96',
    },
    disabled: {
      main: '#838a96',
    },
    error: {
      main: '#f44336', // Rojo
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800', // Naranja
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3', // Azul
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50', // Verde
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
    number: {
      positive: '#368139',
      negative: '#f44336',
    },
  },
});

const alternativeTheme = createTheme({
  name: "dark",
  palette: {
    primary: {
      main: '#757575',
      lightText: '#717070',
      dark: '#191919',
      contrastText: '#FFFFFF',
      success: '#4caf50',
      error: '#f44336',
      cancel: '#838a96',
      info: '#2196f4',
      tableMainHeader: '#191919',
      tableSecondaryHeader: '#757575',
      hoverBackground: '#e3f2fd',
    },
    secondary: {
      main: '#424242',
    },
    cancel: {
      main: '#838a96'
    },
    disabled: {
      main: '#838a96',
    },
    error: {
      main: '#f44336', // Rojo
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800', // Naranja
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3', // Azul
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50', // Verde
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
    number: {
      positive: '#4caf50',
      negative: '#f44336',
    },
  },
});

const pinkTheme = createTheme({
  name: "pink",
  palette: {
    primary: {
      main: '#ffcdd2',
      lightText: '#717070',
      dark: '#c98dc5',
      contrastText: '#FFFFFF',
      success: '#4caf50',
      error: '#f44336',
      cancel: '#838a96',
      info: '#2196f4',
      tableMainHeader: '#c98dc5',
      tableSecondaryHeader: '#ffcdd2',
      hoverBackground: '#e3f2fd',
    },
    secondary: {
      main: '#876262',
    },
    cancel: {
      main: '#838a96'
    },
    disabled: {
      main: '#838a96',
    },
    error: {
      main: '#f44336', // Rojo
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800', // Naranja
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3', // Azul
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50', // Verde
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
    number: {
      positive: '#4caf50',
      negative: '#f44336',
    },
  },
});

const themes = {
  primary: standardTheme,
  dark: alternativeTheme,
  pink: pinkTheme,
}

export default themes;