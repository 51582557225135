// React imports
import { useState } from "react";

// MUI component imports
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// PropTypes imports
import PropTypes from 'prop-types';

// Date-fns imports
import { es } from "date-fns/locale";

function EasyMonthPicker(props) {

  // State constants
  const [value, setValue] = useState(props.initialDate ? props.initialDate : null);

  // Handlers
  const changeDateHandler = newDate => {
    setValue(newDate);
    props.onChange(newDate);
  }

  return <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
    <DatePicker
      views={['year', 'month']}
      openTo="month"
      label="Mes del estado de cuenta"
      minDate={props.minValue ? props.minValue : new Date('2012-03-01')}
      maxDate={props.maxValue ? props.maxValue : new Date('2023-06-01')}
      value={value}
      onChange={changeDateHandler}
      renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
    />
  </LocalizationProvider>
}

EasyMonthPicker.propTypes = {
  onChange: PropTypes.func.isRequired, // function to be called when the user changes selection
  minValue: PropTypes.instanceOf(Date),  // Minimum date allowed for selection
  maxValue: PropTypes.instanceOf(Date),  // Maximum date allowed for selection
  initialDate: PropTypes.instanceOf(Date), // Date to initialize the component
}

export default EasyMonthPicker;