// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// MUI imports
import { FormControl, TextField, Button, Stack } from "@mui/material";

function FamilyForm(props) {

  // State constants
  const [name, setName] = useState(props.family ? props.family.nombre : '');

  // Change handlers
  const handleNameChange = event => setName(event.target.value);
  const handleSubmit = event => props.onSubmit({ nombre: name, id: props.family ? props.family.id : null, admin_id: props.family ? props.family.admin_id : null });

  return <Stack spacing={1}>
    <FormControl fullWidth>
      <TextField id="name" label="Nombre" variant="filled" value={name} onChange={handleNameChange} />
    </FormControl>

    <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>{props.intent === "create" ? "Crear familia" : "Modificar familia"}</Button>
    {props.intent === "modify" ?
      <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>Volver</Button> :
      <></>
    }

  </Stack>
}

FamilyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intent: PropTypes.string.isRequired,
  family: PropTypes.object,
}

export default FamilyForm;