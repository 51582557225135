// Styles imports
import { useTheme } from '@mui/material/styles';

// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI components imports
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { Stack, Typography } from '@mui/material';

// My component imports
import { accountPropType, cardMovementPropType, cardPropType, categoryPropType, currencyPropType } from '../../utils/myPropTypes';
import { getAmountText } from '../../utils/misc';
import NewMovementList from "../lists/newMovementList";
import BalanceCuentaListItem from './balanceCuentaListItem';

function BalanceTable(props) {

  // Theme constants
  const theme = useTheme();

  // State constants
  const [showAssets, setShowAssets] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [showAccountsRecivables, setShowAccountsRecivables] = useState(false);
  const [showAccountsPayables, setShowAccountsPayables] = useState(false);
  const [showLiabilities, setShowLiabilities] = useState(false);
  const [showAccountStates, setShowAccountStates] = useState(false);
  const [showCurrentMovements, setShowCurrentMovements] = useState(false);


  return <Stack>

    {/* --- Comienza el activo --- */}

    <Table stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableMainHeader, color: "white" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setShowAssets(prev => !prev)}
              color="inherit"
            >
              {showAssets ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ backgroundColor: theme.palette.primary.tableMainHeader }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography color={'white'} fontWeight={'bold'}>Activo</Typography>
              <Typography color={'white'} fontWeight={'bold'}>
                {getAmountText(props.balance.activo.total, props.currency)}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>

    <Collapse in={showAssets}>

      {/* --- Comienza disponibilidades --- */}
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader, color: "white" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowAccounts(prev => !prev)}
                color="inherit"
              >
                {showAccounts ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography color={'white'} fontWeight={'bold'} variant="body">Disponibilidades</Typography>
                <Typography color={'white'} fontWeight={'bold'} variant="body">
                  {getAmountText(props.balance.activo.disponibilidades.reduce((total, cuenta) => total + cuenta.balance, 0), props.currency)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Collapse in={showAccounts}>
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table>
            <TableBody>
              {props.balance.activo.disponibilidades.map(account => {
                return <BalanceCuentaListItem key={account.id} account={account} currency={props.currency} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

      {/* --- Comienza cuentas por cobrar --- */}
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader, color: "white" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowAccountsRecivables(prev => !prev)}
                color="inherit"
              >
                {showAccountsRecivables ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography color={'white'} fontWeight={'bold'} variant="body">Cuentas por cobrar</Typography>
                <Typography color={'white'} fontWeight={'bold'} variant="body">
                  {getAmountText(props.balance.activo.cuentas_por_cobrar.reduce((total, cuenta) => total + cuenta.balance, 0), props.currency)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Collapse in={showAccountsRecivables}>
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table>
            <TableBody>
              {props.balance.activo.cuentas_por_cobrar.map(account => {
                return <BalanceCuentaListItem key={account.id} account={account} currency={props.currency} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

    </Collapse>

    {/* --- Comienza el pasivo --- */}

    <Table stickyHeader={true} sx={{ marginTop: "5px" }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableMainHeader, color: "white" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setShowLiabilities(prev => !prev)}
              color="inherit"
            >
              {showLiabilities ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ backgroundColor: theme.palette.primary.tableMainHeader }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography color={'white'} fontWeight={'bold'}>Pasivo</Typography>
              <Typography color={'white'} fontWeight={'bold'}>
                {getAmountText(props.balance.pasivo.total, props.currency)}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>

    <Collapse in={showLiabilities}>

      {/* --- Comienza cuentas por pagar --- */}
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader, color: "white" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowAccountsPayables(prev => !prev)}
                color="inherit"
              >
                {showAccountsPayables ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography color={'white'} fontWeight={'bold'} variant="body">Cuentas por pagar</Typography>
                <Typography color={'white'} fontWeight={'bold'} variant="body">
                  {getAmountText(props.balance.pasivo.cuentas_por_pagar.reduce((total, cuenta) => total + cuenta.balance, 0), props.currency)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <Collapse in={showAccountsPayables}>
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table>
            <TableBody>
              {props.balance.pasivo.cuentas_por_pagar.map(account => {
                return <BalanceCuentaListItem key={account.id} account={account} currency={props.currency} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

      {/* --- Estados de cuenta de tarjeta --- */}
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader, color: "white" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowAccountStates(prev => !prev)}
                color="inherit"
              >
                {showAccountStates ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography color={'white'} fontWeight={'bold'} variant="body">Saldos de tarjetas</Typography>
                <Typography color={'white'} fontWeight={'bold'} variant="body">
                  {getAmountText(props.balance.pasivo.balances_de_tarjeta.total, props.currency)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Collapse in={showAccountStates}>
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table>
            <TableBody>
              {props.balance.pasivo.balances_de_tarjeta.balances.map(cardBalance => {
                return <TableRow key={cardBalance.tarjeta.id}>
                  <TableCell>
                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.secondary">{cardBalance.tarjeta.nombre}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {cardBalance.tarjeta.agente_de_custodia.tipo.nombre + ' ' + cardBalance.tarjeta.agente_de_custodia.nombre}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{getAmountText(cardBalance.balance, props.currency)}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>

      {/* --- Comienzan el pasivo - movimiento actuales --- */}
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader, color: "white" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setShowCurrentMovements(prev => !prev)}
                color="inherit"
              >
                {showCurrentMovements ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell sx={{ backgroundColor: theme.palette.primary.tableSecondaryHeader }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography color={'white'} fontWeight={'bold'} variant="body">Movimientos actuales</Typography>
                <Typography color={'white'} fontWeight={'bold'} variant="body">
                  {getAmountText(props.balance.pasivo.movimientos_actuales.total, props.currency)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Collapse in={showCurrentMovements}>
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table>
            <TableBody>
              <NewMovementList
                movements={props.balance.pasivo.movimientos_actuales.movimientos.map(item => item.movimiento)}
                updateList={props.updateBalance}
                cards={props.cards}
                accounts={props.accounts}
                categories={props.categories}
                showInstallment={true}
                compact={true}
                showSortBar={false}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Collapse>

    {/* --- Comienza el patrimonio --- */}

    <Table stickyHeader={true} sx={{ marginTop: "5px" }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" variant="head" sx={{ backgroundColor: theme.palette.primary.tableMainHeader, color: "white" }}></TableCell>
          <TableCell sx={{ backgroundColor: theme.palette.primary.tableMainHeader }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography color={'white'} fontWeight={'bold'}>Patrimonio</Typography>
              <Typography color={'white'} fontWeight={'bold'}>
                {getAmountText(props.balance.patrimonio.total, props.currency)}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  </Stack >
}

BalanceTable.propTypes = {
  balance: PropTypes.shape({
    moneda: PropTypes.shape(currencyPropType).isRequired,  // The currency the balance is expressed in
    activo: PropTypes.shape({
      total: PropTypes.number.isRequired,  // The total balance of the assets
      cuentas: PropTypes.arrayOf(PropTypes.shape({
        cuenta: PropTypes.shape(accountPropType).isRequired,  // The account
        balance: PropTypes.number.isRequired,  // The balance of the account expressed in the functional currency
      })).isRequired,  // The accounts of the user
    }).isRequired,  // The assets of the user
    pasivo: PropTypes.shape({
      total: PropTypes.number.isRequired,  // The total balance of the liabilities
      balances_de_tarjeta: PropTypes.arrayOf(PropTypes.shape({
        tarjeta: PropTypes.shape(cardPropType).isRequired,  // The card
        balance: PropTypes.number.isRequired,  // The balance of the card expressed in the functional currency
      })).isRequired,  // The card balances of the user
      movimientos_actuales: PropTypes.arrayOf(PropTypes.shape({
        movimiento: PropTypes.shape(cardMovementPropType).isRequired,  // The card movement
        balance: PropTypes.number.isRequired,  // The balance of the sum of all the card movements expressed in the functional currency
      })).isRequired,  // The card movements of the user
    }).isRequired,  // The liabilities of the user
    patrimonio: PropTypes.shape({
      total: PropTypes.number.isRequired,  // The total balance of the equity
    }).isRequired,  // The equity of the user
  }).isRequired,  // The balance of the user
  currency: currencyPropType.isRequired,  // The currency the table is expressed in
  updateBalance: PropTypes.func.isRequired,  // The function to be called when the balance needs to be updated because something has changed
  cards: PropTypes.arrayOf(PropTypes.shape(cardPropType)).isRequired,  // The cards of the user
  accounts: PropTypes.arrayOf(PropTypes.shape(accountPropType)).isRequired,  // The accounts of the user
  categories: PropTypes.arrayOf(PropTypes.shape(categoryPropType)).isRequired,  // The categories of the user
}

export default BalanceTable;