// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Typography, Box, Button } from "@mui/material";
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTheme } from '@mui/material/styles';

// My components imports
import ConfirmationDialog from './confirmationDialog';
import { dateTimeToDisplayFormat } from '../../utils/date';

function ReceivedFamilyInvitationListItem(props) {

  // Other constants
  const theme = useTheme();
  const sentText = props.invitation ? dateTimeToDisplayFormat(props.invitation.creada_en) : '';
  const rejectedText = props.invitation ? (props.invitation.rechazada ? props.invitation.rechazada_en : '') : '';
  const revokedText = props.invitation ? (props.invitation.revocada ? props.invitation.revocada_en : '') : '';
  const icon = <FamilyRestroomIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />;

  // State constants
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [dialogExpectedText, setDialogExpectedText] = useState('');
  const [userIsRejectingInvitation, setUserIsRejectingInvitation] = useState(false);
  const [userIsAcceptingInvitation, setUserIsAcceptingInvitation] = useState(false);

  // Effect hooks
  useEffect(() => {
    console.log(props.invitation);
  }, [props.invitation])

  // Handlers
  const rejectClickHandler = event => {
    event.stopPropagation();
    setDialogTitle('Rechazar invitación');
    setDialogText('¿Está seguro/a que desea rechazar la invitación?');
    setDialogExpectedText('Rechazar');
    setDialogOpen(true);
    setUserIsRejectingInvitation(true);
    setUserIsAcceptingInvitation(false);
  }

  const acceptClickHandler = event => {
    event.stopPropagation();
    setDialogTitle('Aceptar invitación');
    let newDialgText = '';
    if (props.userHasActiveSubscription) {
      newDialgText = 'Parece que tienes una suscripción activa. Cuando perteneces a una familia, solo el administrador de la familia paga suscripción.';
      newDialgText += ' Por este motivo, si aceptas la invitación, cancelaremos tu suscripción actual y te uniremos a la familia.';
      newDialgText += ' ¿Estás seguro/a de que deseas aceptar la invitación y que tu suscripción sea cancelada?';
    } else {
      newDialgText += ' ¿Estás seguro/a de que deseas aceptar la invitación?';
    }

    setDialogText(newDialgText);
    setDialogExpectedText('Aceptar');
    setDialogOpen(true);
    setUserIsRejectingInvitation(false);
    setUserIsAcceptingInvitation(true);
  }

  return <Stack spacing={1}>
    <Typography variant="body1" fontWeight={"bold"}>{"Invitación a integrar una familia:"}</Typography>
    <Stack direction="row" sx={{ padding: "25px" }} spacing={2}>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setDialogOpen(false);
          if (userIsAcceptingInvitation) {
            props.onAccept(props.invitation);
          } else if (userIsRejectingInvitation) {
            props.onReject(props.invitation);
          }
        }}
        title={dialogTitle}
        text={dialogText}
        expectedText={dialogExpectedText}
      />

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
        {icon}
      </Box>

      <Stack direction="column" spacing={0} justifyContent={'center'} sx={{ flexGrow: 1 }}>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1">{"Familia:"}</Typography>
          <Typography variant="body1" fontWeight="bold">{props.invitation.familia.nombre}</Typography>
        </Stack>


        <Stack direction="row" spacing={0.5}>
          <Typography variant="caption">
            Recibida:
          </Typography>
          <Typography variant="caption">
            {sentText}
          </Typography>
        </Stack>

        {props.invitation.rechazada ?
          <Stack>
            <Typography variant="caption">
              Rechazada:
            </Typography>
            <Typography variant="caption">
              {rejectedText}
            </Typography>
          </Stack> :
          <></>
        }

        {props.invitation.revocada ?
          <Stack>
            <Typography variant="caption">
              Revocada:
            </Typography>
            <Typography variant="caption">
              {revokedText}
            </Typography>
          </Stack> :
          <></>
        }
      </Stack>

      {props.invitation.revocada ?
        <Stack direction="column" justifyContent={'center'}>
          <CancelIcon fontSize="medium" sx={{ color: theme.palette.primary.cancel }} />
        </Stack> : (
          props.invitation.rechazada ?
            <Stack direction="column" justifyContent={'center'}>
              <ThumbDownIcon fontSize="medium" sx={{ color: theme.palette.primary.cancel }} />
            </Stack> : <></>
        )
      }

    </Stack >

    {
      (props.invitation.revocada || props.invitation.rechazada) ? <></> :
        <Stack direction={'row'} spacing={1} justifyContent="space-evenly">
          <Button variant="contained" color="success" onClick={acceptClickHandler} fullWidth>
            <Stack direction={'row'} spacing={1} justifyContent="center">
              <ThumbUpIcon />
              <Typography variant="button">Aceptar</Typography>
            </Stack>
          </Button>
          <Button variant="contained" color="error" onClick={rejectClickHandler} fullWidth>
            <Stack direction={'row'} spacing={1} justifyContent="center">
              <ThumbDownIcon />
              <Typography variant="button">Rechazar</Typography>
            </Stack>
          </Button>
        </Stack>
    }

  </Stack >
}

ReceivedFamilyInvitationListItem.propTypes = {
  invitation: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onReject: PropTypes.func, // Function to be called when user whats to reject the invitation
  onAccept: PropTypes.func, // Function to be called when user whats to accept the invitation
  userHasActiveSubscription: PropTypes.bool, // True if user has an active subscription
};

export default ReceivedFamilyInvitationListItem;