// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField, Typography, Stack } from "@mui/material";
import { accountPropType } from "../../utils/myPropTypes";

// Auxiliary functions
function pushAccount(account, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === account.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(account);
  }
  return list;
}

function CuentaPicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;
  const { initialValues, accounts } = props;

  // State constants
  const [selectedAccounts, setSelectedAccounts] = useState(initialValues ? initialValues : []);
  const [selectedAccount, setSelectedAccount] = useState(initialValues ? initialValues : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedAccounts);
      } else {
        props.onChange(selectedAccount);
      }
    }
  }, [selectedAccounts, selectedAccount, ready])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setReady(false);
    }
    return () => { isMounted = false }
  }, [accounts, initialValues])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (multiple) {
        setSelectedAccounts(initialValues);
      } else {
        setSelectedAccount(initialValues);
      }
    }
    return () => { isMounted = false }
  }, [initialValues])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="cuentaId"
    options={accounts}
    // defaultValue={[]}
    getOptionLabel={acct => {
      if (acct !== null && acct.nombre !== undefined) {
        return acct.agente_de_custodia.nombre + ': ' + (acct.nombre ? acct.nombre : acct.tipo_de_cuenta.nombre) + ' (' + acct.moneda.nombre + ', ' + acct.moneda.nombre_corto + ')';
      } else {
        return null;
      }
    }}
    value={multiple ? selectedAccounts : selectedAccount}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));
        const lastItem = myNewList.pop();
        // if lastItem is undefined set selectedAccounts to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedAccounts([]);
          setReady(true);
          return null;
        };
        // User is trying to add an existing account, make sure the account isn't already selected
        setSelectedAccounts(pushAccount(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedAccount(newValue);
        setReady(true);
      }
    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((account, index) => (
        <Chip
          label={account.nombre + ' (' + account.moneda.nombre_corto + ')'}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={props.label ? props.label : (multiple ? "Cuentas" : "Cuenta")}
      placeholder={multiple ? "Selecciona las cuentas" : "Selecciona la cuenta"}
    />
    }

    renderOption={(props, option) => {
      return <Stack sx={{ marginBottom: "10px" }} {...props}>
        <Typography variant="subtitle2">{option.agente_de_custodia.nombre + ': ' + (option.nombre ? option.nombre : option.tipo_de_cuenta.nombre)}</Typography>
        <Typography variant="body2" style={{ fontStyle: 'italic' }}>({option.moneda.nombre})</Typography>
      </Stack>
    }}
  />
}

CuentaPicker.propTypes = {
  initialValues: PropTypes.oneOfType([accountPropType, PropTypes.arrayOf(accountPropType)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // All available accounts
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
  label: PropTypes.string, // Default: "Cuenta", Label to be displayed
}

export default CuentaPicker;