// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function Transferencias() {
  return <Typography variant="body1">
    Las <TextChipLink label="transferencias" url={urls.registerTransferUrl} /> las utilizarás para registrar el movimiento de dinero entre tus cuentas.
    Por ejemplo, cuando hagas una transferencia bancaria de una cuenta propia a otra cuenta propia, lo registrarás como una transferencia.
    Del mismo modo, cuando retiras dinero del cajero automático, lo registrarás como una transferencia desde la cuenta bancaria a la billetera.
    Es importante notar que si transfieres dinero de una cuenta propia a una cuenta de un tercero, no debes registrar una transferencia, sino un gasto, dado que seguramente estés pagando por un bien o un servicio.
    <br /><br />
    Cómo verás mas adelante, cuando transfieres dinero entre una cuenta propia en una moneda a otra cuenta propia con una moneda diferente, en realidad no estás transfiriendo dinero, sino que estás realizando un <TextChipLink label="cambio de moneda" url={urls.registerCurrencyExchangeUrl} /> y deberás registrarlo como tal.
  </Typography>
}

export default Transferencias;