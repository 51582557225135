// App logo import
import appLogo from "../../static_content/FinanzasUY.png";

// Prop types import
import PropTypes from "prop-types";

// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Button, Stack, Typography, TextField, Accordion, AccordionDetails, AccordionSummary, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper } from "@mui/material";
import { ContactlessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

// My components imports
import { paypalSubscriptionPropType } from '../../utils/myPropTypes';
import { payPalDateToDisplayFormat } from "../../utils/date";

const PAYPAL_STATUS_DICTIONARY = {
  ACTIVE: "ACTIVA",
  CANCELLED: "CANCELADA",
  EXPIRED: "EXPIRADA",
  SUSPENDED: "SUSPENDIDA",
};

function SubscriptionListItem({ subscription, onCancelSubscription }) {

  // State constants
  const [reason, setReason] = useState("");
  const [showCancelationOption, setShowCancelationOption] = useState(false);
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  // Other constants
  const isInTrialPeriod = new Date(subscription.fecha_de_fin_de_prueba) > new Date();

  // Effects, temporary to debug. Delete later
  useEffect(() => {
    console.log(subscription);
  }, [subscription]);

  // Handlers
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleCancelSubscription = () => {
    onCancelSubscription(reason);
  }

  const handleCancelSubscriptionClick = () => {
    setShowAreYouSure(true);
  }

  return <Stack spacing={0}>
    <Stack>
      <Stack spacing={1} sx={{ padding: "10px", paddingLeft: "10px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <img src={appLogo} alt="App logo" style={{ width: 90, height: "auto" }} />
          <Typography variant="body1">{`${subscription.plan_de_suscripcion.nombre} - ${PAYPAL_STATUS_DICTIONARY[subscription.status]}`}</Typography>
        </Stack>
        {subscription.status === "CANCELLED" &&
          <Typography variant="caption" fontStyle="italic">
            {`Si cambias de opinión, podrás volver a suscribirte una vez que la suscripción actual expire`}
          </Typography>
        }
      </Stack>

      <Stack spacing={1} sx={{ padding: "10px" }}>
        <Typography variant="body2">{`Inicio de la suscripción: ${payPalDateToDisplayFormat(subscription.fecha_de_inicio)}`}</Typography>
        {isInTrialPeriod ?
          <Typography variant="body2">{`Fin de la prueba gratuita: ${payPalDateToDisplayFormat(subscription.fecha_de_fin_de_prueba)}`}</Typography> :
          <Typography variant="body2">
            {(subscription.status === "ACTIVE" ? 'Próxima renovación:' : 'Fecha de expiración:') + `${payPalDateToDisplayFormat(subscription.fecha_de_fin)}`}
          </Typography>
        }
        <Typography variant="body2">{`Precio: ${subscription.plan_de_suscripcion.monto} ${subscription.plan_de_suscripcion.moneda} por ${subscription.plan_de_suscripcion.frecuencia}`}</Typography>
      </Stack>
    </Stack>

    <Dialog
      open={showAreYouSure}
      onClose={() => setShowAreYouSure(false)}
    >
      <DialogTitle>¡Cuidado! Esta acción no tiene vuelta atrás</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Si decides cancelar y luego te arrepientes, deberás volver a suscribirte una vez que la suscripción expire.
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => setShowAreYouSure(false)}>Me arrepentí</Button>
          <Button onClick={handleCancelSubscription}>Si, quiero cancelar</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
    {subscription.status === "ACTIVE" &&
      <Accordion
        expanded={showCancelationOption}
        onChange={() => setShowCancelationOption(prev => !prev)}
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="body1">Cancelar suscripción</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
            <TextField label="Razon" value={reason} onChange={handleReasonChange} multiline rows={3} variant="outlined" />
            <Button variant="contained" onClick={handleCancelSubscriptionClick} disabled={!reason}>Cancelar suscripción</Button>
          </Stack>
        </AccordionDetails>
      </Accordion>}
  </Stack >;
}

SubscriptionListItem.propTypes = {
  subscription: paypalSubscriptionPropType.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
};

export default SubscriptionListItem;