// React components imports
import { useEffect, useState } from "react";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings.js"
import SubscriptionsHandler from "./subscriptionsHandler.js";
import MyPaper from "../basic/myPaper.js";
import { getUser } from "../../utils/api.js";
import { Alert } from "@mui/material";
import MyLoadingList from "../basic/myLoadingList.js";

function SubscriptionPage() {

  // State constants
  const [user, setUser] = useState(null);
  const [userIsHeadOfFamily, setUserIsHeadOfFamily] = useState(false);
  const [loading, setLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoading(true);
      getUser()
        .then(response => {
          console.log('This is the user', response);
          setUser(response);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && user) {
      setUserIsHeadOfFamily(user.familia && user.familia.admin_id === user.id || !user.familia);
    }
    return () => isMounted = false;
  }, [user]);

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
    ]}
    currentPageName="Suscripción"
  >
    <MyPaper>
      {
        loading ?
          <MyLoadingList /> :
          (userIsHeadOfFamily ? <SubscriptionsHandler /> : <Alert severity="info">Solo el administrador de la familia puede suscribirse a un plan</Alert>)
      }
    </MyPaper>
  </MyMenuComponent>


}

export default SubscriptionPage;