// MUI imports
import MyPaper from "./myPaper";
import { Stack } from "@mui/material";

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AddButton(props) {
  return <MyPaper shallow>
    <Stack direction="row" justifyContent="flex-end">
      <AddCircleIcon sx={{ fontSize: "65px", cursor: "pointer" }} color="primary" onClick={() => props.onClick()} />
    </Stack>
  </MyPaper>
}

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired, // Function to be called when user clicks de button
}

export default AddButton;