// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function ReporteDeCotizacionDeMonedas() {
  return <Typography variant="body1">
    El <TextChipLink label="Reporte de cotización de monedas" url={urls.visualizeCurrencyExchange} /> te mostrará la cotización del día de las monedas disponibles en la aplicación:
    <br /><br />
    <ul>
      <li>Dólar estadounidense (USD)</li>
      <li>Euro (EUR)</li>
      <li>Real brasileño (BRL)</li>
      <li>Peso argentino (ARS)</li>
      <li>Unidad indexada (UYI)</li>
    </ul>
    Adicionalmente, el reporte te permite seleccionar un rango de fechas y una o varias monedas para analizar su evolución en el tiempo.
    Al graficar, se mostrará el promedio entre la cotización de compra y venta de cada moneda para cada día.
  </Typography>
}

export default ReporteDeCotizacionDeMonedas;