// MUI imports
import { Divider, Stack, Typography } from "@mui/material";

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import EtiquetalListItem from "../basic/etiquetaListItem";


function DisplayEtiquetaList(props) {
  return <Stack spacing={1} direction="column" divider={<Divider flexItem />}>
    {props.etiquetasList && props.etiquetasList.length > 0 ?
      props.etiquetasList.map((etiqueta, index) => {
        return <EtiquetalListItem
          key={index}
          etiqueta={etiqueta}
          onModify={props.onModify}
          onDelete={props.onDelete}
          disableClicks={props.disableClicks}
        />
      })
      :
      <Stack alignItems="center">
        <Typography variant="body2">No hay etiquetas para mostrar</Typography>
      </Stack>
    }
  </Stack>
}

DisplayEtiquetaList.propTypes = {
  etiquetasList: PropTypes.arrayOf(PropTypes.object).isRequired, // List of movements to display
  onDelete: PropTypes.func.isRequired, // Function to be called when a movement is to be deleted
  onModify: PropTypes.func.isRequired, // Function to be called when the user clicks to modify the momvement
}

export default DisplayEtiquetaList;