// React imports
import { useEffect, useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Button } from '@mui/material';

function ToggleCardBalance(props) {

  // Handlers
  const changeBalanceHanler = bal => {
    props.onChange(bal)
  }

  return <Stack direction="row" justifyContent="space-between" spacing={0.5}>
    {props.balances.map(balance => {
      return <Button
        fullWidth
        key={balance.id}
        variant={props.selectedBalance.id === balance.id ? "contained" : "outlined"}
        onClick={() => changeBalanceHanler(balance)}
        size="small"
      >
        {'Saldo en (' + balance.moneda.nombre_corto + ')'}
      </Button>
    })}
  </Stack>
}

ToggleCardBalance.propTypes = {
  balances: PropTypes.arrayOf(PropTypes.object).isRequired, // Balances from wich to toggle
  selectedBalance: PropTypes.object.isRequired, // Parent controlled value
  onChange: PropTypes.func.isRequired, // Function to be called when the value changes
}

export default ToggleCardBalance;