// React imports
import { useEffect, useState, useRef } from "react";

// React Router imports
import { useParams, useHistory } from "react-router-dom";

// MUI component imports
import { Alert, Stack, Button } from "@mui/material";

// My component imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings.js";
import { deleteCardMovement, getCardAccountStates, getCardMovements, getCards, getCategories } from "../../utils/api";
import { dateGetFirstDayOfPastMonth, dateGetLastDayOfMonth, dateGetFirstDayOfMonth } from "../../utils/date";
import EstadoDeCuentaListItem from "../basic/estadoDeCuentaListItem";
import MyPaper from "../basic/myPaper.js";
import MyLoadingList from "../basic/myLoadingList.js";
import DisplayMovementList from "../lists/displayMovementList";
import RegisterCardMovement from "./registerCardMovement";
import EasyMonthPicker from "../inputs/easyMonthPicker";
import { useUserProfile } from "../../utils/userProfile";


function CardAccountStatesPage(props) {

  // Constants
  const params = useParams();
  const { cardId } = useParams();
  const firstUpdate = useRef(true);
  const history = useHistory();

  // State constants
  const { profile, setProfile } = useUserProfile();
  const [accountState, setAccountState] = useState(null);
  const [minDate, setMinDate] = useState(dateGetFirstDayOfPastMonth(new Date()));
  const [maxDate, setMaxDate] = useState(dateGetLastDayOfMonth(new Date()));
  const [selectedDate, setSelectedDate] = useState(null);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cardMovementListToDisplay, setCardMovementListToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingCardMovement, setEditingCardMovement] = useState(false);
  const [initialValues, setInitialValues] = useState({
    initialCardMovement: null,
    intent: 'modify'
  })
  const [updateAccountState, setUpdateAccountState] = useState(false);
  const [noAccountState, setNoAccountState] = useState(false);
  const [currentCard, setCurrentCard] = useState(null); // This is for the purpose of showing card name in navigation bar only

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([
      getCardAccountStates(params.cardId, { closed: true }),
      getCards(),
      getCategories()
    ])
      .then(response => {
        if (isMounted) {
          if (response[0].results.length === 0) {
            setNoAccountState(true);
          } else {
            let accountStates = response[0].results.sort((a, b) => new Date(a.fecha_de_cierre) - new Date(b.fecha_de_cierre));
            setMinDate(new Date(accountStates[0].fecha_de_cierre));
            setMaxDate(new Date(accountStates[accountStates.length - 1].fecha_de_cierre));
            setAccountState(accountStates[accountStates.length - 1]);
            setSelectedDate(new Date(accountStates[accountStates.length - 1].fecha_de_cierre));
            setCards(response[1].results);
            setCategories(response[2].results);
          }
        }
      })
      .catch(err => console.log(err))
    return () => { isMounted = false }
  }, [params.cardId])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && cards.length > 0) {
      // Find current card based on card id and set it to currentCard
      let card = cards.find(card => card.id === parseInt(cardId));
      setCurrentCard(card);
    }
    return () => { isMounted = false }
  }, [cards, cardId])


  useEffect(() => {
    if (accountState !== null) {
      getCardMovements({ accountStates: [accountState] })
        .then(response => {
          setCardMovementListToDisplay(response.results);
        })
        .catch(err => console.log(err));
    }
  }, [accountState])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !firstUpdate.current) {
      setLoading(false);
    }
    return () => { isMounted = false }
  }, [cardMovementListToDisplay])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && selectedDate) {
      setLoading(true);
      getCardAccountStates(params.cardId, { initialDate: dateGetFirstDayOfMonth(selectedDate), finalDate: dateGetLastDayOfMonth(selectedDate), closed: true })
        .then(response => {
          setAccountState(response.results[0]);
        })
        .catch(err => console.log(err));
    }
    return () => { isMounted = false }
  }, [selectedDate, params.cardId, updateAccountState])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setLoading(true);
      setEditingCardMovement(true);
    }
  }, [initialValues])

  // Handlers
  const handleAccountStateClick = state => {
    console.log('clicked');
  }

  const handleDeleteCardMovement = cardMovement => {
    deleteCardMovement(cardMovement.id)
      .then(() => {
        setUpdateAccountState(prev => !prev);
      })
      .catch(err => console.log(err))
  }

  const handleModifyCardMovementClick = cardMovement => {
    setInitialValues({
      initialCardMovement: cardMovement,
      intent: 'modify',
    })
  }

  const handleCardMovementChange = cardMovement => {
    setUpdateAccountState(prev => !prev);
    setEditingCardMovement(false);
  }

  const handleCancelCardMovement = () => {
    setEditingCardMovement(false);
    setUpdateAccountState(prev => !prev);
  }

  return <MyMenuComponent
    links={[
      { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
      { url: currentCard ? urls.configureCustodyAgentUrl + "/" + currentCard.agente_de_custodia.id : urls.configureCustodyAgentUrl, name: currentCard ? currentCard.agente_de_custodia.nombre : 'Cargando...' },
      { url: urls.configureCardsUrl + "/" + cardId, name: currentCard ? currentCard.nombre : 'Cargando...' }
    ]}
  >
    {
      noAccountState ?
        <MyPaper shallow>
          <Stack spacing={1}>
            <Alert severity="info">No hay estados de cuenta generados aún para esta tarjeta</Alert>
            <Button variant="contained" color="cancel" onClick={() => history.push(urls.configureCardsUrl + '/' + params.cardId)} fullWidth>Volver</Button>
          </Stack>
        </MyPaper> :
        (editingCardMovement ?
          <MyPaper>
            <RegisterCardMovement
              initialValues={initialValues}
              cards={cards}
              categories={categories}
              onSubmit={handleCardMovementChange}
              onCancel={handleCancelCardMovement}
              isExpense={initialValues.initialCardMovement.monto < 0}
            />
          </MyPaper>
          :
          <Stack sx={{ paddingTop: "15px" }} spacing={1}>
            <MyPaper>
              <EasyMonthPicker
                onChange={value => setSelectedDate(value)}
                minValue={minDate}
                maxValue={maxDate}
                initialDate={selectedDate}
              />
            </MyPaper>

            <Stack>
              {
                accountState ?
                  <MyPaper>
                    <EstadoDeCuentaListItem
                      estadoDeCuenta={accountState}
                      onClick={handleAccountStateClick}
                      movimientos={cardMovementListToDisplay}
                    />
                  </MyPaper>
                  :
                  <></>
              }

              {
                loading ?
                  <MyPaper><MyLoadingList /></MyPaper>
                  :
                  <MyPaper>
                    <DisplayMovementList
                      movementList={cardMovementListToDisplay}
                      onDelete={handleDeleteCardMovement}
                      onModify={handleModifyCardMovementClick}
                      showInstallment
                      compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
                    />
                  </MyPaper>
              }
            </Stack>

          </Stack>)
    }


  </MyMenuComponent>
}

export default CardAccountStatesPage;