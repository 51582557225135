// React router imports
import { useHistory, useParams } from 'react-router-dom';

// React imports
import { useState, useEffect } from 'react';

// MUI imports
import { Stack } from '@mui/system';
import { Dialog, DialogContent, Divider, Typography, Button } from '@mui/material';

// My component imports
import MyMenuComponent from './myMenuComponent';
import { urls } from '../../settings';
import { getUserAccounts, getCards, getCustodyAgent, getCustodyAgentsTypes, getCountries, getCurrencies } from '../../utils/api';
import MyLoadingList from '../basic/myLoadingList';
import MyPaper from '../basic/myPaper';
import CreateModifyCustodyAgent from './createModifyCustodyAgent';
import CustodyAgentHeader from '../basic/custodyAgentHeader';
import CuentaListItem from '../basic/cuentaListItem.js';
import TarjetaListItem from '../basic/tarjetaListItem.js';
import CreateModifyAccount from './createModifyAccount';
import { emptyAccount, emptyCard } from '../../utils/constants';
import CreateModifyCard from './createModifyCard';

function ConfigureCustodyAgent(props) {

  // Constants
  const { custodyAgentId } = useParams();
  const history = useHistory();

  // State constants
  const [custodyAgent, setCustodyAgent] = useState(null);
  const [custodyAgentTypes, setCustodyAgentTypes] = useState([]);
  const [cards, setCards] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [updateList, setUpdateList] = useState(true);
  const [showCustodyAgentForm, setShowCustodyAgentForm] = useState(false);
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [countries, setCountries] = useState([]);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    setInitialized(false);
    setAccountsLoaded(false);
    Promise.all([getCustodyAgent(custodyAgentId), getCustodyAgentsTypes(), getCountries()])
      .then(responses => {
        if (isMounted) {
          setCustodyAgent(responses[0]);
          setCustodyAgentTypes(responses[1].results);
          setCountries(responses[2].results);
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [custodyAgentId, updateList])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && custodyAgent !== null) {
      Promise.all([getUserAccounts(custodyAgent.usuario.id), getCards(custodyAgent.usuario.id), getCurrencies()])
        .then(responses => {
          if (isMounted) {
            setAccounts(responses[0].results.filter(acct => acct.agente_de_custodia.id === custodyAgent.id));
            setCards(responses[1].results.filter(card => card.agente_de_custodia.id === custodyAgent.id));
            setCurrencies(responses[2].results);
          }
        })
        .catch(err => console.log(err));
    }
    return () => { isMounted = false }
  }, [custodyAgent])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && custodyAgent !== null && accounts !== null) {
      setAccountsLoaded(true);
    }
    return () => { isMounted = false }
  }, [accounts])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && custodyAgent !== null && cards !== null) {
      setCardsLoaded(true);
    }
    return () => { isMounted = false }
  }, [cards])

  useEffect(() => {
    let isMounted = true;
    if (custodyAgent !== null && custodyAgentTypes.length > 0 && countries.length > 0) {
      if (isMounted) {
        setInitialized(true);
      }
    }
    return () => { isMounted = false }
  }, [custodyAgent, custodyAgentTypes, countries])


  // Handlers
  const handleAccountChanged = () => {
    setShowCustodyAgentForm(false);
    setUpdateList(prev => !prev)
  }

  const handleCardCreated = () => {
    setShowCardForm(false);
    setUpdateList(prev => !prev)
  }

  const handleAccountCreated = () => {
    setShowAccountForm(false);
    setUpdateList(prev => !prev);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
    ]}
  >

    <Dialog open={showCustodyAgentForm} onClose={() => setShowCustodyAgentForm(false)}>
      <DialogContent>
        <CreateModifyCustodyAgent
          initialValues={{
            initialCustodyAgent: custodyAgent,
            intent: 'modify'
          }}
          onSubmit={handleAccountChanged}
          oncancel={() => setShowCustodyAgentForm(false)}
          custodyAgentTypes={custodyAgentTypes}
          countries={countries}
        />
      </DialogContent>
    </Dialog>

    <Stack>
      {initialized ? <>
        <MyPaper>
          <CustodyAgentHeader
            custodyAgent={custodyAgent}
            onClick={() => setShowCustodyAgentForm(true)}
          />
        </MyPaper>
        <Stack>
          <Typography variant="subtitle2">Cuentas</Typography>
          <Dialog open={showAccountForm} onClose={() => setShowAccountForm(false)}>
            <DialogContent fullwidth>
              <CreateModifyAccount
                initialValues={{
                  initialAccount: emptyAccount,
                  intent: 'create',
                  custodyAgent: custodyAgent
                }}
                onSubmit={handleAccountCreated}
                onCancel={() => setShowAccountForm(false)}
                custodyAgents={[custodyAgent]}
                currencies={currencies}
              />
            </DialogContent>
          </Dialog>
          <MyPaper>
            {accountsLoaded ?
              (accounts.length === 0 ? <Typography variant="body1">No hay cuentas</Typography> :
                accounts.map((account, index, array) => {
                  let divider = index < array.length - 1 ? <Divider light /> : <></>;
                  return <Stack id={account.id}>
                    <CuentaListItem
                      cuenta={account}
                      onDelete={() => console.log('delete clicked')}
                      onModify={() => history.push(urls.configureAccountsUrl + '/' + account.id)}
                    />
                    {divider}
                  </Stack>
                })
              ) :
              <MyLoadingList />
            }
            <Stack>
              <Button variant="outlined" onClick={() => setShowAccountForm(true)}>Crear una cuenta</Button>
            </Stack>
          </MyPaper>
          {["Lugar físico", "Deudas"].includes(custodyAgent.tipo.nombre) ?
            <></> :
            <>
              <Typography variant="subtitle2">Tarjetas</Typography>
              <Dialog open={showCardForm} onClose={() => setShowCardForm(false)}>
                <DialogContent fullwidth>
                  <CreateModifyCard
                    initialValues={{
                      initialCard: (() => {
                        let card = emptyCard;
                        card.agente_de_custodia = custodyAgent;
                        return card;
                      })(),
                      intent: 'create',
                      custodyAgent: custodyAgent
                    }}
                    onSubmit={handleCardCreated}
                    onCancel={() => setShowCardForm(false)}
                    custodyAgents={[custodyAgent]}
                    currencies={currencies}
                  />
                </DialogContent>
              </Dialog>
              <MyPaper>
                {cardsLoaded ?
                  (cards.length === 0 ? <Typography variant="body1">No hay tarjetas</Typography> :
                    cards.map((card, index, array) => {
                      let divider = index < array.length - 1 ? <Divider light /> : <></>;
                      return <Stack id={card.id} sx={{ cursor: "pointer" }}>
                        <TarjetaListItem
                          tarjeta={card}
                          onDelete={() => console.log('delete clicked')}
                          onModify={() => history.push(urls.configureCardsUrl + "/" + card.id)}
                        />
                        {divider}
                      </Stack>
                    })
                  ) : <MyLoadingList />
                }
                <Stack>
                  <Button variant="outlined" onClick={() => setShowCardForm(true)} fullwidth>Crear una tarjeta</Button>
                </Stack>
              </MyPaper>
            </>
          }
        </Stack>
      </>
        :
        <></>
      }
    </Stack>


  </MyMenuComponent >
}

export default ConfigureCustodyAgent;