// React imports
import { useState, useEffect } from 'react';

// React Router imports
import { useHistory, useParams } from "react-router-dom";

// MUI imports
import { Dialog, DialogContent, Stack } from '@mui/material';

// My components imports
import { createCard, modifyCard, modifyCardBalance, getCard, getCurrencies, getUserAccounts, getCustodyAgents } from "../../utils/api.js";
import { urls } from "../../settings.js";
import MyMenuComponent from './myMenuComponent.js';
import CardForm from '../forms/cardForm.js';
import CardOtherActionsForm from './cardOtherActionsForm.js';
import MyPaper from '../basic/myPaper';
import MyLoading from '../basic/MyLoading.js';
import TarjetaDetailedCard from '../basic/tarjetaDetailedCard.js';
import FoldUnfoldButton from '../basic/FoldUnfoldButton.js';
import RegisterCardPayment from './registerCardPayment.js';
import SearchCardPayment from './searchCardPayment.js';
import { emptyCardPayment } from '../../utils/constants.js';

function ConfigureCard(props) {

  // Constants
  const history = useHistory();
  const params = useParams();

  // State constants
  const [currencies, setCurrencies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateCardData, setUPdateCardData] = useState(false);
  const [showCardForm, setShowCardForm] = useState(params.cardId === "new");
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showPaymentList, setShowPaymentList] = useState(false);
  const [custodyAgents, setCustodyAgents] = useState([]);

  // Effect hooks  
  useEffect(() => {

    let isMounted = true;

    Promise.all([getCurrencies(), params.cardId !== "new" ? getCard(params.cardId) : false, getUserAccounts(), getCustodyAgents()]).then(values => {
      if (isMounted) {
        setCurrencies(values[0].results);
        setAccounts(values[2].results);
        if (params.cardId !== 'new') {
          setCard(values[1].response);
        }
        setCustodyAgents(values[3].results.filter(custodyAgent => custodyAgent.tipo.nombre === 'Banco' || custodyAgent.tipo.nombre === 'Corredor de bolsa'));
        setLoading(false);
        setShowCardForm(false);
      }
    }, reason => console.log(reason));

    return () => { isMounted = false };
  }, [updateCardData, params.cardId])

  useEffect(() => {
    let isMounted = true;
    if (card && isMounted) {
      // setShowCardForm(false);
    }
    return () => { isMounted = false }
  }, [card]);

  // Change handlers
  const modifyCardHandler = (name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId) => {
    if (params.cardId === 'new') {
      createCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId)
        .then(newCard => history.push(urls.configureCardsUrl))
        .catch(err => console.log(err))
    } else if (params.cardId !== 'new') {
      modifyCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId, params.cardId)
        .then(newCard => {
          setCard(newCard);
          setUPdateCardData(prev => !prev);
        })
        .catch(err => console.log(err))
    }
  }

  const handleBalanceChange = async (saldo, newBalance) => {
    return modifyCardBalance(saldo.tarjeta_id, saldo.id, newBalance, saldo.moneda_id)
      .then((newSaldo) => {
        getCard(params.cardId)
          .then(value => {
            setCard(value.response);
          })
          .catch(err => console.log(err)); // Balances has changed, triger data update
      })
      .catch(err => { console.log(err); })
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
      { url: card ? urls.configureCustodyAgentUrl + '/' + card.agente_de_custodia.id : urls.configureCustodyAgentUrl, name: card ? card.agente_de_custodia.nombre : 'Configurar' },
    ]}
  // currentPageName={"Tarjeta"}
  >
    {loading ?
      <MyPaper><MyLoading /></MyPaper> :
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack spacing={1}>
          <Dialog open={showCardForm} onClose={() => setUPdateCardData(prev => !prev)}>
            <DialogContent>
              <CardForm
                card={card}
                onSubmit={modifyCardHandler}
                onCancel={() => setUPdateCardData(prev => !prev)}
                onBalanceChange={handleBalanceChange}
                currencies={currencies}
                agents={custodyAgents}
                intent='modify'
              />
            </DialogContent>
          </Dialog>
          <MyPaper>
            <TarjetaDetailedCard
              tarjeta={card}
              detailed
              onDelete={() => console.log('delete')}
              onModify={() => setShowCardForm(true)}
            />
          </MyPaper>
          <MyPaper>
            <Stack spacing={1}>
              <FoldUnfoldButton
                onClick={() => setShowPaymentForm(prev => !prev)}
                foldedText="Registrar pago"
                unFoldedText="Ocultar"
                startFolded={!showPaymentForm}
              />
              {showPaymentForm ?
                <RegisterCardPayment
                  accounts={accounts}
                  onSubmit={payment => {
                    setShowPaymentForm(false);
                    setUPdateCardData(prev => !prev);
                  }}
                  initialValues={{
                    initialCardPayment: (() => {
                      let temp = JSON.parse(JSON.stringify(emptyCardPayment));
                      temp.tarjeta = card;
                      return temp;
                    })(),
                    intent: "create",
                  }}
                />
                :
                <></>
              }
              <FoldUnfoldButton
                onClick={() => setShowPaymentList(prev => !prev)}
                foldedText="Pagos anteriores"
                unFoldedText="Ocultar"
                startFolded={!showPaymentList}
              />
              {showPaymentList ?
                <SearchCardPayment card={card} accounts={accounts} onPaymentChange={() => setUPdateCardData(prev => !prev)} /> : <></>
              }
            </Stack>
          </MyPaper>
        </Stack>
        <MyPaper>
          <CardOtherActionsForm />
        </MyPaper>
      </Stack>

    }
  </MyMenuComponent>
}

export default ConfigureCard;