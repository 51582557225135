// Proptypes imports
import PropTypes from "prop-types";

// React router imports
import { useHistory } from "react-router-dom";

// MUI imports
import { Chip } from "@mui/material";

function TextChipLink(props) {

  // Destructure props
  const { label, url } = props;

  // Constants
  const history = useHistory();

  return <Chip label={label} variant="outlined" color="primary" size="small" onClick={() => history.push(url)} />;
}

TextChipLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default TextChipLink;