// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Autocomplete, Chip, TextField, Stack } from "@mui/material";

// My components imports
import { getCategoryIcon } from "../../utils/misc";

// Auxiliary functions
function pushCategory(category, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === category.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(category);
  }
  return list;
}

function CategoriaPicker(props) {

  // Constants
  const multiple = props.multiple === undefined ? true : props.multiple;

  // State constants
  const [selectedCategories, setSelectedCategories] = useState(props.initialValues ? props.initialValues : []);
  const [selectedCategory, setSelectedCategory] = useState(props.initialValues ? props.initialValues : null);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (multiple) {
        props.onChange(selectedCategories);
      } else {
        props.onChange(selectedCategory);
      }
    }
  }, [selectedCategories, selectedCategory, ready])

  return <Autocomplete
    fullWidth
    multiple={multiple}
    filterSelectedOptions
    isOptionEqualToValue={(option, value) => option.id === value.id}
    clearOnBlur
    id="categoriaId"
    options={props.categories}
    // defaultValue={[]}
    getOptionLabel={category => {
      if (category !== null && category.nombre !== undefined) {
        return category.nombre;
      } else {
        return null;
      }
    }}
    renderOption={(props, option) => {
      return <Stack direction="row" spacing={3} {...props}>
        {getCategoryIcon(option.nombre, "medium")}
        {option.nombre}
      </Stack>
    }}
    value={multiple ? selectedCategories : selectedCategory}
    onChange={(event, newValue) => {
      if (multiple) {
        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set selectedAccounts to empty list
        if (typeof lastItem === 'undefined') {
          setSelectedCategories([]);
          setReady(true);
          return null;
        };

        // User is trying to add an existing label, make sure the label isn't already selected
        setSelectedCategories(pushCategory(lastItem, myNewList));
        setReady(true);
      } else {
        setSelectedCategory(newValue);
        setReady(true);
      }

    }}

    renderTags={(tagValue, getTagProps) =>
      tagValue.map((category, index) => (
        <Chip
          label={category.nombre}
          {...getTagProps({ index })}
          variant="outlined"
          color="primary"
        />
      )
      )
    }

    renderInput={(params) => <TextField
      {...params}
      label={multiple ? "Categorías" : "Categoría"}
      placeholder={multiple ? "Selecciona las categorías" : "Selecciona la categoría"}
    />
    }
  />
}

CategoriaPicker.propTypes = {
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]), // Values to initialize selection
  onChange: PropTypes.func.isRequired, // Function to be called when values changes
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // All available categories
  multiple: PropTypes.bool, // Default: true, Indicates whether multiple selection is allowed
}


export default CategoriaPicker;