// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function ReporteMensual() {
  return <Typography variant="body1">
    El <TextChipLink label="Reporte mensual" url={urls.visualizeMonthlyExpenses} /> te mostrará una gráfica con la evolución mensual de tus ingresos, gastos y ahorros a lo largo de un período determinado.
    <br /><br />
    Haciendo click en las barras de la gráfica podrás ver el detalle de los movimientos que componen los ingresos, gastos (o ambos para el caso del ahorro) para cada mes.
    <br /><br />
    Adicionalmente, el reporte te permite aplicar filtros para poder ver la evolución de aspectos específicos de tus finanzas en una base mensual.
    Por ejemplo, podrás ver la evolución mensual de tus gastos en una categoría específica, o la evolución de los gastos que contienen ciertas etiquetas.
    <br /><br />
    Además, el reporte permite seleccionar la moneda en la que se mostrarán los montos. Para lograr esto, la aplicación convierte los montos a la moneda seleccionada al tipo de cambio de la fecha en que se realizó cada gasto o ingreso.
    <br /><br />
    La Unidad Indexada (UYI) es una de las monedas disponibles en este reporte. Esta unidad modifica su valor diariamente en función de la inflación (% de aumento de precios) en Uruguay. Por este motivo, esta moneda es particularmente útil en este reporte para poder analizar la evolución de tus ingresos, gastos y ahorros en términos reales (descontando la inflación).
  </Typography>
}

export default ReporteMensual;