// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from '@mui/system';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// My component imports
import { groupCardMovementsByCurrency, getAmountText } from '../../utils/misc';

function EstadoDeCuentaListItem(props) {

  // Constants
  const theme = useTheme();
  const options = { year: 'numeric', month: 'long' };
  const periodText = new Date(props.estadoDeCuenta.fecha_de_cierre).toLocaleDateString('es-uy', options);
  const groupedMovements = groupCardMovementsByCurrency(props.movimientos);


  return (
    <Stack>
      <Stack>
        <Stack direction="row" spacing={1} onClick={() => props.onClick(props.estadoDeCuenta)}>
          <Stack justifyContent="center"><ListAltIcon fontSize="medium" color="primary" /></Stack>
          <Stack justifyContent="center"><Typography variant="h6">{periodText}</Typography></Stack>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Typography variant="subtitle2">
            {"Fecha de cierre: " + props.estadoDeCuenta.fecha_de_cierre}
          </Typography>
          <Typography variant="caption">
            {(props.estadoDeCuenta.cerrado ? "(Cerrado)" : "(Abierto)")}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        {Object.keys(groupedMovements).map((moneda, movimientos) => {
          const ammount = groupedMovements[moneda].reduce((sum, mov) => sum + mov.monto, 0);
          return (
            <Stack key={moneda} direction="row" spacing={1}>
              <Typography variant="subtitle2">
                Saldo en {moneda}:
              </Typography>
              <Typography variant="subtitle2" color={ammount < 0 ? theme.palette.error.main : theme.palette.success.main}>
                {getAmountText(ammount)}
              </Typography>
            </Stack>)
        })}
      </Stack>
    </Stack>

  )
}

EstadoDeCuentaListItem.propTypes = {
  estadoDeCuenta: PropTypes.object.isRequired, // EstadoDeCuenta object to display
  onClick: PropTypes.func.isRequired, // Function to be called when the user clicks on the object
  movimientos: PropTypes.arrayOf(PropTypes.object).isRequired // Movements of the card state account
}

export default EstadoDeCuentaListItem;