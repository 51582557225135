// React imports
import { useState } from "react";

// Proptypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, Box, Chip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

// My components imports
import ConfirmationDialog from "./confirmationDialog";
import themes from "../../styles/themes";

function EtiquetalListItem(props) {
  // Similar to movimientoListItem, represents a single label to be displayed in a list, when it is clicked it calls the onModify handler and when the delete button is clicked it calls the onDelete handler. (The delete button is a MUI icon). The format of the label is the same as the one used in the rest of the app.
  // props.etiqueta is the label object to be displayed
  // props.onModify is the handler for the click on the label
  // props.onDelete is the handler for the click on the delete button

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const deleteConfirmHandler = event => {
    event.stopPropagation();
    props.onDelete(props.etiqueta);
  }

  const modifyClickHandler = event => {
    event.stopPropagation();
    props.onModify(props.etiqueta);
  }

  const cancelDeleteHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  return <Stack direction="row" onClick={showConfirmationDialog ? null : modifyClickHandler} sx={{ padding: "10px" }} justifyContent="space-between">
    <ConfirmationDialog
      title='Borrar etiqueta'
      text={'¿Está seguro que desea borrar la etiqueta "' + props.etiqueta.texto + '"?'}
      open={showConfirmationDialog}
      cancelButtonText='Cancelar'
      confirmButtonText='Borrar'
      onClose={cancelDeleteHandler}
      onConfirm={deleteConfirmHandler}
      expectedText='estoy seguro'
    />
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      <Chip label={props.etiqueta.texto} color="primary" variant="outlined" />
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      <DeleteIcon onClick={showConfirmationDialog ? null : deleteClickHandler} sx={{ color: themes.primary.palette.primary.main }} />
    </Box>
  </Stack>
}

EtiquetalListItem.propTypes = {
  etiqueta: PropTypes.object.isRequired,
  onModify: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default EtiquetalListItem;