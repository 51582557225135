// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI components imports
import { Stack } from "@mui/system";

// My component imports
import MyPaper from "../basic/myPaper.js";
import { deleteCardMovement, getCards, getCategories } from "../../utils/api.js";
import AddButton from "../basic/addButton.js";
import RegisterCardMovement from "./registerCardMovement.js";
import MovimientoTarjetaListItem from "../basic/movimientoTarjetaListItem.js";
import { emptyCardMovement, SUCCESS_FEEDBACK_TIMER } from "../../utils/constants.js";
import MyLoading from "../basic/MyLoading";
import { CardError } from "../basic/errors.js";
import MyLoadingList from "../basic/myLoadingList.js";

// Auxiliary functions
function updateInitialValues(initValues, newMov, intent) {
  let newInitialValues = JSON.parse(JSON.stringify(initValues));
  newInitialValues.initialCardMovement = newMov;
  newInitialValues.intent = intent;
  return newInitialValues;
}

function RegisterCardMovementPage(props) {

  // Constants
  const emptyInitialValues = {
    cards: [],
    categories: [],
    intent: 'create',
    initialCardMovement: emptyCardMovement,
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [ready, setReady] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getCards(), getCategories()])
      .then(response => {
        if (isMounted) {
          response[0].results.length == 0 && setError(true);
          setCards(response[0].results);
          setCategories(response[1].results);
          setInitialLoading(false);
        }
      })
      .catch(err => console.log(err));
    if (ready && !initialValues.initialCardMovement.tarjeta) {
      setShowForm(true);
    }
    return () => { isMounted = false; }
  }, [initialValues, ready]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loadingDelete) {
      setShowDeleteSuccess(false);
    }
  }, [loadingDelete])

  // Handlers
  const handleCardMovementChange = mov => {
    setInitialValues(prev => updateInitialValues(prev, mov, 'modify'));
    setShowForm(false);
  }

  const handleNewCardMovementClick = event => {
    setInitialValues(prev => updateInitialValues(emptyInitialValues, emptyCardMovement, 'create'));
    setShowForm(true);
  }

  const handleCardModifyClick = mov => {
    setInitialValues(prev => updateInitialValues(prev, mov, 'modify'));
    setShowForm(true);
  }

  const handleDelete = mov => {
    setReady(true);
    setLoadingDelete(true);
    deleteCardMovement(mov.id)
      .then(response => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
          setLoadingDelete(false);
          setInitialValues(emptyInitialValues);
        }, [SUCCESS_FEEDBACK_TIMER])
      })
      .catch(err => console.log(err));
  }

  return initialLoading ? <MyPaper><MyLoadingList /></MyPaper> :
    error ? <MyPaper><CardError /></MyPaper> :
      showForm ?
        <MyPaper>
          <RegisterCardMovement
            initialValues={initialValues}
            cards={cards}
            categories={categories}
            onSubmit={handleCardMovementChange}
            isExpense={initialValues.intent === "modify" ? initialValues.initialCardMovement.monto < 0 : props.isExpense}
            onCancel={() => setShowForm(false)}
            allowSwitching={props.allowSwitching}
          />
        </MyPaper> :
        <>
          <MyPaper>{
            initialValues.initialCardMovement.tarjeta ? <>
              {loadingDelete ? <Stack alignItems="center"><MyLoading success={showDeleteSuccess} /></Stack> : <></>}
              {!loadingDelete ?
                <MovimientoTarjetaListItem
                  onDelete={handleDelete}
                  onModify={handleCardModifyClick}
                  movimientoTarjeta={initialValues.initialCardMovement}
                /> : <></>
              }
            </> : <></>
          }

          </MyPaper>
          <AddButton onClick={handleNewCardMovementClick} />
        </>
}

RegisterCardMovementPage.propTypes = {
  isExpense: PropTypes.bool, // Value that indicates whether user is registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterCardMovementPage;