// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function ReporteDeFlujoDeFondos() {
  return <Typography variant="body1">
    El reporte de <TextChipLink label="Reporte de flujo de fondos" url={urls.visualizeCashFlow} /> te permitirá visualizar tus movimientos <strong>de efectivo</strong> en un período de tiempo específico de una forma rápida y sencilla.
    <br /><br />
    A diferencia del <TextChipLink label="Reporte de gastos e ingresos" url={urls.visualizeExpenses} />, este reporte no incluye los gastos a crédito. Por ejemplo, si compras un producto con tarjeta de crédito, el gasto no se contabilizará en este reporte. En cambio, si pagas el saldo de tu tarjeta de crédito, el pago sí se contabilizará porque implicó un movimiento de efectivo.
    <br /><br />
    Este reporte, <strong>no es adecuado</strong> si lo que quieres saber es si gastaste mucho o poco en un período determinado. Este reporte sirve para evaluar si tuviste un flujo de efectivo positivo o negativo.
  </Typography>
}

export default ReporteDeFlujoDeFondos;