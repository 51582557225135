// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { FormControl, TextField, Button, InputLabel, Stack } from '@mui/material';

// My components imports
import LabelInput from "../inputs/labelInput.js";
import MontoInput from "../inputs/montoInput.js";
import CuentaPicker from "../inputs/cuentaPicker.js";
import EasyDatePicker from "../inputs/easyDatePicker.js";
import { getDateFromDateTime } from "../../utils/date.js";

function TransferForm(props) {

  // State constants
  const [sourceAccount, setSourceAccount] = useState(props.initialTransfer ? props.initialTransfer.cuenta_origen : null);
  const [destinationAccount, setDestinationAccount] = useState(props.initialTransfer ? props.initialTransfer.cuenta_destino : null);
  const [availabelSrcAccounts, setAvailableSrcAccounts] = useState(props.accounts);
  const [availableDstAccounts, setAvailableDstAccounts] = useState(props.accounts);
  const [comment, setComment] = useState(props.initialTransfer ? props.initialTransfer.comentario : '');
  const [ammount, setAmmount] = useState(props.initialTransfer ? parseFloat(props.initialTransfer.monto_origen) : 0);
  const [date, setDate] = useState(props.initialTransfer ? props.initialTransfer.fecha : getDateFromDateTime(new Date()));
  const [labels, setLabels] = useState(props.initialTransfer ? props.initialTransfer.etiquetas : []);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (sourceAccount === null) {
        setAvailableSrcAccounts(props.accounts);
        setAvailableDstAccounts(props.accounts);
        setDestinationAccount(null);
      }
      if (props.accounts && sourceAccount) {
        const newAvailableDstAccounts = props.accounts.filter(acct => acct.moneda.id === sourceAccount.moneda.id && acct.id !== sourceAccount.id);
        setAvailableDstAccounts(newAvailableDstAccounts);
      }
    }
    return () => { isMounted = false }
  }, [sourceAccount])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (availabelSrcAccounts.length > 0 && ready) {
        setDestinationAccount(null);
      }
    }
    return () => { isMounted = false }
  }, [availableDstAccounts, ready])

  const handleSourceAccountChange = newAccount => {
    setSourceAccount(newAccount);
    setReady(true);
  }

  const handleDestinationAccountChange = newAccount => {
    setDestinationAccount(newAccount);
    setReady(true);
  }

  const handleConfirm = () => {
    props.onSubmit({
      id: props.initialTransfer ? props.initialTransfer.id : 0,
      cuenta_origen: sourceAccount,
      cuenta_destino: destinationAccount,
      monto_origen: ammount,
      monto_destino: -ammount,
      comentario: comment,
      etiquetas: labels,
      fecha: date,
    })
  }

  return <Stack spacing={1}>
    <Stack spacing={1}>
      <FormControl fullWidth>
        <EasyDatePicker
          helperValues={['hoy', 'ayer', 'antesDeAyer']}
          onChange={newDate => setDate(newDate)}
          initialValue={props.initialTransfer ? props.initialTransfer.fecha : getDateFromDateTime(new Date())}
        />
      </FormControl>
      {sourceAccount !== undefined ?
        <CuentaPicker
          accounts={availabelSrcAccounts}
          initialValues={sourceAccount}
          onChange={handleSourceAccountChange}
          multiple={false}
          label="Cuenta de origen"
        />
        : <></>
      }

    </Stack>

    {sourceAccount ?
      <>
        <FormControl fullWidth>
          <CuentaPicker
            accounts={availableDstAccounts}
            initialValues={destinationAccount}
            onChange={handleDestinationAccountChange}
            multiple={false}
            label="Cuenta de destino"
          />
        </FormControl>
        <FormControl fullWidth>
          <MontoInput
            ammount={ammount}
            onChange={newAmount => setAmmount(newAmount)}
            isExpense={true}
          />
        </FormControl>

        <FormControl fullWidth>
          <LabelInput initialLabels={labels} onChange={(selectedLabels) => setLabels(selectedLabels)} />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            id="comentario"
            value={comment}
            onChange={event => setComment(event.target.value)}
            variant="outlined"
            type="text"
            label="Comentario"
          />
        </FormControl>
      </> :
      <></>
    }
    <Button
      variant="contained"
      onClick={handleConfirm}
      fullWidth
      disabled={!(sourceAccount && destinationAccount && ammount)}
    >
      Registrar transferencia
    </Button>
    {props.intent === 'modify' ?
      <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>
        Cancelar
      </Button> : <></>
    }


  </Stack>
}

TransferForm.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available accounts, this variable must be initialized before rendering this component
  initialTransfer: PropTypes.object, // Object to initialize Transfer
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user submit the Transfer
  onCancel: PropTypes.func.isRequired, // Function to be called when the user cancel the operation
  intent: PropTypes.string.isRequired, // Indicates whether we are creating a transfer or modificating one
}

export default TransferForm;