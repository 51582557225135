// PropTypes imports
import PropTypes from 'prop-types';

// React imports
import { useState, useEffect } from "react";

// MUI imports
import Chip from '@mui/material/Chip';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle.js";

// My component imports
import { getLabels, createLabel } from "../../utils/api.js";
import { Stack } from "@mui/system";
import CreateLabelDialog from "../app/createLabelDialog.js";

// Auxiliary functions
function pushLabel(label, list) {
  let alreadyIn = false;
  list.forEach(item => {
    if (item.id === label.id) {
      alreadyIn = true;
    }
  })
  if (!alreadyIn) {
    list.push(label);
  }
  return list;
}

function LabelInput(props) {

  // Destructure props constants so they can be used directly inside useEffect
  const { initialLabels } = props;
  const { onChange } = props;
  const { hideAddButton } = props;

  // State constants
  const [labels, setLabels] = useState([]); // All available labels
  const [labelStack, setLabelStack] = useState(initialLabels ? initialLabels : []); // The list of labels selected by the user
  const [updateLabels, setUpdateLabels] = useState(false);
  const [showCreateLabelForm, setShowCreateLabelForm] = useState(false);
  const [initialLabelText, setInitialLabelText] = useState('');
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLabels()
        .then(response => {
          setLabels(response.results);
        })
        .catch(err => {
          setLabels([]);
        });
    }
    return () => { isMounted = false }
  }, [updateLabels])

  useEffect(() => {
    if (ready) {
      onChange(labelStack);
    }
  }, [labelStack, ready, onChange])

  // Handlers
  const createdLabelHandler = label => {
    setShowCreateLabelForm(false)
    // First make a copy of the object
    let myNewList = JSON.parse(JSON.stringify(labelStack));
    setLabelStack(pushLabel(label, myNewList));
    setReady(true);
    setInitialLabelText('');
  }

  const clearInitialLabelTextHandler = () => {
    setInitialLabelText('');
  }

  return <Stack direction="row">
    <CreateLabelDialog
      onCreated={createdLabelHandler}
      onCancel={() => {
        setShowCreateLabelForm(false);
        clearInitialLabelTextHandler();
      }}
      initialText={initialLabelText}
      open={showCreateLabelForm}
    />
    <Autocomplete
      fullWidth
      multiple
      filterSelectedOptions
      freeSolo
      clearOnBlur
      id="labelsId"
      options={labels}
      defaultValue={[]}
      getOptionLabel={label => label.texto}
      value={labelStack}
      onChange={(event, newValue) => {
        // Trigger the update of available labels
        setUpdateLabels(prev => !prev);

        // if newValue is undefined there is nothing to do
        if (typeof newValue === 'undefined') return null;

        // First make a copy of the object
        let myNewList = JSON.parse(JSON.stringify(newValue));

        const lastItem = myNewList.pop();

        // if lastItem is undefined set labelStack to empty list
        if (typeof lastItem === 'undefined') {
          setLabelStack([]);
          setReady(true);
          return null;
        };

        // if lastItem.id is undefined it means the user entered the value writing and hitting enter
        if (lastItem.id === undefined) {
          clearInitialLabelTextHandler();
          // First verify that the label to be added is not all spaces
          if (lastItem.trim() === '') {
            setReady(true);
            return null;
          }
          // now, check if the value entered corresponds to an existing label
          const filteredList = labels.filter(item => item.texto === lastItem);
          if (filteredList.length > 0) {
            // it corresponds to an existing label
            setLabelStack(pushLabel(filteredList.pop(), labelStack));
            setReady(true);
          } else {
            // it does not corresponds to an existing label, I need to create a new label
            if (lastItem === '') {
              // The user entered an empty string, do nothing
              setReady(true);
            } else {
              // The user entered a new label, create it  
              createLabel(lastItem)
                .then(response => {
                  createdLabelHandler(response);
                })
                .catch(err => {
                  console.log('Error creating label');
                  console.log(err);
                  setLabelStack(myNewList);
                  setReady(true);
                });
            }
          }
        } else {
          // User is trying to add an existing label, make sure the label isn't already selected
          setLabelStack(pushLabel(lastItem, myNewList));
          setReady(true);
        }
      }}
      onClose={event => setInitialLabelText(String(event.target.value).toLocaleLowerCase())}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.texto ? option.texto : option}
            {...getTagProps({ index })}
            variant="outlined"
            color="primary"
          />
        ))
      }

      renderInput={(params) => <TextField
        {...params}
        label="Etiquetas"
        placeholder="Agrega etiquetas"
      />
      }
    />
    {
      hideAddButton ?
        <></> :
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowCreateLabelForm(true)}
          edge="end"
        >
          <AddCircle fontSize="large" color="primary" />
        </IconButton>
    }

  </Stack>
}

LabelInput.propTypes = {
  initialLabels: PropTypes.arrayOf(PropTypes.object), // Labels to initialize form
  onChange: PropTypes.func.isRequired, // Funciton to be called when label array changes
  hideAddButton: PropTypes.bool, // Indicates if it is needed to hide add button from user
}

export default LabelInput;