// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Button, FormControl, TextField, Stack, Typography, useTheme, InputLabel, Select, MenuItem } from '@mui/material';

// My component imports
import AgendaInput from '../inputs/agendaInput.js';
import LabelInput from '../inputs/labelInput.js';
import MontoInput from "../inputs/montoInput.js";
import TarjetaPicker from "../inputs/tarjetaPicker.js";
import CategoriaPicker from "../inputs/categoriaPicker.js";
import MonedaPicker from "../inputs/monedaPicker.js";
import { getScheduledRegisterButtonText } from '../../utils/misc.js';
import { agendaPropType, categoryPropType, scheduledCardMovementPropType } from '../../utils/myPropTypes.js';

function ScheduledCardMovementForm(props) {
  // Deconstructing props for easier access
  const { agendas, cards, categories } = props;

  // Utilizing MUI theme
  const theme = useTheme();

  // State management for form fields
  const [selectedAgenda, setSelectedAgenda] = useState(props.initialMovement.agenda);
  const [installments, setInstallments] = useState(props.initialMovement.cantidad_de_cuotas || 1);
  const [selectedCard, setSelectedCard] = useState(props.initialMovement.tarjeta || cards[0]);
  const [selectedCurrency, setSelectedCurrency] = useState(props.initialMovement.moneda);
  const [selectedCategory, setSelectedCategory] = useState(
    props.initialMovement.categoria || categories.find(cat => cat.nombre === 'Compras') // Defaulting to 'Compras'
  );
  const [ammount, setAmmount] = useState(props.initialMovement.monto);
  const [labels, setLabels] = useState(props.initialMovement.etiquetas);
  const [comment, setComment] = useState(props.initialMovement.comentario);
  const [formIsReady, setFormIsReady] = useState(false);
  const [hintText, setHintText] = useState('');

  // Similar effect hooks for form validation and readiness as in your previous forms
  useEffect(() => {
    let isMounted = true;
    if (selectedAgenda && selectedCard && selectedCategory && selectedAgenda && selectedCurrency && ammount && isMounted) {
      setFormIsReady(true);
    } else {
      setFormIsReady(false);
      setHintText('Por favor, completa todos los campos obligatorios: agenda, tarjeta de crédito, moneda, categoría y monto.');
    }
    return () => { isMounted = false };
  }, [selectedCard, selectedCategory, selectedAgenda, selectedCurrency, ammount]);

  // Change handlers for form inputs
  const handleCommentChange = (event) => setComment(event.target.value);
  const handleFormSubmit = (event) => {
    // Prevent default form submission behavior
    event.preventDefault();

    // Submit handler logic here
    props.onSubmit({
      id: props.initialMovement ? props.initialMovement.id : null,
      agenda: selectedAgenda,
      tarjeta: selectedCard,
      moneda: selectedCurrency,
      monto: ammount,
      cantidad_de_cuotas: installments,
      categoria: selectedCategory,
      comentario: comment,
      etiquetas: labels,
    });
  };

  const handleInstallmentsChange = (event) => setInstallments(event.target.value);

  // Form rendering
  return (
    <form onSubmit={handleFormSubmit}>
      <Stack spacing={2}>
        <AgendaInput agendas={agendas} selectedAgenda={selectedAgenda} onChange={setSelectedAgenda} />
        <TarjetaPicker multiple={false} cards={cards} initialValues={selectedCard} onChange={setSelectedCard} />
        <MonedaPicker currencies={selectedCard.saldos_de_tarjeta.map(saldo => saldo.moneda)} initialValues={selectedCurrency} onChange={setSelectedCurrency} />
        <MontoInput ammount={ammount} onChange={setAmmount} label="Monto" />
        <FormControl fullWidth>
          <InputLabel id="cuotasLabel">Cuotas</InputLabel>
          <Select
            labelId="cuotasLabel"
            id="cuotas"
            value={installments}
            label="Cuotas"
            onChange={handleInstallmentsChange}
          >
            {[...Array(30).keys()].map(item => <MenuItem value={item + 1} key={item}>{item + 1}</MenuItem>)}
          </Select>
        </FormControl>
        <CategoriaPicker multiple={false} categories={categories} initialValues={selectedCategory} onChange={setSelectedCategory} />
        <LabelInput initialLabels={labels} onChange={setLabels} />
        <FormControl fullWidth>
          <TextField id="comentario" value={comment} onChange={handleCommentChange} variant="outlined" label="Comentario" />
        </FormControl>
        {formIsReady ? (
          <Button type="submit" variant="contained" fullWidth>
            {getScheduledRegisterButtonText(props.intent, props.isExpense)}
          </Button>
        ) : (
          <Typography variant="caption" sx={{ color: theme.palette.error.main }}>
            {hintText}
          </Typography>
        )}
        <Button variant="contained" color="secondary" onClick={props.onCancel} fullWidth>
          Cancelar
        </Button>
      </Stack>
    </form>
  );
}

ScheduledCardMovementForm.propTypes = {
  agendas: PropTypes.arrayOf(agendaPropType).isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(categoryPropType).isRequired,
  initialMovement: scheduledCardMovementPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(['create', 'modify']).isRequired,
  isExpense: PropTypes.bool.isRequired,
};

export default ScheduledCardMovementForm;
