// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function CompromisosFinancieros() {
  return <Typography variant="body1">
    Dentro de <TextChipLink url={urls.configureDebtAccountsUrl} label="Compromisos financieros" />, <strong>FinanzasUY</strong> te permitirá crear cuentas que van a representar justamente eso, <strong>compromisos financieros</strong> que hayas tomado con alguien o que alguien haya tomado contigo.
    <br /><br />
    Por ejemplo, si le prestaste dinero a un amigo, podés crear una cuenta de compromiso financiero para llevar un registro de cuánto te debe y cuánto te ha pagado.
    ¿Cómo registrarías esto? Primero, creas una cuenta de compromiso financiero con balance cero llamada "Préstamo a Juan". Luego, generas una transferencia de dinero desde tu cuenta donde estaba el dinero que le prestaste a la cuenta de "Préstamo a Juan" por el monto que le prestaste. La cuenta de compromiso financiero quedará con saldo positivo, dado que es un activo para ti, es plata que te deben. Finalmente, cada vez que Juan te pague, generás una transferencia de dinero desde la cuenta de "Préstamo a Juan" a tu cuenta de banco por el monto que te pagó.
    <br /><br />
    Otro ejemplo podría ser que sales a cenar con un amigo y definen que él paga la cuenta y luego tú le pagas la mitad. En este caso, podrías crear una cuenta de compromiso financiero con balance cero llamada "Cena con Juan" y registrar el pago de tu parte de la cena desde la cuenta de compromiso financiero "Cena con Juan". Esto registrará que realizaste un gasto ese día, porque salistea cenar, pero ese gasto no movió efectivo, sino que te generó un pasivo (una deuda) con tu amigo, que en la App se verá reflejado en la cuenta de compromiso financiero "Cena con Juan" con saldo negativo. Cuando le pagues, registrarás una transferencia desde la cuenta donde sacaste el dinero para pagarle hacia la cuenta de compromiso financiero "Cena con Juan", que dejará la cuenta de compromiso financiero con balance cero.
    <br /><br />
    Un último ejemplo podría ser el pago de la anualidad del colegio de tu hijo hipotético, Pedro. Podrías crear una cuenta de compromiso financiero con balance cero llamada "Colegio de Pedro" y transferir a dicha cuenta el monto total de la anualidad. Esto registrará que moviste efectivo, pero no generaste ningún gasto aún. Lo que se generó fue un activo, dado que el colegio te debe 12 meses de servicio de educación para tu hijo (por el valor total de la anualidad). Ahora, cada mes, lo que harás será registrar un gasto desde la cuenta de "Colegio de Pedro" por el monto de la cuota mensual (un doceavo de la anualidad). Esto registrará que generaste un gasto, pero no moviste efectivo. Al cabo de 12 meses, la cuenta de "Colegio de Pedro" quedará con balance cero, dado que el colegio ya te dio el servicio de educación para tu hijo por el que le pagaste. Registrarlo de esta forma, te permitirá tener una mejor evaluación de tus gastos fijos mensuales que si simplemente registraras como un gasto el pago total de la anualidad en el mes que lo realizaste.
    <br /><br />
    A modo de resumen, las cuentas de compromiso financiero no almacenan dinero real sino que representan compromisos financieros que hayas tomado con alguien o que alguien haya tomado contigo. Estos compromisos financieros pueden ser activos (si alguien te debe) o pasivos (si tu debes). Un activo se verá reflejado como un saldo positivo en la cuenta de compromiso financiero, mientras que un pasivo se verá reflejado como un saldo negativo.
  </Typography>
}

export default CompromisosFinancieros;