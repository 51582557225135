// React imports
import { useEffect, useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, FormControl, Stack, Typography, Button, TextField } from '@mui/material';

// My component imports
import CuentaPicker from '../inputs/cuentaPicker.js';
import EasyDatePicker from '../inputs/easyDatePicker.js';
import MontoInput from '../inputs/montoInput.js';
import ToggleCardBalance from '../basic/toggleCardBalance.js';
import { getAmountText } from '../../utils/misc.js';
import LabelInput from '../inputs/labelInput.js';
import { getDateFromDateTime } from '../../utils/date.js';

function PayCardForm(props) {

  // State constants
  const [selectedAccount, setSelectedAccount] = useState(props.initialPayment ? props.initialPayment.cuenta : null);
  const [selectedDate, setSeletedDate] = useState(props.initialPayment ? props.initialPayment.fecha : getDateFromDateTime(new Date()));
  const [srcAmmount, setSrcAmmount] = useState(props.initialPayment ? props.initialPayment.monto_origen : 0);
  const [dstAmmount, setDstAmmount] = useState(props.initialPayment ? props.initialPayment.monto_destino : 0);
  const [exchangeRate, setExchangeRate] = useState(props.initialPayment ? (props.initialPayment.monto_origen / props.initialPayment.monto_destino) : 1);
  const [selectedBalance, setSelectedBalance] = useState(props.initialPayment && props.initialPayment.saldo_de_tarjeta ?
    props.initialPayment.saldo_de_tarjeta :
    props.initialPayment.tarjeta.saldos_de_tarjeta[0]);
  const [selectedLabels, setSelectedLabels] = useState(props.initialPayment ? props.initialPayment.etiquetas : []);
  const [currencyExchangePresent, setCurrencyExchangePresent] = useState(props.initialPayment && props.initialPayment.cuenta ? props.initialPayment.cuenta.moneda.id !== props.initialPayment.saldo_de_tarjeta.moneda.id : false);
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      if (selectedAccount && selectedBalance) {
        if (selectedAccount.moneda.id !== selectedBalance.moneda.id) {
          setExchangeRate(parseFloat(dstAmmount) !== 0 ? (srcAmmount / dstAmmount) : '0');
          setCurrencyExchangePresent(true);
        } else {
          setExchangeRate(1)
          setCurrencyExchangePresent(false);
        }
      }
    }
  }, [srcAmmount, dstAmmount, selectedBalance, selectedAccount])

  useEffect(() => {
    if (ready) {
      setDstAmmount(0);
    }
  }, [selectedBalance])

  useEffect(() => {
    if (ready) {
      setDstAmmount(0);
      setSrcAmmount(0);
    }
  }, [selectedAccount])

  // Handlers
  const changeAccountHandler = acct => {
    setReady(true);
    setSelectedAccount(acct);
  }

  const changeDateHandler = date => {
    setReady(true);
    setSeletedDate(date);
  }

  const changeSrcAmmountHandler = newAmmount => {
    setReady(true);
    setSrcAmmount(newAmmount);
    if (!currencyExchangePresent) {
      setDstAmmount(newAmmount);
    }
  }

  const changeDstAmmountHandler = newAmmount => {
    setReady(true);
    setDstAmmount(newAmmount);
  }

  const changeBalanceHandler = newBalance => {
    setReady(true);
    setSelectedBalance(newBalance);
  }

  const handleSubmitForm = () => {
    props.onSubmit({
      id: props.initialPayment ? props.initialPayment.id : 0,
      saldo_de_tarjeta: selectedBalance,
      cuenta: selectedAccount,
      monto_origen: srcAmmount,
      monto_destino: dstAmmount,
      fecha: selectedDate,
      etiquetas_ids: selectedLabels.map(label => label.id),
      comentario: '',
    });
  }

  return <Stack spacing={1}>
    <FormControl fullWidth>
      <ToggleCardBalance
        selectedBalance={selectedBalance}
        onChange={changeBalanceHandler}
        balances={props.initialPayment.tarjeta.saldos_de_tarjeta}
      />
    </FormControl>
    <FormControl fullWidth>
      <EasyDatePicker
        initialValue={selectedDate}
        helperValues={['hoy', 'ayer', 'antesDeAyer']}
        onChange={changeDateHandler}
      />
    </FormControl>
    <FormControl fullWidth>
      <CuentaPicker accounts={props.cuentas} onChange={changeAccountHandler} multiple={false} initialValues={selectedAccount} />
    </FormControl>
    {selectedAccount ? (
      <>
        <FormControl fullWidth>
          <MontoInput
            ammount={srcAmmount}
            onChange={changeSrcAmmountHandler}
            label={"Monto pagado en (" + selectedAccount.moneda.nombre_corto + ")"}
            isExpense={false}
          />
        </FormControl>
        {currencyExchangePresent ?
          <Stack>
            <FormControl fullWidth>
              <MontoInput
                ammount={dstAmmount}
                onChange={changeDstAmmountHandler}
                label={"Deuda cancelada en (" + selectedBalance.moneda.nombre_corto + ")"}
                isExpense={false}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField label="Tipo de cambio" variant="filled" value={getAmountText(exchangeRate)} readOnly />
            </FormControl>
          </Stack>
          :
          <></>}

        <Stack spacing={2}>
          <FormControl fullWidth>
            <LabelInput
              initialLabels={selectedLabels}
              onChange={newLabels => setSelectedLabels(newLabels)}
            />
          </FormControl>
          {props.initialPayment.saldo_de_tarjeta ?
            <></>
            :
            <Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ marginRight: "5px", marginLeft: "10px" }}>
                <Typography variant="subtitle2">{"Deuda total en " + selectedBalance.moneda.nombre_corto + ':'}</Typography>
                <Typography variant="subtitle2">{getAmountText(selectedBalance.balance)}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">-</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ marginRight: "5px", marginLeft: "10px" }}>
                <Typography variant="subtitle2">{"Pago registrado en " + selectedBalance.moneda.nombre_corto + ':'}</Typography>
                <Typography variant="subtitle2">{getAmountText(parseFloat(dstAmmount))}</Typography>
              </Stack>
              <Divider />
              <Stack direction="row" justifyContent="space-between" sx={{ marginRight: "5px", marginLeft: "10px" }}>
                <Typography variant="subtitle2">{"Deuda posterior en " + selectedBalance.moneda.nombre_corto + ':'}</Typography>
                <Typography variant="subtitle2">{getAmountText(parseFloat(selectedBalance.balance + dstAmmount))}</Typography>
              </Stack>
            </Stack>
          }
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmitForm}
            disabled={!(selectedBalance && selectedAccount)}
          >Registrar el pago de tarjeta</Button>
        </Stack>
      </>
    )
      : <></>
    }
  </Stack>
}

PayCardForm.propTypes = {
  initialPayment: PropTypes.object, // Initial payment to be used to initialize the form
  cuentas: PropTypes.arrayOf(PropTypes.object).isRequired, // All available accounts
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user submits a payment
}

export default PayCardForm;