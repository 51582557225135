// React imports
import { useState, useEffect } from "react";
// MUI imports
import { Dialog, DialogContent, Divider, Stack, Typography } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings";
import MyPaper from "../basic/myPaper";
import { emptyFamily, emptyFamilyInvitation } from "../../utils/constants";
import CreateModifyFamily from "./createModifyFamily";
import FamilyHeader from "../basic/familyHeader";
import AddButton from "../basic/addButton";
import CreateModifyFamilyInvitation from "./createModifyFamilyInvitation";
import FamilyInvitationsList from "../lists/familyInvitationsList";
import ReceivedFamilyInvitationsList from "../lists/receivedFamilyInvitationsList";
import FamilyMemberListItem from "../basic/familyMemberListItem";
import { acceptFamilyInvitation, deleteFamily, expelFromFamily, getFamily, getFamilyInvitations, getUser, leaveFamily, rejectFamilyInvitation } from "../../utils/api";
import { userIsSubscribed } from "../../utils/subscription";
import MyLoadingList from "../basic/myLoadingList";
import { logoutUser } from "../../utils/authUtils";


function ConfigureFamilyPage(props) {

  // State constants
  const [family, setFamily] = useState(emptyFamily);
  const [user, setUser] = useState(null);
  const [showInvitationDialog, setShowInvitationDialog] = useState(false);
  const [currentInvitation, setCurrentInvitation] = useState(emptyFamilyInvitation);
  const [familyInvitations, setFamilyInvitations] = useState([]);
  const [ready, setReady] = useState(false);
  const [updateFamily, setUpdateFamily] = useState(false);
  const [userHasActiveSubscription, setUserHasActiveSubscription] = useState(false);
  const [loading, setLoading] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    userIsSubscribed()
      .then(response => {
        isMounted && setUserHasActiveSubscription(response);
      })
      .catch(err => console.log(err))
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoading(true);
      getUser()
        .then(response => {
          setUser(response);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err)
        })
    }
    return () => { isMounted = false }
  }, [updateFamily])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (user && user.id && user.familia && user.familia.id) {
        getFamily(user.familia.id)
          .then(response => {
            setFamily(response);
          })
          .catch(err => console.log(err))
      } else if (user && user.id) {
        getFamilyInvitations()
          .then(response => {
            if (response.results.length > 0) {
              setFamilyInvitations(response.results);
            }
          })
          .catch(err => console.log(err))
      }
    }
    return () => { isMounted = false }
  }, [user])

  useEffect(() => {
    console.log(familyInvitations);
  }, [familyInvitations])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && ready) {
      setShowInvitationDialog(true);
    }
    return () => { isMounted = false }
  }, [currentInvitation, ready])

  // Handlers
  const handleFamilyCreated = family => {
    setFamily(family);
    setReady(false);
    setUpdateFamily(prev => !prev);
  }

  const handleCreateInvitation = () => {
    setCurrentInvitation(emptyFamilyInvitation);
    setReady(true);
  }

  const handleRevokeInvitation = invitation => {
    console.log('Revoking invitation: ', invitation);
    setCurrentInvitation(invitation);
    setReady(true);
  }

  const handleInvitationModified = invitation => {
    setShowInvitationDialog(false);
    setReady(false);
    setUpdateFamily(prev => !prev);
  }

  const handleClosedInvitationDialog = () => {
    setShowInvitationDialog(false);
    setCurrentInvitation(emptyFamilyInvitation);
    setReady(false);
  }

  const handleRejectInvitation = invitation => {
    rejectFamilyInvitation(invitation.id)
      .then(response => {
        setUpdateFamily(prev => !prev);
      }
      )
      .catch(err => console.log(err))
  }

  const handleAcceptInvitation = invitation => {
    acceptFamilyInvitation(invitation.id)
      .then(response => {
        setUpdateFamily(prev => !prev);
      }
      )
      .catch(err => console.log(err))
  }

  const handleDeleteFamily = (family) => {
    deleteFamily(family.id)
      .then(response => {
        setFamily(emptyFamily);
        setUpdateFamily(prev => !prev);
      })
      .catch(err => console.log(err))
  }

  const handleLeaveFamily = (member) => {
    leaveFamily(family.id)
      .then(response => {
        setFamily(emptyFamily);
        setUpdateFamily(prev => !prev);
        // Sign out the user
        logoutUser();
      })
      .catch(err => console.log(err));
  }

  const handleExpelMember = (family, member) => {
    console.log('Expelling member: ', member, ' from family: ', family);
    expelFromFamily(family.id, member.id)
      .then(response => {
        setUpdateFamily(prev => !prev);
      })
      .catch(err => console.log(err));
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configuración' },
    ]}
  >
    {loading ? <MyLoadingList /> : (family && family.id ?
      <Stack direction={'column'} spacing={1}>
        <MyPaper>
          <FamilyHeader family={family} user={user} onDelete={handleDeleteFamily} />
        </MyPaper>
        <Typography variant={'subtitle2'}>Miembros:</Typography>
        <MyPaper>
          {family.miembros.length === 0 ?
            <Typography variant={'body2'}>No hay miembros en la familia</Typography> :
            family.miembros.map((member, index) => {
              return <Stack key={member.id} spacing={1}>
                <FamilyMemberListItem member={member} user={user} onLeave={handleLeaveFamily} onExpel={handleExpelMember} hideFamilyName />
                {index < family.miembros.length - 1 ? <Divider /> : null}
              </Stack> // Substitute this line with MemberListItem
            })
          }
        </MyPaper>
        {user.familia_administrada ? <>
          <AddButton
            onClick={handleCreateInvitation}
          />
          <Typography variant={'subtitle2'}>Invitaciones:</Typography>
          <MyPaper>
            <Dialog open={showInvitationDialog} onClose={handleClosedInvitationDialog}>
              <DialogContent sx={{ padding: "10px", minWidth: "300px" }}>
                <CreateModifyFamilyInvitation
                  initialValues={{
                    initialInvitation: currentInvitation,
                    intent: currentInvitation.id ? 'modify' : 'create',
                  }}
                  onSubmit={handleInvitationModified}
                  onCancel={handleClosedInvitationDialog}
                  user={user}
                />
              </DialogContent>
            </Dialog>
            {user.familia_administrada ?
              (family.invitaciones ?
                <FamilyInvitationsList
                  invitations={family.invitaciones}
                  onRevoke={handleRevokeInvitation}
                /> :
                null) :
              <Typography variant={'body2'}>No tienes permisos para ver las invitaciones</Typography>
            }
          </MyPaper>
        </> : null
        }

      </Stack>
      :
      <Stack direction={'column'} spacing={1}>
        <Typography variant={'subtitle2'}>Invitaciones:</Typography>
        <MyPaper>
          <ReceivedFamilyInvitationsList
            invitations={familyInvitations}
            onReject={handleRejectInvitation}
            onAccept={handleAcceptInvitation}
            userHasActiveSubscription={userHasActiveSubscription}
          />
        </MyPaper>
        <Typography variant={'subtitle2'}>Crea tu propia familia:</Typography>
        <MyPaper>
          <CreateModifyFamily
            initialValues={{
              initialFamily: family,
              intent: 'create',
            }}
            onSubmit={handleFamilyCreated}
            onCancel={() => console.log('Cancel')}
          />
        </MyPaper>
      </Stack>)
    }
  </MyMenuComponent>

}


export default ConfigureFamilyPage;