// React imports
import { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import PayCardForm from '../forms/payCardForm';

// MUI component imports
import MyLoading from '../basic/MyLoading.js';
import { createCardPayment, modifyCardPayment } from '../../utils/api';

function RegisterCardPayment(props) {

  // State constants
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Handlers
  const handleSubmit = data => {
    setLoading(true);
    if (props.initialValues.intent === "create") {
      createCardPayment(data.saldo_de_tarjeta.id, data.cuenta.id, data.monto_destino, -Math.abs(parseFloat(data.monto_origen)), data.fecha, data.comentario, data.etiquetas_ids)
        .then(cardPayment => {
          setSuccess(true);
          setTimeout(() => props.onSubmit(cardPayment), [500])
        })
        .catch(err => console.log(err))
    } else if (props.initialValues.intent === "modify") {
      modifyCardPayment(data.saldo_de_tarjeta.id, data.cuenta.id, data.monto_destino, -Math.abs(parseFloat(data.monto_origen)), data.fecha, data.comentario, data.etiquetas_ids, data.id)
        .then(cardPayment => {
          setSuccess(true);
          setTimeout(() => props.onSubmit(cardPayment), [500])
        })
        .catch(err => console.log(err))
    }
  }

  return loading ?
    <MyLoading success={success} />
    :
    <PayCardForm
      cuentas={props.accounts}
      onSubmit={handleSubmit}
      initialPayment={props.initialValues.initialCardPayment}
    />
}


RegisterCardPayment.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // All available accounts
  onSubmit: PropTypes.func.isRequired, // Function to be called when a payment is successfuly registered
  initialValues: PropTypes.shape({
    initialCardPayment: PropTypes.object, // Values to initialize form
    intent: PropTypes.oneOf(["modify", "create"]), // Indicates whether the user is creating or mofidying a payment
  }).isRequired,
}

export default RegisterCardPayment;