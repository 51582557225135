// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI component improts
import { Stack } from "@mui/system";

// My component imports
import MyPaper from "../basic/myPaper.js";
import { deleteMovement, getCategories, getUserAccounts } from "../../utils/api.js";
import AddButton from "../basic/addButton.js";
import RegisterMovement from "./registerMovement.js";
import MovimientoListItem from "../basic/movimientoListItem.js";
import MyLoading from "../basic/MyLoading.js";
import { emptyMovement, SUCCESS_FEEDBACK_TIMER } from "../../utils/constants.js";
import { AccountError } from "../basic/errors.js";
import MyLoadingList from "../basic/myLoadingList.js";

// Auxiliary functions
function updateInitialValues(initValues, newMov, intent) {
  let newInitialValues = JSON.parse(JSON.stringify(initValues));
  newInitialValues.initialMovement = newMov;
  newInitialValues.intent = intent;
  return newInitialValues;
}

function RegisterMovementPage(props) {

  // Constants

  const emptyInitialValues = {
    initialMovement: emptyMovement,
    intent: 'create',
    categories: [],
    accounts: [],
  }

  // State constants
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [ready, setReady] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getUserAccounts(props.isExpense ? 'registrarGasto' : 'registrarIngreso'), getCategories()])
      .then(response => {
        if (isMounted) {
          response[0].results.length == 0 && setError(true);
          setAccounts(response[0].results);
          setCategories(response[1].results);
          setInitialLoading(false);
        }
      })
      .catch(err => console.log(err));
    if (ready && !initialValues.initialMovement.cuenta) {
      setShowForm(true);
    }
    return () => { isMounted = false }
  }, [initialValues, ready]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !loadingDelete) {
      setShowDeleteSuccess(false);
    }
  }, [loadingDelete])

  // Handlers
  const handleMovementChange = mov => {
    setInitialValues(prev => updateInitialValues(prev, mov, 'modify'));
    setShowForm(false);
  }

  const handleNewMovementClick = event => {
    setInitialValues(prev => updateInitialValues(emptyInitialValues, emptyMovement, 'create'));
    setShowForm(true);
  }

  const handleModifyClick = mov => {
    setInitialValues(prev => updateInitialValues(prev, mov, 'modify'));
    setShowForm(true);
  }

  const handleDelete = mov => {
    setReady(true);
    setLoadingDelete(true);
    deleteMovement(mov.id)
      .then(response => {
        setShowDeleteSuccess(true);
        setTimeout(() => {
          setLoadingDelete(false);
          setInitialValues(emptyInitialValues);
        }, [SUCCESS_FEEDBACK_TIMER]);
      })
      .catch(err => console.log(err));
  }

  return initialLoading ? <MyPaper><MyLoadingList /></MyPaper> :
    error ? <AccountError /> :
      showForm ?
        <MyPaper>
          <RegisterMovement
            initialValues={initialValues}
            accounts={accounts}
            categories={categories}
            onSubmit={handleMovementChange}
            isExpense={initialValues.intent === "modify" ? initialValues.initialMovement.monto < 0 : props.isExpense}
            onCancel={() => setShowForm(false)}
            allowSwitching={props.allowSwitching}
          />
        </MyPaper> :
        <Stack>
          <MyPaper>
            {initialValues.initialMovement.cuenta ? (
              loadingDelete ?
                <Stack alignItems="center"><MyLoading success={showDeleteSuccess} /></Stack> :
                <MovimientoListItem
                  onDelete={handleDelete}
                  onModify={handleModifyClick}
                  movimiento={initialValues.initialMovement}
                />
            ) : <></>}
          </MyPaper>
          <AddButton onClick={handleNewMovementClick} />
        </Stack>
}

RegisterMovementPage.propTypes = {
  isExpense: PropTypes.bool, // Value that indicates whether user is registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterMovementPage;