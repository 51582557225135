// React imports
import { useState, useContext } from "react";

// MUI components imports
import { ListItemText, List, ListItem, ListItemAvatar, Avatar, CircularProgress, Alert, Button } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';

// My components imports
import MyMenuComponent from "./myMenuComponent";
import MyPaper from "../basic/myPaper";
import ServiceWorkerContext from "../../utils/serviceWorkerContext.js";
import AppUpdateContext from "../../utils/appUpdate.js";
import { urls } from "../../settings.js";

function ConfigureApp() {

  // State constants
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  // Context constants
  const registration = useContext(ServiceWorkerContext);
  const { appUpdate } = useContext(AppUpdateContext)

  // Handlers
  const handleUpdateSearchClick = () => {
    setLoading(true);
    setShowDialog(false);
    registration.update()
      .then(response => {
        setLoading(false);
        setShowDialog(true);
      })
      .catch(err => {
        setLoading(false);
        setShowDialog(true);
      });
  }

  const handleUpdateAppClick = event => {
    window.location.reload();
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configurar' }
    ]}
    currentPageName={'Configurar app'}
  >
    <MyPaper>
      <List>
        <ListItem onClick={handleUpdateSearchClick} sx={{ cursor: "pointer" }}>
          <ListItemAvatar>
            <Avatar>
              {loading ? <CircularProgress color="primary" /> : <CachedIcon color="primary" />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Actualizaciones" secondary="Buscar actualizaciones" />
        </ListItem>
      </List>
      {showDialog ? (
        appUpdate ?
          <Alert
            severity="info"
            action={<Button onClick={handleUpdateAppClick} variant="outlined">Actualizar</Button>}
          >
            "Hay actualizaciones disponibles"
          </Alert> :
          <Alert severity={"error"}>
            "No hay actualizaciones disponibles"
          </Alert>
      ) : null
      }
    </MyPaper>

  </MyMenuComponent >
}

export default ConfigureApp;