// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Typography, Stack, Divider, isMuiElement } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getAccountMovementAvg, getAccountMovementsSum, getAccountMovmentMax, getAccountMovmentMin, getAmountText, groupByCategory } from '../../utils/misc';
import MyChart from '../graphs/myChart';
import { useState } from 'react';
import { useEffect } from 'react';

function BasicSummaryTable(props) {

  // Constants
  const theme = useTheme();

  // State constants
  const [sum, setSum] = useState(getAmountText(getAccountMovementsSum(props.movementList, props.account)));
  const [avg, setAvg] = useState(getAmountText(getAccountMovementAvg(props.movementList, props.account)));
  const [max, setMax] = useState(getAmountText(getAccountMovmentMax(props.movementList, props.account)));
  const [min, setMin] = useState(getAmountText(getAccountMovmentMin(props.movementList, props.account)));

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSum(getAmountText(getAccountMovementsSum(props.movementList, props.account)));
      setAvg(getAmountText(getAccountMovementAvg(props.movementList, props.account)));
      setMax(getAmountText(getAccountMovmentMax(props.movementList, props.account)));
      setMin(getAmountText(getAccountMovmentMin(props.movementList, props.account)));
    }
    return () => { isMounted = false }
  }, props.movementList, props.account)

  useEffect(() => {
    // console.log(sum);
    // console.log(avg);
    // console.log(max);
    // console.log(min);
  }, [sum, avg, max, min])

  return <Stack>
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">Suma:</Typography>
      <Typography
        variant="subtitle2"
        flexGrow={1}
        textAlign="end"
        color={parseFloat(sum) < 0 ? theme.palette.error.main : theme.palette.success.main}
      >
        {props.account.moneda.nombre_corto + ' ' + sum}
      </Typography>
    </Stack>
    <Divider />
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">Máximo:</Typography>
      <Typography
        variant="subtitle2"
        flexGrow={1}
        textAlign="end"
        color={parseFloat(max) < 0 ? theme.palette.error.main : theme.palette.success.main}
      >
        {props.account.moneda.nombre_corto + ' ' + max}
      </Typography>
    </Stack>
    <Divider />
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">Mínimo:</Typography>
      <Typography
        variant="subtitle2"
        flexGrow={1}
        textAlign="end"
        color={parseFloat(min) < 0 ? theme.palette.error.main : theme.palette.success.main}
      >
        {props.account.moneda.nombre_corto + ' ' + min}
      </Typography>
    </Stack>
    <Divider />
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2">Promedio:</Typography>
      <Typography
        variant="subtitle2"
        flexGrow={1}
        textAlign="end"
        color={parseFloat(avg) < 0 ? theme.palette.error.main : theme.palette.success.main}
      >
        {props.account.moneda.nombre_corto + ' ' + avg}
      </Typography>
    </Stack>
  </Stack >

}

BasicSummaryTable.propTypes = {
  movementList: PropTypes.arrayOf(PropTypes.object).isRequired, // Array of movements from which build the summary
  account: PropTypes.object.isRequired, // Account being summarized
}

export default BasicSummaryTable;