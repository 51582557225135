// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Box, Stack, Typography } from "@mui/material";
import { styled } from '@mui/system';


const MyBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.light,
  border: "solid 1px",
  borderColor: theme.palette.primary.main,
  borderRadius: '5px',
  padding: '10px',
  ":hover": {
    backgroundColor: theme.palette.primary.hoverBackground,
    borderColor: theme.palette.primary.dark,
  }
}));

function IconCard(props) {

  return (
    <Stack direction='column' spacing={1} sx={{ width: '33%', cursor: "pointer" }} on >
      <MyBox>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {props.children}
        </Box>
      </MyBox>
      <Typography align='center' sx={{ fontSize: '15px' }}>{props.text}</Typography>
    </Stack>
  );
}

IconCard.propTypes = {
  text: PropTypes.string, // text to be displayed in the IconCard
  children: PropTypes.element, // the icon that goes inside the IconCard
}

export default IconCard;