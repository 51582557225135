// React imports
import { useState } from "react";

// MUI imports
import { Stack, Typography, Box, Chip, Divider } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import ConfirmationDialog from "./confirmationDialog";
import { getAmountText } from "../../utils/misc";

function TarjetaDetailedCard(props) {

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const cardName = props.tarjeta.nombre;
  const icon = <CreditCardIcon fontSize="small" color="primary" />;

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    props.onDelete(props.tarjeta);
  }
  const modifyClickHandler = event => {
    event.stopPropagation();
    props.onModify(props.tarjeta);
  }

  return <Stack direction="column" onClick={modifyClickHandler} spacing={1} sx={{ marginTop: "25px" }}>
    <ConfirmationDialog
      title='Borrar tarjeta de crédito'
      text={'¿Estas seguro de que quieres borrar la tarjeta?'}
      open={showConfirmationDialog}
      cancelButtonText='Cancelar'
      confirmButtonText='Borrar'
      onClose={event => setShowConfirmationDialog(false)}
      onConfirm={() => props.onDelete(props.tarjeta)}
      expectedText='estoy seguro'
    />
    <Stack direction="row" spacing={1}>
      <Stack justifyContent="center">
        {icon}
      </Stack>
      <Typography variant="subtitle1">
        {cardName}
      </Typography>
    </Stack>

    <Divider />

    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Stack direction="column" spacing={0.5} flexGrow={1}>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="subtitle2">Fecha de cierre: </Typography>
          <Chip
            // color="primary"
            label={props.tarjeta.fecha_de_cierre_actual}
            size="small"
            icon={<CalendarMonthIcon fontSize="small" />}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="subtitle2">Fecha de vencimiento: </Typography>
          <Chip
            // color="primary"
            label={props.tarjeta.fecha_de_vencimiento_actual}
            size="small"
            icon={<CalendarMonthIcon fontSize="small" />}
            variant="outlined"
          />
        </Stack>
        {props.tarjeta.saldos_de_tarjeta.map(saldo => {
          return <Stack direction="row" spacing={1} justifyContent="space-between" key={saldo.id}>
            <Typography variant="subtitle2">Saldo en {saldo.moneda.nombre_corto}: </Typography>
            <Typography variant="subtitle2">{getAmountText(saldo.balance, saldo.moneda)}</Typography>
          </Stack>
        })}

      </Stack>
      <Divider />
      <Stack justifyContent="center">
        <DeleteIcon onClick={deleteClickHandler} color="disabled" sx={{ fontSize: 35, marginRight: "5px", marginLeft: "5px" }} />
      </Stack>
    </Stack>


  </Stack >
}

TarjetaDetailedCard.propTypes = {
  tarjeta: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user confirms that wants to delete the Account
  onModify: PropTypes.func, // Function to be called when user whats to modify Account
};

export default TarjetaDetailedCard;