// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Alert, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings";
import MyPaper from "../basic/myPaper";
import { emptyAgenda } from "../../utils/constants";
import CreateModifyAgenda from "./creteModifyAgenda";
import { deleteAgenda, get, getAgendas } from "../../utils/api";
import AgendaList from "../lists/agendaList";
import MyLoadingList from "../basic/myLoadingList";
import AddButton from "../basic/addButton";


function AgendasPage() {

  // State constants
  const [agendas, setAgendas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [currentAgenda, setCurrentAgenda] = useState(emptyAgenda);

  // Auxiliary functions
  const updateAgendas = async () => {
    setLoading(true);
    setShowForm(false);
    const valueToReturn = await getAgendas()
      .then(response => {
        setAgendas(response.results);
      }).catch(error => {
        console.error('Error al obtener las agendas', error);
      })
    setLoading(false);
    return valueToReturn;
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      updateAgendas();
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    console.log('agendas', agendas);
  }, [agendas]);

  // Handlers
  const handleAgendaDelete = (agendaId) => {
    console.log('Borrando agenda', agendaId);
    deleteAgenda(agendaId)
      .then(response => {
        console.log('agenda borrada', response);
        updateAgendas();
      }).catch(error => {
        console.error('Error al borrar agenda', error);
      })
  }

  const handleModifyAgenda = (agenda) => {
    setCurrentAgenda(agenda);
    setShowForm(true);
  }

  const handleAddButtonClick = () => {
    setShowForm(true);
  }


  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configurar' }
    ]}
    currentPageName={'Agendas'}
  >
    <MyPaper>
      {loading ? <MyLoadingList /> : <AgendaList agendas={agendas} onDelete={handleAgendaDelete} onModify={handleModifyAgenda} />}
      {agendas.length === 0 && !loading && <Stack spacing={1}>
        <Alert severity="info" variant="outlined">
          <Typography variant="body1">No hay agendas creadas</Typography>
        </Alert>
        <Button variant="contained" onClick={handleAddButtonClick} fullWidth>Crear una agenda</Button>
      </Stack>}
      <Dialog open={showForm} onClose={() => setShowForm(false)}>
        <DialogContent>
          <CreateModifyAgenda
            initialData={currentAgenda}
            onSubmit={updateAgendas}
            onCancel={() => setShowForm(false)}
          />
        </DialogContent>
      </Dialog>
    </MyPaper>
    {agendas.length > 0 && <AddButton onClick={handleAddButtonClick} />}
  </MyMenuComponent>
}

export default AgendasPage