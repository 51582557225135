// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { getCustodyAgentTypeIcon } from '../../utils/misc';
import PublicIcon from '@mui/icons-material/Public';

function CustodyAgentHeader(props) {

  // Constants
  const theme = useTheme();
  const nameText = props.custodyAgent ? props.custodyAgent.nombre : '';
  const typeText = props.custodyAgent ? props.custodyAgent.tipo.nombre : '';
  const countryText = props.custodyAgent ? props.custodyAgent.pais.nombre : '';

  return <Stack spacing={1} direction="column" onClick={() => props.onClick(props.custodyAgent)}>
    <Stack direction="row" spacing={1}>
      <Typography variant="h6">{nameText}</Typography>
    </Stack>

    <Stack direction="row" spacing={1}>
      {getCustodyAgentTypeIcon(props.custodyAgent.tipo.nombre, "small", theme.palette.primary.main)}
      <Typography
        sx={{
          fontWeight: 'bold',
          // paddingBottom: '2px',
          // fontSize: '1.1rem',
        }}
        variant="subtitle2"
      >
        {typeText}
      </Typography>
    </Stack>

    <Stack direction="row" spacing={1}>
      <PublicIcon sx={{ color: theme.palette.primary.main }} fontSize="small" />
      <Typography
        sx={{
          fontWeight: 'bold',
          // paddingBottom: '2px',
          // fontSize: '1.1rem',
        }}
        variant="subtitle2"
      >
        {countryText}
      </Typography>
    </Stack>

  </Stack>
}

CustodyAgentHeader.propTypes = {
  custodyAgent: PropTypes.object, // Agente de custodia to display
  onClick: PropTypes.func, // Function to be called when user clicks on Header
}

export default CustodyAgentHeader;