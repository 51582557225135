// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography, Box, useTheme } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// My component imports
import { getCategoryIcon, getCustodyAgentTypeIcon, parseName } from "../../utils/misc";
import ConfirmationDialog from "./confirmationDialog";
import { scheduledMovementPropType } from "../../utils/myPropTypes";
import { generateAgendaPeriodicityText } from "./agendaListItem";


function MovimientoProgramadoListItemClassic({ movimiento, onDelete, onModify }) {
  // State constants
  const [labelsToShow, setLabelsToShow] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const theme = useTheme();
  const ammountText = movimiento ?
    movimiento.cuenta.moneda.nombre_corto + ' ' + movimiento.monto.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) : '';
  const commentText = movimiento ? movimiento.comentario : '';
  const acctName = movimiento ? movimiento.cuenta.nombre : '';
  const categoryName = movimiento ? movimiento.categoria.nombre : '';
  const icon = getCategoryIcon(movimiento ? movimiento.categoria.nombre : null, "small");

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const modifyClickHandler = event => {
    onModify(movimiento);
  }

  const handleDeleteConfirmation = event => {
    event.stopPropagation();
    onDelete(movimiento)
  }

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  // Effect hooks
  useEffect(() => {
    if (movimiento) {
      let myLabels = JSON.parse(JSON.stringify(movimiento.etiquetas));
      let labelsStack = myLabels.map(label => {
        return <Chip
          key={label.id}
          label={label.texto}
          variant="outlined"
          size="small"
          color="primary"
          sx={{ marginRight: "2px", marginBottom: "2px" }}
        />
      });
      setLabelsToShow(labelsStack);
    }
  }, [movimiento])

  return <Stack
    direction="column"
    spacing={1}
    sx={{ cursor: "pointer", backgroundColor: theme.palette.primary.main, borderRadius: "5px", padding: "3px" }}
    onClick={modifyClickHandler}
  >

    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estás seguro que quieres borrar este gasto?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={handleDeleteCancel}
      onConfirm={handleDeleteConfirmation}
    />

    <Stack direction="column" alignItems={"flex-start"} sx={{ padding: '3px' }}>
      <Stack direction={"row"} spacing={1}>
        <EditCalendarIcon color="primary" sx={{ fontSize: 15, color: "white" }} />
        <Typography variant="caption" fontWeight={"bold"}>{movimiento.agenda.nombre.toUpperCase()}</Typography>
      </Stack>
      {generateAgendaPeriodicityText(movimiento.agenda)}
    </Stack>

    <Stack direction="column" spacing={1} justifyContent={"center"} alignItems={"flex-start"} sx={{ backgroundColor: "white", borderRadius: "3px", padding: "3px" }}>
      <Stack direction="row" spacing={1} justifyContent={"center"} alignItems={"center"}>

        <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
          <Chip
            // color="primary"
            label={parseName(acctName)}
            size="medium"
            icon={getCustodyAgentTypeIcon(movimiento ? movimiento.cuenta.agente_de_custodia.tipo.nombre : null, "small")}
            variant="outlined"
          />
        </Stack>
        <Stack direction="row">
          <Chip
            // color="primary"
            label={categoryName}
            size="medium"
            icon={icon}
            variant="outlined"
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1} justifyContent={"space-between"} sx={{ width: "100%" }}>
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
          <Typography sx={{
            fontWeight: 'bold',
            alignSelf: 'center',
            paddingBottom: '2px',
            fontSize: '1.1rem',
            color: movimiento ? (movimiento.monto < 0 ? theme.palette.error.main : "green") : "green"
          }}
          >
            {ammountText}
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
          <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 35 }} />
        </Box>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle2">{commentText}</Typography>
      </Stack>
      <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>
        {labelsToShow}
      </Stack>
    </Stack>
  </Stack>



}

MovimientoProgramadoListItemClassic.propTypes = {
  movimiento: PropTypes.oneOf([scheduledMovementPropType]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default MovimientoProgramadoListItemClassic;