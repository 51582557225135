// MUI imports
import { Stack, Typography, Box } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

// PropTypes imports
import PropTypes from 'prop-types';

function FamilyInvitationListItem(props) {

  // Other constants
  const theme = useTheme();
  const emailText = props.invitation ? props.invitation.correo : '';
  const sentText = props.invitation ? props.invitation.creada_en : '';
  const revokedText = props.invitation ? (props.invitation.revocada ? props.invitation.revocada_en : '') : '';
  const icon = <EmailIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />;

  // Handlers
  const revokeClickHandler = event => {
    event.stopPropagation();
    props.onRevoke(props.invitation);
  }

  return <Stack direction="row" spacing={2} justifyContent='space-between' sx={{ paddingBottom: "20px" }}>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      {icon}
    </Box>

    <Stack direction="column" spacing={0} justifyContent={'center'} sx={{ flexGrow: 1 }}>
      <Typography variant="body">
        {emailText}
      </Typography>
      <Stack>
        <Typography variant="caption">
          Enviada:
        </Typography>
        <Typography variant="caption">
          {sentText}
        </Typography>
      </Stack>

      {props.invitation.revocada ?
        <Stack>
          <Typography variant="caption">
            Revocada:
          </Typography>
          <Typography variant="caption">
            {revokedText}
          </Typography>
        </Stack> :
        <></>
      }
    </Stack>

    {props.invitation.revocada || props.invitation.rechazada || props.invitation.aceptada ?
      <Stack direction="row" justifyContent={'flex-end'} alignItems="center" sx={{ flexGrow: 1 }}>
        <CancelIcon fontSize="medium" sx={{ color: theme.palette.primary.cancel }} />
      </Stack> :
      <Stack direction="row" onClick={revokeClickHandler} justifyContent={'flex-end'} alignItems="center" sx={{ flexGrow: 1 }}>
        <DeleteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
      </Stack>
    }

  </Stack >
}

FamilyInvitationListItem.propTypes = {
  invitation: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onRevoke: PropTypes.func, // Function to be called when user whats to revoke the invitation
};

export default FamilyInvitationListItem;