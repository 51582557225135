// React imports
import { useState, useEffect } from "react";

// React Router imports
import { useHistory } from "react-router-dom";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings";
import MyPaper from "../basic/myPaper";
import AddButton from "../basic/addButton";
import ScheduledRegistersList from "../lists/scheduledRegistersList";
import { getAgendas, getMovimientosProgramados, getMovimientosTarjetaProgramados } from "../../utils/api";
import { useUserProfile } from "../../utils/userProfile";
import RegisterScheduledExpense from "./registerScheduledExpense";
import MyLoadingList from "../basic/myLoadingList";
import { Alert, Button, Stack, Typography } from "@mui/material";


function ConfigureScheduledRegistersPage() {

  // Other constants
  const { profile } = useUserProfile();
  const history = useHistory();

  // State constants
  const [scheduledRegisters, setScheduledRegisters] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noAgendaError, setNoAgendaError] = useState(false);

  // Auxiliary functions
  function updateListFunction() {
    setLoading(true);
    setScheduledRegisters([]);
    let promises = [getMovimientosProgramados(), getMovimientosTarjetaProgramados()];
    Promise.all(promises)
      .then(responses => {
        const registerToUpdate = responses[0].results.concat(responses[1].results);
        setScheduledRegisters(registerToUpdate);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error al obtener los movimientos programados', error);
      });
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAgendas()
        .then(agendas => {
          if (agendas.results.length === 0) {
            setNoAgendaError(true);
          } else {
            setNoAgendaError(false);
            updateListFunction();
          }
        })
        .catch(error => {
          console.error('Error al obtener las agendas', error);
        });
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    console.log('scheduledRegisters has changed:', scheduledRegisters);
  }, [scheduledRegisters]);

  // Handlers
  const handleScheduledMovementCreate = () => {
    setShowForm(true);
  }

  const handleCancelScheduledMovementCreation = () => {
    setShowForm(false);
  }

  const handleSuccessfullCreation = (newScheduledMovement) => {
    updateListFunction();
    setShowForm(false);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configurar' },
    ]}
    currentPageName={'Programar registros'}
  > {noAgendaError && <MyPaper shallow>
    <Stack spacing={1}>
      <Alert severity="info" variant="outlined">
        <Typography>No hay agendas configuradas. Para poder configurar el registro programado de un gasto o un ingreso, primero
          necesitas crear una agenda.</Typography>
      </Alert>
      <Button onClick={() => history.push(urls.schedulesUrl)} variant="contained" color="primary" fullWidth>Ir a crear una agenda</Button>
    </Stack>
  </MyPaper>}
    {!noAgendaError && (showForm ?
      <RegisterScheduledExpense
        onCancel={handleCancelScheduledMovementCreation}
        onSubmit={handleSuccessfullCreation}
      />
      : (
        loading ? <MyPaper><MyLoadingList /></MyPaper> :
          <MyPaper shallow>
            {scheduledRegisters.length === 0 ?
              <Stack spacing={1}>
                <Alert severity="info" variant="outlined">
                  <Typography>No hay movimientos programados. Puedes agregar uno nuevo haciendo clic en el botón de abajo.</Typography>
                </Alert>
                <Button onClick={handleScheduledMovementCreate} variant="contained" color="primary" fullWidth>Agregar un movimiento programado</Button>
              </Stack>
              :
              <Stack>
                <ScheduledRegistersList
                  scheduledRegisters={scheduledRegisters}
                  updateList={updateListFunction}
                  compact={profile.tipo_de_lista === "ValidListTypes.compact"}
                />
                <AddButton onClick={handleScheduledMovementCreate} />
              </Stack>
            }
          </MyPaper>
      ))
    }
  </MyMenuComponent>
}

export default ConfigureScheduledRegistersPage;