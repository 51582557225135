// React Router imports
import { useHistory } from "react-router-dom";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Link, Box, Breadcrumbs, Stack, Button } from '@mui/material';
import { styled } from '@mui/system';

// My components imports
import Menu from "../basic/menu.js";
import { ErrorBoundary } from '../../utils/system.js';


const MyBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  position: 'fixed',
  left: '0',
  top: '0',
  zIndex: '2',
  padding: '10px',
  backgroundColor: 'white',
  width: '100%',
}));

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function MyMenuComponent(props) {

  const history = useHistory();

  return <Box>
    <MyBreadcrumbs aria-label="breadcrumb">
      {props.links.map((link, index) => {
        return <Link
          underline="hover"
          color="inherit"
          onClick={() => history.push(link.url)}
          key={index}
        >
          <Button disableElevation sx={{ fontSize: '0.5rem' }} variant="outlined">{link.name}</Button>
        </Link>
      }).concat(props.currentPageName ?
        <Button disableElevation sx={{ fontSize: '0.5rem' }} variant="outlined" key="currentPageButtonId">{props.currentPageName}</Button> :
        null)
      }

    </MyBreadcrumbs>
    <Offset />
    <Stack direction="column" alignItems="center" sx={{ padding: '5px', marginTop: "15px" }}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Stack>
    <Menu position="right" />
  </Box>
}

MyMenuComponent.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, name: PropTypes.string })), // array of objects containing urls and names for the navigation bar
  currentPageName: PropTypes.string, // name of the current page
}

export default MyMenuComponent;