// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

// My components imports
import EasyDateRangePicker from '../inputs/easyDateRangePicker.js';

function NewCardDatesForm(props) {

  return <div>
    <FormControl fullWidth>
      <EasyDateRangePicker
        helperValues={[]}
        strict={true}
        onChange={props.onChange}
        initialValues={{ initialDate: props.initialCloseDate, finalDate: props.initialDueDate }}
        initialLabel="Fecha de cierre"
        finalLabel="Fecha de vencimiento"
        initialDateDisabled={false}
        finalDateDisabled={false}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </FormControl>

    <Button variant="contained" color="cancel" onClick={() => props.onCancel()} fullWidth>Cancelar</Button>
  </div>

}

NewCardDatesForm.propTypes = {
  initialCloseDate: PropTypes.string, // Initial value for closeDate
  initialDueDate: PropTypes.string, // Initial value for closeDate
  minDate: PropTypes.instanceOf(Date), // Minimum date to be allowed in either close or due dates
  maxDate: PropTypes.instanceOf(Date), // Maxim date to be allowed in either close or due dates
  onCancel: PropTypes.func, // Function to be called when canceling 
  onChange: PropTypes.func, // Function to be called when there is a change in close or due dates
}

export default NewCardDatesForm;