// React imports
import React, { useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { TextField, Button, MenuItem, Stack, Switch, Typography } from '@mui/material';

// My components imports
import EasyDateRangePicker from '../inputs/easyDateRangePicker';
import EasyDatePicker from '../inputs/easyDatePicker';
import { getDateFromDateTime } from '../../utils/date';
import { tiposPeriodicidad, diasSemana } from '../../utils/constants';
import { agendaPropType } from '../../utils/myPropTypes';


let diasDelMes = [...Array(31).keys()].map(i => ({ label: i + 1, value: i + 1 }));
diasDelMes.push({ label: "Último día del mes", value: 0 });


const AgendaForm = ({ initialData, onSubmit, onCancel, nameError = false, fechaDeInicioError = false, fechaDeFinError = false }) => {

  const [nombre, setNombre] = useState(initialData.nombre);
  const [tipoDePeriodicidad, setTipoDePeriodicidad] = useState(initialData.tipo_periodicidad);
  const [diaDeLaSemana, setDiaDeLaSemana] = useState(0);
  const [diaDelMes, setDiaDelMes] = useState(initialData.dia_mes);
  const [initialDate, setInitialDate] = useState(initialData.fecha_inicio);
  const [finalDate, setFinalDate] = useState(initialData.fecha_fin);
  const [noFinalDate, setNoFinalDate] = useState(initialData.fecha_fin ? false : true);
  const [diaDelAno, setDiaDelAno] = useState(getDateFromDateTime(new Date(new Date().getFullYear(), initialData.mes - 1, diaDelMes)));

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
  };

  const handlePeriodicidadChange = (event) => {
    setTipoDePeriodicidad(event.target.value);
  };

  const handleDiaDeLaSemanaChange = (event) => {
    setDiaDeLaSemana(event.target.value);
  }

  const handleDiaDelMesChange = (event) => {
    if (event.target.value === "Último día del mes") {
      setDiaDelMes(0);
    } else {
      setDiaDelMes(event.target.value);
    }
  }

  const handleDateChange = (initialDate, finalDate) => {
    setInitialDate(initialDate);
    setFinalDate(finalDate);
  }

  const handleInitialDateChange = (date) => {
    setInitialDate(date);
  }

  const handleNoFinalDateChange = (event) => {
    if (event.target.checked) {
      setFinalDate('');
    } else {
      // Current initial date plus one year
      const newFinalDate = new Date(new Date(initialDate).getFullYear() + 1, new Date(initialDate).getMonth(), new Date(initialDate).getDate());
      setFinalDate(getDateFromDateTime(newFinalDate));
    };
    setNoFinalDate(event.target.checked);
  }

  const handleDiaDelAnoChange = (date) => {
    console.log('Setting dia del año', date);
    setDiaDelAno(date);
  }

  const handleSubmmit = () => {
    const dataToReturn = {
      nombre: nombre,
      tipo_periodicidad: tipoDePeriodicidad,
      fecha_inicio: initialDate,
    }
    if (initialData.id) { dataToReturn.id = initialData.id; }
    if (finalDate) { dataToReturn.fecha_fin = finalDate; }
    if (tipoDePeriodicidad === 'semanal') { dataToReturn.dia_semana = diaDeLaSemana; }
    if (tipoDePeriodicidad === 'mensual') { dataToReturn.dia_mes = diaDelMes; }
    if (tipoDePeriodicidad === 'anual') {
      const [year, month, day] = diaDelAno.split('-');
      dataToReturn.mes = parseInt(month, 10);
      dataToReturn.dia_mes = parseInt(day, 10);
    }
    onSubmit(dataToReturn);
  }

  return (
    <Stack spacing={1}>

      <TextField
        label="Nombre"
        name="nombre"
        value={nombre}
        onChange={handleNombreChange}
        error={nameError}
        helperText={nameError ? "Ya existe una agenda con el mismo nombre" : ""}
        fullWidth
      />

      {noFinalDate ?
        <EasyDatePicker
          label={"Fecha de inicio"}
          initialValue={initialDate}
          onChange={handleInitialDateChange}
          error={fechaDeInicioError}
          textError={fechaDeInicioError ? "La fecha de inicio debe de ser en el futuro" : ""}
        /> :
        <EasyDateRangePicker
          initialLabel={"Fecha de inicio"}
          finalLabel={"Fecha de fin"}
          initialValues={
            {
              initialDate: initialDate,
              finalDate: finalDate,
            }
          }
          strict={true}
          onChange={handleDateChange}
          initialDateError={fechaDeInicioError}
          initialDateTextError={fechaDeInicioError ? "La fecha de inicio debe de ser en el futuro" : ""}
          finalDateError={fechaDeFinError}
          finalDateTextError={fechaDeFinError ? "La fecha de fin debe de ser posterior a la fecha de inicio" : ""}
        />
      }

      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography>Sin fecha de fin</Typography>
        <Switch
          checked={noFinalDate}
          onChange={handleNoFinalDateChange}
          name="noFinalDate"
        />
      </Stack>

      <TextField
        select
        label="Tipo de Periodicidad"
        name="tipo_periodicidad"
        value={tipoDePeriodicidad}
        onChange={handlePeriodicidadChange}
        fullWidth
      >
        {tiposPeriodicidad.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {tipoDePeriodicidad === 'semanal' && (
        <TextField
          select
          label="Día de la Semana"
          name="dia_semana"
          value={diaDeLaSemana}
          onChange={handleDiaDeLaSemanaChange}
          fullWidth
        >
          {diasSemana.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}

      {tipoDePeriodicidad === 'mensual' && (
        <TextField
          select
          label="Día del Mes"
          name="dia_mes"
          value={diaDelMes}
          onChange={handleDiaDelMesChange}
          fullWidth
        >
          {diasDelMes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )

      }

      {tipoDePeriodicidad === 'anual' && (
        <EasyDatePicker
          label={"Día del año"}
          initialValue={diaDelAno}
          onChange={handleDiaDelAnoChange}
        />
      )
      }

      <Button variant="contained" color="primary" onClick={handleSubmmit}>{initialData.id ? 'Modificar agenda' : 'Crear agenda'}</Button>
      <Button variant="contained" color="cancel" onClick={onCancel}>Cancelar</Button>
    </Stack>
  );
};

AgendaForm.propTypes = {
  initialData: agendaPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  nameError: PropTypes.bool,
  fechaDeInicioError: PropTypes.bool,
  fechaDeFinError: PropTypes.bool,
};

export default AgendaForm;
