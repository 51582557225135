// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, Stack } from '@mui/material';

// My components imports
import AgendaListItem from '../basic/agendaListItem';
import { agendaPropType } from '../../utils/myPropTypes';

function AgendaList({ agendas, onDelete, onModify }) {
  return (
    <Stack spacing={2}>
      {agendas.map((agenda) => (
        <Stack spacing={2}>
          <AgendaListItem key={agenda.id} agenda={agenda} onDelete={onDelete} onModify={onModify} />
          {/* Add divider only if it is not the last element*/}
          {agendas.indexOf(agenda) < agendas.length - 1 && <Divider />}
        </Stack>
      ))}
    </Stack>
  );
}

AgendaList.propTypes = {
  agendas: PropTypes.arrayOf(agendaPropType).isRequired,
  onDelete: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
};

export default AgendaList;
