// MUI imports
import { ListItem, ListItemText } from "@mui/material";

// My components imports
import { paymentReceiptPropType } from "../../utils/myPropTypes";
import { dateToDisplayFormat } from "../../utils/date";


function SubscriptionPaymentReceiptListItem(props) {

  const { paymentReceipt } = props;

  return (
    <ListItem key={paymentReceipt.id}>
      <ListItemText
        primary={'Fecha de pago: ' + dateToDisplayFormat(paymentReceipt.fecha_de_pago)}
        secondary={'Monto: ' + paymentReceipt.monto + ' ' + paymentReceipt.moneda.nombre_corto}
      />
    </ListItem>
  );
}

SubscriptionPaymentReceiptListItem.propTypes = {
  paymentReceipt: paymentReceiptPropType,
};

export default SubscriptionPaymentReceiptListItem;