// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from "@mui/system";
import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

// My component imports
import { deepObjectCopy, getAmountText, getAmountDisplayColor, getCategoryIcon } from '../../utils/misc';


function CategorySummaryTable(props) {

  // Auxiliary functions
  const getCategoryName = (item) => {
    if (item.categoria.nombre === 'Transferencia') {
      return item.monto < 0 ? 'Creditos pagados' : 'Creditos cobrados';
    } else {
      return item.categoria.nombre;
    }
  }

  // Function to handle row click
  const handleRowClick = (type, category) => {
    // User has clicked on a row, let's construct the query object corresponding to that row based on the current query object of the parent component
    let newQueryObject = deepObjectCopy(props.queryObject);
    switch (type) {
      case 'ingresos':
        delete newQueryObject.amountLte;
        newQueryObject.amountGte = 0;
        newQueryObject.filterAmountWithAnd = false;
        break;
      case 'gastos':
        delete newQueryObject.amountGte;
        newQueryObject.amountLte = 0;
        newQueryObject.filterAmountWithAnd = false;
        break;
      case 'total':
        newQueryObject.amountGte = 0;
        newQueryObject.amountLte = 0;
        newQueryObject.filterAmountWithAnd = false;
        break;
      default:
        break;
    }
    if (category !== 'total') {
      newQueryObject.categories = [category];
    }
    props.onRowClick(newQueryObject);
  }

  return <TableContainer>
    <Table>
      <TableHead>
        <TableCell>Tabla de resumen</TableCell>
        <TableCell></TableCell>
      </TableHead>
      {props.data.ingresos.por_categoria.map(item => {
        return <TableRow key={getCategoryName(item)} onClick={() => handleRowClick('ingresos', item.categoria)} sx={{ cursor: 'pointer' }}>
          <TableCell>
            <Stack direction="row" spacing={1}>
              {getCategoryIcon(item.categoria.nombre, "small")}
              <i>{getCategoryName(item)}</i>
            </Stack>
          </TableCell>
          <TableCell sx={{ color: getAmountDisplayColor(parseFloat(item.monto)) }}><i>{getAmountText(parseFloat(item.monto), props.data.moneda)}</i></TableCell>
        </TableRow>
      })}
      <TableRow sx={{ backgroundColor: "lightgrey", cursor: 'pointer' }} onClick={() => handleRowClick('ingresos', 'total')}>
        <TableCell sx={{ fontWeight: "bold" }}>Ingresos:</TableCell>
        <TableCell sx={{ fontWeight: "bold", color: getAmountDisplayColor(props.data.ingresos.total) }}>{getAmountText(parseFloat(props.data.ingresos.total), props.data.moneda)}</TableCell>
      </TableRow>

      {props.data.gastos.por_categoria.map(item => {
        return <TableRow key={getCategoryName(item)} onClick={() => handleRowClick('gastos', item.categoria)} sx={{ cursor: 'pointer' }}>
          <TableCell>
            <Stack direction="row" spacing={1}>
              {getCategoryIcon(item.categoria.nombre, "small")}
              <i>{getCategoryName(item)}</i>
            </Stack>
          </TableCell>
          <TableCell sx={{ color: getAmountDisplayColor(parseFloat(item.monto)) }}><i>{getAmountText(parseFloat(item.monto), props.data.moneda)}</i></TableCell>
        </TableRow>
      })}
      <TableRow sx={{ backgroundColor: "lightgrey", cursor: 'pointer' }} onClick={() => handleRowClick('gastos', 'total')}>
        <TableCell sx={{ fontWeight: "bold" }}>{props.isCashflow ? 'Egresos:' : 'Gastos:'}</TableCell>
        <TableCell sx={{ fontWeight: "bold", color: getAmountDisplayColor(parseFloat(props.data.gastos.total)) }}>{getAmountText(parseFloat(props.data.gastos.total), props.data.moneda)}</TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: "grey", color: "white", cursor: 'pointer' }} onClick={() => handleRowClick('total', 'total')}>
        <TableCell sx={{ fontWeight: "bold" }}>{props.isCashflow ? 'Flujo total' : 'Total:'}</TableCell>
        <TableCell sx={{ fontWeight: "bold", color: getAmountDisplayColor(parseFloat(props.data.gastos.total + props.data.ingresos.total)) }}>
          {getAmountText(parseFloat(props.data.gastos.total + props.data.ingresos.total), props.data.moneda)}
        </TableCell>
      </TableRow>
    </Table>
  </TableContainer>
}

CategorySummaryTable.propTypes = {
  data: PropTypes.shape({
    gastos: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    ingresos: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    total: PropTypes.shape({
      por_categoria: PropTypes.arrayOf(PropTypes.shape({
        categoria: PropTypes.object,
        monto: PropTypes.number
      })),
      por_etiqueta: PropTypes.arrayOf(PropTypes.shape({
        etiqueta: PropTypes.object,
        monto: PropTypes.number
      })),
      total: PropTypes.number,
    }),
    moneda: PropTypes.shape({
      nombre_corto: PropTypes.string,
      nombre: PropTypes.string,
      id: PropTypes.number
    })
  }),
  queryObject: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  isCashflow: PropTypes.bool, // If true, the table will be displayed as a cashflow table, otherwise it will be displayed as a ER table. Default is false.
}

export default CategorySummaryTable;