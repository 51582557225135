// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useParams } from "react-router-dom";

// MUI component imports
import { Stack } from "@mui/material";

// My component imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings.js";
import { getCardAccountStates, getCardMovements, getCards, getCategories, getUserAccounts } from "../../utils/api";
import EstadoDeCuentaListItem from "../basic/estadoDeCuentaListItem";
import MyPaper from "../basic/myPaper.js";
import MyLoadingList from "../basic/myLoadingList.js";
import NewMovementList from "../lists/newMovementList";
import { useUserProfile } from "../../utils/userProfile";


function CardCurrentMovementsPage(props) {

  // Constants
  const params = useParams();
  const { cardId } = useParams();

  // State constants
  const { profile, setProfile } = useUserProfile();
  const [accountStates, setAccountStates] = useState([]);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [cardMovementListToDisplay, setCardMovementListToDisplay] = useState([]);
  const [currentAccountState, setCurrentAccountState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState(null); // This is for the purpose of showing card name in navigation bar only

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getCardAccountStates(params.cardId, { initialDate: new Date() }), getCards(), getCategories(), getUserAccounts()])
      .then(response => {
        if (isMounted) {
          setAccountStates(response[0].results.sort((a, b) => new Date(a.fecha_de_cierre) - new Date(b.fecha_de_cierre)).filter(item => !item.cerrado));
          setCards(response[1].results);
          setCategories(response[2].results);
          setAccounts(response[3].results);
        }
      })
      .catch(err => console.log(err))
    return () => { isMounted = false }
  }, [params.cardId])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && cards.length > 0) {
      setCurrentCard(cards.find(card => card.id === parseInt(params.cardId)));
    }
    return () => { isMounted = false }
  }, [cards, params.cardId])

  // Handlers
  const handleAccountStateClick = state => {
    // Todo: complete this, but first modify API so it allows to filter MovimientoTarjeta by EstadoDeCuenta
    setCurrentAccountState(state);
    setLoading(true);
    const queryObject = {
      accountStates: [state]
    }
    getCardMovements(queryObject)
      .then(response => {
        setCardMovementListToDisplay(response.results);
        setLoading(false);
        console.log(response.results);
      })
      .catch(err => console.log(err));
  }

  const handleCardMovementChange = () => {
    handleAccountStateClick(currentAccountState);
  }

  return <MyMenuComponent
    links={[
      { url: urls.configureCustodyAgentUrl, name: 'Lugares de custodia' },
      { url: currentCard ? urls.configureCustodyAgentUrl + "/" + currentCard.agente_de_custodia.id : urls.configureCustodyAgentUrl, name: currentCard ? currentCard.agente_de_custodia.nombre : 'Cargando...' },
      { url: urls.configureCardsUrl + "/" + params.cardId, name: currentCard ? currentCard.nombre : 'Cargando...' }
    ]}
  >
    <Stack sx={{ paddingTop: "15px" }} spacing={1}>
      {accountStates.map(state => {
        return <Stack key={state.id}>
          <MyPaper>
            <EstadoDeCuentaListItem
              estadoDeCuenta={state}
              onClick={handleAccountStateClick}
              movimientos={currentAccountState && (currentAccountState.id === state.id) ? cardMovementListToDisplay : []}
            />
          </MyPaper>
          {currentAccountState && (currentAccountState.id === state.id) ?
            (loading ?
              <MyPaper><MyLoadingList /></MyPaper>
              :
              <NewMovementList
                movements={cardMovementListToDisplay}
                updateList={handleCardMovementChange}
                cards={cards}
                accounts={accounts}
                categories={categories}
                showInstallment
                compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
              />)
            :
            <></>
          }
        </Stack>
      })}
    </Stack>

  </MyMenuComponent>
}

export default CardCurrentMovementsPage;