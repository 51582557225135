// React imports
import { useEffect, useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import { createAccount, modifyAccount } from "../../utils/api";
import AccountForm from '../forms/accountForm';
import MyLoadingList from '../basic/myLoadingList';
import { deepObjectCopy } from '../../utils/misc';
import { accountPropType } from '../../utils/myPropTypes';

function CreateModifyAccount(props) {

  // Deconstructing props
  const { currencies, custodyAgents, initialValues, onCancel, onSubmit } = props;

  // State constants
  const [ready, setReady] = useState(false);
  const [initialAccount, setInitialAccount] = useState(initialValues.initialAccount);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted && currencies && currencies.length > 0 && custodyAgents && custodyAgents.length > 0) {
      if (initialValues.custodyAgent) {
        // User has passed a custody agent, so we set it as the initial value, lets modify the initial values
        let newIinitialAccount = deepObjectCopy(initialValues.initialAccount);
        newIinitialAccount.agente_de_custodia = initialValues.custodyAgent;
        setInitialAccount(newIinitialAccount);
      } else {
        // User has not passed a custody agent, so we leave the initial values as they are
        setInitialAccount(initialValues.initialAccount);
      }
    }
    return () => { isMounted = false };
  }, [currencies, custodyAgents]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && initialAccount) {
      setReady(true);
    }
    return () => { isMounted = false };
  }, [initialAccount]);

  // Handlers
  const submitHandler = data => {
    if (initialValues.intent === 'create') {
      createAccount(data.nombre, data.numero, data.balance, data.moneda.id, data.agente_de_custodia.id, data.tipo_de_cuenta.id)
        .then(result => onSubmit(result))
        .catch(err => console.log(err))
    } else {
      modifyAccount(data.nombre, data.numero, data.balance, data.moneda.id, data.agente_de_custodia.id, data.tipo_de_cuenta.id, data.id)
        .then(result => onSubmit(result))
        .catch(err => console.log(err))
    }
  }

  return ready ? <AccountForm
    account={initialAccount}
    currencies={currencies}
    custodyAgents={custodyAgents}
    intent={initialValues.intent}
    onSubmit={submitHandler}
    onCancel={onCancel}
  /> :
    <MyLoadingList />
}

CreateModifyAccount.propTypes = {
  initialValues: PropTypes.shape({
    initialAccount: accountPropType,// Account object to initialize Form in case of modify intent
    intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing account
    custodyAgent: PropTypes.object, // Optional value, if present, the custody agent will be preselected
  }).isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired, // All available currencies
  custodyAgents: PropTypes.arrayOf(PropTypes.object).isRequired, // All available custody agents
  onSubmit: PropTypes.func.isRequired, // Function to be called when an account is created or modified
  onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the operation
}

export default CreateModifyAccount;