// React imports
import { useEffect, useState } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Dialog, DialogContent, DialogTitle, TextField, Button, FormControl, Stack, DialogContentText } from '@mui/material';
import { createLabel } from '../../utils/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

function CreateLabelDialog(props) {

  // Destructor props constants
  const { open } = props;
  const { onCancel } = props;
  const { initialText } = props;
  const { onCreated } = props;

  // State constants
  const [labelText, setLabelText] = useState(initialText); // The text of the label to be created
  const [showSuccess, setShowSuccess] = useState(false); // Whether the success message should be shown
  const [currentLabel, setCurrentLabel] = useState(null); // The label that was just created
  const [error, setError] = useState(false); // Whether the error message should be shown]

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Initialize state constants in case there are more than one run of this component in the same page
      setShowSuccess(false);
      setCurrentLabel(null);
      setError(false);
    }
    return () => { isMounted = false }
  }, [open]);

  useEffect(() => {
    setLabelText(initialText);
  }, [initialText])

  // Handlers
  const handleLabelTextChange = (event) => {
    // Lowercase the text
    setLabelText(event.target.value.toLowerCase());
  }

  const handleCreateLabel = () => {
    // Remove trailing spaces
    let trimmedText = labelText.trim();
    setLabelText(trimmedText);
    if (trimmedText === '') {
      return;
    }
    createLabel(labelText)
      .then(result => {
        setCurrentLabel(result);
        setShowSuccess(true);
      })
      .catch(err => {
        console.log(err);
        if (err.code === 409 && err.text === "etiqueta already exists") {
          console.log('etiqueta duplicada!');
          setError(true);
        }
      })
  }

  const handleCancel = () => {
    setLabelText('');
    onCancel();
  }

  return <Dialog onClose={handleCancel} open={open}>
    <DialogTitle>Crear etiqueta</DialogTitle>
    <DialogContent>
      {showSuccess ?
        <FormControl fullWidth>
          <Stack alignItems="center">
            <CheckCircleIcon color="success" fontSize="large" />
          </Stack>

          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "5px" }}
            onClick={() => onCreated(currentLabel)}
          >Volver</Button>
        </FormControl> :
        (
          error ?
            <Stack direction="column" spacing={1}>
              <ErrorIcon fontSize="large" color="error" />
              <DialogContentText>
                Vaya, parece que la etiqueta ya existe.
                Puedes utilizarla directamente.
              </DialogContentText>
            </Stack> :
            <FormControl>
              <TextField value={labelText} onChange={handleLabelTextChange} label="Nueva etiqueta" />
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "5px" }}
                onClick={handleCreateLabel}
                disabled={labelText === ''}
              >Crear etiqueta</Button>
            </FormControl>
        )
      }

    </DialogContent>
  </Dialog >;
}

CreateLabelDialog.propTypes = {
  onCreated: PropTypes.func.isRequired, // Function to be called when a new label is created
  onCancel: PropTypes.func.isRequired, // Function to be called when user cancels
  open: PropTypes.bool.isRequired, // Indicates whether the dialog should be open
  initialText: PropTypes.string, // Text to initialize dialog
}

export default CreateLabelDialog;