// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI component imports
import { Stack } from "@mui/system";

// My components imports
import EasyDateRangePicker from "../inputs/easyDateRangePicker";
import CardPaymentList from "../lists/CardPaymentList";
import { getCardPayments } from "../../utils/api";
import { getDateFromDateTime } from "../../utils/date";
import { useUserProfile } from "../../utils/userProfile";


function SearchCardPayment(props) {

  // Constants
  const { profile } = useUserProfile();

  // State constants
  const [initialDate, setInitialDate] = useState(getDateFromDateTime(new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate())));
  const [finalDate, setFinalDate] = useState(getDateFromDateTime(new Date()));
  const [paymentsList, setPaymentsList] = useState([]);

  // Effect hooks
  useEffect(() => {
    getCardPayments({ initialDate: initialDate, finalDate: finalDate, cards: [props.card] })
      .then(response => {
        setPaymentsList(response.results);
      })
      .catch(err => console.log(err));
  }, [initialDate, finalDate])

  // Handlers
  const handleDateChange = (init, fin) => {
    setInitialDate(init);
    setFinalDate(fin);
  }

  return <Stack spacing={1}>
    <EasyDateRangePicker
      initialLabel={"Desde"}
      finalLabel={"Hasta"}
      initialValues={{
        initialDate: initialDate,
        finalDate: finalDate,
      }}
      helperValues={["inicioDeMes", "inicioDeAno"]}
      onChange={handleDateChange}
    />
    <CardPaymentList
      paymentsList={paymentsList}
      updateList={props.onPaymentChange}
      card={props.card}
      accounts={props.accounts}
      compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
    />
  </Stack>
}

SearchCardPayment.propTypes = {
  card: PropTypes.object.isRequired, // Card from which to search payments
  accounts: PropTypes.arrayOf(PropTypes.object), // List of all available accounts
  onPaymentChange: PropTypes.func.isRequired, // Function to be called when a payment is modified by the user
}

export default SearchCardPayment;