// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function ReporteDeBalance() {
  return <Typography variant="body1">
    El <TextChipLink label="Reporte de balance" url={urls.visualizeBalance} /> te mostrará tus <strong>activos</strong>, que se componen de:<br />
    <ul>
      <li><strong>Disponibilidades</strong></li>
      <Typography variant="caption" fontStyle="italic">Esto es el dinero que tienes en tus cuentas</Typography>
      <li><strong>Cuentas por cobrar</strong></li>
      <Typography variant="caption" fontStyle="italic">Estas son las cuentas de compromisos financieros con saldo positivo</Typography>
    </ul>
    Luego mostrará tus <strong>pasivos</strong>, que se componen de:<br />
    <ul><li><strong>Cuentas por pagar</strong></li>
      <Typography variant="caption" fontStyle="italic">Estas son las cuentas de compromisos financieros con saldo negativo</Typography>
      <li><strong>Saldo de tarjetas</strong></li>
      <Typography variant="caption" fontStyle="italic">La deuda que figura en tu último estado de cuenta no pago</Typography>
      <li><strong>Movimientos actuales en las tarjetas</strong></li>
      <Typography variant="caption" fontStyle="italic">Es la suma de todos las compras que hiciste con la tarjeta y aún no fueron incluídas en algún estado de cuenta</Typography>
    </ul>
    Finalmente, te mostrará tu <strong>patrimonio</strong> neto, que es la diferencia entre tus <strong>activos</strong> y tus <strong>pasivos</strong>.
  </Typography>
}

export default ReporteDeBalance;