// React imports
import { useState } from "react";

// MUI imports
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

// My components imports
import { urls } from "../../settings.js";
import MyMenuComponent from './myMenuComponent.js';
import RegisterCardMovementPage from "./registerCardMovementPage.js";
import MyPaper from "../basic/myPaper.js";
import RegisterMovementPage from "./registerMovementPage.js";

function RegisterExpense(props) {

  // State constants
  const [typeOfPayment, setTypeOfPayment] = useState("cash"); // Possible options are "cash" or "credit"

  // Handlers
  const handleTypeOfPaymentChange = (event, newTypeOfPayment) => {
    if (newTypeOfPayment !== null) {
      setTypeOfPayment(newTypeOfPayment);
    }
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.registrationsUrl, name: 'Registrar' },
    ]}
    currentPageName={'Compra'}
  >
    <MyPaper>
      <ToggleButtonGroup
        color="primary"
        value={typeOfPayment}
        onChange={handleTypeOfPaymentChange}
        exclusive
        sx={{ display: "flex", flewGrow: 1, flexDirection: "row" }}
      >
        <ToggleButton value="cash" sx={{ width: "50%" }}>Contado</ToggleButton>
        <ToggleButton value="credit" sx={{ width: "50%" }}>Crédito</ToggleButton>
      </ToggleButtonGroup>
    </MyPaper>

    {typeOfPayment === "cash" ?
      <RegisterMovementPage isExpense allowSwitching /> :
      <RegisterCardMovementPage isExpense allowSwitching />
    }
  </MyMenuComponent>
}

export default RegisterExpense;
