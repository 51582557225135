// React imports
import { useState, useEffect } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// React router imports
import { useHistory } from "react-router-dom";

// MUI imports
import { Stack, TextField } from '@mui/material';

// My components imports
import MyPaper from '../basic/myPaper';
import CustodyAgentListItem from '../basic/custodyAgentListItem';
import { urls } from '../../settings';


function CustodyAgentsList(props) {

  // Constants
  const history = useHistory();

  // State constants
  const [agentList, setAgentList] = useState([]);
  const [filterText, setFilterText] = useState('');

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setAgentList(props.custodyAgents.filter((custodyAgent) => {
        return custodyAgent.nombre.toLowerCase().includes(filterText.toLowerCase());
      }).map((custodyAgent) => {
        return <MyPaper key={custodyAgent.id}>
          <CustodyAgentListItem
            custodyAgent={custodyAgent}
            onDelete={() => props.onDelete(custodyAgent)}
            onModify={() => {
              history.push(urls.configureCustodyAgentUrl + '/' + custodyAgent.id)
            }}
          />
        </MyPaper>
      }));
    }
    return () => { isMounted = false }
  }, [props.custodyAgents, filterText]);

  return <Stack>
    <MyPaper>
      <TextField
        label="Filtrar"
        value={filterText}
        onChange={(event) => setFilterText(event.target.value)}
        fullWidth
        autoFocus
      />
    </MyPaper>
    {agentList}
  </Stack>
}

CustodyAgentsList.propTypes = {
  custodyAgents: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default CustodyAgentsList;