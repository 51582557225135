// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Alert, Chip, Divider, FormControl, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// My component imports
import { urls } from "../../settings";
import MyMenuComponent from "./myMenuComponent";
import MyPaper from "../basic/myPaper.js";
import EasyDateRangePicker from "../inputs/easyDateRangePicker.js";
import { getDateFromDateTime } from "../../utils/date";
import { getCurrencies, getCurrencyExchange } from "../../utils/api";
import { getAmountText } from "../../utils/misc.js";
import MonedaPicker from "../inputs/monedaPicker.js";
import usFlag from "../../static_content/us_flag.gif";
import arFlag from "../../static_content/arg_flag.gif";
import eurFlag from "../../static_content/euro_flag.png";
import brFlag from "../../static_content/brasil_flag.png";
import uiFlag from "../../static_content/UI_flag.png";
import MyLineChart from "../graphs/myLineChart.js";


const STATIC_CURRENCIES_CODES = ['USD', 'EUR', 'BRL', 'UYI', 'ARS'];
const FLAGS = {
  USD: usFlag,
  EUR: eurFlag,
  BRL: brFlag,
  UYI: uiFlag,
  ARS: arFlag
}

function VisualizeCuerrencyExchange() {

  // State constants
  const [currencies, setCurrencies] = useState([]);  // All currencies
  const [staticCurrencies, setStaticCurrencies] = useState([])  // Static currencies for the board at the top of the page
  const [selectedCurrency, setSelectedCurrency] = useState(null);  // Selected currency
  const [initialDate, setInitialDate] = useState(getDateFromDateTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1))); // Initial date, frist day of the month
  const [finalDate, setFinalDate] = useState(getDateFromDateTime(new Date()));
  const [currencyRates, setCurrencyRates] = useState([]);
  const [staticCurrencyRates, setStaticCurrencyRates] = useState([]);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCurrencies()
        .then(response => {
          setStaticCurrencies(response.results.filter(item => STATIC_CURRENCIES_CODES.includes(item.nombre_corto)))
          setCurrencies(response.results.filter(curr => curr.nombre_corto !== "UYT" && curr.nombre_corto !== "UYU"));
        })
        .catch(err => console.log(err))
    }
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    // First update static data
    let isMounted = true;
    if (isMounted) {
      const staticPromises = staticCurrencies.map(currency => {
        return getCurrencyExchange(currency.id, { last: true }); // get las quote
      })

      Promise.all(staticPromises)
        .then(responses => {
          setStaticCurrencyRates(responses.map(response => response));
        })
        .catch(err => console.log(err));

      // Now dynamic data for the line Chart
      selectedCurrency && getCurrencyExchange(selectedCurrency.id, { initialDate: initialDate, finalDate: finalDate })
        .then(response => {
          console.log("this are the currency rates", response.results);
          setCurrencyRates(response.results);
        })
        .catch(err => console.log(err));;
    }
    return () => { isMounted = false }
  }, [initialDate, finalDate, selectedCurrency, staticCurrencies])

  // Handlers
  const handleDateChange = (init, fin) => {
    setInitialDate(init);
    setFinalDate(fin);
  }

  const handleCurrencyChange = (newCurr) => {
    setSelectedCurrency(newCurr);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.visualizationsUrl, name: 'Analizar' }
    ]}
    currentPageName={'Cotizaciones de moneda'}
  >

    <MyPaper>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Stack justifyContent="center" flexGrow={1}>
            <Typography variant="subtitle2">Última actualización:</Typography>
          </Stack>
          <Stack>
            <Chip
              label={staticCurrencyRates.length > 0 ? staticCurrencyRates[0].fecha : ''}
              avatar={<CalendarMonthIcon />}
              variant="contained"
            />
          </Stack>
        </Stack>
        <Divider />
        {staticCurrencyRates.map(quote => {
          if (quote.moneda) {
            return <Stack direction="row" spacing={3} key={quote.id}>
              <Stack justifyContent="center">
                <img src={FLAGS[quote.moneda.nombre_corto]} width={40} height={30} />
              </Stack>
              <Stack flexGrow={1} flexDirection="row">
                <Stack flexGrow={1} justifyContent="flex-start" alignItems="flex-start">
                  <Typography variant="subtitle2" flexGrow={1}>Compra: {quote ? getAmountText(quote.compra) : 'N/A'}</Typography>
                </Stack>
                <Stack flexGrow={1} justifyContent="flex-start" alignItems="flex-start">
                  <Typography variant="subtitle2">Venta: {quote ? getAmountText(quote.venta) : 'N/A'}</Typography>
                </Stack>
              </Stack>
            </Stack>
          } else {
            return <></>
          }
        })}
      </Stack>
    </MyPaper>
    <MyPaper>
      <Stack spacing={1}>
        <EasyDateRangePicker
          initialValues={{ initialDate: initialDate, finalDate: finalDate }}
          onChange={handleDateChange}
          helperValues={["inicioDeMes", "inicioDeAno"]}
        />

        {currencies.length > 0 ?
          <FormControl fullWidth>
            <MonedaPicker
              currencies={currencies}
              onChange={handleCurrencyChange}
              initialValues={selectedCurrency}
              multiple={false}
            />
          </FormControl> : <></>
        }
      </Stack>
      {currencyRates.length > 0 ?
        <MyLineChart
          data={currencyRates.map(rate => { return { fecha: rate.fecha, promedio: ((rate.compra + rate.venta) / 2).toFixed(2) } })}
          xLegend={"fecha"}
          yLegend={"promedio"}
        />
        :
        <Stack>
          <Alert severity="info" sx={{ marginTop: "5px" }}>
            <Typography>Selecciona una moneda en el cuadro de arriba para obtener información histórica</Typography>
          </Alert>
        </Stack>
      }

    </MyPaper>
  </MyMenuComponent>

}

export default VisualizeCuerrencyExchange;