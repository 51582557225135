// React imports
import { useState, useEffect } from "react";

// MUI component imports
import { TextField, Stack } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// PropTypes imports
import PropTypes from 'prop-types';

// Date-fns imports
import { es } from "date-fns/locale";
import { getDateFromDateTime, getDateTimeFromDateString } from "../../utils/date";

function EasyMonthRangePicker(props) {

  // Destructor onChange function from props
  const { onChange } = props;

  // State constants
  const [initialDate, setInitialDate] = useState(props.initialDate ? getDateTimeFromDateString(props.initialDate) : new Date());
  const [finalDate, setFinalDate] = useState(props.finalDate ? getDateTimeFromDateString(props.finalDate) : new Date());
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      onChange(getDateFromDateTime(initialDate), getDateFromDateTime(finalDate));
    }
  }, [initialDate, finalDate, ready, onChange])

  // Handlers
  const changeInitialDateHandler = newDate => {
    setInitialDate(newDate);
    setReady(true);
  }

  const changeFinalDateHandler = newDate => {
    setFinalDate(newDate);
    setReady(true);
  }

  return <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
    <Stack direction="row" spacing={1}>
      <DatePicker
        views={['year', 'month']}
        openTo="month"
        label="Mes inicial"
        minDate={props.minDate}
        maxDate={finalDate ? finalDate : props.maxDate}
        value={initialDate}
        onChange={changeInitialDateHandler}
        renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
      />
      <DatePicker
        views={['year', 'month']}
        openTo="month"
        label="Mes final"
        minDate={initialDate ? initialDate : props.minDate}
        maxDate={props.maxDate}
        value={finalDate}
        onChange={changeFinalDateHandler}
        renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
      />
    </Stack>

  </LocalizationProvider>
}

EasyMonthRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired, // function to be called when the user changes selection
  minDate: PropTypes.instanceOf(Date),  // Minimum date allowed for selection
  maxDate: PropTypes.instanceOf(Date),  // Maximum date allowed for selection
  initialDate: PropTypes.string, // String representing the initial date, format: "YYYY-MM-DD"
  finalDate: PropTypes.string, // String representing the final date, format: "YYYY-MM-DD"
}

export default EasyMonthRangePicker;