// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Button, FormControl, TextField, useTheme } from '@mui/material';

// My component imports
import LabelInput from '../inputs/labelInput.js';
import CuentaPicker from '../inputs/cuentaPicker.js';
import { getScheduledRegisterButtonText } from '../../utils/misc.js';
import MontoInput from "../inputs/montoInput.js";
import CategoriaPicker from "../inputs/categoriaPicker.js";
import { Stack, Typography } from "@mui/material";
import { accountPropType, agendaPropType, categoryPropType } from '../../utils/myPropTypes.js';
import AgendaInput from '../inputs/agendaInput.js';


function ScheduledMovementForm(props) {

  // Deconstructing props
  const { agendas } = props;

  // Other constants
  const theme = useTheme();

  // State constants
  const [selectedAgenda, setSelectedAgenda] = useState(props.initialMovement.agenda);
  const [selectedAcct, setSelectedAcct] = useState(props.initialMovement.cuenta ? props.initialMovement.cuenta : props.accounts[0]);
  const [labels, setLabels] = useState(props.initialMovement.etiquetas);
  const [selectedCategory, setSelectedCategory] = useState(
    props.initialMovement.categoria ?
      props.initialMovement.categoria :
      props.categories.filter(cat => cat.nombre === 'Supermercado')[0]); // Supermercado is supposed to be the most frequently used category
  const [ammount, setAmmount] = useState(props.initialMovement.monto);
  const [comment, setComment] = useState(props.initialMovement.comentario);
  const [formIsReady, setFormIsReady] = useState(false);
  const [hintText, setHintText] = useState('');

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (selectedAgenda && selectedAcct && selectedCategory && agendas.length > 0 && isMounted) {
      setFormIsReady(true);
    } else {
      setFormIsReady(false);
      setHintText('Por favor, completa todos los campos obligatorios: agenda, cuenta, categoría y monto.');
      agendas.length === 0 && console.error('No hay agendas disponibles');
    }
    return () => { isMounted = false }
  }, [selectedAgenda, selectedAcct, selectedCategory, ammount, agendas]);

  useEffect(() => {
    let isMounted = true;
    let icompleteFields = [];
    if (!selectedAcct) {
      icompleteFields.push('la cuenta');
    }
    if (!selectedCategory) {
      icompleteFields.push('la categoría');
    }
    if (isMounted) {
      // Give user feedback on mandatory fields, add a 'y' before the last one, only if there are more than one. 
      // At last add a recommendation to add labes aswell, only if there are no labels
      if (icompleteFields.length > 0) {
        let hint = 'Antes de registrar el movimiento por favor completa ';
        if (icompleteFields.length > 1) {
          hint += icompleteFields.slice(0, -1).join(', ') + ' y ' + icompleteFields.slice(-1);
        } else {
          hint += icompleteFields[0];
        }
        if (labels.length === 0) {
          hint += '. También te recomendamos agregar etiquetas para poder filtrar tus movimientos más fácilmente.';
        } else {
          hint += '.';
        }
        setHintText(hint);
      } else {
        setHintText('');
      }
    }
    return () => { isMounted = false }
  }, [selectedAcct, selectedCategory, labels]);

  // Change handlers
  const handleCommentChange = event => setComment(event.target.value);

  const modifyMovementHandler = event => {
    props.onSubmit({
      id: props.initialMovement ? props.initialMovement.id : null,
      agenda: selectedAgenda,
      cuenta: selectedAcct,
      monto: ammount,
      categoria: selectedCategory,
      comentario: comment,
      etiquetas: labels,
    });
  };

  return <Stack spacing={1}>

    <AgendaInput agendas={props.agendas} selectedAgenda={selectedAgenda} onChange={setSelectedAgenda} />

    <FormControl fullWidth>
      <CuentaPicker
        initialValues={selectedAcct}
        onChange={newValue => setSelectedAcct(newValue)}
        accounts={props.accounts}
        multiple={false}
      />
    </FormControl>

    <FormControl fullWidth>
      <CategoriaPicker
        categories={props.categories}
        initialValues={selectedCategory}
        onChange={newVal => setSelectedCategory(newVal)}
        multiple={false}
      />
    </FormControl>

    <FormControl fullWidth>
      <MontoInput
        ammount={ammount}
        onChange={value => setAmmount(value)}
        label="Monto"
        isExpense={props.isExpense}
        allowSwitching={props.allowSwitching}
      />
    </FormControl>

    <FormControl fullWidth>
      <LabelInput
        initialLabels={props.initialMovement.etiquetas}
        onChange={(selectedLabels) => setLabels(selectedLabels)}
      />
    </FormControl>

    <FormControl fullWidth>
      <TextField
        id="comentario"
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        type="text"
        label="Comentario"
      />
    </FormControl>

    {formIsReady ?
      <Button variant="contained" onClick={modifyMovementHandler} fullWidth>
        {getScheduledRegisterButtonText(props.intent, props.isExpense)}
      </Button> : <Typography variant="caption" sx={{ color: theme.palette.error.main }}>
        {hintText}
      </Typography>
    }

    <Button variant="contained" color="cancel" onClick={props.onCancel} fullWidth>
      Cancelar
    </Button>
  </Stack>

}

ScheduledMovementForm.propTypes = {
  accounts: PropTypes.arrayOf(accountPropType).isRequired, // List of available accounts, this variable must be initialized before rendering this component
  categories: PropTypes.arrayOf(categoryPropType).isRequired, // List of available categories, this variable must be initialized before rendering this component
  agendas: PropTypes.arrayOf(agendaPropType).isRequired, // List of available agendas, this variable must be initialized before rendering this component
  initialMovement: PropTypes.object, // Object to initialize Transfer
  onSubmit: PropTypes.func.isRequired, // Function to be called when the user submit the Transfer
  onCancel: PropTypes.func.isRequired, // Function to be called when the user cancel the operation
  intent: PropTypes.oneOf('create', 'modify'), // Indicates whether we are creating or modificating a movement
  isExpense: PropTypes.bool.isRequired, // Indicates whether the user in registering an expense or an income
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default ScheduledMovementForm;