// MUI imports
import { Typography } from "@mui/material";

// My components imports
import TextChipLink from "../basic/textChipLink.js";
import { urls } from "../../settings.js";

function Actividad() {
  return <Typography variant="body1">
    El reporte de <TextChipLink label="Actividad" url={urls.searchMovementUrl} /> te permitirá buscar tus movimientos de una forma rápida y sencilla. Podrás filtrar por fecha, cuenta, tarjeta, categoría, etiqueta, tipo de movimiento, monto, descripción, y más.
    <br /><br />
    Este reporte está diseñado para ayudarte a buscar movimientos específicos, y no para analizar tus finanzas.
    <br /><br />
    Un caso de uso muy común de este reporte es cuando tienes dudas si recordaste registrar en la aplicación un gasto determinado.
    <br /><br />
    Otro caso de uso común es cuando quieres saber cuanto pagaste en un mes determinado por un servicio específico. Por ejemplo, cuanto pagaste de luz en el mes de agosto.

  </Typography>
}

export default Actividad;