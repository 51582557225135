// MUI imports
import { ListItem } from "@mui/material";
import { styled } from '@mui/system';

const MyListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '15px',
  marginBottom: '2px',
  '&:active': {
    backgroundColor: 'rgb(230, 230, 230)',
  },
  '&:hover': {
    backgroundColor: 'rgb(230, 230, 230)',
  },
  cursor: 'pointer',
}));

export default MyListItem;