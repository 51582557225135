// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";

// My components imports
import DisplayEtiquetaList from './displayEtiquetaList';
import { deleteLabel } from '../../utils/api';
import CreateModifyLabel from '../app/createModifyLabel';


function EtiquetaList(props) {

  // Deconstructing props
  const { etiquetas } = props;

  // State constants
  const [showForm, setShowForm] = useState(false);
  const [etiquetaList, setEtiquetaList] = useState(props.etiquetas);
  const [etiquetaToModify, setEtiquetaToModify] = useState(null);
  const [filterText, setFilterText] = useState('');

  // Effect hooks
  useEffect(() => {
    setEtiquetaList(etiquetas);
  }, [etiquetas])

  useEffect(() => {
    if (etiquetaToModify) {
      // If there is an etiqueta to modify
      setShowForm(true);
    }
  }, [etiquetaToModify])

  useEffect(() => {
    if (filterText === '') {
      setEtiquetaList(etiquetas);
    } else {
      setEtiquetaList(etiquetas.filter(etiqueta => etiqueta.texto.toLowerCase().includes(filterText.toLowerCase())));
    }
  }, [filterText, etiquetas])


  const handleDeleteConfirmation = (etiqueta) => {
    deleteLabel(etiqueta.id)
      .then(result => {
        // Ask the parent component to update the movement list
        props.updateList(null);
      })
      .catch(err => console.log(err));
  }

  const handleModifyEtiquetaClick = etiqueta => {
    setEtiquetaToModify(etiqueta);
  }

  const handleCancelModifyEtiqueta = () => {
    setEtiquetaToModify(null);
    setShowForm(false);
  }

  return <Stack spacing={1} direction="column">
    <TextField
      label="Filtrar etiquetas"
      value={filterText}
      onChange={event => setFilterText(event.target.value)}
    />
    <DisplayEtiquetaList
      etiquetasList={etiquetaList}
      onDelete={handleDeleteConfirmation}
      onModify={handleModifyEtiquetaClick}
    />
    <Dialog open={showForm} onClose={handleCancelModifyEtiqueta}>
      <DialogTitle>Modificar etiqueta</DialogTitle>
      <DialogContent>
        <CreateModifyLabel
          initialValues={{
            initialLabel: etiquetaToModify,
            intent: 'modify'
          }}
          onSubmit={() => props.updateList(null)}
          onCancel={handleCancelModifyEtiqueta}
        />
      </DialogContent>
    </Dialog>
  </Stack>

}

EtiquetaList.propTypes = {
  etiquetas: PropTypes.arrayOf(PropTypes.object).isRequired, // List of etiquetas to display
  updateList: PropTypes.func.isRequired, // Function to be called when the list is to be updated
}

export default EtiquetaList;