// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Divider, Stack, Typography } from "@mui/material";

// My component imports
import { ErrorBoundary } from '../../utils/system.js';
import PagoDeTarjetaListItem from '../basic/pagoDeTarjetaListItem.js';


function DisplayCardPaymentList(props) {
  return <ErrorBoundary>
    <Stack spacing={1} direction="column" divider={<Divider flexItem />}>
      {props.paymentsList && props.paymentsList.length > 0 ?
        props.paymentsList.map((payment, index) => {
          return <PagoDeTarjetaListItem
            key={index}
            pagoDeTarjeta={payment}
            tarjeta={props.card}
            onModify={props.onModify}
            onDelete={props.onDelete}
            compact={props.compact}
          />
        })
        :
        <Stack alignItems="center">
          <Typography variant="body2">No hay pagos para mostrar</Typography>
        </Stack>
      }
    </Stack>
  </ErrorBoundary>
}

DisplayCardPaymentList.propTypes = {
  paymentsList: PropTypes.arrayOf(PropTypes.object).isRequired, // List of movements to display
  card: PropTypes.object.isRequired, // Card for which the payments were made
  onDelete: PropTypes.func.isRequired, // Function to be called when a movement is to be deleted
  onModify: PropTypes.func.isRequired, // Function to be called when the user clicks to modify the momvement
  compact: PropTypes.bool, // If true, show compact version of the list, otherwise show classic version. Default is false
}

export default DisplayCardPaymentList;