// MUI imports
import { Stack } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings.js";
import TutorialSection from "../basic/tutorialSection.js";
import Introduccion from "../tutorial/introduccion.js";
import LugaresDeCustodiaYCuentas from "../tutorial/lugaresDeCustodiaYCuentas.js";
import TarjetaDeCredito from "../tutorial/tarjetaDeCredito.js";
import RegistroDeGastosEIngresos from "../tutorial/registroDeGastosEIngresos.js";
import Categorias from "../tutorial/categorias.js";
import Etiquetas from "../tutorial/etiquetas.js";
import Transferencias from "../tutorial/transferencias.js";
import CambioDeMoneda from "../tutorial/cambioDeMoneda.js";
import Actividad from "../tutorial/actividad.js";
import ReporteDeGastosEIngresos from "../tutorial/reporteDeGastosEingresos.js";
import ReporteDeFlujoDeFondos from "../tutorial/reporteDeFlujoDeFondos.js";
import ReporteDeBalance from "../tutorial/ReporteDeBalance.js";
import CompromisosFinancieros from "../tutorial/compromisosFinancieros.js";
import ReporteMensual from "../tutorial/reporteMensual.js";
import ReporteDeCotizacionDeMonedas from "../tutorial/reporteDeCotizacionDeMonedas.js";


function TutorialPage() {
  return (
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' },
      ]}
      currentPageName={'Aprende a usar FinanzasUY'}
    >
      <Stack>

        <TutorialSection title={'Introducción'} defaultExpanded>
          <Introduccion />
        </TutorialSection>

        <TutorialSection title={'Lugares de custodia y cuentas'}>
          <LugaresDeCustodiaYCuentas />
        </TutorialSection>

        <TutorialSection title={'Tarjetas'}>
          <TarjetaDeCredito />
        </TutorialSection>

        <TutorialSection title={'Registro de gastos e ingresos'}>
          <RegistroDeGastosEIngresos />
        </TutorialSection>

        <TutorialSection title={'Categorías'}>
          <Categorias />
        </TutorialSection>

        <TutorialSection title={'Etiquetas'}>
          <Etiquetas />
        </TutorialSection>

        <TutorialSection title={'Transferencias'}>
          <Transferencias />
        </TutorialSection>

        <TutorialSection title={'Compromisos financieros'}>
          <CompromisosFinancieros />
        </TutorialSection>

        <TutorialSection title={'Cambio de moneda'}>
          <CambioDeMoneda />
        </TutorialSection>

        <TutorialSection title={'Actividad'}>
          <Actividad />
        </TutorialSection>

        <TutorialSection title={'Reporte de gastos e ingresos'}>
          <ReporteDeGastosEIngresos />
        </TutorialSection>

        <TutorialSection title={'Reporte de flujo de fondos'}>
          <ReporteDeFlujoDeFondos />
        </TutorialSection>

        <TutorialSection title={'Reporte de balance'}>
          <ReporteDeBalance />
        </TutorialSection>

        <TutorialSection title={'Reporte mensual'}>
          <ReporteMensual />
        </TutorialSection>

        <TutorialSection title={'Reporte de cotizaciones de monedas'}>
          <ReporteDeCotizacionDeMonedas />
        </TutorialSection>

      </Stack>

    </MyMenuComponent >
  );
}

export default TutorialPage;