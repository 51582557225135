// React imports
import { useState, useEffect } from 'react';

// React Router imports
import { useHistory, useParams } from "react-router-dom";

// MUI imports
import { Stack, Button, Typography } from '@mui/material';

// My components imports
import MyPaper from '../basic/myPaper';
import NewCardDatesForm from '../forms/newCardDatesForm';
import { urls } from "../../settings.js";
import { getCardAccountState, modifyCardAccountState, getCard } from '../../utils/api.js';
import { dateGetFirstDayOfMonth, dateGetLastDayOfMonth, dateGetLastDayOfNextMonth } from '../../utils/date';
import MyLoading from '../basic/MyLoading';
import MyMenuComponent from './myMenuComponent';
import ConfirmationDialog from '../basic/confirmationDialog';

function ConfigureCardDate(props) {

  // Constants
  const history = useHistory();
  const params = useParams();

  // State constants
  const [loading, setLoading] = useState(true);
  const [cardAccountState, setCardAccountState] = useState(null);
  const [card, setCard] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  // Effect hooks
  useEffect(() => {
    getCardAccountState(params.dateId)
      .then(state => {
        setCardAccountState(state);
        setMinDate(dateGetFirstDayOfMonth(state.fecha_de_cierre));
        setMaxDate(dateGetLastDayOfMonth(state.fecha_de_cierre));
      })
      .catch(err => console.log(err));
    getCard(params.cardId)
      .then(retrievedCard => {
        setCard(retrievedCard.response);
      })
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    if (cardAccountState) {
      // closeDate and dueDate are defined, it is safe to show form
      setLoading(false);

      // Verify if closeDate and dueDate are valid before modifying in API
      if (minDate && maxDate) { // wait for minDate and maxDate to be initialized
        if (new Date(cardAccountState.fecha_de_cierre) >= minDate && new Date(cardAccountState.fecha_de_cierre) <= maxDate) {
          modifyCardAccountState(params.cardId, params.dateId, new Date(cardAccountState.fecha_de_cierre), new Date(cardAccountState.fecha_de_vencimiento))
            .catch(err => console.log(err));
        } else {
          // Show dialog telling that close date is out of range
          setShowDialog(true);
        }
      }
    } else {
      setLoading(true);
    }
  }, [cardAccountState])

  // Handlers
  const cancelHandler = () => history.push(urls.configureCardsUrl + '/' + params.cardId + '/dates/');
  const handleDateChange = (newCloseDate, newDueDate) => {
    let newCardAccountState = JSON.parse(JSON.stringify(cardAccountState));
    newCardAccountState.fecha_de_cierre = newCloseDate;
    newCardAccountState.fecha_de_vencimiento = newDueDate;
    setCardAccountState(newCardAccountState);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.configurationsUrl, name: 'Configurar' },
      { url: urls.configureCardsUrl, name: 'Tarjetas' }
    ]}
    currentPageName={params.cardId === "new" ? "Nueva" : "Editar"}
  >
    <ConfirmationDialog
      text="La fecha de cierre no puede ser de un mes diferente."
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
        console.log('canceled!');
      }}
      cancelButtonText="OK"
      dontShowConfirmationButton={true}
    />

    <Stack direction="column" justifyContent="center" alignItems="center">
      <MyPaper elevation={1}>
        {loading ?
          <div>
            <MyLoading />
            <Button variant="contained" color="cancel" onClick={cancelHandler} fullWidth>Cancelar</Button>
          </div> :
          <div>
            <Typography sx={{ marginBottom: '20px' }}>
              {card ? card.nombre : ''}
            </Typography>
            <NewCardDatesForm
              initialCloseDate={cardAccountState.fecha_de_cierre}
              initialDueDate={cardAccountState.fecha_de_vencimiento}
              onCancel={cancelHandler}
              onChange={handleDateChange}
              minDate={minDate}
              maxDate={dateGetLastDayOfNextMonth(minDate)}
            />
          </div>
        }
      </MyPaper>
    </Stack>
  </MyMenuComponent>

}

export default ConfigureCardDate;