// React imports
import { useEffect, useState, useCallback } from 'react';

// MUI imports
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// My components imports
import { urls } from "../../settings.js";
import { getMovements, getUserAccounts, getCategories, getCards, getCardMovements, getTransfers, getCardPayments } from "../../utils/api.js";
import NewMovementList from "../lists/newMovementList.js";
import FilterMovements from '../filters/filterMovements.js';
import MyMenuComponent from "./myMenuComponent.js";
import MyPaper from "../basic/myPaper.js";
import MyLoadingList from '../basic/myLoadingList.js';
import { useUserProfile } from '../../utils/userProfile.js';
import { emptyFilterMovement } from '../../utils/constants.js';
import { getMovementsQuery, getCardMovementsQuery, getCardPaymentsQuery, getExchangesQuery, getTransfersQuery } from '../../utils/queries.js';
import { Alert, Snackbar } from '@mui/material';

function SearchMovement(props) {

  // State constants
  const { profile } = useUserProfile();
  const [movements, setMovements] = useState([]);
  const [cardMovements, setCardMovements] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [exchanges, setExchanges] = useState([]); // TODO: implementar exchanges en el backend y en el frontend
  const [cardPayments, setCardPayments] = useState([]);
  const [mixedMovements, setMixedMovements] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [updateTable, setUpdatetable] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentQueryObject, setCurrentQueryObject] = useState(emptyFilterMovement);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let isMounted = true;
    Promise.all([getUserAccounts(), getCategories(), getCards()])
      .then(response => {
        if (isMounted) {
          setAccounts(response[0].results);
          setCategories(response[1].results);
          setCards(response[2].results);
          setSearching(false);
        }
      })
      .catch(err => console.log(err));
    return () => { isMounted = false }
  }, [mixedMovements])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMixedMovements(movements.concat(cardMovements).concat(transfers).concat(cardPayments).concat(exchanges));
    }
    return () => { isMounted = false }
  }, [movements, cardMovements, transfers, cardPayments, exchanges]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && ready) {
      let promises = [];
      // First set all movements to empty
      Promise.all([setMovements([]), setCardMovements([]), setTransfers([]), setCardPayments([]), setExchanges([])])
        .then(() => {
          // Construct promises based on currentQueryObject
          if (currentQueryObject.cashCredit === "credit") {
            promises.push(getCardMovements(getCardMovementsQuery(currentQueryObject)).then(response => setCardMovements(response.results)));
          } else if (currentQueryObject.cashCredit === "both" || currentQueryObject.cashCredit === "cash") {
            if ((currentQueryObject.movementTypes.includes('income') || currentQueryObject.movementTypes.includes('expenses'))) {
              promises.push(getMovements(getMovementsQuery(currentQueryObject)).then(response => setMovements(response.results)));
              if (currentQueryObject.cashCredit == 'both') {
                promises.push(getCardMovements(getCardMovementsQuery(currentQueryObject)).then(response => setCardMovements(response.results)));
              }
            }
            if (currentQueryObject.movementTypes.includes('cardPayments')) promises.push(getCardPayments(getCardPaymentsQuery(currentQueryObject)).then(response => setCardPayments(response.results)));
            if (currentQueryObject.movementTypes.includes('transfers')) promises.push(getTransfers(getTransfersQuery(currentQueryObject)).then(response => setTransfers(response.results)));
            if (currentQueryObject.movementTypes.includes('exchanges')) promises.push(getTransfers(getExchangesQuery(currentQueryObject)).then(response => setExchanges(response.results)));
          }
          return true;
        })
        .then(() => Promise.all(promises).then(response => setSearching(false)))
        .catch(err => setShowErrorMessage(true));
    }
    setReady(false);
    return () => { isMounted = false };
  }, [currentQueryObject, updateTable, ready])

  // Handlers
  const updateTableHandler = () => {
    setShowFilter(false);
    setSearching(true);
    setReady(true);
    setUpdatetable(!updateTable);
  };

  const searchMovementsHandler = useCallback(queryObject => {
    setShowFilter(false);
    setSearching(true);
    setReady(true);
    setCurrentQueryObject(queryObject);
  }, []);

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      // { url: urls.searchUrl, name: 'Buscar' },
    ]}
    currentPageName={'Actividad'}
  >
    <Snackbar open={showErrorMessage} autoHideDuration={2000} onClose={() => setShowErrorMessage(false)} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} tran>
      <Alert severity='error'>Algo no funcionó, intenta nuevamente</Alert>
    </Snackbar>

    <Stack direction="column" justifyContent="center" alignItems="center">

      {showFilter ?
        <FilterMovements
          initialValues={currentQueryObject}
          accounts={accounts}
          cards={cards}
          categories={categories}
          onSearchStarted={searchMovementsHandler}
        /> :
        <>
          <MyPaper elevation={1} sx={{ padding: "10px", marginBottom: "5px" }}>
            <Button
              variant="contained"
              onClick={() => setShowFilter(true)} fullWidth
            >Volver a buscar</Button>
          </MyPaper>
          {searching ? <MyPaper><MyLoadingList /></MyPaper> :
            <NewMovementList
              movements={mixedMovements}
              updateList={updateTableHandler}
              cards={cards}
              accounts={accounts}
              categories={categories}
              compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
            />
          }
        </>
      }
    </Stack>

  </MyMenuComponent>



}

export default SearchMovement;