// React imports
import { useEffect, useState } from "react";

// My components imports
import { urls } from "../../settings.js";
import MyMenuComponent from "./myMenuComponent"
import { getBalance, getCards, getCurrencies, getUserAccounts, getCategories } from "../../utils/api.js";
import MyPaper from "../basic/myPaper.js";
import BalanceTable from "../basic/balanceTable.js";
import MonedaPicker from "../inputs/monedaPicker.js";
import MyLoadingList from "../basic/myLoadingList.js";
import { Stack } from "@mui/material";


function VisualizeBalance() {

  // State constants
  const [balance, setBalance] = useState(null); // The balance of the user
  const [currencies, setCurrencies] = useState([]); // The currencies available in the app
  const [cards, setCards] = useState([]); // The cards of the user
  const [accounts, setAccounts] = useState([]); // The accounts of the user
  const [categories, setCategories] = useState([]); // The categories of avialable in the app
  const [balanceCurrency, setBalanceCurrency] = useState(null); // The currency the balance is expressed in
  const [loading, setLoading] = useState(true); // Whether the page is loading or not
  const [loadingBalance, setLoadingBalance] = useState(true); // Whether the balance is loading or not
  const [updateBalance, setUpdateBalance] = useState(false); // Whether the balance should be updated or not

  // Auxiliary functions
  const updateBalanceFunction = () => {
    getBalance(balanceCurrency.id)
      .then((response) => {
        // Set balance
        console.log(response);
        setBalance(response);
      });
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Promise.all([getCurrencies(), getUserAccounts(), getCards(), getCategories()])
        .then((responses) => {
          // Set currencies
          setCurrencies(responses[0].results);
          // Set default currency to UYU
          setBalanceCurrency(responses[0].results.find((currency) => currency.nombre_corto === 'UYU'));
          // Set accounts
          setAccounts(responses[1].results);
          // Set cards
          setCards(responses[2].results);
          // Set categories
          setCategories(responses[3].results);
          // Set loading to false
          setLoading(false);
        });
    }
    return () => isMounted = false;
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (balanceCurrency !== null && currencies.length > 0) {
        setLoadingBalance(true);
        updateBalanceFunction();
      }
    }
    return () => isMounted = false;
  }, [balanceCurrency, currencies]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && balanceCurrency !== null && currencies.length > 0) {
      setLoadingBalance(true);
      updateBalanceFunction();
    }
    return () => isMounted = false;
  }, [updateBalance]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && balance !== null) {
      setLoadingBalance(false);
    }
    return () => isMounted = false;
  }, [balance]);


  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.visualizationsUrl, name: 'Analizar' },
    ]}
    currentPageName={'Balance'}
  >
    <MyPaper>
      {loading ? <MyLoadingList /> :
        <Stack spacing={2}>
          <MonedaPicker currencies={currencies} onChange={setBalanceCurrency} initialValues={balanceCurrency} multiple={false} />
          {loadingBalance ? <MyLoadingList /> :
            <BalanceTable
              balance={balance}
              currency={balanceCurrency}
              accounts={accounts}
              cards={cards}
              categories={categories}
              updateBalance={() => setUpdateBalance(prev => !prev)}
            />
          }
        </Stack>
      }
    </MyPaper>
  </MyMenuComponent>
}

export default VisualizeBalance;