// MUI imports
import { Radio, Stack, Typography } from "@mui/material";

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import { paypalPlanPropType } from "../../utils/myPropTypes";

function SubscriptionPlanListItem(props) {

  // Props
  const { plan, onClick, checked } = props;
  let frequency_of_trial = ""
  if (plan.ciclos_de_prueba !== 0 && plan.ciclos_de_prueba !== null) {
    if (plan.ciclos_de_prueba > 1) {
      if (plan.frecuencia_de_prueba === "mes") {
        frequency_of_trial = plan.frecuencia_de_prueba + "es";
      } else {
        frequency_of_trial = plan.frecuencia_de_prueba + "s";
      }
    } else {
      frequency_of_trial = plan.frecuencia_de_prueba;
    }
  }

  return <Stack direction="row" onClick={() => onClick(plan)} spacing={1} sx={{ padding: "25px", cursor: "pointer", backgroundColor: "rgba(255, 255, 255, 0)" }}>
    <Stack justifyContent="center">
      <Radio
        checked={checked}
        name={plan.nombre}
      />
    </Stack>
    <Stack>
      <Stack direction={"row"} alignItems="center" spacing={1}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>{plan.nombre}</Typography>
        <Typography variant="caption" sx={{ fontStyle: "italic" }}>{plan.codigo_promocional ? `(${plan.codigo_promocional.codigo})` : ''}</Typography>
      </Stack>
      <Stack direction={"row"} alignItems="center" spacing={1}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{`${plan.monto} ${plan.moneda}`}</Typography>
        <Typography variant="body1">{`cada ${plan.intervalo} ${plan.frecuencia}`}</Typography>
      </Stack>
      {plan.ciclos_de_prueba !== 0 && plan.ciclos_de_prueba !== null && <Typography variant="caption2" fontWeight="bold">{`Prueba gratuita de ${plan.ciclos_de_prueba} ${frequency_of_trial}`}</Typography>}
      <Typography variant="caption2">{plan.descripcion}</Typography>
    </Stack>
  </Stack >
}

SubscriptionPlanListItem.propTypes = {
  plan: paypalPlanPropType.isRequired, // Subscription plan object
  onClick: PropTypes.func.isRequired, // Click handler
  checked: PropTypes.bool.isRequired, // Checked status
};

export default SubscriptionPlanListItem;