// Proptypes imports
import PropTypes from 'prop-types';

// Recharts imports
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Legend, CartesianGrid, Tooltip } from 'recharts';

// MUI imports
import { Paper, Stack, Typography } from '@mui/material';

// Custom tooltip
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // Formatear el valor como desees aquí. Ejemplo: agregar comas para miles.
    let formatedNumber = payload[0].value.toFixed(2) // Step 1: Ensure two decimal places
      .replace('.', ',') // Step 2: Replace the decimal point with a comma
      .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Step 3: Add dot as thousand separator

    const formattedValue = `$ ${formatedNumber}`;

    // Add some transparency to paper
    return <Paper sx={{ padding: "5px", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1">Fecha: </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>{label}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1">Valor: </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>{formattedValue}</Typography>
        </Stack>
      </Stack>
    </Paper>

  }

  return null;
};

function MyBarChart(props) {

  // Decontruct props
  const { data, xLegend, yLegend, color, handleGraphClick } = props;

  return <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={data}
      margin={{
        top: 5,
        right: 10,
        left: -20,
        bottom: 15,
      }}
    >
      <Tooltip content={CustomTooltip} />
      <Bar
        dataKey={yLegend}
        fill={color}
        onClick={handleGraphClick}
      />
      <CartesianGrid strokeDasharray="1 5" vertical={false} />
      <XAxis dataKey={xLegend} tick={{ fontSize: 10, angle: -45, dy: 20 }} />
      <YAxis tick={{ fontSize: 10, angle: -45 }} />
      <Legend verticalAlign="top" />
    </BarChart>
  </ResponsiveContainer>
}

// Proptypes
MyBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  xLegend: PropTypes.string.isRequired,
  yLegend: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  handleGraphClick: PropTypes.func.isRequired
}

export default MyBarChart;