// My components imports
import { getObjectType } from "./misc";
import MovimientoListItem from "../components/basic/movimientoListItem.js";
import MovimientoTarjetaListItem from "../components/basic/movimientoTarjetaListItem";
import TransferenciaListItem from "../components/basic/transferenciaListItem";
import PagoDeTarjetaListItem from "../components/basic/pagoDeTarjetaListItem";
import RegisterMovement from "../components/app/registerMovement";
import RegisterCardMovement from "../components/app/registerCardMovement";
import RegisterTransfer from "../components/app/registerTransfer";
import RegisterCurrencyExchange from "../components/app/registerCurrencyExchange";
import RegisterCardPayment from "../components/app/registerCardPayment";
import MovimientoProgramadoListItem from "../components/basic/movimientoProgramadoListItem.js";
import MovimientoTarjetaProgramadoListItem from "../components/basic/movimientoTarjetaProgramadoListItem";

function getObjectListItem(obj, { ...props }) {
  if (getObjectType(obj).type === "Movimiento") {
    return <MovimientoListItem movimiento={obj} {...props} />
  };
  if (getObjectType(obj).type === "MovimientoTarjeta") {
    return <MovimientoTarjetaListItem movimientoTarjeta={obj} {...props} />
  };
  if (getObjectType(obj).type === "Transferencia") {
    return <TransferenciaListItem transferencia={obj} {...props} />
  };
  if (getObjectType(obj).type === "PagoDeTarjeta") {
    return <PagoDeTarjetaListItem pagoDeTarjeta={obj} {...props} />
  }
}

function getRegisterObject(obj, { ...props }) {
  if (getObjectType(obj).type === "Movimiento") {
    return <RegisterMovement initialValues={obj} allowSwitching={true} {...props} />
  };
  if (getObjectType(obj).type === "MovimientoTarjeta") {
    return <RegisterCardMovement allowSwitching={true} initialValues={obj} {...props} />
  };
  if (getObjectType(obj).type === "Transferencia") {
    if (getObjectType(obj).variant === "Transferencia") {
      return <RegisterTransfer initialValues={obj} {...props} />
    } else {
      return <RegisterCurrencyExchange initialValues={obj} {...props} />
    }
  };
  if (getObjectType(obj).type === "PagoDeTarjeta") {
    return <RegisterCardPayment initialValues={obj} {...props} />
  }
  // Object type not found, returning empty object
  return <></>
}
/**
 * Function that returns a list item for the correct visual representation of a scheduled register 
 * @param {MovimientoProgramado|MovimientoTarjetaProgramado} obj - Scheduled register object
 * @param {*} param1 
 * @returns {React.Component} - List item component
 */
function getScheduledListItem(obj, { ...props }) {
  if (getObjectType(obj).type === "MovimientoProgramado") {
    return <MovimientoProgramadoListItem movimiento={obj} {...props} />
  } else if (getObjectType(obj).type === "MovimientoTarjetaProgramado") {
    return <MovimientoTarjetaProgramadoListItem movimiento={obj} {...props} />
  } else {
    console.error('Tipo de objeto no reconocido:', getObjectType(obj));
    return <></>
  }
}

export { getObjectListItem, getRegisterObject, getScheduledListItem };