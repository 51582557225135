// React imports
import { useEffect, useState } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Chip, Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useTheme } from "@mui/material";

// My components imports
import ConfirmationDialog from "./confirmationDialog.js";
import MyDialog from "./MyDialog.js";
import { getAmountText } from "../../utils/misc.js";
import { dateToDisplayFormat } from "../../utils/date.js";

function TransferenciaListItemCompact(props) {

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isCurrencyExchange, setIsCurrencyExchange] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  // Other constants
  const theme = useTheme();
  const srcAmmountText = getAmountText(props.transferencia.monto_origen, props.transferencia.cuenta_origen.moneda)
  const srcAcctName = props.transferencia.cuenta_origen.nombre + " (" + props.transferencia.cuenta_origen.moneda.nombre_corto + ")";
  const dstAmmountText = getAmountText(props.transferencia.monto_destino, props.transferencia.cuenta_destino.moneda)
  const dstAcctName = props.transferencia.cuenta_destino.nombre + " (" + props.transferencia.cuenta_destino.moneda.nombre_corto + ")";
  const commentText = props.transferencia.comentario;
  const perspective = props.pointOfView ? (props.transferencia.cuenta_origen.id === props.pointOfView.id ? "sourceAccount" : "destinationAccount") : "sourceAccount";
  const accountText = perspective === "sourceAccount" ? srcAcctName : dstAcctName;
  const ammountColor = perspective === "sourceAccount" ?
    (props.transferencia.monto_origen >= 0 ? theme.palette.number.positive : theme.palette.number.negative) :
    (props.transferencia.monto_destino >= 0 ? theme.palette.number.positive : theme.palette.number.negative);

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const modifyClickHandler = event => {
    event.stopPropagation();
    props.onModify(props.transferencia);
  }

  const handleDeleteCancel = event => {
    event.stopPropagation();
    setShowConfirmationDialog(false);
  }

  const handleInfoClick = event => {
    event.stopPropagation();
    event.preventDefault();
    setInfoDialogOpen(true);
  }

  // Effect hooks
  useEffect(() => {
    if (props.transferencia) {
      if (props.transferencia.cuenta_origen.moneda.id === props.transferencia.cuenta_destino.moneda.id) {
        setIsCurrencyExchange(false);
      } else {
        setIsCurrencyExchange(true);
      }
    }
  }, [props.transferencia])

  return <Stack direction="row" spacing={2} onClick={modifyClickHandler} sx={{ cursor: "pointer" }}>
    <ConfirmationDialog
      open={showConfirmationDialog}
      text="¿Estas seguro que quieres borrar esta transferencia?"
      cancelButtonText="Cancelar"
      confirmButtonText="Borrar"
      onClose={handleDeleteCancel}
      onConfirm={() => props.onDelete(props.transferencia)}
    />

    <Stack direction="column" justifyContent="center">
      {isCurrencyExchange ?
        <CurrencyExchangeIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} /> :
        <SyncAltIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />}
    </Stack>

    <Stack direction={"column"} justifyContent={"center"}>

      <Stack direction="row" flexWrap={"wrap"}>
        {props.transferencia.etiquetas.map(label => {
          return <Chip
            key={label.id}
            label={label.texto}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginRight: "2px", marginBottom: "2px", fontSize: "0.7rem" }}
          />
        })}
      </Stack>

      <Stack direction="row" spacing={0.5} flexWrap={"wrap"}>
        <Typography variant="caption">{dateToDisplayFormat(props.transferencia.fecha) + ' - ' + accountText}</Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1" color={ammountColor} sx={{ fontWeight: "bold" }}>
          {perspective === "destinationAccount" ?
            getAmountText(props.transferencia.monto_destino, props.transferencia.cuenta_destino.moneda) :
            getAmountText(props.transferencia.monto_origen, props.transferencia.cuenta_origen.moneda)
          }
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Typography variant="caption" sx={{ fontStyle: "italic", color: theme.palette.primary.lightText }}>{commentText}</Typography>
      </Stack>

    </Stack>

    <Stack justifyContent="center" alignItems={"flex-end"} flexGrow={1}>
      <MyDialog
        onClose={() => setInfoDialogOpen(false)}
        open={infoDialogOpen}
        onOpen={() => setInfoDialogOpen(true)}
        title="¿Cuál es la otra cuenta?"
        iconSize={"medium"}
      >
        {perspective === "destinationAccount" ?
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Stack justifyContent="center">
              <Typography variant="body2">
                {isCurrencyExchange ? ("El monto fue cambiado por " + srcAmmountText + " que vinieron de la cuenta " + srcAcctName)
                  :
                  ("El monto se transfirió desde la cuenta " + srcAcctName)}
              </Typography>
            </Stack>
          </Stack>
          :
          <Stack spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Stack direction="row" justifyContent="center">
              <Typography variant="body2">
                {isCurrencyExchange ? ("El monto fue cambiado por " + dstAmmountText + " que fueron a la cuenta " + dstAcctName)
                  :
                  ("El monto se transfirió hacia la cuenta " + dstAcctName)}
              </Typography>
            </Stack>

          </Stack>
        }
      </MyDialog>
    </Stack>
    <Stack direction="column" justifyContent={"center"} alignItems={"flex-end"}>
      <DeleteIcon onClick={deleteClickHandler} color="cancel" sx={{ fontSize: 25 }} />
    </Stack>

  </Stack >
}

TransferenciaListItemCompact.propTypes = {
  transferencia: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func.isRequired, // Function to be called when user whats to delete Movimiento
  onModify: PropTypes.func.isRequired, // Function to be called when user whats to modify Movimiento
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
};

export default TransferenciaListItemCompact;