// AWS imports
import { Auth, Hub } from 'aws-amplify';

// JWT imports
import jwt_decode from "jwt-decode";

// My components imports
import { refreshAccessToken, apiLogin } from './api';
import { appName } from '../settings';

async function getSub() {
  const accessToken = await getAccessToken();
  return jwt_decode(accessToken).sub;
}

function tokenIsExpired(token) {
  let decodedToken = null;
  decodedToken = jwt_decode(token);
  if (typeof decodedToken.exp === 'undefined') {
    throw new Error('Token no tiene fecha de expiración');
  }
  const now = Date.now().valueOf() / 1000;
  if (decodedToken.exp < now) {
    return true;
  } else {
    return false;
  }
}

async function getAccessToken() {
  try {
    const tokens = retrieveTokens();
    if (typeof tokens.accessToken === 'undefined' || typeof tokens.refreshToken === 'undefined') {
      throw new Error('No hay accessToken y/o refreshToken guardados');
    } else {
      if (tokenIsExpired(tokens.accessToken)) {
        // accessToken is expired, let's refresh it
        const newAccessToken = await refreshAccessToken(tokens.refreshToken);
        saveTokens({ access_token: newAccessToken });
        return newAccessToken;
      } else {
        return tokens.accessToken;
      }
    }
  } catch (error) {
    // Error refreshing token, let's generate a tokenRefresh_failure myApp event
    const tokenRefreshErrorEvent = {
      event: 'tokenRefresh_failure',
      data: {
        provider: 'app'
      },
      message: 'Error refreshing token'
    }
    Hub.dispatch(appName, tokenRefreshErrorEvent);
    throw new Error('Error al obtener el accessToken');
  }

}

async function forceAccessTokenRefresh() {
  try {
    const tokens = retrieveTokens();
    if (typeof tokens.accessToken === 'undefined' || typeof tokens.refreshToken === 'undefined') {
      throw new Error('No hay accessToken y/o refreshToken guardados');
    } else {
      const newAccessToken = await refreshAccessToken(tokens.refreshToken);
      saveTokens({ access_token: newAccessToken });
      return newAccessToken;
    }
  } catch (error) {
    // Error refreshing token, let's generate a tokenRefresh_failure myApp event
    const tokenRefreshErrorEvent = {
      event: 'tokenRefresh_failure',
      data: {
        provider: 'app'
      },
      message: 'Error refreshing token'
    }
    Hub.dispatch(appName, tokenRefreshErrorEvent);
    throw new Error('Error al obtener el accessToken');
  }

}


async function getProviderIdToken(provider) {
  if (provider === 'cognito') {
    const sessionInfo = await Auth.currentSession();
    return sessionInfo.idToken.jwtToken;
  }
}

function saveTokens(tokens) {
  // Save idToken, only if tokens.id_token is not undefined
  if (typeof tokens.id_token !== 'undefined') {
    localStorage.setItem('idToken', tokens.id_token);
  }
  if (typeof tokens.access_token !== 'undefined') {
    localStorage.setItem('accessToken', tokens.access_token);
  }
  if (typeof tokens.refresh_token !== 'undefined') {
    localStorage.setItem('refreshToken', tokens.refresh_token);
  }
}

function retrieveTokens() {
  const idToken = localStorage.getItem('idToken');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  return { idToken, accessToken, refreshToken };
}

function clearTokens() {
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

function isLoggedIn() {
  const { idToken, accessToken, refreshToken } = retrieveTokens();
  if (idToken) {
    // Check if idToken is not expired as this is the condition for the user to be considered logged in
    const idTokenExpired = tokenIsExpired(idToken);
    if (idTokenExpired) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function logoutUser() {
  try {
    clearTokens();
    // Generate logout event
    const signOutEvent = {
      event: 'signOut',
      data: {
        provider: 'app'
      },
      message: 'User signed out'
    }
    Hub.dispatch(appName, signOutEvent);
  } catch (error) {
    console.error('Error al hacer logout del usuario', error);
  }
}

async function logInUser(token) {
  await apiLogin(token).then(tokens => {
    saveTokens(tokens);
    // User has successfully signed in with the app, let's trigger a signIn event in the app channel
    const eventPayload = {
      event: 'signIn',
      data: {
        provider: appName,
        token: tokens.idToken
      },
      message: 'User has signed in to the app'
    }
    Hub.dispatch(appName, eventPayload);
  }).catch(err => {
    // User has not been able to sign in with the app, let's trigger a signIn_failure event in the app channel
    const eventPayload = {
      event: 'signIn_failure',
      data: {
        provider: appName
      },
      message: 'User has not been able to sign in to the app'
    }
    Hub.dispatch(appName, eventPayload);
  });
}

export { getSub, getProviderIdToken, getAccessToken, saveTokens, clearTokens, isLoggedIn, logoutUser, logInUser, forceAccessTokenRefresh }