// Proptypes imports
import PropTypes from 'prop-types';

// React imports
import { useState } from 'react';

// MUI imports
import { Button, Stack, Typography } from '@mui/material';

// My component imports
import InviteFamilyMembersForm from '../forms/inviteFamilyMembersForm';
import { createFamilyInvitation, revokeFamilyInvitation } from '../../utils/api.js';

function CreateModifyFamilyInvitation(props) {

  // State constants
  const [createInvitationError, setCreateInvitationError] = useState(false);
  const [createInvitationErrorText, setCreateInvitationErrorText] = useState('');

  // Change handlers
  const handleSubmit = invitation => {
    if (props.initialValues.intent === 'create') {
      createFamilyInvitation(invitation.correo, props.user.familia.id)
        .then(response => {
          props.onSubmit(response.data);
        })
        .catch(error => {
          setCreateInvitationError(true);
          setCreateInvitationErrorText(error.text);
          console.log(error);
        });
    }
  }

  const handleRevokeInvitation = () => {
    if (props.initialValues.intent === 'modify') {
      revokeFamilyInvitation(props.initialValues.initialInvitation.id)
        .then(response => {
          props.onSubmit(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return props.initialValues.intent === "create" ?
    <InviteFamilyMembersForm
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      invitation={props.initialValues.initialInvitation}
      error={createInvitationError}
      errorText={createInvitationErrorText}
    /> :
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'subtitle2'}>¿Estas seguro que quieres revocar la invitación?:</Typography>
      <Button variant={'contained'} color={'primary'} onClick={handleRevokeInvitation}>Seguro</Button>
      <Button variant="contained" color="cancel" onClick={() => props.onCancel()}>Volver</Button>
    </Stack>
}

CreateModifyFamilyInvitation.propTypes = {
  initialValues: PropTypes.shape({
    initialInvitation: PropTypes.object,
    intent: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired, // Function to be called when user submits the form
  onCancel: PropTypes.func.isRequired, // Function to be called when user cancels the form
  user: PropTypes.object.isRequired, // User object
}

export default CreateModifyFamilyInvitation;