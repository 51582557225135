// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import NewMovementForm from "../forms/movementForm";
import { createMovement, modifyMovement } from "../../utils/api";

function RegisterMovement(props) {

  // Handlers
  const modifyMovementHandler = mov => {
    const labelsIds = mov.etiquetas.map(label => label.id);
    if (props.initialValues.intent === 'create') {
      createMovement(mov.cuenta.id, mov.monto, mov.categoria.id, mov.fecha, mov.comentario, labelsIds)
        .then(response => {
          props.onSubmit(response);
        })
        .catch(err => console.log(err));
    } else if (props.initialValues.intent === 'modify') {
      modifyMovement(mov.cuenta.id, mov.monto, mov.categoria.id, mov.fecha, mov.comentario, labelsIds, mov.id)
        .then(response => {
          props.onSubmit(response);
        })
        .catch(err => console.log(err));
    };
  }

  return <NewMovementForm
    initialMovement={props.initialValues.initialMovement}
    intent={props.initialValues.intent}
    onSubmit={modifyMovementHandler}
    onCancel={event => props.onCancel()}
    accounts={props.accounts}
    categories={props.categories}
    isExpense={props.isExpense}
    allowSwitching={props.allowSwitching}
  />

}

RegisterMovement.propTypes = {
  initialValues: PropTypes.shape({
    initialMovement: PropTypes.shape({
      cuenta: PropTypes.object, // Value to initializa card selection
      categoria: PropTypes.object, // Value to initialize category selection
      moneda: PropTypes.object, // Value for currency initialization
      etiquetas: PropTypes.arrayOf(PropTypes.object), // Value to initialize labels
      fecha: PropTypes.string, // Value to initialize date
      comentario: PropTypes.string, // Value to initialize comment
      monto: PropTypes.number, // Value to initialize ammount
      id: PropTypes.number, // Id of initial movement
    }),
    intent: PropTypes.oneOf(['create', 'modify']), // Value that determines if we are creating or modifying a MovimientoTarjeta
  }), // values to initialize data inputs
  accounts: PropTypes.arrayOf(PropTypes.object), // List containing all available cards
  categories: PropTypes.arrayOf(PropTypes.object), // LIst containing all available categories
  onSubmit: PropTypes.func, // Function to be called when user click on submit button
  onCancel: PropTypes.func, // Function to be called when user click on cancel button
  isExpense: PropTypes.bool, // Indicates whether the movement is a expense or not. If it is a expense I need to invert symbol
  allowSwitching: PropTypes.bool, // Indicates if the user is allowed to switch between expense and income
}

export default RegisterMovement;