// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

// My component imports
import MonedaPicker from "../inputs/monedaPicker.js";
import CustodyAgentPicker from "../inputs/custodyAgentPicker.js";
import AccountTypesPicker from "../inputs/accountTypesPicker.js";
import { accountPropType, currencyPropType } from '../../utils/myPropTypes.js';

function AccountForm(props) {

  // Decontrstruct props
  const { account, intent, currencies, onCancel } = props;

  // State constants
  const [selectedCurrency, setSelectedCurrency] = useState(account.moneda);
  const [selectedCustodyAgent, setSelectedCustodyAgent] = useState(account.agente_de_custodia);
  const [selectedAccountType, setSelectedAccountType] = useState(account.tipo_de_cuenta);
  const [name, setName] = useState(account.nombre);
  const [number, setNumber] = useState(account.numero);
  const [balance, setBalance] = useState(account.balance);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedCurrency(account.moneda);
      setSelectedCustodyAgent(account.agente_de_custodia);
      setSelectedAccountType(account.tipo_de_cuenta);
      setName(account.nombre);
      setNumber(account.numero);
      setBalance(account.balance);
    }
    return () => { isMounted = false }
  }, [account]);

  // Change handlers
  const handleCurrencyChange = newValue => setSelectedCurrency(newValue)
  const handleNameChange = event => setName(event.target.value);
  const handleNumberChange = event => setNumber(event.target.value);
  const handleInitialBalanceChange = event => setBalance(event.target.value);
  const handleCustodyAgentChange = newValue => setSelectedCustodyAgent(newValue);
  const handleAccountTypeChange = newValue => setSelectedAccountType(newValue);
  const modifyAccountHandler = event => {
    event.preventDefault();
    let data = {
      nombre: name,
      numero: number,
      balance: balance,
      moneda: selectedCurrency,
      agente_de_custodia: selectedCustodyAgent,
      tipo_de_cuenta: selectedAccountType,
    }
    if (intent === 'modify') {
      data.id = account.id;
    }
    props.onSubmit(data)
  }

  return <div>
    <FormControl sx={{ marginBottom: "5px" }} fullWidth>
      <CustodyAgentPicker
        selectedAgents={selectedCustodyAgent}
        agents={props.custodyAgents}
        multiple={false}
        onChange={handleCustodyAgentChange}
      />
    </FormControl>

    {selectedCustodyAgent ? <Stack spacing={1}>
      <FormControl fullWidth>
        <AccountTypesPicker
          selectedAccountTypes={selectedAccountType}
          accountTypes={selectedCustodyAgent.tipo.tipos_de_cuenta}
          onChange={handleAccountTypeChange}
          multiple={false}
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField id="name" label="Nombre" variant="filled" value={name} onChange={handleNameChange} />
      </FormControl>

      <FormControl fullWidth>
        <TextField id="number" label="Número" variant="filled" value={number} onChange={handleNumberChange} />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel htmlFor="balance-inicial">Balance inicial</InputLabel>
        <FilledInput
          id="balance-inicial"
          value={balance}
          onChange={handleInitialBalanceChange}
          variant="filled"
          type="number"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Balance inicial"
        />
      </FormControl>

      <FormControl fullWidth>
        <MonedaPicker
          currencies={currencies}
          multiple={false}
          initialValues={selectedCurrency}
          onChange={handleCurrencyChange}
          readOnly={intent === "modify"}
        />
      </FormControl>

      <Button variant="contained" onClick={modifyAccountHandler}>
        {intent === 'create' ? 'Crear cuenta' : 'Modificar cuenta'}
      </Button>
    </Stack> :
      <></>
    }
    <Stack>
      <Button variant="contained" color="cancel" sx={{ marginTop: "5px" }} onClick={onCancel}>Cancelar</Button>
    </Stack>

  </div>

}

AccountForm.propTypes = {
  account: accountPropType, // Account object to initialize Form in case of modify intent
  currencies: PropTypes.arrayOf(currencyPropType).isRequired, // All available currenciens
  onSubmit: PropTypes.func, // Function to be called when user submits
  onCancel: PropTypes.func.isRequired, // Function to be called when operation is canceled
  intent: PropTypes.oneOf(["create", "modify"]), // Indicates whether the user is trying to create a new one or modify an existing account
}

export default AccountForm;