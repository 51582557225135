// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// Date-fns imports
import subDays from "date-fns/subDays";
import set from "date-fns/set";

// MUI imports
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Stack } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";

// My component imports
import { getDateFromDateTime, getDateTimeFromDateString } from "../../utils/date";

// Global constants
const HELPERS = {
  hoy: {
    date: new Date(),
    text: 'Hoy'
  },
  ayer: {
    date: subDays(new Date(), 1),
    text: 'Ayer'
  },
  antesDeAyer: {
    date: subDays(new Date(), 2),
    text: 'Antes de ayer'
  },
  inicioDeMes: {
    date: set(new Date(), { date: 1 }),
    text: 'Inicio de mes'
  }
}

// Auxiliary functions
function getButonType(helperValue, actualValue) {
  const dateMatches = HELPERS[helperValue].date === actualValue;
  return dateMatches ? "contained" : "outlined"
}

function EasyDatePicker(props) {

  // Deconstructing props
  const { initialValue, helperValues = [], onChange, label = "Fecha", error = false, textError = '' } = props;


  // State constants
  const [date, setDate] = useState(getDateTimeFromDateString(initialValue)); // This component receives a string, works with dates and returns string
  const [ready, setReady] = useState(false);

  // Effect hooks
  useEffect(() => {
    if (ready) {
      onChange(getDateFromDateTime(date));
    }
  }, [date, ready])

  // Handlers
  const handleDateChange = response => {
    const newValue = response.$d;
    setReady(true);
    setDate(newValue);
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack direction="row" spacing={0.5} sx={{ marginBottom: '15px' }}>
      {helperValues.map((helperValue, index) => {
        return (
          <Button
            variant={getButonType(helperValue, date)}
            onClick={event => {
              setReady(true);
              setDate(HELPERS[helperValue].date);
            }}
            key={index}
            sx={{ display: "flex", flexGrow: 1 }}
          >
            {HELPERS[helperValue].text}
          </Button>
        )
      })}
    </Stack>
    <DatePicker
      label={label}
      value={date}
      onChange={handleDateChange}
      renderInput={(params) => <TextField {...params} error={error} helperText={textError} />}
      inputFormat="DD/MM/YYYY"
    />
  </LocalizationProvider>
}

EasyDatePicker.propTypes = {
  initialValue: PropTypes.string.isRequired, // Value to initialize date
  onChange: PropTypes.func.isRequired, // Function to be called when the date changes
  helperValues: PropTypes.arrayOf(PropTypes.string), // Helper values to aid in date selection
  label: PropTypes.string, // Label for date picker
  error: PropTypes.bool, // Error state
  textError: PropTypes.string, // Error message
}

export default EasyDatePicker;