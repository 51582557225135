// React imports
import { useState } from "react";

// MUI imports
import { Stack, Typography, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import ConfirmationDialog from "./confirmationDialog";
import { getCustodyAgentTypeIcon } from "../../utils/misc";

function CustodyAgentListItem(props) {

  // State constants
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Other constants
  const theme = useTheme();
  const nameText = props.custodyAgent ? props.custodyAgent.nombre : '';
  const icon = getCustodyAgentTypeIcon(props.custodyAgent ? props.custodyAgent.tipo.nombre : null, "large", theme.palette.primary.main);
  const countryText = props.custodyAgent ? props.custodyAgent.pais.nombre : '';

  // Handlers
  const deleteClickHandler = event => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  }

  const deleteConfirmHandler = event => {
    event.stopPropagation();
    console.log('No está disponible aún la funcionalidad de borrar custodyAgent');
    // Uncomment following line when funcionality is developed
    // props.onDelete(props.cuenta);
  }

  const modifyClickHandler = event => {
    props.onModify(props.custodyAgent);
  }

  return <Stack direction="row" onClick={modifyClickHandler} sx={{ padding: "25px", cursor: "pointer" }} spacing={2}>
    <ConfirmationDialog
      title='Borrar agente de custodia'
      text={'Funcionalidad aún no disponible...'}
      open={showConfirmationDialog}
      cancelButtonText='Cancelar'
      confirmButtonText='Borrar'
      onClose={event => setShowConfirmationDialog(false)}
      onConfirm={deleteConfirmHandler}
      expectedText='estoy seguro'
    />
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
      {icon}
    </Box>

    <Stack direction="column" spacing={0}>
      <Typography variant="h6">
        {nameText}
      </Typography>
      <Typography
        variant="subtitle1"
      >
        {countryText}
      </Typography>
    </Stack>

    <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "flex-end", alignSelf: 'center' }}>
      <DeleteIcon onClick={deleteClickHandler} color="primary" sx={{ fontSize: 35 }} />
    </Box>
  </Stack >
}

CustodyAgentListItem.propTypes = {
  custodyAgent: PropTypes.shape({
  }).isRequired, // value to initialize data inputs
  onDelete: PropTypes.func, // Function to be called when user confirms that wants to delete the Account
  onModify: PropTypes.func, // Function to be called when user whats to modify Account
};

export default CustodyAgentListItem;