// React imports
import { useState, useEffect } from "react";

// MUI imports
import { Stack } from "@mui/system";
import { Alert, Button } from "@mui/material";

// My components imports
import MyMenuComponent from "./myMenuComponent";
import MyPaper from "../basic/myPaper.js";
import GenericFilter from "../filters/genericFilter";
import { urls } from "../../settings.js"
import { getCardMovements, getCards, getCategories, getCurrencies, getExpenses, getMovements, getUserAccounts } from "../../utils/api";
import { emptyFilterExpensesAndIncome } from "../../utils/constants";
import ExpenseIncomeGraphs from "../basic/expenseIncomeGraphs.js";
import { deepObjectCopy } from "../../utils/misc";
import MyLoadingList from "../basic/myLoadingList";
import NewMovementList from "../lists/newMovementList";
import { useUserProfile } from "../../utils/userProfile";


function VisualizeExpenses(props) {

  // State constants
  const { profile } = useUserProfile();
  const [accounts, setAccounts] = useState([]);
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currentQueryObject, setCurrentQueryObject] = useState(emptyFilterExpensesAndIncome);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNewCurrency, setLoadingNewCurrency] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [data, setData] = useState(null);
  const [movementsToDisplay, setMovementsToDisplay] = useState([]);
  const [movementsQueryObject, setMovementsQueryObject] = useState(null);
  const [loadingMovementList, setLoadingMovementList] = useState(false);
  const [showMovementList, setShowMovementList] = useState(false);
  const [initializingFilters, setInitializingFilters] = useState(true);


  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    Promise.all([getUserAccounts(), getCards(), getCategories(), getCurrencies()])
      .then(responses => {
        if (isMounted) {
          setAccounts(responses[0].results);
          setCards(responses[1].results);
          setCategories(responses[2].results);
          setCurrencies(responses[3].results);
          setInitializingFilters(false);
        }
      })
      .catch(err => console.log(err))
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (ready && isMounted) {
      getExpenses(currentQueryObject)
        .then(response => {
          setData(response.results[0]);
          setLoading(false);
          setLoadingNewCurrency(false);
        })
        .catch(err => console.log(err))
    }
    return () => { isMounted = false }
  }, [currentQueryObject, ready])

  useEffect(() => {
    let isMounted = true;
    if (ready) {
      setShowFilter(false);
    }
    return () => { isMounted = false }
  }, [data, ready])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoadingMovementList(false);
    }
    return () => { isMounted = false }
  }, [movementsToDisplay])

  // Handlers
  const handleFilterSubmit = queryObject => {
    setLoading(true);
    let newQueryObject = deepObjectCopy(queryObject);
    newQueryObject.currency = currencies.find(curr => curr.nombre_corto === 'UYU');
    setCurrentQueryObject(newQueryObject);
    setReady(true);
  }

  const handleCurrencyChange = newCurrency => {
    setLoadingNewCurrency(true);
    let newQueryObject = deepObjectCopy(currentQueryObject);
    newQueryObject.currency = newCurrency;
    setCurrentQueryObject(newQueryObject);
  }

  const handleBackToTable = () => {
    setShowMovementList(false);
    setMovementsToDisplay([]);
    setMovementsQueryObject(null);
  }

  const handleFilterAgain = () => {
    setShowFilter(true);
    setReady(false);
    setData(null);
    handleBackToTable();
  }

  const handleRowClick = async queryObject => {
    setLoadingMovementList(true);
    setShowMovementList(true);
    let movements = [];
    let cardMovements = [];
    if (queryObject.cashCredit === 'both' || queryObject.cashCredit === 'cash') {
      const movementsQueryResponse = await getMovements(queryObject)
      movements = movementsQueryResponse.results;
    }
    if (queryObject.cashCredit === 'both' || queryObject.cashCredit === 'credit') {
      const cardMovementsQueryResponse = await getCardMovements(queryObject);
      cardMovements = cardMovementsQueryResponse.results;
    }
    const mixedMovements = movements.concat(cardMovements);
    setMovementsToDisplay(mixedMovements);
    setMovementsQueryObject(queryObject);
  }

  return <MyMenuComponent
    links={[
      { url: urls.homeUrl, name: 'Inicio' },
      { url: urls.visualizationsUrl, name: 'Analizar' },
    ]}
    currentPageName={'Gastos e Ingresos'}
  >
    <>
      {showFilter ?
        (initializingFilters ? <MyPaper><MyLoadingList /></MyPaper> :
          <GenericFilter
            initialValues={currentQueryObject}
            accounts={accounts}
            cards={cards}
            categories={categories}
            onSearchStarted={handleFilterSubmit}
            excludeTransfers={true}
            dateRangeHelperValues={["hoy", "ayer", "inicioDeMes", "mesPasado"]}
          />)
        :
        <Stack spacing={1}>
          <MyPaper>
            <Stack spacing={0}>
              <Button onClick={handleFilterAgain} variant="contained" fullWidth sx={{ marginBottom: "10px" }}>Volver a filtrar</Button>
              {
                showMovementList ?
                  <Button
                    onClick={handleBackToTable}
                    variant="contained"
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                  >
                    Volver a la tabla
                  </Button>
                  :
                  null
              }
            </Stack>
          </MyPaper>
          {data ?
            (
              showMovementList ?
                (loadingMovementList ?
                  <MyLoadingList /> :
                  <NewMovementList
                    movements={movementsToDisplay}
                    updateList={() => handleRowClick(movementsQueryObject)}
                    cards={cards}
                    accounts={accounts}
                    categories={categories}
                    compact={profile.tipo_de_lista === 'ValidListTypes.compact'}
                  />
                ) :
                <ExpenseIncomeGraphs
                  data={data}
                  currencies={currencies}
                  loading={loadingNewCurrency}
                  onCurrencyChange={handleCurrencyChange}
                  queryObject={currentQueryObject}
                  onRowClick={handleRowClick}
                />
            ) :
            <MyPaper>
              {loading ? <MyLoadingList /> : <Alert severity="info">No hay ni gastos ni ingresos para el criterio de filtrado seleccionado</Alert>}
            </MyPaper>
          }
        </Stack>
      }
    </>
  </MyMenuComponent>
}

export default VisualizeExpenses;