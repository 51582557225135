// Proptypes import
import PropTypes from "prop-types";

// MUI components imports
import { Autocomplete, TextField } from "@mui/material";

// My components imports
import { agendaPropType } from "../../utils/myPropTypes";

function AgendaInput(props) {

  // State constants
  const { agendas, selectedAgenda, onChange } = props;

  return <Autocomplete
    value={selectedAgenda}
    onChange={(event, newValue) => {
      onChange(newValue);
    }}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    options={agendas}
    getOptionLabel={(option) => option.nombre}
    renderInput={(params) => <TextField {...params} label="Agenda" />}
  />;

}

AgendaInput.propTypes = {
  agendas: PropTypes.arrayOf(agendaPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedAgenda: agendaPropType,
};

export default AgendaInput;