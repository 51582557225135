// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";

// My components imports
import MyPaper from '../basic/myPaper.js';
import SortButtonGroup from "../basic/sortButtonGroup.js";
import DisplayMovementList from "./displayMovementList.js";
import { generateDeleteFunction, isExpense, sortedMovementList } from "../../utils/misc.js";
import { getRegisterObject } from "../../utils/visualizations.js";

function NewMovementList(props) {

  // Unstructure showSortBar prop deafulting to true
  const { showSortBar = true } = props;
  const { noContainer = false } = props;

  // State constants
  const [showForm, setShowForm] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [sortAscending, setSortAscending] = useState(false);
  const [movementList, setMovementList] = useState(props.movements);
  const [movementToModify, setMovementToModify] = useState(null);

  // Effect hooks
  useEffect(() => {
    setMovementList(sortedMovementList(props.movements, sortBy, sortAscending));
  }, [sortAscending, sortBy, props.movements]);

  useEffect(() => {
    let isMounted = true;
    if (movementToModify && isMounted) {
      setShowForm(true);
    }
    return () => { isMounted = false }
  }, [movementToModify])

  useEffect(() => {
    if (!showForm) {
      setMovementToModify(null);
    }
  }, [showForm])

  // Handlers
  const handleDeleteConfirmation = (mov) => {
    generateDeleteFunction(mov)(mov.id)
      .then(result => {
        // Ask the parent component to update the movement list
        props.updateList(null);
      })
      .catch(err => console.log(err));
  }

  const handleModifyMovementClick = mov => {
    setMovementToModify(mov);
  }

  const modifiedMovementHandler = mov => {
    setShowForm(false);
    props.updateList();
  }

  const contentToReturn = <Stack spacing={1}>
    <Dialog open={showForm} onClose={() => setShowForm(false)}>
      <DialogContent>
        {movementToModify ? getRegisterObject(movementToModify, {
          onSubmit: modifiedMovementHandler,
          onCancel: () => props.updateList(),
          initialValues: {
            initialMovement: movementToModify,
            initialCardMovement: movementToModify,
            initialTransfer: movementToModify,
            initialCardPayment: movementToModify,
            intent: "modify",
          },
          cards: props.cards,
          isExpense: isExpense(movementToModify),
          accounts: props.accounts,
          categories: props.categories,
        }) : <></>}
      </DialogContent>
    </Dialog>
    <Stack>

      {props.movements.length === 0 ?
        <Typography variant="overline">No hay ningún movimiento para mostrar</Typography> :
        <>
          {showSortBar &&
            <SortButtonGroup
              onChange={sortObject => {
                setSortBy(sortObject.sortBy);
                setSortAscending(sortObject.sortAscending);
              }}
              initialSortBy={sortBy}
              initiallySortAscending={sortAscending}
            />}
          <DisplayMovementList
            movementList={movementList}
            onDelete={handleDeleteConfirmation}
            onModify={handleModifyMovementClick}
            pointOfView={props.pointOfView}
            showInstallment={props.showInstallment}
            compact={props.compact ? props.compact : false}
          />
        </>
      }

    </Stack>

  </Stack>
  return noContainer ? <>{contentToReturn}</> : <MyPaper sx={{ padding: "10px" }}>{contentToReturn}</MyPaper>

}

NewMovementList.propTypes = {
  movements: PropTypes.arrayOf(PropTypes.object).isRequired, // List of movements to be diplayed
  updateList: PropTypes.func.isRequired, // Function to be called when a the list needs to be updated
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available cards
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available accounts
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available categories
  pointOfView: PropTypes.object, // Account object that determines from which perspective to show transfer or currency exchange
  showInstallment: PropTypes.bool, // Indicates whether the installment number should be shown on MovimientoTarjeta
  compact: PropTypes.bool, // If true, show compact version of the list, otherwise show classic version. Default is false
  showSortBar: PropTypes.bool, // If true, show sort bar. Default is true
  noContainer: PropTypes.bool, // If true, don't MyPaper container. Default is false
}

export default NewMovementList;
