// Purpose: A custom paper component that wraps the MUI Paper component and adds some custom styling.

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Paper } from "@mui/material";
import { styled } from '@mui/system';

function MyPaper(props) {

  const ObjectToReturn = styled(Paper)(({ theme }) => ({
    padding: '10px',
    maxWidth: '600px',
    width: '90vw',
    marginBottom: '5px !important',
    // margin: '10px',
  }));

  return <ObjectToReturn elevation={props.shallow ? 0 : 1}>
    {props.children}
  </ObjectToReturn>;

}

MyPaper.propTypes = {
  shallow: PropTypes.bool,  // If true, the elevation is 0
};


export default MyPaper;