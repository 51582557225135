// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import { Typography, Button, Alert, Stack } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// My components imports
import { urls } from "../../settings.js";
import MyPaper from "../basic/myPaper.js";

function CardOrAccountError(textComponent) {
  // Constants
  const history = useHistory();

  return <MyPaper>
    <Alert severity="info">
      <Stack spacing={1}>

        {textComponent}

        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={() => history.push(urls.configureCustodyAgentUrl)}
          sx={{ padding: "5px" }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <AccountBalanceIcon />
            <Typography>Ir a lugares de custodia</Typography>
          </Stack>
        </Button>
      </Stack>
    </Alert>
  </MyPaper>
}

export function CardError() {
  return CardOrAccountError(
    <Stack>
      <Typography variant="body1">
        Para poder registrar un gasto o ingreso a crédito debes primero tener una tarjeta de crédito configurada.
        Crea una tarjeta dentro alguno de tus lugares de custodia del tipo "banco" o "corredor de bolsa", si aún no tienes ningún lugar de custodia de este tipo, crea uno previamente.
      </Typography>
    </Stack>
  );
}

export function AccountError(props) {
  return CardOrAccountError(
    <Typography variant="body1" sx={{ marginTop: "10px" }}>
      Para poder registrar un gasto o ingreso al contado debes primero tener una cuenta de débito configurada.
      Crea una cuenta dentro alguno de tus lugares de custodia del tipo "banco" o "corredor de bolsa", si aún no tienes ningún lugar de custodia de este tipo, crea uno previamente.
    </Typography>
  );
}

export function TransferError() {
  return CardOrAccountError(
    <Typography variant="body1" sx={{ marginTop: "10px" }}>
      Para poder registrar una transferencia necesitas tener al menos dos cuentas con la misma moneda.
      Para poder ingresar la transferencia ve a luegares de custodia y crea las cuentas que necesitas.
    </Typography>
  );
}

export function CurrencyExchangeError() {
  return CardOrAccountError(
    <Typography variant="body1" sx={{ marginTop: "10px" }}>
      Para poder registrar un cambio de moneda necesitas tener al menos dos cuentas con monedas diferentes.
      Para poder ingresar el cambio de moneda ve a luegares de custodia y crea las cuentas que necesitas.
    </Typography>
  );
}