// React imports
import { useState, useEffect } from "react";

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack, FormControl, ButtonGroup, Button, Switch, Typography } from "@mui/material";

// My components imports
import LabelInput from "../inputs/labelInput";
import CuentaPicker from "../inputs/cuentaPicker";
import TarjetaPicker from "../inputs/tarjetaPicker.js";
import CategoriaPicker from "../inputs/categoriaPicker";
import EasyDateRangePicker from "../inputs/easyDateRangePicker";
import MyPaper from "../basic/myPaper.js";
import MyDialog from "../basic/MyDialog.js";
import ExpenseIncomeTransferPicker from "../inputs/expenseIncomeTransferPicker.js";
import CommentInput from "../inputs/commentInput.js";
import { labelPropType, categoryPropType, accountPropType, cardPropType } from "../../utils/myPropTypes";

function defineInitialMovementTypes(initialValues) {
  let movementTypes = [];
  // For this filter, both labels (income and expenses) present are represented by 'amountGte' and 'amountLte' equal to 0 plus 'filterAmountWithAnd' equal to false
  // Both labels (income and expenses) absent are represented by 'amountGte' and 'amountLte' equal to 0 plus 'filterAmountWithAnd' equal to true
  // Only income present is represented by 'amountGte' equal to 0 and 'amountLte' being absent and 'filterAmountWithAnd' equal to false
  // Only expenses present is represented by 'amountLte' equal to 0 and 'amountGte' being absent and 'filterAmountWithAnd' equal to false
  if (initialValues.hasOwnProperty('amountGte') && initialValues.hasOwnProperty('amountLte') && initialValues.amountGte === 0 && initialValues.amountLte === 0 && !initialValues.filterAmountWithAnd) {
    movementTypes.push('income');
    movementTypes.push('expenses');
  }
  if (initialValues.hasOwnProperty('amountGte') && initialValues.hasOwnProperty('amountLte') && initialValues.amountGte === 0 && initialValues.amountLte === 0 && initialValues.filterAmountWithAnd) {
    // Do nothing
  }
  if (initialValues.hasOwnProperty('amountGte') && !initialValues.hasOwnProperty('amountLte') && initialValues.amountGte === 0 && !initialValues.filterAmountWithAnd) {
    movementTypes.push('income');
  }
  if (!initialValues.hasOwnProperty('amountGte') && initialValues.hasOwnProperty('amountLte') && initialValues.amountLte === 0 && !initialValues.filterAmountWithAnd) {
    movementTypes.push('expenses');
  }
  // Include transfers and exchanges
  if (initialValues.hasOwnProperty('onlyTransfers') && initialValues.onlyTransfers) {
    movementTypes.push('transfers');
  }
  if (initialValues.hasOwnProperty('onlyCurrencyExchanges') && initialValues.onlyCurrencyExchanges) {
    movementTypes.push('exchanges');
  }
  return movementTypes;
}


function GenericFilter(props) {

  // State constants
  const [labels, setLabels] = useState(props.initialValues.labels);
  const [categories, setCategories] = useState(props.initialValues.categories);
  const [accounts, setAccounts] = useState(props.initialValues.accounts);
  const [cards, setCards] = useState(props.initialValues.cards);
  const [initialDate, setInitialDate] = useState(props.initialValues.initialDate);
  const [finalDate, setFinalDate] = useState(props.initialValues.finalDate);
  const [cashCredit, setCashCredit] = useState(props.initialValues.cashCredit);
  const [filterLabelsWithAnd, setFilterLabelsWithAnd] = useState(props.initialValues.filterLabelsWithAnd);
  const [labelsDialogOpen, setLabelsDialogOpen] = useState(false);
  const [movementTypes, setMovementTypes] = useState(defineInitialMovementTypes(props.initialValues));
  const [commentContains, setCommentContains] = useState(props.initialValues.commentContains);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted && cashCredit === 'credit') {
      setMovementTypes(prev => prev.filter(item => item !== 'transfers' && item !== 'exchanges'));
    }
    return () => { isMounted = false };
  }, [cashCredit]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (cashCredit === 'credit') {
        setMovementTypes(defineInitialMovementTypes(props.initialValues).filter(item => item !== 'transfers' && item !== 'exchanges'));
      } else {
        setMovementTypes(defineInitialMovementTypes(props.initialValues));
      }
    }
    return () => { isMounted = false };
  }, [props.initialValues, cashCredit]);

  // Handlers
  const handleSearchClick = () => {
    let objToReturn = {
      // Dates to filter, are in format YYYY-MM-DD
      initialDate: initialDate,
      finalDate: finalDate,
      // Selector to filter by cash, credit or both
      cashCredit: cashCredit,
      // Selector to filter by movement type
      onlyTransfers: movementTypes.includes('transfers') ? true : false,
      onlyCurrencyExchanges: movementTypes.includes('exchanges') ? true : false,
      // Selector to filter by account
      accounts: accounts,
      srcAccounts: accounts,
      dstAccounts: accounts,
      // Selector to filter by card
      cards: cards,
      // Selector to filter by category
      categories: categories,
      // Selectors to filter by label
      labels: labels,
      filterLabelsWithAnd: filterLabelsWithAnd,
      // Selectors to filter by comment
      commentContains: commentContains,
    };
    if (movementTypes.includes('income') && movementTypes.includes('expenses')) {
      objToReturn.amountGte = 0;
      objToReturn.amountLte = 0;
      objToReturn.filterAmountWithAnd = false;
    } else if (movementTypes.includes('income')) {
      objToReturn.amountGte = 0;
      objToReturn.filterAmountWithAnd = false;
    } else if (movementTypes.includes('expenses')) {
      objToReturn.amountLte = 0;
      objToReturn.filterAmountWithAnd = false;
    } else {
      objToReturn.amountGte = 0;
      objToReturn.amountLte = 0;
      objToReturn.filterAmountWithAnd = true;
    }
    props.onSearchStarted(objToReturn);
  };

  const handleDateRangeChange = (initDate, finDate) => {
    setInitialDate(initDate);
    setFinalDate(finDate);
  }

  return <MyPaper>
    <Stack direction="column" spacing={1}>

      <FormControl fullWidth>
        <EasyDateRangePicker
          initialLabel="Fecha inicial"
          finalLabel="Fecha final"
          initialValues={{ initialDate: initialDate, finalDate: finalDate }}
          strict={false}
          helperValues={props.dateRangeHelperValues ? props.dateRangeHelperValues : ['hoy', 'ayer', 'inicioDeMes', 'inicioDeAno']}
          onChange={handleDateRangeChange}
        />
      </FormControl>

      {!props.hideCashCreditSelector ?
        <FormControl fullWidth>
          <ButtonGroup size="small">
            <Button
              variant={cashCredit === 'cash' ? "contained" : "outlined"}
              onClick={() => setCashCredit('cash')}
              size="small"
              sx={{ display: "flex", flexGrow: 1, width: "33%" }}
            >Contado</Button>
            <Button
              variant={cashCredit === 'credit' ? "contained" : "outlined"}
              onClick={() => setCashCredit('credit')}
              size="small"
              sx={{ display: "flex", flexGrow: 1, width: "33%" }}
            >Crédito</Button>
            <Button
              variant={cashCredit === 'both' ? "contained" : "outlined"}
              onClick={() => setCashCredit('both')}
              size="small"
              sx={{ display: "flex", flexGrow: 1, width: "33%" }}
            >Ambos</Button>
          </ButtonGroup>
        </FormControl> : <></>
      }

      <FormControl fullWidth>
        <ExpenseIncomeTransferPicker
          initialValues={movementTypes}
          onChange={values => setMovementTypes(values)}
          includeTransfers={props.excludeTransfers ? false : (cashCredit === 'credit' ? false : true)}
          multiple={true}
        />
      </FormControl>

      {(cashCredit === 'both' || cashCredit === 'cash') ?
        <FormControl fullWidth>
          <CuentaPicker
            accounts={props.accounts}
            onChange={selectedAccounts => setAccounts(selectedAccounts)}
            initialValues={accounts}
            multiple
          />
        </FormControl> :
        <></>
      }

      {((cashCredit === 'both' && !props.hideCardSelector) || (cashCredit === 'credit' && !props.hideCardSelector)) ?
        <FormControl fullWidth>
          <TarjetaPicker
            cards={props.cards}
            onChange={selectedCards => setCards(selectedCards)}
            initialValues={cards}
          />
        </FormControl> :
        <></>
      }

      <FormControl fullWidth>
        <CategoriaPicker
          categories={props.categories}
          onChange={selectedCategories => setCategories(selectedCategories)}
          initialValues={categories}
        />
      </FormControl>

      <FormControl fullWidth>
        <LabelInput
          initialLabels={labels}
          onChange={(selectedLabels) => setLabels(selectedLabels)}
          hideAddButton
        />
      </FormControl>

      <FormControl fullWidth>
        <Stack direction="row" spacing={1}>
          <Stack justifyContent="center">
            <MyDialog onClose={() => setLabelsDialogOpen(false)} open={labelsDialogOpen} onOpen={() => setLabelsDialogOpen(true)}>
              Al activar este switch la búsqueda incluirá únicamente aquellos movimientos
              que tengan todas las etiquetas seleccionadas a la vez
            </MyDialog>
          </Stack>
          <Stack justifyContent="center" flexGrow={1}>
            <Typography>Debe tener todas las etiquetas</Typography>
          </Stack>
          <Stack justifyContent="center">
            <Switch checked={filterLabelsWithAnd} onChange={() => setFilterLabelsWithAnd(prev => !prev)} />
          </Stack>
        </Stack>
      </FormControl>


      <FormControl fullWidth>
        <CommentInput
          initialComment={commentContains}
          onChange={comment => setCommentContains(comment)}
        />
      </FormControl>

      <Button variant="contained" onClick={handleSearchClick} fullWidth>Buscar</Button>
    </Stack>
  </MyPaper>

}

GenericFilter.propTypes = {
  initialValues: PropTypes.shape({
    initialDate: PropTypes.string,  // Initial date to initialize filter, format YYYY-MM-DD
    finalDate: PropTypes.string,  // Final date to initialize filter, format YYYY-MM-DD
    accounts: PropTypes.arrayOf(accountPropType), // Accounts to initialize filter
    cards: PropTypes.arrayOf(cardPropType), // Cards to initialize filter
    categories: PropTypes.arrayOf(categoryPropType), // Categories to initialize filter
    labels: PropTypes.arrayOf(labelPropType),
    filterLabelsWithAnd: PropTypes.bool, // If true, the search will only include movements that have all the labels selected
    cashCredit: PropTypes.oneOf(['cash', 'credit', 'both']), // If 'cash', the search will only include movements that are cash, if 'credit', the search will only include movements that are credit, if 'both', the search will include both cash and credit movements
    onlyTransfers: PropTypes.bool, // If true, the search will only include transfers
    onlyCurrencyExchanges: PropTypes.bool, // If true, the search will only include currency exchanges
    amountLte: PropTypes.number, // If present, the search will only include movements with amount less than or equal to this value
    amountGte: PropTypes.number, // If present, the search will only include movements with amount greater than or equal to this value
    filterAmountWithAnd: PropTypes.bool, // If true, the search will only include movements that have both 'amountLte' and 'amountGte' present
    commentContains: PropTypes.string, // If present, the search will only include movements that have this string in their comment
    labelsDialogOpen: PropTypes.bool, // If true, the dialog explaining the 'filterLabelsWithAnd' switch will be open
  }), // Values to initialize filter
  onSearchStarted: PropTypes.func.isRequired, // Function to be called when user click to start searching
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired, // List of avialable accounts
  cards: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available cards
  categories: PropTypes.arrayOf(PropTypes.object).isRequired, // List of available categories
  excludeTransfers: PropTypes.bool, // If true, the filter will not include neither transfers nor currency exchanges
  dateRangeHelperValues: PropTypes.arrayOf(PropTypes.string), // If present, the date range picker will include the helper values passed, default is ['hoy', 'ayer', 'inicioDeMes', 'inicioDeAno']
  hideCashCreditSelector: PropTypes.bool, // If true, the cash/credit selector will not be shown. 
  hideCardSelector: PropTypes.bool, // If true, the card selector will not be shown.
}

export default GenericFilter;