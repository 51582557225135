// React Router imports
import { useHistory } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PaidIcon from '@mui/icons-material/Paid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// My components imports
import { urls } from "../../settings.js";
import MyPaper from "../basic/myPaper";
import MyMenuComponent from "./myMenuComponent.js";
import MyListItem from "../basic/myListItem.js";


function DataRegistrations(props) {

  // Other constants
  const history = useHistory();

  // State hooks

  // Change handlers

  return <div>
    <MyMenuComponent
      links={[
        { url: urls.homeUrl, name: 'Inicio' }
      ]}
      currentPageName={"Registrar"}
    >
      <Stack direction="column" alignItems="center">
        <MyPaper>
          <Stack spacing={1}>
            <List >
              <MyListItem onClick={event => history.push(urls.registerExpenseUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <ShoppingCartIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Gasto" secondary="Registra un gasto" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.registerIncomeUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <PaidIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Ingreso" secondary="Registra un ingreso" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.registerTransferUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <ChangeCircleIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Transferencia" secondary="Registra una transferencia" />
              </MyListItem>

              <MyListItem onClick={event => history.push(urls.registerCurrencyExchangeUrl)}>
                <ListItemAvatar>
                  <Avatar>
                    <CurrencyExchangeIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Cambio de moneda" secondary="Compra o vende monedas" />
              </MyListItem>

            </List>
          </Stack>
        </MyPaper>
      </Stack>
    </MyMenuComponent>


  </div>
}

export default DataRegistrations;