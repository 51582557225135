// PropTypes imports
import PropTypes from 'prop-types';

// My component imports
import MyLoadingList from '../basic/myLoadingList';
import CustodyAgentForm from '../forms/custodyAgentForm';
import { createCustodyAgent, modifyCustodyAgent } from '../../utils/api';


function CreateModifyCustodyAgent(props) {

  // Handlers
  const submitHandler = data => {
    if (props.initialValues.intent === 'create') {
      createCustodyAgent(data.nombre, data.tipo.id, data.pais.id)
        .then(result => props.onSubmit(result))
        .catch(err => console.log(err))
    } else {
      console.log(data);
      modifyCustodyAgent(data.nombre, data.tipo.id, data.pais.id, data.id)
        .then(result => props.onSubmit(result))
        .catch(err => console.log(err))
    }
  }

  return props.custodyAgentTypes && props.custodyAgentTypes.length > 0 ?
    <CustodyAgentForm
      custodyAgent={props.initialValues.initialCustodyAgent}
      custodyAgentTypes={props.custodyAgentTypes}
      countries={props.countries}
      intent={props.initialValues.intent}
      onSubmit={submitHandler}
      onCancel={props.onCancel}
    /> :
    <MyLoadingList />
}

CreateModifyCustodyAgent.propTypes = {
  initialValues: PropTypes.shape({
    initialCustodyAgent: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      tipo: PropTypes.shape({
        id: PropTypes.number,
        nombre: PropTypes.string,
      }),
      pais: PropTypes.object,
    }),
    intent: PropTypes.oneOf(["create", "modify"]),
  }),
  onSubmit: PropTypes.func.isRequired,
  oncancel: PropTypes.func.isRequired,
  custodyAgentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CreateModifyCustodyAgent;